<template lang="pug">
extends SurveyStepTemplate.pug
block input
    <div class="survey-pain-scale-step">
        <div style="display: flex;">
            <div class="survey-pain-scale-step__slider__minmax">{{ minValue }}</div>
            <v-app class="survey-pain-scale-step__slider__slider">
                <v-slider class="survey-pain-scale-step__slider__vslider" :step="1" v-model="value" :min="step.sliderMinValue" :max="maxValue" track-color="#c0c0c0" />
            </v-app>
            <div class="survey-pain-scale-step__slider__minmax">{{ maxValue }}</div>
        </div>
        <div class="survey-pain-scale-step__footer__value"><span>{{ displayValue }}</span></div>
    </div>
</template>

<script>
import SurveySliderMixin from '@mixins/SurveySliderMixin'
import SurveyStepMixin from '@mixins/SurveyStepMixin'

export default {
    name: 'SurveyPainScaleStep',
    mixins: [SurveySliderMixin, SurveyStepMixin],
    created() {
        this.minValue = 0
        this.maxValue = 10
        this.init()
    }
}
</script>
