import store from '@/store'

/**
 * An Activity object.
 */
class Activity {
    constructor(object) {
        this.slug = object.slug
        this.scheduleSlug = object.scheduleSlug
        this.contentSlug = object.contentSlug
        this.needsReview = !!object.needsReview
        this.order = object.order || 0
        this.group = object.group
        this.redoType = object.redoType || Activity.RedoType.noneAndShow

        this.side = this.getBilateralSide()
        // To assist with post-reg filtering
        this.isPostRegClinicalSurvey = !!object.isPostRegClinicalSurvey
    }

    get content() {
        return store.state.content.content[this.contentSlug]
    }
    containsTag(tag) {
        return !!this.content?.containsTag(tag)
    }

    get canRedo() {
        return this.redoType !== Activity.RedoType.noneAndHide && this.redoType !== Activity.RedoType.noneAndShow
    }

    // If journey is bilateral, and Activity specifies a side, return the side, else undefined.
    // TODO: Once we have Journey.isBilateral, we can also add that condition, which is a belt-and-braces.
    getBilateralSide() {
        if (this.slug?.endsWith('-left')) {
            return Activity.Side.left
        }
        if (this.slug?.endsWith('-right')) {
            return Activity.Side.right
        }

        return Activity.Side.none
    }
}

Activity.RedoType = {
    noneAndShow: 'noneAndShow', // disallow redo, show when complete
    noneAndHide: 'noneAndHide', // disallow redo, hide when complete
    modify: 'modify', // allow redo, modify existing result, show when complete. WARNING: TALK TO BACKEND TEAM BEFORE USING THIS!
    append: 'append', // allow redo, create new result, show when complete
    repeatUntilComplete: 'repeatUntilComplete' // for Schedule of type repeatingRelative, forbid any more repeats once there is any Result for this activity with status complete
}
Activity.Side = {
    none: 'none',
    left: 'left',
    right: 'right'
}
export default Activity
