<template>
    <PortalRegistrationLayout :show-clinician-assets="showClinicianAssets">
        <template #title>
            {{ title || lang('patientRegLoginTitle') }}
        </template>

        <template #text>
            {{ text || lang('patientRegLoginText') }}
        </template>

        <template #form>
            <Form
                ref="loginForm"
                tag="form"
                class="portal-registration-card__form mr-form"
                @submit="validateAndLogin()"
            >
                <!-- Username (email or phone) -->
                <validated-input
                    name="username"
                    :label="lang('formUsername')"
                    :placeholder="lang('patientRegUsernamePlaceholder')"
                    rules="required|username"
                    field-wrapper-class="mr-form__field"
                    v-model="form.username"
                />
                <!-- Password -->
                <validated-input
                    name="password"
                    :label="lang('formPassword')"
                    :placeholder="lang('formPasswordPlaceholder')"
                    rules="required|no_spaces|password_standard"
                    type="password"
                    field-wrapper-class="mr-form__field"
                    v-model="form.password"
                />
                <!-- Forgot password -->
                <slot name="reset-password">
                    <router-link
                        :to="{ name: 'ResetPassword' }"
                        class="mr-link"
                    >
                        {{ lang('loginForgotPassword') }}
                    </router-link>
                </slot>

                <div class="portal-registration-card__btn-group">
                    <button
                        v-if="isStartingAtFrontPage"
                        id="back"
                        type="button"
                        class="mr-btn mr-btn--secondary"
                        @click="onBack"
                    >
                        {{ lang('back') }}
                    </button>
                    <button
                        id="login"
                        type="submit"
                        class="mr-btn mr-btn--primary"
                    >
                        {{ lang('login') }}
                    </button>

                    <slot name="buttons" />
                </div>
            </Form>
        </template>

        <template #footer>
            <component
                v-if="!!popupClass"
                :is="popupClass"
                :config="popupConfig"
            />
        </template>
    </PortalRegistrationLayout>
</template>

<script>
import _ from 'lodash'
import Analytics from '@serv/Analytics'
import Auth from '@serv/Auth'
import { Form } from 'vee-validate'
import { lang } from '@comp/Filters'
import LegacyBrowserMixin from '@mixins/LegacyBrowserMixin'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import LoginService from '@serv/LoginService'
import { mapState } from 'vuex'
import NotifyMsg from '@comp/NotifyMsg.vue'
import NotifyService from '@serv/NotifyService'
import PopupOneButton from '@comp/popups/PopupOneButton.vue'
import PortalRegistrationLayout from '@layouts/portal/PortalRegistrationLayout.vue'
import PortalRegistrationMixin from '@mixins/PortalRegistrationMixin'
import Redirect from '@serv/Redirect'
import RegistrationService from '@serv/RegistrationService'
import StringHelper from '@serv/StringHelper'
import User from '@model/User'
import ValidationMixin from '@mixins/ValidationMixin'
import Whoami from '@serv/Whoami'

export default {
    name: 'PortalRegistrationLogin',
    mixins: [LegacyBrowserMixin, PortalRegistrationMixin, ValidationMixin],
    components: {
        PortalRegistrationLayout,
        Form,
        NotifyMsg,
        PopupOneButton
    },
    inject: ['$bus', '$config'],
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        showClinicianAssets: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {},
            isFailed: false
        }
    },
    computed: {
        ...mapState({
            bannerUrl: state => state.portalRegistration.bannerUrl,
            logoUrl: state => state.portalRegistration.logoUrl,
            clinician: state => state.portalRegistration.clinician
        }),
        nextStepRequiredResources() {
            return {
                content: this.content,
                journeys: this.journeys
            }
        }
    },
    methods: {
        lang,
        validateAndLogin() {
            this.$refs.loginForm.validate().then(success => {
                if (success) {
                    this.isLoading = true

                    const username = StringHelper.removeWhitespace(
                        this.form.username
                    ).toLowerCase()
                    const usernameType = StringHelper.isValidEmail(username)
                        ? 'email'
                        : 'phone'
                    Analytics.sendEvent('patientLogin', {
                        usernameType: usernameType
                    })

                    return Auth.doAuth(username, this.form.password)
                        .then(
                            () => {
                                this.$bus.$emit('login')

                                // Get user object
                                Whoami.getNavigation()
                                    .then(() => {
                                        this.isLoading = false

                                        // If user is not a patient, error and return to dashboard root
                                        if (this.user.persona != User.Persona.patient) {
                                            NotifyService.error(
                                                Locale.getLanguageItem(
                                                    'loginUnauthorisedFromFrontEnd'
                                                ),
                                                1000
                                            )

                                            LoginService.logout().then(() => {
                                                Redirect.gotoName('Login')

                                                return
                                            })
                                        }
                                        NotifyService.success(
                                            Locale.getLanguageItem(
                                                'accountLogInSuccess'
                                            ),
                                            400
                                        )
                                        Analytics.sendEvent('patientLoginResponse', {
                                            usernameType: usernameType,
                                            resultCode: 200
                                        })

                                        // Move to Terms or Portal page
                                        if (!this.content) {
                                            RegistrationService.ensureJourneysContentTeamsUsers()
                                        } else {
                                            RegistrationService.nextStep()
                                        }
                                    })
                                    .catch(error => {
                                        this.isLoading = false

                                        Logging.log(error)
                                    })
                            },
                            errorCollection => {
                                this.isLoading = false

                                // Check for account lockout
                                const contains403 = _.filter(
                                    errorCollection,
                                    error =>
                                        error?.response && error.response.status == 403
                                )
                                if (contains403.length > 0) {
                                    // Account locked out
                                    const text = contains403[0]?.response.data.detail
                                    NotifyService.error(text)
                                    Analytics.sendEvent('patientLoginResponse', {
                                        usernameType: usernameType,
                                        resultCode: 403,
                                        resultText: text
                                    })
                                } else {
                                    // Other error
                                    const text = Locale.getLanguageItem(
                                        'accountLogInFailure'
                                    )
                                    if (text) {
                                        NotifyService.error(text)
                                    }
                                    const status = Array.isArray(errorCollection)
                                        ? errorCollection[0].response.status
                                        : errorCollection.response.status
                                    Analytics.sendEvent('patientLoginResponse', {
                                        usernameType: usernameType,
                                        resultCode: status,
                                        resultText: text
                                    })
                                }
                            }
                        )
                        .catch(error => {
                            this.isLoading = false

                            Logging.log(error)
                        })
                }
            })
        },
        onBack() {
            Redirect.router.back()
        }
    },
    created() {
        this.$store.commit('setIsWebRegistration', true)
        if (this.user) {
            RegistrationService.initFromRouteName()
        }
    },
    watch: {
        user(newValue, oldValue) {
            if (newValue && !oldValue) {
                RegistrationService.initFromRouteName()
            }
        },
        nextStepRequiredResources(newValue) {
            if (newValue.content && newValue.journeys) {
                RegistrationService.nextStep()
            }
        }
    }
}
</script>
