import Locale from '@serv/Locale.js'

export function _resolvePatientJourneyListColumnOfType_rtmStatus(column, patientJourney, row) {
    const rtmStatus = row[column.id]?.value

    if (column.hasExplicitValue && rtmStatus) {
        column.setExplicitValueConfig(rtmStatus)
    } else {
        column.cellText = rtmStatus ? Locale.getLanguageItem(rtmStatus) : ''
    }
}
