import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        isLoaded: {},
        isLoadedLogging: false, // set by component for verbose logging
        requiredState: [], // set by component
        allLoaded: false // set here when all requiredState loaded
    }),
    computed: {
        ...mapGetters([
            'departments',
            'hasContent',
            'hasJourneys',
            'hasPatients',
            'hasPatientJourneyListResponse',
            'hasPracticeSurveyResults',
            'owner',
            'providers',
            'reports',
            'surgeons',
            'teams',
            'user',
            'users'
        ]),
        pageFooterText() {
            const pageFooterText = this.owner.keyValues.stringMap.pageFooterText
            if (pageFooterText && pageFooterText[this.user.locale]) {
                return pageFooterText[this.user.locale]
            }

            return Locale.getLanguageItem('pageFooterText')
        }
    },
    methods: {
        updateAllLoaded() {
            let loaded = true
            this.requiredState.forEach(key => {
                loaded = loaded && this.isLoaded[key] == true
            })
            this.allLoaded = loaded
            if (this.isLoadedLogging) {
                Logging.log(`${this.$options.name} requiredState: ${this.requiredState}`)
                Logging.log(`${this.$options.name} isLoaded: ${JSON.stringify(this.isLoaded, null, 2)}`)
                Logging.log(`${this.$options.name} allLoaded: ${this.allLoaded}`)
            }
        }
    },
    mounted() {
        // Initialise isLoaded from loaded status of all store keys
        this.isLoaded.departments = this.departments != undefined
        this.isLoaded.content = this.hasContent
        this.isLoaded.journeys = this.hasJourneys
        this.isLoaded.owner = this.owner != undefined
        this.isLoaded.patients = this.hasPatients
        this.isLoaded.hasPatientJourneyListResponse = this.hasPatientJourneyListResponse
        this.isLoaded.practiceSurveyResults = this.hasPracticeSurveyResults
        this.isLoaded.providers = this.providers != undefined
        this.isLoaded.reports = this.reports != undefined
        this.isLoaded.surgeons = this.surgeons != undefined
        this.isLoaded.teams = this.teams != undefined
        this.isLoaded.user = this.user != undefined
        this.isLoaded.users = this.users != undefined
        this.updateAllLoaded()
    },
    watch: {
        departments() {
            this.isLoaded.departments = true
            this.updateAllLoaded()
        },
        hasContent() {
            this.isLoaded.content = true
            this.updateAllLoaded()
        },
        hasJourneys() {
            this.isLoaded.journeys = true
            this.updateAllLoaded()
        },
        hasPatients() {
            this.isLoaded.patients = true
            this.updateAllLoaded()
        },
        hasPatientJourneyListResponse(value) {
            this.isLoaded.hasPatientJourneyListResponse = value
            this.updateAllLoaded()
        },
        hasPracticeSurveyResults() {
            this.isLoaded.practiceSurveyResults = true
            this.updateAllLoaded()
        },
        owner() {
            this.isLoaded.owner = true
            this.updateAllLoaded()
        },
        providers() {
            this.isLoaded.providers = true
            this.updateAllLoaded()
        },
        reports() {
            this.isLoaded.reports = true
            this.updateAllLoaded()
        },
        surgeons() {
            this.isLoaded.surgeons = true
            this.updateAllLoaded()
        },
        teams() {
            this.isLoaded.teams = true
            this.updateAllLoaded()
        },
        user() {
            this.isLoaded.user = true
            this.updateAllLoaded()
        },
        users() {
            this.isLoaded.users = true
            this.updateAllLoaded()
        }
    }
}
