import Logging from '@serv/Logging'
import store from '@/store'

/**
 * The UserService service provides helper functions for User types.
 */
class UserService {
    // Form helper, to copy fields between a form data holder, and a user object.
    copyFieldsFromObjectToObject(fields, fromObject, toObject) {
        fields.forEach(field => {
            toObject[field] = fromObject[field]
        })
    }

    // Return team from lead and provider and department (which can be undefined), or undefined if not found.
    getTeamFromLeadProviderDepartment(leadId, providerSlug, departmentSlug) {
        if (leadId && providerSlug) {
            const matchingTeams = Object.values(store.state.user.teams).filter(team => {
                return (
                    team.leadId == leadId && team.providerSlug == providerSlug && team.departmentSlug == departmentSlug
                )
            })
            if (matchingTeams.length != 1) {
                Logging.error(
                    `Could not find exactly 1 team with leadId=${leadId}, providerSlug=${providerSlug}, departmentSlug=${departmentSlug}`
                )
            } else {
                return matchingTeams[0]
            }
        }
    }

    // Return true if email is set and in anonymous format.
    isEmailAnonymous(email) {
        return email && email.endsWith('@patient.mr')
    }

    // Has identified (non-anonymous) email
    isEmailIdentified(email) {
        return email && !email.endsWith('@patient.mr')
    }

    // Extract firstName, lastName from full name
    setFirstLastNamesFromFullName(fullname, object) {
        const parts = fullname.split(' ')
        object.firstName = parts.shift()
        object.lastName = parts.join(' ')
    }

    // Get teams whose leads are transfer targets
    getTransferTargetTeams(includeLegacy) {
        const teams = Object.values(store.state.user.teams).filter(team => {
            const lead = store.state.user.teamLeads[team.leadId]

            return lead && lead.isTransferTarget && (!team.isLegacy || !includeLegacy)
        })

        return teams
    }

    isUserMemberOfProviderTeams(providerSlug) {
        return store.state.user.user.providerSlugs.includes(providerSlug)
    }
}

export default new UserService()
