import ListTransform from '@model/list/ListTransform'

/**
 * List filter for clinical milestone column.
 */
class ListTransformMessages extends ListTransform {
    /**
     * Sort the list of rows in place.
     */
    sortRows(rows) {
        const maxValue = 999999
        // Use the unreadMessageCount on the associated object in patient.listColumns
        const columnIndex = this.column.columnIndex
        rows.sort((a, b) => {
            let valueA = a.listColumns[columnIndex].value
            let valueB = b.listColumns[columnIndex].value
            valueA = valueA == undefined ? maxValue : valueA
            valueB = valueB == undefined ? maxValue : valueB

            return valueA - valueB
        })
    }

    reverse(rows) {
        const columnIndex = this.column.columnIndex
        const rowsUndefined = rows.filter(row => row.listColumns[columnIndex].value == undefined)
        const rowsDefined = rows.filter(row => row.listColumns[columnIndex].value != undefined)
        rows.length = 0
        rows.push(...rowsDefined.reverse())
        rows.push(...rowsUndefined)
    }

    getSortParameter() {
        return 'num_unread_messages'
    }
}

export default ListTransformMessages
