import BaseContent from '@model/content/BaseContent'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'

class Task extends BaseContent {
    constructor(object) {
        super(object)

        // Steps
        this.stepSlugs = []
        this.steps = []
        this.stepSlugToStep = {}
        if (object.steps != undefined) {
            this.stepSlugs = object.steps.map(obj => obj.$link)
        }
    }

    // Get keys for logging
    get logKeys() {
        return [...super.logKeys, 'steps']
    }

    get totalSteps() {
        return this.steps.length
    }

    // Called immediately after content deserialsiation, BEFORE other initial resource requests are completed.
    resolve(slugToObject) {
        super.resolve(slugToObject)

        // Steps
        this.steps = []
        this.stepSlugToStep = {}
        this.stepSlugs.forEach(slug => {
            const step = slugToObject[slug]
            if (step == undefined) {
                Logging.warn(`In Task ${this.slug}, cannot find step with slug ${slug}`)
            } else {
                step.resolve(slugToObject)
                this.steps.push(step)
                this.stepSlugToStep[slug] = step
            }
        })
        delete this.stepSlugs
    }

    // Called after ALL initial resource requests are completed.
    postProcess() {
        // Allow overriding of title using stringMap
        const surveyTitleKey = `surveyTitle-${this.slug}`
        const surveyTitledMapped = Locale.getLanguageItemOrUndefined(surveyTitleKey)
        if (surveyTitledMapped) {
            this.title = surveyTitledMapped
        }
    }

    getStepAt(stepIndex) {
        return this.steps[stepIndex]
    }
}

export default Task
