import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import Patient from '@model/Patient'
import Utils from '@serv/Utils'

const sortOffsetDoneAllSurveys = 10000
const sortOffsetNothingNext = 50000
const sortOffsetUnregistered = 100000

/**
 * Get list of qualifying (based on configuration) ScheduleEvent objects, based on “now” (Y)
 * Get list of matching complete survey results (X)
 * This gives us X/Y completed
 * IF X>=Y, display “Next available (in N days)” where N is days to schedule start date for the nearest future qualifying ScheduleEvent
 * ELSE display “Incomplete surveys (N days remaining)” where N is days to nearest schedule end date for the ScheduleEvents
 * Column will be sorted by N, with patients having current outstanding surveys shown first.
 */
export function _resolveObjectListColumnOfType_surveysStatus(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.tasks can only be used on objects of type: Patient`)

        return
    }
    /** Sort increasing gives the most natural view
     * 1. Patients with incomplete and expiring, soonest first
     * 2. Patients with next available, soonest first
     * 3. Patients with nothing remaining
     * 4. Patients unregistered
     */
    const patient = row
    if (!patient.isRegistered) {
        column.cellText = ''
        column.number = sortOffsetUnregistered

        return
    }

    // Get list of qualifying (based on configuration) survey Activity objects, based on “now” (Y)
    const nowMoment = moment()
    const patientJourney = patient.firstJourney
    const columnEvents = patientJourney.getColumnScheduleEventsAtMoment(column, nowMoment)
    const numEvents = columnEvents.length

    // Get list of matching complete survey results (X)
    let eventsWithResults = []
    let eventsWithoutResults = []
    for (const event of columnEvents) {
        if (event.latestSurveyResult) {
            eventsWithResults.push(event)
        } else {
            eventsWithoutResults.push(event)
        }
    }
    if (eventsWithResults.length >= numEvents) {
        // All surveys complete for this point in time
        // Get schedule start date for the nearest future qualifying survey Activity
        // IF exists, display "Next available in N days" where N is days to schedule start
        // ELSE display "No longer available"
        const nextEvent = patientJourney.getColumnNextScheduleEventBeyondMoment(column, nowMoment)
        if (nextEvent) {
            const startMoment = new moment(nextEvent.startDate)
            const diffSummary = Utils.getMomentDiffSummary(nowMoment, startMoment)
            column.cellText = Locale.getLanguageItem('patientListSurveysStatusNextAvailable', [diffSummary])
            column.number = startMoment.diff(nowMoment, 'days') + sortOffsetDoneAllSurveys
        } else {
            column.cellText = Locale.getLanguageItem('patientListSurveysStatusNothingRemaining')
            column.number = sortOffsetNothingNext
        }
    } else {
        // Some surveys incomplete at this point in time
        // Display "Incomplete surveys (N days remaining)" where N is days to nearest schedule end date for the Activity objects
        eventsWithoutResults.sort((a, b) => a.endDate - b.endDate)
        if (eventsWithoutResults.length > 0) {
            const endMoment = new moment(eventsWithoutResults[0].endDate)
            const diffSummary = Utils.getMomentDiffSummary(nowMoment, endMoment)
            column.cellText = Locale.getLanguageItem('patientListSurveysStatusIncomplete', [diffSummary])
            column.number = endMoment.diff(nowMoment, 'days')
        } else {
            column.number = sortOffsetNothingNext
        }
    }
}

/**
 * As above. The properties returned by the report are:
 * numActivitiesComplete ( >= 0 )
 * numActivitiesIncomplete ( >= 0 )
 * numDaysToNext (to end of current schedule, or start of next schedule, or null if beyond all)
 */
export function _resolvePatientJourneyListColumnOfType_surveysStatus(column, patientJourney, row) {
    if (row.numDaysToNext == null) {
        // Beyond all windows
        column.cellText = Locale.getLanguageItem('patientListSurveysStatusNothingRemaining')
        column.number = sortOffsetNothingNext
    } else if (row.numActivitiesIncomplete == 0) {
        // All surveys complete for this point in time
        // Get schedule start date for the nearest future qualifying survey Activity
        // Display "Next available in N days" where N is days to schedule start
        column.number = row.numDaysToNext
        const diffSummary = Utils.getDaysDiffSummary(row.numDaysToNext)
        column.cellText = Locale.getLanguageItem('patientListSurveysStatusNextAvailable', [diffSummary])
    } else {
        // Some surveys incomplete at this point in time
        // Display "Incomplete surveys (N days remaining)" where N is days to nearest schedule end date for the Activity objects
        column.number = row.numDaysToNext
        const diffSummary = Utils.getDaysDiffSummary(row.numDaysToNext)
        column.cellText = Locale.getLanguageItem('patientListSurveysStatusIncomplete', [diffSummary])
    }
}

// eslint-disable-next-line no-unused-vars
export function _resolvePatientJourneyListColumnOfType_surveysCompleted(column, patientJourney, row, errors) {
    const numDaysToNext = row[column.id]?.daysToNext || null
    const numActivitiesIncomplete = row[column.id]?.incomplete || 0

    column.number = numDaysToNext

    if (numDaysToNext == null) {
        column.cellText = Locale.getLanguageItem('patientListSurveysStatusNothingRemaining')
        column.number = sortOffsetNothingNext
    } else {
        const diffSummary = Utils.getDaysDiffSummary(numDaysToNext)
        column.cellText = Locale.getLanguageItem(
            numActivitiesIncomplete > 0
                ? 'patientListSurveysStatusIncomplete'
                : 'patientListSurveysStatusNextAvailable',
            [diffSummary]
        )
    }
}
