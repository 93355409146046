import Logging from '@serv/Logging'
import moment from 'moment/moment'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
import store from '@/store'
import Utils from '@serv/Utils'

export function _resolveObjectListColumnOfType_rtmScheduleEnd(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.rtmScheduleEnd can only be used on objects of type: Patient`)

        return
    }

    const journey = store.state.resources.journeys[row.firstJourney.journeySlug]
    if (journey && journey.rtmEndSchedule) {
        const scheduleMoments = PatientService.getPatientScheduleStartEndMoments(row, journey.rtmEndSchedule)
        if (scheduleMoments[1]) {
            const nowMoment = moment()
            const diffText = Utils.getMomentDiffSummary(nowMoment, scheduleMoments[1])
            column.cellText = scheduleMoments[1].format(Utils.readableDateFormat)
            column.cellSubtitle = `(${diffText})`
        }
    }
}

export function _resolvePatientJourneyListColumnOfType_rtmScheduleEnd(column, patientJourney, row) {
    const date = row.rtmScheduleEndDate
    if (date) {
        const nowMoment = moment().startOf('day')
        const dateMoment = moment(date)
        const diffText = Utils.getMomentDiffSummary(nowMoment, dateMoment)
        column.cellText = dateMoment.format(Utils.readableDateFormat)
        column.cellSubtitle = `(${diffText})`
    }
}
