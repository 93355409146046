import ConfigManager from '@config/ConfigManager'
import Logging from '@serv/Logging'
import Request from '@serv/Request'
import Storage from '@serv/Storage'
import User from '@model/User'
import { GroupChannelHandler, GroupChannelModule } from '@sendbird/chat/groupChannel'

import SendbirdChat, { LogLevel } from '@sendbird/chat'

class ChatService {
    constructor() {
        this.sendbird = undefined
        this.channelHandler = undefined
        this.isChatFrozen = undefined
        this.frozenReason = undefined
    }

    get sendbirdLoggedUser() {
        return ((this.sendbird || {}).currentUser || {}).userId
    }

    canChat(user) {
        return user.owner.keyValues.featureFlags.hasChat && user.has(User.Capability.canChat)
    }

    _initSendbird(user) {
        if (!ConfigManager.isMockingServer) {
            this.sendbird = SendbirdChat.init({
                appId: user.sendbirdApplicationId,
                modules: [new GroupChannelModule()]
            })

            // https://sendbird.com/docs/chat/v4/javascript/logger
            if (Storage.get('debugChat')) {
                this.sendbird.logLevel = LogLevel[Storage.get('debugChat').toUpperCase()]
            }
        }
    }

    async disconnect() {
        if (this.sendbirdLoggedUser) {
            await this.sendbird.removeAllConnectionHandler()
        }

        return new Promise((resolve, reject) => {
            if (this.sendbirdLoggedUser) {
                this.sendbird
                    .disconnect()
                    .then(() => resolve())
                    .catch(error => reject(error))
            } else {
                resolve()
            }
        })
    }

    _connectToSendbirdServer(sendbirdUser, sessionToken) {
        return new Promise((resolve, reject) => {
            this.disconnect()
                .then(() => {
                    this.sendbird
                        .connect(sendbirdUser, sessionToken)
                        .then(() => resolve(sendbirdUser))
                        .catch(error => {
                            Logging.error(
                                `ChatService: Cannot connect to the sendbird server. ${error.message}. Error code: ${error.code}`
                            )
                            reject(error)
                        })
                })
                .catch(error => reject(error))
        })
    }

    connect(user, patientJourneyId, channelUrl) {
        return new Promise((resolve, reject) => {
            Request.post(
                Request.Stem.chatOpen,
                {},
                {
                    params: {
                        patientJourneyId: patientJourneyId
                    }
                }
            )
                .then(response => {
                    const sendbirdUser = response.data.sendbirdUserId
                    const sessionToken = response.data.sessionToken
                    const state = response.data.state
                    this.isChatFrozen =
                        [ChatService.State.frozen, ChatService.State.frozenCreated].includes(response.data.state) ||
                        !!response.data.frozenReason
                    this.frozenReason = response.data.frozenReason

                    if (sendbirdUser && sessionToken) {
                        this._initSendbird(user)

                        this._connectToSendbirdServer(sendbirdUser, sessionToken)
                            .then(sendbirdUser => resolve(sendbirdUser))
                            .catch(() => reject())
                    } else if (state == ChatService.State.frozenCreated) {
                        Logging.warn(`ChatService: Sendbird channel does not exist. Displaying dummy empty channel`)

                        resolve()
                    } else {
                        reject()
                    }
                })
                .catch(() => {
                    Logging.error(
                        `ChatService: Cannot get sendbird user and token. User: ${user.personaId}, patientJourneyId: ${patientJourneyId}, channelUrl: ${channelUrl}`
                    )

                    reject()
                })
        })
    }

    isUserMemberOfChannel(channel) {
        return channel.members.find(member => member.userId == this.sendbirdLoggedUser)
    }

    getChannel(user, channelUrl) {
        return new Promise((resolve, reject) => {
            return this.sendbird.groupChannel
                .getChannel(channelUrl)
                .then(channel => {
                    /**
                     * SB caches channel and after reopening same chat pane SB returns earlier retrieved channel without
                     * checking is connected SB user member of this channel
                     */
                    if (this.isUserMemberOfChannel(channel)) {
                        resolve(channel)
                    } else {
                        throw {
                            message: 'Not authorized. "User must be a member.".',
                            code: ChatService.Errors.unauthorized
                        }
                    }
                })
                .catch(error => {
                    Logging.error(
                        `ChatService: ${error.message} Error code: ${error.code}. Could not get channel for url: ${channelUrl}, user: ${user.personaId}, sendbird user: ${this.sendbirdLoggedUser}`
                    )

                    reject(error)
                })
        })
    }

    async getChannelMessages(channel, messageNumber) {
        messageNumber = messageNumber || 50
        const messageListQuery = channel.createPreviousMessageListQuery({
            limit: messageNumber,
            reverse: false
        })

        return await messageListQuery.load()
    }

    getPreviousChannelMessages(channel, timeStamp) {
        return new Promise((resolve, reject) => {
            return channel
                .getMessagesByTimestamp(timeStamp, {
                    isInclusive: false,
                    prevResultSize: 40,
                    reverse: false
                })
                .then(messages => {
                    resolve(messages)
                })
                .catch(error => reject(error))
        })
    }

    sendMessage(channel, message) {
        return new Promise((resolve, reject) => {
            return channel
                .sendUserMessage({ message: message })
                .onFailed((error, message) => {
                    if (message && message.errorCode) {
                        Logging.error(
                            `ChatService: sending message failed for channel ${message.channelUrl}, error code: ${message.errorCode}, sendbird user: ${this.sendbirdLoggedUser}`
                        )
                    }
                    reject(error)
                })
                .onSucceeded(message => {
                    resolve(message)
                })
        })
    }

    /**
     * Retrieve number of members who haven't read a message
     * If count <= 0 - all members have read the message
     */
    getReadMembers(channel, history) {
        return history.map(message => {
            message.read = channel.getUnreadMemberCount(message) <= 0

            return message
        })
    }

    async readMessage(channel, channelUrl) {
        // Only flag a message as read if we're not spoofing
        if (!Storage.get('spoof') && !!channel) {
            await channel.markAsRead()

            Request.post(
                Request.Stem.chatPaneOpened,
                { sendbirdUserId: this.sendbirdLoggedUser },
                {
                    params: {
                        channelUrl: channelUrl
                    }
                }
            ).catch(error => {
                Logging.error(
                    `ChatService: ${error?.message}. Chat channel: ${channelUrl}, chat user: ${this.sendbirdLoggedUser}`
                )
            })
        }
    }

    setEventHandler(channel, handlerId, callbackFncs) {
        this.channelHandler = new GroupChannelHandler(callbackFncs)

        this.sendbird.groupChannel.addGroupChannelHandler(handlerId, this.channelHandler)
    }

    removeEventHandler(handlerId) {
        this.sendbird?.groupChannel?.removeGroupChannelHandler(handlerId)
    }
}

ChatService.State = Object.freeze({
    active: 'active',
    frozen: 'frozen',
    frozenCreated: 'frozen_created'
})

ChatService.Errors = Object.freeze({
    unauthorized: 400108
})

export default new ChatService()
