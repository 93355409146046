import ListTransform from '@model/list/ListTransform'
import Utils from '@serv/Utils'

/**
 * List transform for patient appointments.
 */
class ListTransformAppointments extends ListTransform {
    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    // Sort chronologically by first future appointment date
    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        const dateZero = Utils.dateFarPast
        rows.sort((a, b) => {
            const apptsA = a.listColumns[columnIndex].futureAppointments
            const apptsB = b.listColumns[columnIndex].futureAppointments
            const dateA = apptsA && apptsA.length > 0 ? apptsA[0].date : dateZero
            const dateB = apptsB && apptsB.length > 0 ? apptsB[0].date : dateZero

            return dateA.localeCompare(dateB) // date format locale compare is chronological
        })
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'appointment_0_date'
    }
}

export default ListTransformAppointments
