import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'

class ListTransformHasSteps extends ListTransform {
    constructor(object) {
        object.filterKey = 'hasSteps'
        super(object)
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem('patientListSteps'),
            valueLabels: [
                {
                    value: 1,
                    label: Locale.getLanguageItem('yes')
                },
                {
                    value: 0,
                    label: Locale.getLanguageItem('no')
                }
            ],
            addAll: true
        }

        return config
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        return { values: keyStrings }
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'has_steps'
    }
}

export default ListTransformHasSteps
