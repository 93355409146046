import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Patient from '@model/Patient'

export function _resolveObjectListColumnOfType_boolean(column, row) {
    if (row instanceof Patient && column.dateMilestone) {
        column.boolean = !!row.getMilestoneOfSlugDate(column.dateMilestone)
    } else {
        if (!column.property) {
            Logging.error(`ListColumn of type boolean must specify property`)

            return
        }
        column.boolean = !!row[column.property]
    }
    column.cellText = Locale.getLanguageItem(column.boolean ? 'yes' : 'no')
}
export function _resolvePatientJourneyListColumnOfType_boolean(column, patientJourney, row) {
    if (column.dateMilestone) {
        const property = `${column.dateMilestone}Date`
        column.boolean = !!row[property]
    } else {
        if (!column.property) {
            Logging.error(`ListColumn of type boolean must specify property`)

            return
        }
        column.boolean = !!row[column.property]
    }
    column.cellText = Locale.getLanguageItem(column.boolean ? 'yes' : 'no')
}

// eslint-disable-next-line no-unused-vars
export function _resolvePatientJourneyListColumnOfType_milestoneExists(column, patientJourney, row, errors) {
    column.boolean = row[column.id]?.value
    column.cellText = Locale.getLanguageItem(column.boolean ? 'yes' : 'no')
}
