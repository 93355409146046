import clientConfig from './client-config.json'

class ConfigManager {
    constructor() {
        this.config = clientConfig
    }

    get devConfig() {
        return this.config.dev || {}
    }

    // Return true if we are "running from mocks"
    get isMockingServer() {
        return this.devConfig.mockSet
    }

    // If we're debugging a single patient, return the patientId (personaId)
    get singlePatientId() {
        return this.devConfig.singlePatientId
    }

    // If we're debugging a single survey, return the survey slug
    get singleSurveySlug() {
        return this.devConfig.singleSurveySlug
    }

    // Set to false to disable logout after N mins of inactivity
    get logoutInactivity() {
        return this.devConfig.logoutInactivity
    }
}

export default new ConfigManager()
