import * as Msal from 'msal'
import axios from 'axios'
import Config from '@serv/Config'
import CorinAuthConfig from '@config/CorinAuthConfig'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import MSKAuth from '@serv/Auth/MSKAuth'
import NotifyService from '@serv/NotifyService'
import Redirect from '@serv/Redirect'
import Request from '@serv/Request'
import RequestCycler from '@serv/RequestCycler'
import Storage from '@serv/Storage'
import store from '@/store'

class CorinAuth {
    /**
     * Initialize Auth singleton with Microsoft Authentication Library (msal)
     * application connecting to Corin Azure Active Directory B2C (ADB2C) authority
     * server.
     */
    constructor() {
        // Used by suspendAuthHeader/ resumeAuthHeader
        this.suspendedAuthHeader = undefined

        if (Config.environment !== 'testing' && Config.environment !== 'spoof' && Config.environment !== 'mock') {
            this.session = { service: 'CorinAuth' }
            this.country = null
            this.app = new Msal.UserAgentApplication(CorinAuthConfig[Config.environment])
            this.redirectCallback = (err, response) => {
                Logging.log('Handling SSO Redirect')
                const errorDesc = Storage.getStorage()

                // Check if error stored is MSAL reset password request
                if (
                    errorDesc['msal.error.description'] &&
                    errorDesc['msal.error.description'].indexOf('AADB2C90118') > -1
                ) {
                    window.location.href = CorinAuthConfig['forgotPassword'][Config.environment]
                }
                if (err) {
                    NotifyService.error(Locale.getLanguageItem('corinTokenFail'))
                    Logging.error(`CorinAuth error: ${err}`)
                }

                if (response) {
                    this.session.idToken = response.idToken

                    const isActive = this.session.idToken.claims.extension_UserIsActive
                    if (!isActive) {
                        NotifyService.error(Locale.getLanguageItem('corinUserNotActive'))
                    }

                    if (response.accessToken) {
                        this._handleAccessTokenResponse(response)

                        return this._updateUser()
                    }

                    return this._getAccessToken()
                }
            }

            this.app.handleRedirectCallback(this.redirectCallback)
        }
    }
    reset() {
        // Used by suspendAuthHeader/ resumeAuthHeader
        this.suspendedAuthHeader = undefined
        this.session = {}
    }

    /**
     * Do authentication (login) by redirecting to ADB2C login page. Defer to MSKAuth if
     * username and password are provided.
     */
    doAuth() {
        // HCP or AdminAssistant will send username and password
        if (arguments.length > 0) {
            if (this.session) {
                delete this.session.service
            }

            return MSKAuth.doAuth.call(MSKAuth, ...arguments)
        }

        return this._loginRedirect()
    }

    doSilentAuth(redirect) {
        if (this.app && this.app.getAccount()) {
            return this._getAccessToken().then(Redirect.gotoName('PostLogin', redirect))
        }
        Logging.error(`CorinAuth: No account found for silent auth`)
    }

    /**
     * Redirect the user to ADB2C login page where they will enter username and
     * password, before being redirected back.
     * @private
     */
    _loginRedirect() {
        return this.app.loginRedirect(CorinAuthConfig.loginConfig)
    }

    _logoutRedirect() {
        return Redirect.gotoName('Logout', undefined, { expired: 1 })
    }

    /**
     * Upon receiving an access token, set the session and Authorization header, and
     * schedule the access token renewal.
     * @private
     */
    _handleAccessTokenResponse(response) {
        this.session.idToken = response.idToken
        this.session.accessToken = response.accessToken
        this.session.expirationTime = response.expiresOn
        this.session.environment = Config.environment
        this.session.country = Config.country

        Storage.set('mr_session', this.session)
        this._setHeader(response.accessToken)

        this.scheduleAccessTokenRenewal()

        return response
    }

    /**
     * Attempt to aquire an access token from ADB2C automatically then handle the
     * response.
     * @private
     */
    _getAccessToken() {
        return this.app
            .acquireTokenSilent(CorinAuthConfig.tokenConfig[Config.environment])
            .then(response => {
                this._handleAccessTokenResponse(response)
                this._updateUser()
            })
            .catch(err => {
                if (err.name == 'InteractionRequiredAuthError') {
                    return this.app.acquireTokenRedirect(CorinAuthConfig.loginConfig)
                }

                if (err.errorCode == 'token_renewal_error') {
                    Logging.log('Timed out. Retrying')

                    return this._getAccessToken()
                }

                this.revokeToken()

                return this._logoutRedirect()
            })
    }
    /**
     * Set the Authorization header for all axios requests.
     * @private
     */
    _setHeader(accessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    }

    /**
     * Suspend the Authorization header.
     */
    suspendAuthHeader() {
        this.suspendedAuthHeader = axios.defaults.headers.common['Authorization']
        if (!this.suspendedAuthHeader) {
            Logging.error('No auth header to suspend.')
        }
        delete axios.defaults.headers.common['Authorization']
    }

    /**
     * Resume the Authorization header.
     */
    resumeAuthHeader() {
        if (this.suspendedAuthHeader) {
            axios.defaults.headers.common['Authorization'] = this.suspendedAuthHeader
            this.suspendedAuthHeader = undefined
        } else {
            Logging.error('No auth header to resume.')
        }
    }

    /**
     * Update the user on the backend based on information taken from the ID Token.
     * In doing so, request cycle to determine which environment the user belongs.
     * @private
     */
    _updateUser() {
        const externalId = this.session.idToken.claims.extension_UserId
        const userData = {
            email: this.session.idToken.claims.emails[0],
            firstName: this.session.idToken.claims.given_name,
            lastName: this.session.idToken.claims.family_name,
            externalId: externalId
        }

        return RequestCycler.cycleRequest((baseURL, country) => {
            const url = Request.Stem.user.replace('{personaId}', externalId)

            return Request.patch(`${baseURL}/${url}`, userData).then(resp => {
                Config.configure(country)
                store.dispatch('loadCorinAuthed')

                return resp
            })
        }).catch(() => {
            // Will trigger only if all `RequestCycler` requests fail
            NotifyService.error(Locale.getLanguageItem('accountCorinLogOut'))
            Logging.error(
                `CorinAuth: User does not have the permissions to access the CorinRPM dashboard. Persona ID: ${externalId}`
            )
            window.setTimeout(() => {
                this._logoutRedirect()
            }, 4500)
        })
    }

    /**
     * Check if there is an active Corin session. This returns false if the user is
     * Corin but they're using MSKAuth.
     */
    isCorinSession() {
        return this.session.service == 'CorinAuth'
    }

    /**
     * Check if there is a session remembered in Storage
     * and set it as the active session.
     */
    hasSession() {
        if (Storage.get('mr_session')) {
            this.session = Storage.get('mr_session')
            if (!this.isCorinSession()) {
                return MSKAuth.hasSession.call(MSKAuth, ...arguments)
            }

            if (!this.session.idToken && Config.isConfigured) {
                // only trigger this if not previously restored
                Config.restore()
                this._setHeader(this.session.accessToken)
                store.dispatch('loadCorinAuthed')

                return true
            }
        }

        return store.state.user.corinAuthed
    }

    /**
     * Destroy current session.
     */
    revokeToken() {
        if (!this.isCorinSession()) {
            MSKAuth.revokeToken.call(MSKAuth, ...arguments)
        } else {
            Storage.remove('mr_session')
            this.app.logout()
        }
    }

    /**
     * Schedule the automatic renewal of the access token ten seconds before the current
     * one expires. If that fails, redirect the user to A2B2C login.
     */
    scheduleAccessTokenRenewal() {
        const currentTime = moment().valueOf()
        const milliseconds = Math.max(
            this.session.expirationTime - currentTime - 10 * 1000 || 0, // 10 seconds
            10
        )
        Logging.log(`Scheduling token refresh in ${milliseconds}ms`)

        this.timer = setTimeout(() => {
            Logging.log('Refreshing Token')
            this._getAccessToken()
        }, milliseconds)
    }
}

export default new CorinAuth()
