import Milestone from '@model/Milestone'
import ReviewMilestone from '@model/ReviewMilestone'
class ScheduledReviewMilestone extends ReviewMilestone {
    constructor(object) {
        object.type = Milestone.Type.scheduledReview
        super(object)
        this.scheduleSlug = object.scheduleSlug
        this.notes = object.notes
    }
}

export default ScheduledReviewMilestone
