import DataTransformTagDimension from '@model/data/DataTransformTagDimension'
import Department from './Department'
import Logging from '@serv/Logging'
import store from '@/store'

class Provider {
    constructor(object) {
        this.id = object.id
        this.slug = object.slug
        this.title = object.title
        this.country = object.country
        this.departments = object.departments
        this.keyValues = object.keyValues || {}

        this.tags = {}
        object.tags = object.tags || []
        for (const tag of object.tags) {
            this.tags[tag.dimension] = tag.value
        }
        this.calculateRegion()

        this.departmentSlugs = []
        for (const departmentObj of object.departments || []) {
            departmentObj.providerSlug = this.slug
            const department = new Department(departmentObj, object.tags)
            store.commit('addDepartment', department)
            this.departmentSlugs.push(department.slug)
        }
    }

    // Set region from tags. The first regionTagDimension defined on this object is assumed to provide the region.
    calculateRegion() {
        // Default property if no other valid region tag specified
        this.regionDimension = 'country'
        // Override with valid region tag?
        for (const dimension of Provider.regionTagDimensions) {
            if (this.tags[dimension]) {
                this.regionDimension = dimension
                break
            }
        }
        this.region = this.tags[this.regionDimension]
    }

    /**
     * Get the string key for the region. Requires the regionDimension (tag) and region (value).
     */
    get getStringKeyForRegion() {
        if (!this.regionDimension || !this.region) {
            Logging.error(`Could not get stringKey for provider: ${this.slug}`)

            return ''
        }

        return DataTransformTagDimension.getStringKeyForTagDimensionValue(this.regionDimension, this.region)
    }

    getRegionByDimension(dimension) {
        this.region = this.tags[dimension]
        if (this.region) {
            return DataTransformTagDimension.getStringKeyForTagDimensionValue(dimension, this.region)
        }
    }
}

/**
 * All tag dimensions that should be interpreted as regions.
 * Do NOT reorder these - they are  listed in order from smallest to largest, in order to provide the best default
 * behaviour.
 */
Provider.regionTagDimensions = ['jhub-aor', 'gb-county', 'au-state', 'country', 'region']

export default Provider
