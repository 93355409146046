import ListTransform from '@model/list/ListTransform'
import StringHelper from '@serv/StringHelper'

class ListTransformProperties extends ListTransform {
    constructor(object) {
        super(object)
    }

    sortRows(rows) {
        const sortedBy = this.column.sortedBy
        const maxValue = 999999
        rows.sort((a, b) => {
            const isNumber = !isNaN(a[sortedBy]) || !isNaN(b[sortedBy])

            if (isNumber) {
                let valueA = a[sortedBy]
                valueA = valueA == undefined ? maxValue : valueA
                let valueB = b[sortedBy]
                valueB = valueB == undefined ? maxValue : valueB

                return valueA - valueB
            }
            const valueA = a[sortedBy] || ''
            const valueB = b[sortedBy] || ''

            return valueA.localeCompare(valueB)
        })
    }

    reverse(rows) {
        const sortedBy = this.column.sortedBy

        const rowsUndefined = rows.filter(row => row[sortedBy] == undefined)
        const rowsDefined = rows.filter(row => row[sortedBy] != undefined)
        rows.length = 0
        rows.push(...rowsDefined.reverse())
        rows.push(...rowsUndefined)
    }

    getSortParameter() {
        return StringHelper.camelCaseToSnakeCase(this.column.sortedBy)
    }
}

export default ListTransformProperties
