/**
 * The configuration service will provide the app with
 * the appropriate Config for the country and environment.
 */
import axios from 'axios'
import ConfigManager from '@config/ConfigManager'
import ConfigOptions from '@config/ConfigOptions'
import Logging from '@serv/Logging'
import Storage from '@serv/Storage'
import Utils from '@serv/Utils'

class ConfigService {
    constructor() {
        this.debugEnabled = false
        this.reset()
    }

    get isQaEnv() {
        return this.environment == 'qa'
    }

    // Called on logout
    reset() {
        this.defaultBrand = 'mr'
        this.isConfigured = false
        this.countries = ['gb', 'us', 'fr']
        this.country = null
        this.spoof = false
        this.isRecaptchaEnabled = true
    }

    setCountry(country) {
        if (country && !this.isConfigured) {
            if (!this.countries.includes(country)) {
                throw new Error(`No such country: ${country}`)
            }
            Object.assign(this, this.getCountryOptions(country))
            this.setBaseUrl(this.base_url)
        }
        this.country = country
    }

    setEnv(env) {
        this.environment = env || 'testing'
    }

    setVersion(version) {
        this.version = version
    }

    setBrand(brand) {
        if (brand) {
            this.defaultBrand = brand
            Logging.log('Default brand configured to:', brand)
        }
    }

    setSpoof() {
        this.spoof = true
    }

    setOptions(optionObj) {
        if (!!optionObj && optionObj.hasOwnProperty('gb') && optionObj.gb.hasOwnProperty('base_url')) {
            this.CountryOptions = optionObj
        } else {
            throw new Error('The options object is invalid')
        }
    }

    setRecaptchaStatus(status) {
        this.isRecaptchaEnabled = status
    }

    /**
     * Get options for the specified country.
     */
    getCountryOptions(country) {
        if (this.CountryOptions.hasOwnProperty(country)) {
            return this.CountryOptions[country]
        }
        Logging.log('No config for country:', country)
    }

    /**
     * Merge country options into the Config instance (base_url, oauth token, etc),
     * set up axios, set up session country.
     */
    configure(country) {
        Object.assign(this, this.getCountryOptions(country))
        if (Storage.get('mr_session')) {
            Storage.appendToObject('mr_session', 'country', country)
        }
        this.setCountry(country)
        this.setBaseUrl(this.base_url)
        this.isConfigured = true
        Logging.log('Config set for country:', country)

        return true
    }

    setBaseUrl(baseUrl) {
        if (ConfigManager.isMockingServer) {
            baseUrl = ConfigManager.devConfig.baseUrl
        }
        this.base_url = baseUrl
        axios.defaults.baseURL = baseUrl
    }

    /**
     * Reconfigure the app from Storage.
     * Unify everything related to country and env under `mr_session`.
     */
    restore() {
        let session = Storage.get('mr_session')
        if (Utils.isPlainObject(session)) {
            let env = session.environment
            let country = session.country

            this.setEnv(env)
            this.setOptions(ConfigOptions[env])
            this.configure(country)
            Logging.log(`App is configured to run on: ${env} ${country}`)

            return true
        }

        return false
    }
}

/**
 * Bootstrap the Config service with defaults from `process.env`.
 * The app can be reconfigured later.
 */
let Config = new ConfigService()

Config.setVersion(import.meta.env.VITE_TAG)
Config.setEnv(import.meta.env.VITE_ENVIRONMENT)
Config.setBrand(import.meta.env.BRAND)

if (import.meta.env.VITE_RECAPTCHA && import.meta.env.VITE_RECAPTCHA == 'off') {
    Config.setRecaptchaStatus(false)
}

if (ConfigOptions.hasOwnProperty(Config.environment)) {
    Config.setOptions(ConfigOptions[Config.environment])
} else {
    throw new Error('ConfigOptions has no such env:', Config.environment)
}

export default Config
