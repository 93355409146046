import BaseContent from '@model/content/BaseContent'

class Step extends BaseContent {
    constructor(object) {
        super(object)
        this.enabled = object.enabled
    }

    get isSkippable() {
        return this.tags.includes('allow-skip')
    }
}

Step.Type = {
    question: 'question',
    form: 'form',
    exercise: 'exercise',
    infoStep: 'infoStep',
    painScale: 'painScale',
    healthScale: 'healthScale',
    sliderScale: 'sliderScale'
}

export default Step
