import _ from 'lodash'
import Activity from '@model/Activity'
import ActivityList from '@model/ActivityList'
import ActivityListMilestone from '@model/ActivityListMilestone'
import Clinician from '@model/Clinician'
import Department from '@model/Department'
import Journey from '@model/Journey'
import Logging from '@serv/Logging'
import Milestone from '../models/Milestone'
import moment from 'moment'
import Owner from '@model/Owner'
import Patient from '@model/Patient'
import PatientJourney from '@model/PatientJourney'
import Provider from '@model/Provider'
import Schedule from '@model/Schedule'
import { startCase } from '@comp/Filters'
import StepResult from '@model/StepResult'
import store from '@/store'
import StringHelper from '@serv/StringHelper'
import Survey from '@model/content/Survey'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'
import Team from '@model/Team'
import User from '@model/User'
import Utils from '@serv/Utils'

/**
 * Functions for mocking models.
 * Typically each function:
 * - Takes an input object that can configure what is mocked
 * - Returns an object with properties containing references to the mocked model(s).
 */
class MockService {
    /**
     * Functions to ensure store containers exist.
     */
    ensureStoreContent() {
        store.state.content.content = store.state.content.content || {}
        store.state.content.surveys = store.state.content.surveys || {}
    }
    ensureStoreDepartments() {
        store.state.resources.departments = store.state.resources.departments || {}
    }
    ensureStoreJourneys() {
        store.state.resources.journeys = store.state.resources.journeys || {}
    }
    ensureStorePatients() {
        store.state.user.patients = store.state.user.patients || {}
    }
    ensureStorePatientJourneys() {
        store.state.user.patientJourneys = store.state.user.patientJourneys || {}
    }
    ensureStorePracticeSurveyResults() {
        store.state.resources.practiceSurveyResults = store.state.resources.practiceSurveyResults || {}
    }
    ensureStoreProviders() {
        store.state.resources.providers = store.state.resources.providers || {}
    }
    ensureStoreSurgeons() {
        store.state.user.surgeons = store.state.user.surgeons || {}
    }
    ensureStoreTeams() {
        store.state.user.teams = store.state.user.teams || {}
    }
    ensureStoreTeamLeads() {
        store.state.user.teamLeads = store.state.user.teamLeads || {}
    }
    ensureStoreUsers() {
        store.state.user.users = store.state.user.users || {}
    }

    get clinicianTitles() {
        return ['Dr', 'Prof']
    }
    get patientTitles() {
        return ['Mr', 'Miss', 'Mrs']
    }
    get firstNames() {
        return [
            'Adam',
            'Annie',
            'Boris',
            'Becky',
            'Charles',
            'Candy',
            'David',
            'Daisy',
            'Edgar',
            'Emily',
            'Frederick',
            'Freda',
            'Giles',
            'Gina',
            'Harry',
            'Hilda'
        ]
    }
    get lastNames() {
        return [
            'Adams',
            'Bones',
            'Crisp',
            'Dogwart',
            'Eggshell',
            'Forbington-Smythe',
            'Glenn',
            'Holmes',
            'India',
            'Jones',
            'Karlin',
            'Lennard',
            'Millington',
            'Neave'
        ]
    }

    /**
     * Mock a number of Providers.
     * numProviders: the number of Providers to mock (default = 1)
     */
    mockProviders(config) {
        config = config || {}
        const numProviders = config.numProviders || 1
        const providers = []
        for (let i = 0; i < numProviders; i++) {
            const provider = new Provider({
                id: i + 1,
                slug: config.slug || `provider-${i}`,
                title: config.title || `Provider ${i}`,
                tags: config.tags,
                country: config.country || 'GB'
            })
            providers.push(provider)
        }
        // Store in state
        this.ensureStoreProviders()
        providers.forEach(provider => (store.state.resources.providers[provider.slug] = provider))

        return {
            providers: providers,
            provider: providers[0],
            providerSlugs: providers.map(provider => provider.slug)
        }
    }

    /**
     * Mock a number of Teams.
     * numTeams: the number of Teams to mock (default = 1)
     */
    mockTeams(config) {
        config = config || {}
        const numTeams = config.numTeams || 1
        const teams = []
        for (let i = 0; i < numTeams; i++) {
            const team = new Team({
                id: config.teamId || i + 1,
                leadId: config.leadId || i + 1,
                providerSlug: config.providerSlug || `team ${i}`
            })
            teams.push(team)
        }
        // Store in state
        this.ensureStoreTeams()
        teams.forEach(team => (store.state.user.teams[team.id] = team))

        return {
            teams: teams,
            team: teams[0]
        }
    }

    /**
     * Mock a number of Departments.
     * numDepartments: the number of Departments to mock (default = 1)
     */
    mockDepartments(config) {
        config = config || {}
        const numDepartments = config.numDepartments || 1
        const departments = []
        for (let i = 0; i < numDepartments; i++) {
            const department = new Department({
                id: config.id || i + 1,
                slug: config.slug || `department-${i}`,
                title: config.title || `Department ${i}`
            })
            departments.push(department)
        }
        // Store in state
        this.ensureStoreDepartments()
        departments.forEach(department => (store.state.resources.departments[department.slug] = department))

        return {
            departments: departments,
            department: departments[0],
            departmentSlugs: departments.map(department => department.slug)
        }
    }

    /**
     * Mock a number of Surveys.
     * numSurveys: the number of Surveys to mock (default = 1)
     * surveySlugs: a list of slugs to use. Length should match numSurveys
     * tags: the tags to apply to all surveys (default = PROM)
     */
    mockSurveys(config) {
        config = config || {}
        const numSurveys = config.numSurveys || 1
        const surveys = []
        if (config.surveySlugs && config.surveySlugs.length != numSurveys) {
            Logging.error(
                `mockSurveys config.slugs length is ${config.surveySlugs.length} but numSurveys is ${numSurveys}`
            )
            config.surveySlugs = undefined
        }
        // NOTE: Currently we mock the Survey but NOT any Steps
        for (let i = 0; i < numSurveys; i++) {
            const surveySlug = config.surveySlugs ? config.surveySlugs[i] : `survey-${i}-surv`
            const survey = new Survey({
                slug: surveySlug,
                title: `Survey: ${surveySlug}`,
                name: `Mocked ${surveySlug}`,
                minScore: 0,
                maxScore: 100,
                // Allow per-instance modification of tags array
                tags: Array.from(config.tags || [Survey.promSurveyTag, Survey.clinicalSurveyTag])
            })
            surveys.push(survey)
        }
        // Store in state
        this.ensureStoreContent()
        surveys.forEach(survey => {
            store.state.content.content[survey.slug] = survey
            store.state.content.surveys[survey.slug] = survey
        })

        return {
            surveys: surveys,
            survey: surveys[0],
            surveySlugs: surveys.map(survey => survey.slug)
        }
    }

    /**
     * Mock a number of Journeys.
     * numJourneys: the number of Journeys to mock (default = 1)
     * slug: the journey slug (if mocking a single journey)
     * surveySlugs: survey slugs to create Activities for (default = none)
     * scheduleSlugs: survey slugs to create Activities for (default = [pre-op, 3m-6m-post-op, 6m-1y-post-op])
     */
    mockJourneys(config) {
        config = config || {}
        const numJourneys = config.numJourneys || 1
        const journeys = []

        // Create activity objects
        const activityObjects = []
        for (const surveySlug of config.surveySlugs || []) {
            const survey = store.state.content.content[surveySlug]
            const isBilateral = survey?.tags?.includes(Survey.bilateralTag)
            const sides = isBilateral ? ['-left', '-right'] : ['']
            for (const scheduleSlug of config.scheduleSlugs || ['pre-op', '3m-6m-post-op', '6m-1y-post-op']) {
                for (const side of sides) {
                    activityObjects.push({
                        slug: `${scheduleSlug}-${surveySlug}${side}`,
                        contentSlug: surveySlug,
                        scheduleSlug: scheduleSlug
                    })
                }
            }
        }

        for (let i = 0; i < numJourneys; i++) {
            const journey = new Journey({
                id: i + 1,
                slug: config.slug || `journey-${i}`,
                title: `Journey ${i}`,
                procedureCode: config.procedureCode || `JRNY-${i}`,
                jointSlug: config.jointSlug,
                keyValues: config.keyValues || {},
                activities: activityObjects,
                rtmStartSchedule: config.rtmStartSchedule,
                rtmEndSchedule: config.rtmEndSchedule,
                rtmReminderSchedules: config.rtmReminderSchedules
            })
            if (!config.skipPostProcess) {
                journey.postProcess()
            }
            journeys.push(journey)
        }
        // Store in state
        this.ensureStoreJourneys()
        journeys.forEach(journey => (store.state.resources.journeys[journey.slug] = journey))

        return {
            journeys: journeys,
            journey: journeys[0],
            journeySlugs: journeys.map(journey => journey.slug)
        }
    }

    /**
     * Mock a number of Clinicians.
     * numClinicians: the number of Clinicians to mock (default = 1)
     * isLead: if true, all Clinicans are leads
     */
    mockClinicians(config) {
        config = config || {}
        const numClinicians = config.numClinicians || 1
        const clinicians = []
        for (let i = 0; i < numClinicians; i++) {
            const clinician = new Clinician({
                personaId: config.personaId || i + 1,
                persona: User.Persona.clinician,
                role: config.role || User.Role.surgeon,
                title: config.title || _.sample(this.clinicianTitles),
                firstName: config.firstName || _.sample(this.firstNames),
                lastName: config.lastName || _.sample(this.lastNames),
                providerSlugs: config.providerSlugs,
                capabilities: [
                    User.Capability.canJoinTeam,
                    User.Capability.canLeadTeam,
                    User.Capability.canViewAllTeamPatients,
                    User.Capability.canViewPatientData
                ],
                // TODO may need to update for mocking clinicians across countries
                countryIso: 'GB',
                region: 'gb',
                isRtmProvider: config.isRtmProvider || false
            })
            clinicians.push(clinician)
        }
        // Store in state
        this.ensureStoreTeamLeads()
        this.ensureStoreUsers()
        this.ensureStoreSurgeons()
        clinicians.forEach(clinician => {
            store.state.user.users[clinician.personaId] = clinician
            store.state.user.surgeons[clinician.personaUid] = clinician // NOTE: indexed by uid
            if (config.isLead) {
                store.state.user.teamLeads[clinician.personaId] = clinician
            }
        })

        return {
            clinicians: clinicians,
            clinician: clinicians[0]
        }
    }

    /**
     * Mock a number of Patients.
     * numPatients: the number of Patients to mock (default = 1)
     * personaId: if creating a single patient (default = 1, 2, 3... for multiple patients)
     * journeySlug: the patient journey slug (default = undefined)
     * {milestoneSlug}Date: if set, add a PJM with this date to each Patient (default = undefined)
     */
    mockPatients(config) {
        config = config || {}
        const numPatients = config.numPatients || 1
        const patients = []
        for (let i = 0; i < numPatients; i++) {
            const personaId = config.personaId || i + 1
            config.personaId = personaId
            const patient = new Patient(config)
            const patientJourneys = this.mockPatientJourneysForPatient(config, personaId).patientJourneys
            if (config.hasOwnProperty('registrationDate')) {
                patient.isRegistered = true
            }
            patient.resolvePatientJourneys(patientJourneys)
            // If we are logged in as a user creating mock patients, defining these means PatientPage won't ask for more
            const user = store.state.user.user
            if (user && user.isMock) {
                patient.hasFullPatientJourney = true
                patient.surveyStepResults = []
                patient.activityData = []
                patient.exerciseResults = []
                patient.activityDataDaily = []
            }
            patients.push(patient)
        }
        // Store in state
        this.ensureStorePatients()
        patients.forEach(patient => (store.state.user.patients[patient.personaId] = patient))

        return {
            patients: patients,
            patient: patients[0]
        }
    }

    // Generate a random hospital number.
    mockPatientHospitalNumber() {
        const numLeft = 1000 + Math.floor(8999 * Math.random())
        const numRight = 1000000 + Math.floor(8999999 * Math.random())

        return `ID-${numLeft}-${numRight}`
    }

    /**
     * Mock a number of PatientJourneys for a specified Patient personaId.
     * Sets a single one as isPrimary.
     * Additionally creates a global PatientJourney.
     * numPatientJourneys: the number of (non-global) PatientJourneys to mock (default = 1)
     * journeySlug: the patient journey slug (default = undefined)
     * teamId: the patient journey teamId (defaut = random, from dash user teams)
     * side: the patient journey side (default = undefined)
     * {milestoneSlug}Date: if set, add a PJM with this date to each Patient (default = undefined)
     */
    mockPatientJourneysForPatient(config, personaId) {
        config = config || {}
        const numPatientJourneys = config.numPatientJourneys || 1
        const pathwayMilestones = []
        const globalMilestones = []
        let milestoneId = 1 // unique only within Patient, should suffice!
        for (const milestoneSlug of ['goal', 'invitation', 'registration']) {
            const property = `${milestoneSlug}Date`
            if (config[property]) {
                globalMilestones.push({
                    slug: milestoneSlug,
                    date: config[property],
                    patientJourneyMilestoneId: milestoneId,
                    type: milestoneSlug == 'goal' ? Milestone.Type.goal : Milestone.Type.default
                })
                milestoneId++
            }
        }
        for (const milestoneSlug of ['appointment', 'discharge', 'injury', 'operation', 'referral']) {
            const property = `${milestoneSlug}Date`
            if (config[property]) {
                pathwayMilestones.push({
                    slug: milestoneSlug,
                    date: config[property],
                    patientJourneyMilestoneId: milestoneId,
                    type:
                        milestoneSlug == 'appointment' // TODO all cases!
                            ? Milestone.Type.appointment
                            : Milestone.Type.default
                })
                milestoneId++
            }
        }
        // Primary and secondaries
        const patientJourneys = []
        this.ensureStoreJourneys()
        this.ensureStoreTeams()
        for (let i = 0; i < numPatientJourneys; i++) {
            let teamId, journeySlug
            if (config.teamId && config.journeySlug) {
                teamId = config.teamId
                journeySlug = config.journeySlug
            } else {
                const team = _.sample(Object.values(store.state.user.teams))
                if (team == undefined) {
                    // No teams defined, use fake ID and journeySlug
                    teamId = 1
                    journeySlug = config.journeySlug || 'mr-tkr'
                } else {
                    teamId = team.id
                    journeySlug = config.journeySlug || _.sample(team.journeySlugs)
                }
            }
            // Mock Journey if it doesn't exist
            if (!store.state.resources.journeys[journeySlug]) {
                this.mockJourneys({
                    slug: journeySlug
                })
            }
            const patientJourney = new PatientJourney({
                patientId: personaId,
                patientJourneyId: personaId * 100 + i,
                teamId: teamId,
                journeySlug: journeySlug,
                isPrimary: i == 0,
                isGlobal: false,
                side: config.side,
                patientJourneyMilestones: _.cloneDeep(pathwayMilestones)
            })
            patientJourneys.push(patientJourney)
        }
        // Global
        const globalJourney = new PatientJourney({
            patientId: personaId,
            patientJourneyId: personaId * 100 + numPatientJourneys,
            isPrimary: false,
            isGlobal: true,
            patientJourneyMilestones: globalMilestones
        })
        patientJourneys.push(globalJourney)

        // Store in state
        this.ensureStorePatientJourneys()
        patientJourneys.forEach(
            patientJourney => (store.state.user.patientJourneys[patientJourney.patientJourneyId] = patientJourney)
        )

        return {
            patientJourneys: patientJourneys,
            primaryJourney: patientJourneys[0],
            globalJourney: globalJourney,
            secondaryJourneys: patientJourneys.filter(pj => !pj.isPrimary && !pj.isGlobal)
        }
    }

    /**
     * Mock a number of SurveyResults for a specified Journey.
     * We specify the journey slug, and expect the Journey to be in the store.
     * For each Activity on the Journey, we create a single SurveyResult.
     * journeySlug: the journey slug (default = undefined)
     * patientId: the patient ID (default = undefined)
     * primaryMilestoneDate: the patient primary milestone date (string) (default = now)
     * score: the score value (default = undefined, choose random score)
     * withStepResults: if true, also mock StepResults
     */
    mockJourneySurveyResults(config) {
        config = config || {}
        const journeySlug = config.journeySlug
        const journey = store.state.resources.journeys[journeySlug]
        let activities
        if (journey == undefined) {
            Logging.error(`mockJourneySurveyResults could not find the specified Journey`)
            activities = []
        } else {
            activities = journey.activities
        }
        let resultPrmId = 9000
        const primaryMilestoneDate = config.primaryMilestoneDate || moment().format(Utils.serialisedDateFormat)

        const surveyResults = []
        for (const activity of activities) {
            // Calculate endTime from primaryMilestoneDate and scheduleSlug
            const schedule = Schedule.get(activity.scheduleSlug)
            const primaryMilestoneMoment = moment(primaryMilestoneDate)
            const resultMoment = primaryMilestoneMoment.add(schedule.startOffset, 'days')
            const endTime = resultMoment.format(Utils.serialisedDateTimeFormat)
            // Random score in range [50, 100]
            const score = config.score || 50 + Math.floor(50 * Math.random())

            const surveyResult = new SurveyResult({
                patientId: config.patientId,
                journeySlug: journeySlug,
                procedureLabel: config.procedureLabel,
                scheduleSlug: activity.scheduleSlug,
                surveySlug: activity.contentSlug,
                activitySlug: activity.slug,
                resultPrmId: resultPrmId++,
                patientJourneyId: config.patientJourneyId,
                primaryMilestoneDate: config.primaryMilestoneDate,
                endTime: endTime,
                scoreArray: [{ section: 'Score', value: score }],
                createdById: config.createdById,
                enteredByClinician: config.enteredByClinician,
                isWebSurveyResult: config.isWebSurveyResult,
                originalCompletionDate: config.originalCompletionDate,
                status: config.status
            })
            const survey = store.state.content.surveys[activity.contentSlug]
            if (survey && config.withStepResults) {
                survey.steps.forEach(step => {
                    const choice = _.sample(step.choices)
                    const value = choice.value
                    const stepResult = new StepResult({
                        resultPrmId: surveyResult.resultPrmId,
                        stepSlug: step.slug,
                        type: step.type,
                        value: value,
                        freeTextValue: undefined,
                        endTime: undefined
                    })
                    surveyResult.stepResults.push(stepResult)
                    surveyResult.stepSlugToStepResult[stepResult.stepSlug] = stepResult
                })
            }
            surveyResults.push(surveyResult)
        }
        // Store in state
        this.ensureStorePracticeSurveyResults()
        SurveyResultsService.addPatientPracticeSurveyResults(surveyResults)

        return {
            surveyResults: surveyResults
        }
    }

    /**
     * Ensure a region value is added as a user tag, and in the owner stringMap.
     * The stringMap value is just a startCase() version of the tag value.
     */
    _ensureRegionTagDimensionValue(tag, value) {
        const user = store.state.user.user
        if (!user || !user.isProviderExec) {
            return
        }
        const userTags = user.tags
        if (!userTags[tag].includes(value)) {
            userTags[tag].push(value)

            const tagDimValueSlug = `${tag}-${value}`
            const stringKey = StringHelper.slugToCamelCase(tagDimValueSlug)
            const localeMap = {}
            localeMap[user.locale] = startCase(value)
            user.owner.keyValues.stringMap[stringKey] = localeMap
        }
    }

    /**
     * Mock a number of Providers, with Departments and Clinicians (and the related Teams).
     * numProviders: the number of Providers to mock (default = 1)
     *
     * The Providers are tagged with a random gb-county from the list specified at the top.
     * We ensure that the logged-in user also has all of these tags.
     *
     * The
     */
    mockProvidersDepartmentsTeamsClinicians(config) {
        config = config || {}
        const providerGbCountyTags = ['cornwall', 'devon', 'hampshire', 'somerset', 'surrey', 'wiltshire']
        const departmentJourneyAbbreviations = {
            generalSurgery: ['Colonoscopy', 'Gastroscopy', 'Sigmoidoscopy'],
            lowerLimb: ['ACL', 'TKR', 'THR'],
            upperLimb: ['Rotator Cuff', 'Shoulder Stab.', 'Shoulder Recon.']
        }
        // Mock Providers
        const providers = this.mockProviders({
            numProviders: config.numProviders || 3
        }).providers

        // How many Departments in each Provider? Max is 3
        let numDepartments = 0
        for (let p = 0; p < providers.length; p++) {
            numDepartments += 3 - (p % 3) // 3, 2, 1, 3, 2, 1...
        }
        // Mock Departments
        const departments = this.mockDepartments({
            numDepartments: numDepartments
        }).departments

        // Providers and Departments reference each other
        // Name the departments 'department-pN-dM' where N is the provider index, and M the department index within it
        let d = 0
        for (let p = 0; p < providers.length; p++) {
            const provider = providers[p]

            // Set provider in region
            const gbCounty = providerGbCountyTags[p % providerGbCountyTags.length]
            provider.tags['gb-county'] = gbCounty
            this._ensureRegionTagDimensionValue('gb-county', gbCounty)
            provider.calculateRegion()
            provider.title = `${startCase(provider.region)} County Hospital`

            provider.departmentSlugs = []
            let providerNumDepartments = 3 - (p % 3)
            while (--providerNumDepartments >= 0) {
                const department = departments[d]
                // Update department slug and title
                const dept = Object.keys(departmentJourneyAbbreviations)[providerNumDepartments]
                const region = provider.region
                const newDepartmentSlug = `${region}-${dept}`
                department.title = `${provider.title}: ${startCase(dept)}`
                store.state.resources.departments[newDepartmentSlug] = department
                delete store.state.resources.departments[department.slug]
                department.slug = newDepartmentSlug

                provider.departmentSlugs.push(department.slug)
                department.providerSlug = provider.slug
                d++
            }
        }

        // Each Department has 3 leads - each has a Team containing only themself
        const clinicians = this.mockClinicians({
            numClinicians: 3 * numDepartments,
            isLead: true
        }).clinicians
        let c = 0
        for (let d = 0; d < departments.length; d++) {
            const department = departments[d]
            for (let dc = 0; dc < 3; dc++) {
                const clinician = clinicians[c++]
                department.leadIds.push(clinician.personaId)
                const provider = providers.find(provider => provider.departmentSlugs.includes(department.slug))
                if (provider) {
                    clinician.providerSlugs.push(provider.slug)
                }
            }
        }

        // Mock Teams
        // Each Team has a single Clinician, and a set of 3 journeys *linked to the department index*.
        const surveySlugs = Object.values(store.state.content.surveys || {})
            .filter(survey => survey.isPromSurvey)
            .map(survey => survey.slug)
            .slice(0, 3)
        const journeys = this.mockJourneys({
            numJourneys: 3 * Object.keys(departmentJourneyAbbreviations).length,
            surveySlugs: surveySlugs
        }).journeys
        // Remap journey slugs, abbreviations, titles
        let journeyIndex = 0
        Object.keys(departmentJourneyAbbreviations).forEach(key => {
            const journeyAbbreviations = departmentJourneyAbbreviations[key]
            journeyAbbreviations.forEach(abbr => {
                const journey = journeys[journeyIndex]
                const oldJourneySlug = journey.slug
                journey.slug = abbr
                journey.procedureCode = abbr
                journey.titleRaw = startCase(abbr)
                store.state.resources.journeys[journey.slug] = journey
                delete store.state.resources.journeys[oldJourneySlug]
                journeyIndex++
            })
        })

        const teams = []
        const teamsMap = store.state.user.teams
        this.ensureStoreTeams()
        let teamId = _.isEmpty(teamsMap) ? 1 : Math.max(...Object.keys(teamsMap)) + 1
        for (let d = 0; d < departments.length; d++) {
            const department = departments[d]
            const dept = department.slug.split('-')[1]
            const deptMapIndex = Object.keys(departmentJourneyAbbreviations).indexOf(dept)
            const journeyIndex = deptMapIndex * 3
            for (const leadId of department.leadIds) {
                const journeySlugs = [
                    journeys[journeyIndex + 0].slug,
                    journeys[journeyIndex + 1].slug,
                    journeys[journeyIndex + 2].slug
                ]
                const team = new Team({
                    id: teamId,
                    leadId: leadId,
                    providerSlug: department.providerSlug,
                    departmentSlug: department.slug,
                    journeySlugs: journeySlugs,
                    memberIds: [leadId]
                })
                teams.push(team)
                teamsMap[teamId++] = team

                const clinician = store.state.user.teamLeads[leadId]
                if (clinician) {
                    clinician.journeySlugs = journeySlugs
                }
            }
        }

        return {
            providers: providers,
            departments: departments,
            clinicians: clinicians,
            journeys: journeys,
            teams: teams
        }
    }

    /**
     * Mock a number of rows to add to an Experience report.
     * Returns the number of rows (array of objects) to add.
     * numPatients: the patient cohort size (answer distribution will add up to this)
     * survey: the Survey object
     * step: the Step object, assumed to be a QuestionStep with TextChoices
     */
    mockExperienceReportRows(config) {
        // Randomly distribute patients across choices
        let patientsUsed = 0
        const rows = []
        for (const textChoice of config.step.choices) {
            // Skip textChoice that matches any requiredTextChoice, as the value would be a free-text response
            if (textChoice == config.step.requiredTextField) {
                continue
            }
            // For netprom surveys, use only values >= 7
            let total
            if (
                !(config.survey.slug.includes('netprom') && parseInt(textChoice.value) < 7) &&
                patientsUsed < config.numPatients
            ) {
                total = 1 + Math.floor((Math.random() * config.numPatients) / 3)
                total = Math.min(total, config.numPatients - patientsUsed)
            } else {
                // Push empty row (required so legend shows full range)
                total = 0
            }
            const row = {
                // journeySlug: config.journeySlug,
                surveySlug: config.survey.slug,
                questionSlug: config.step.slug,
                answer: textChoice.value,
                total: total
            }
            // Object.keys(config.keyValues).forEach(
            //     key => (row[key] = config.keyValues[key])
            // )
            rows.push(row)
            patientsUsed += total
        }

        return rows
    }

    /**
     * Mock a row to add to a ProgressTracker report.
     * Returns the row (object) to add.
     * scheduleSlug: the schedule slug
     * numerator
     * denominator
     */
    mockProgressTrackerReportRow(config) {
        // Generate reasonable looking values depending on schedule
        const startOffsetRanges = [
            {
                startOffset: 0,
                valueMin: 94,
                valueVar: 4
            },
            {
                startOffset: 6 * 7,
                valueMin: 90,
                valueVar: 4
            },
            {
                startOffset: 3 * 30,
                valueMin: 86,
                valueVar: 4
            },
            {
                startOffset: 6 * 30,
                valueMin: 82,
                valueVar: 4
            },
            {
                startOffset: 1 * 365,
                valueMin: 78,
                valueVar: 4
            },
            {
                startOffset: 2 * 365,
                valueMin: 74,
                valueVar: 4
            }
        ]
        const schedule = Schedule.get(config.scheduleSlug)
        let numerator = 90
        for (const range of startOffsetRanges) {
            if (schedule.startOffset <= range.startOffset) {
                numerator = range.valueMin + Math.floor(range.valueVar * Math.random())
                break
            }
        }
        const row = {
            numerator: numerator,
            denominator: 100,
            scheduleSlug: config.scheduleSlug
        }

        return row
    }

    mockOwner(config) {
        config = config || {}
        const owner = new Owner(config)
        store.state.user.owner = owner

        return owner
    }

    mockOwnerWithPatientJourneyList() {
        const owner = new Owner({
            keyValues: {
                featureFlags: {
                    hasPatientJourneyList: true
                }
            }
        })
        store.state.user.owner = owner

        return owner
    }

    mockActivityList(withContent, withMenuActivity) {
        const activityListMilestone = new ActivityListMilestone({
            activityListSlug: 'activitylist-actlist',
            type: 'activityList'
        })
        const activityList = new ActivityList({
            slug: 'activitylist-actlist',
            activitySlugs: withMenuActivity
                ? ['foo-activity-menu', 'foo-activity', 'bar-activity']
                : ['foo-activity', 'bar-activity']
        })
        store.state.resources.activityLists = {
            'activitylist-actlist': activityList
        }

        store.state.content.content = {}
        if (withContent) {
            store.state.content.content = {
                'foo-content': { slug: 'foo-content', title: 'Foo content title' },
                'bar-content': { slug: 'bar-content', title: 'Bar content title' }
            }
            if (withMenuActivity) {
                store.state.content.content['foo-content-menu'] = {
                    slug: 'foo-content-menu',
                    title: 'Foo content menu title'
                }
            }
        }

        const activity1 = new Activity({
            slug: 'foo-activity',
            contentSlug: 'foo-content'
        })
        const activity2 = new Activity({
            slug: 'bar-activity',
            contentSlug: 'bar-content'
        })

        store.state.resources.activities = {
            'foo-activity': activity1,
            'bar-activity': activity2
        }

        let activity3
        if (withMenuActivity) {
            activity3 = new Activity({
                slug: 'foo-activity-menu',
                contentSlug: 'foo-content-menu'
            })
            store.state.resources.activities['foo-activity-menu'] = activity3
        }

        return {
            activityListMilestone: activityListMilestone,
            activityList: activityList,
            activity1: activity1,
            activity2: activity2,
            ...(withMenuActivity && { activity3: activity3 })
        }
    }

    mockUser(config) {
        User.constructWithDefaults = true

        return new User(config)
    }

    mockUnregisteredPatientWithSurveyResults(config) {
        const surveySlugs = this.mockSurveys({
            tags: ['clinical-survey'],
            surveySlugs: [config.surveySlug || 'bone-surv'],
            numSurveys: 1
        }).surveySlugs
        const journey = this.mockJourneys({
            surveySlugs: surveySlugs,
            scheduleSlugs: ['post-reg-filter-op', '2y-0d-pre-op']
        }).journey
        const opDate = config.opDate || '2024-09-18'
        const patient = this.mockPatients({
            journeySlug: journey.slug,
            operationDate: opDate
        }).patient
        const surveyResults = this.mockJourneySurveyResults({
            journeySlug: journey.slug,
            patientId: patient.personaId,
            primaryMilestoneDate: opDate,
            patientJourneyId: patient.firstJourney.patientJourneyId
        }).surveyResults
        patient.primaryJourney.surveyResults = surveyResults
        patient.surveyResults = surveyResults

        // Force SurveyResults dates to be early
        const earlyDate = config.earlyDate || '2024-05-23'
        const earlyMoment = moment(earlyDate)
        const earlyTime = earlyMoment.format(Utils.serialisedDateTimeFormat)
        for (const surveyResult of surveyResults) {
            surveyResult.setStartTime(earlyTime)
            surveyResult.setEndTime(earlyTime)
        }

        return {
            patient: patient,
            journey: journey,
            surveyResults: surveyResults
        }
    }
}

export default new MockService()
