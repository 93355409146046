import ListTransform from '@model/list/ListTransform'
import StringHelper from '@serv/StringHelper'

class ListTransformGrmaStocPreOpEducationAppt extends ListTransform {
    constructor(object) {
        object.filterKey = object.column.key
        super(object)
        this.sortKey = this.column.key
    }

    get getFilterConfig() {
        return this.column.getExplicitValueFilterConfig()
    }

    getQueryParamValueFromKeyStrings(keyStrings) {
        if (this.column.valueFilterOptions) {
            return keyStrings
                .map(key => {
                    const option = this.column.valueFilterOptions[key]

                    return Array.isArray(option) ? option[0] : option
                })
                ?.join(',')
        }

        return keyStrings.join(',')
    }

    getSortParameter() {
        return StringHelper.camelCaseToSnakeCase(this.column.key)
    }
}

export default ListTransformGrmaStocPreOpEducationAppt
