import ConfigManager from '@config/ConfigManager'
import Loader from '@comp/MrLoader.vue'
import { isAndroid, isIOS } from 'mobile-device-detect'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'PortalRegistrationMixin',
    components: {
        Loader
    },
    data() {
        return {
            isLoading: false
        }
    },
    computed: {
        ...mapGetters(['content', 'journeys', 'owner', 'user']),
        ...mapState({
            isNhs: state => state.portalRegistration.isNhs,
            isWebRegistration: state => state.portalRegistration.isWebRegistration,
            isStartingAtFrontPage: state => state.portalRegistration.isStartingAtFrontPage,
            didComeFromUrlWithToken: state => state.portalRegistration.didComeFromUrlWithToken,
            hospitalNumberAuthType: state => state.portalRegistration.hospitalNumberAuthType
        }),
        patient() {
            return this.user
        },
        is2Fa() {
            return this.patient ? this.patient.registrationMethod == '2fa' : false
        },
        is1Fa() {
            // If this.patient is undefined, choose 1fa, e.g. for patient self-registration
            return this.patient ? this.patient.registrationMethod == '1fa' : true
        },
        isMobileOnly() {
            return this.patient ? this.patient.registrationMethod == 'mobileOnly' : false
        },
        registrationMethod() {
            if (this.is2Fa) {
                return '2fa'
            }
            if (this.is1Fa) {
                return '1fa'
            }
            if (this.isMobileOnly) {
                return 'mobileOnly'
            }

            return 'unknown'
        },
        isAndroid() {
            return ConfigManager.devConfig.mockDevice == 'Android' || isAndroid
        },
        isIOS() {
            return ConfigManager.devConfig.mockDevice == 'iOS' || isIOS
        }
    }
}
