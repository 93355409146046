/**
 * Provides useful functions for all survey slider types: HealthScaleStep, PainScaleStep, SliderScaleStep
 * We can assume that SurveyStepMixin has also been used.
 */
export default {
    name: 'SurveySliderMixin',
    data() {
        return {
            minValue: undefined,
            maxValue: undefined,
            initialValue: undefined, // initialised from any StepResult.value
            value: undefined,
            active: false, // becomes active when slider first moved, or result exists
            isQuestionStepFreeTextValue: false // if true, writes as freeTextValue
        }
    },
    computed: {
        displayValue() {
            return this.active ? this.value : undefined
        }
    },
    methods: {
        // Initialise state from result
        init() {
            if (this.isQuestionStepFreeTextValue) {
                const number = parseFloat(this.stepResult.freeTextValue)
                if (isNaN(number)) {
                    this.initialValue = (this.minValue + this.maxValue) / 2
                } else {
                    this.initialValue = number
                    this.active = true
                }
            } else {
                if (this.stepResult.value == undefined) {
                    this.initialValue = (this.minValue + this.maxValue) / 2
                } else {
                    this.initialValue = this.stepResult.value
                    this.active = true
                }
            }
            this.value = this.initialValue
        }
    },
    watch: {
        value(newValue) {
            if (this.active || newValue != this.initialValue) {
                if (this.isQuestionStepFreeTextValue) {
                    this.stepResult.freeTextValue = newValue.toString()
                } else {
                    this.stepResult.value = newValue
                }
                this.active = true
                this.taskHandler.calcNextButtonStatus()
            }
        }
    }
}
