import _ from 'lodash'
import ExerciseService from '@serv/ExerciseService'
import ExerciseStep from '@model/content/ExerciseStep'
import Logging from '@serv/Logging'

class ExerciseSettings {
    constructor(object) {
        if (object instanceof ExerciseStep) {
            // Initialise from exercise
            this.stepSlug = object.slug
        } else {
            this.stepSlug = object.stepSlug || object.exerciseSlug
        }
        if (this.stepSlug == undefined) {
            Logging.error('ExerciseSettings must specify step slug')
        }
        this.title = object.title != null ? object.title : undefined
        this.text = object.text != null ? object.text : undefined

        // Note: These will all be undefined if initialising from ExerciseStep
        this.sets = object.sets != null && object.sets != 0 ? object.sets : undefined
        this.reps = object.reps != null && object.reps != 0 ? object.reps : undefined
        // eslint-disable-next-line no-constant-binary-expression
        this.duration = object.duration && (object.duration != 0) != null ? object.duration : undefined
        this.rest = object.rest != null ? object.rest : undefined
        if (this.reps != undefined && this.duration != undefined) {
            Logging.error(
                `ExerciseSettings for ${this.stepSlug}: reps ${this.reps} and duration ${this.duration} should not both be defined`
            )
        }

        // We store this.tempo as a string for convenience - we accept either form of input
        let tempoParts
        if (object.tempo) {
            this.tempo = object.tempo
            tempoParts = this.tempo.split(':')
            this.tempoEccentricValue = tempoParts[0]
            this.tempoConcentricValue = tempoParts[1]
            this.tempoIsometricValue = tempoParts[2]
        } else if (object.tempoEccentricValue != null) {
            this.tempoEccentricValue = object.tempoEccentricValue
            this.tempoConcentricValue = object.tempoConcentricValue
            this.tempoIsometricValue = object.tempoIsometricValue
            this.tempo = `${this.tempoEccentricValue}:${this.tempoConcentricValue}:${this.tempoIsometricValue}`
        } else {
            this.tempo = undefined
            this.tempoEccentricValue = undefined
            this.tempoConcentricValue = undefined
            this.tempoIsometricValue = undefined
        }

        this.load = object.load != null ? object.load / 1000 : undefined // BE stores in grams
        this.intensity = object.intensity != null ? object.intensity : undefined
        if (this.load != undefined && this.intensity != undefined) {
            Logging.error(
                `ExerciseSettings for ${this.stepSlug}: load ${this.load} and intensity ${this.intensity} should not both be defined`
            )
        }

        // activeDays == undefined => we will use the setting on the ExerciseRoutineModifier itself
        this.activeDays = object.activeDays ? ExerciseService.activeDaysEnumsToBools(object.activeDays) : undefined

        // Convert blank groupLabel back to undefined
        if (!object.groupLabel || object.groupLabel == ExerciseSettings.emptyGroupLabel) {
            this.groupLabel = undefined
        } else {
            this.groupLabel = object.groupLabel
        }

        // Used only when managing templates - never serialised to/from backend
        this.isSelected = false
    }

    get targetValue() {
        return (this.sets || 0) * (this.reps || 0)
    }
    get enabled() {
        return this.targetValue != 0
    }
    /**
     * Validate the object - we disallow saving a plan unless all ExerciseSettings pass validation.
     */
    get isValid() {
        return this.sets != undefined && (this.reps != undefined || this.duration != undefined)
    }

    get hasEmptyValues() {
        return (
            this.sets == undefined &&
            this.reps == undefined &&
            this.duration == undefined &&
            this.tempo == undefined &&
            this.rest == undefined &&
            this.load == undefined &&
            this.intensity == undefined &&
            (this.activeDays == undefined || !this.activeDays.includes(true))
        )
    }

    // Return true if active days undefined or all false
    get isActiveDaysUndefined() {
        return this.activeDays == undefined || !this.activeDays.includes(true)
    }

    // Compare another settings object, allowing virtual equivalence of activeDays
    isSameAs(settings) {
        const result =
            this.sets == settings.sets &&
            this.reps == settings.reps &&
            this.duration == settings.duration &&
            this.tempo == settings.tempo &&
            this.rest == settings.rest &&
            this.load == settings.load &&
            this.intensity == settings.intensity &&
            this.title == settings.title &&
            this.text == settings.text &&
            (_.isEqual(this.activeDays, settings.activeDays) ||
                (this.activeDays == undefined && settings.activeDays && !settings.activeDays.includes(true)) ||
                (settings.activeDays == undefined && this.activeDays && !this.activeDays.includes(true)))

        return result
    }

    setActiveDaysToAllTrue() {
        this.activeDays = [true, true, true, true, true, true, true]
    }
}

ExerciseSettings.emptyGroupLabel = 'UNDEFINED'

export default ExerciseSettings
