import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'

export default function _resolveObjectListColumnOfType_milestoneService(column, row) {
    if (!(row instanceof Milestone)) {
        Logging.error(`ListColumn.Type.milestoneService can only be used on objects of type: Milestone`)

        return
    }
    const milestone = row
    if (milestone.subtypeKey) {
        column.cellText = Locale.getLanguageItemForModelEnum(milestone.subtypeKey, milestone.subtype)
    }
}
