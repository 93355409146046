import _ from 'lodash'
import axios from 'axios'
import Config from '@serv/Config'
import ConfigManager from '@config/ConfigManager'
import ConfigOptions from '@config/ConfigOptions'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import NotifyService from '@serv/NotifyService.js'
import Request from '@serv/Request'
import RequestCycler from '@serv/RequestCycler'
import Storage from '@serv/Storage'

/**
 * Do Oauth handshake and set Authorization header on every axios request.
 *
 * The Auth service stores its state to `localStorage` between sessions so that
 * it can pickup where it left off when the session resumes.
 */
class MSKAuth {
    /**
     * Initialize Auth singleton with Oauth parameters.
     * @private
     */
    constructor() {
        this.reset()
        this.region = undefined // Set only if provided as part of auth, e.g. admin login
    }

    // Remove any auth header
    reset() {
        delete axios.defaults.headers.common['Authorization']
        this.session = {}
    }

    /**
     * Do authentication (login) with username and password.
     * Force `@test.mr` usernames to gb-staging.
     * @param {String}
     * @param {String}
     */
    doAuth(username, password) {
        let credentials = {
            grant_type: 'password',
            username: username,
            password: password
        }
        Logging.log('Cycling Auth request...')
        for (const country of Config.countries) {
            const suffix = `.${country}@msk.ai`
            if (username.endsWith(suffix)) {
                // POST for single specified country
                credentials.username = username.replace(suffix, '@msk.ai')
                const config = Config.getCountryOptions(country)
                this.region = country

                return this._doPost(credentials, config.base_url, config.oauth_client, config.oauth_secret).then(
                    _session => {
                        Config.configure(country)

                        return _session
                    }
                )
            }
        }

        return RequestCycler.cycleRequest((baseURL, country, client, secret) =>
            this._doPost(credentials, baseURL, client, secret)
                .then(_session => {
                    Config.configure(country)

                    return _session
                })
                .catch(error => {
                    if (error.response?.status == 403 && error.response?.data?.reason == Request.Error.mfaEnforced) {
                        NotifyService.error(
                            Locale.getLanguageItem('errorMfaEnforced', [error.response.data.redirectUrl])
                        )

                        return
                    }

                    throw error
                })
        )
    }

    /**
     * Post to the token endpoint with user credentials and oauth config.
     * @private
     */
    _doPost(credentials, baseUrl, client, secret) {
        const config = {
            credentials: {
                auth: {
                    username: client,
                    password: secret
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            },
            baseUrl: baseUrl
        }

        return Request.post('/oauth2-provider/token/', $.param(credentials), config).then(res =>
            this._authCallback(res)
        )
    }

    /**
     * Check access token and set headers.
     * We know nothing about `country` at this point. The country is
     * configured by the Request Cycler after a successful POST.
     * @private
     */
    _authCallback(res) {
        this.session = res.data
        this.session.environment = Config.environment
        if (!this.session.access_token) {
            throw new Error('No access_token provided by the server')
        }
        Storage.set('mr_session', this.session)
        this._setHeader(this.session.access_token)
        Logging.log('Session ', this.session)

        return this.session
    }

    /**
     * Set the Authorization header for all axios requests.
     * @private
     */
    _setHeader(access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    }

    /**
     * Check if there is an active session.
     * @return {Boolean}
     */
    hasSession() {
        /*
        Check if there is a session remembered in Storage
        and set it as the active session.
        */
        if (_.isEmpty(this.session)) {
            if (Config.spoof) {
                return true
            }

            if (ConfigManager.isMockingServer) {
                // Using mock data
                return true
            }

            if (_.isEmpty(Storage.get('mr_session'))) {
                Logging.log('No session found, you need to re-authenticate')

                return false
            }

            Logging.log('No active session, but there is one in Storage')
            this.session = Storage.get('mr_session')
            this._setHeader(this.session.access_token)
            Config.restore()
        }

        return true
    }

    /**
     * Destroy current session.
     */
    revokeToken() {
        if (ConfigOptions[this.session.environment]) {
            const activeConfig = ConfigOptions[this.session.environment][Config.country]
            if (activeConfig) {
                let oauthConfig = {
                    auth: {
                        username: activeConfig.oauth_client,
                        password: activeConfig.oauth_secret
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }

                return Request.post(
                    '/oauth2-provider/revoke_token/',
                    $.param({
                        token: this.session.access_token
                    }),
                    oauthConfig
                )
            }
        }
    }
}

export default new MSKAuth()
