import ListTransform from '@model/list/ListTransform'

class ListTransformRtmReviewReminder extends ListTransform {
    constructor(object) {
        object.filterKey = 'rtmReviewReminder'
        super(object)
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'rtm_review_reminder_next_date'
    }
}

export default ListTransformRtmReviewReminder
