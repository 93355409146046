import Logging from '@serv/Logging'
import store from '@/store'

/**
 * A clinical team.
 */
class Team {
    constructor(object) {
        this.id = object.id
        this.leadId = object.leadId
        this.providerSlug = object.providerSlug
        this.departmentSlug = object.departmentSlug
        this.journeySlugs = object.journeySlugs || []
        this.memberIds = object.memberIds || []
        this.isLegacy = !!object.isLegacy
    }

    // Return a globally-unique team ID, by prefixing the lead's region.
    get teamUid() {
        const lead = store.state.user.users[this.leadId]
        if (lead) {
            return `${lead.region}-${this.id}`
        }

        Logging.warn(`Could not find lead from personaId ${this.leadId}`)

        return `gb-${this.id}` // undefined
    }

    /**
     * Check if the team matches a department slug.
     * If the Team has this departmentSlug directly, OR if the team providerSlug
     * exactly matches the departmentSlug, we consider it a match.
     * This is so that we can create within the Provider objects a Department that mimics the whole Provider,
     * simply by giving it the same slug, rather than insisting that the Team has to specify that departmentSlug
     * directly.
     */
    matchesDepartment(departmentSlug) {
        return this.departmentSlug == departmentSlug || this.providerSlug == departmentSlug
    }
    matchesProvider(providerSlug) {
        return this.providerSlug == providerSlug
    }
    // Return true if the specified clinician is the lead or a member.
    hasClinician(clinician) {
        return this.leadId == clinician.personaId || this.memberIds.includes(clinician.personaId)
    }
}

export default Team
