import Milestone from '@model/Milestone'

/*
A review milestone object - intermediate class, NOT instantiated.
*/
class ReviewMilestone extends Milestone {
    constructor(object) {
        object.slug = object.slug || 'review'
        super(object)
        this.reviewerId = object.reviewerId
        this.isCancelled = object.isCancelled
    }
}

export default ReviewMilestone
