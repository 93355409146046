import ListTransform from '@model/list/ListTransform'

class ListTransformLastActive extends ListTransform {
    constructor(object) {
        object.filterKey = 'lastActiveTime'
        super(object)
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'last_active_time'
    }
}

export default ListTransformLastActive
