import _ from 'lodash'
import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
import store from '@/store'
import Utils from '@serv/Utils'

export function _resolveObjectListColumnOfType_teamMembers(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.teamMembers can only be used on objects of type: Patient`)

        return
    }

    const patient = row
    let leads = PatientService.getPatientLeads(patient)
    let users

    if (leads.length > 0) {
        column.teamLead = leads[0]
        users = leads[0]
        leads = leads.shift() // remove 1st element
    } else {
        column.teamLead = undefined
        users = []
    }

    users = _.union(leads, PatientService.getPatientReferees(patient))
    Utils.sortUsersByLastThenFirstNames(users)

    if (column.teamLead) {
        // Always display primaryJourney lead first
        users = [column.teamLead, ...users.filter(user => user.personaId != column.teamLead.personaId)]
    }

    // Truncate list to 3 items, with ellipsis
    let tooLong

    if (users.length > 3) {
        tooLong = true
        users = users.slice(0, 3)
    } else {
        tooLong = false
    }

    column.cellText = users
        .map(user => (column.hideRole ? user.titledFullName : user.titledFullNameWithRole))
        .join(', ')

    if (tooLong) {
        column.cellText += '...'
    }
}

export function _resolvePatientJourneyListColumnOfType_teamMembers(column, patientJourney, row) {
    let tooLong
    const users = new Set()
    let activeRefereeIds = row.activeRefereeIds || []

    if (patientJourney.leadId) {
        activeRefereeIds = [patientJourney.leadId, ...activeRefereeIds]
    }

    activeRefereeIds.forEach(refereeId => {
        const referee = store.state.user.users[refereeId]

        if (referee) {
            users.add(referee)
        }
    })

    if (users.size > 0) {
        let teamMembers = [...users]
        const lead = teamMembers.shift()
        Utils.sortUsersByLastThenFirstNames(teamMembers)
        teamMembers.unshift(lead)

        if (teamMembers.length > 3) {
            tooLong = '...'
            teamMembers = teamMembers.slice(0, 3)
        }

        column.cellText = `${teamMembers
            .map(user => (column.hideRole ? user.titledFullName : user.titledFullNameWithRole))
            .join(', ')}${tooLong || ''}`
    }
}
