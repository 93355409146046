import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'

/**
 * List filter by milestone's patient journey.
 */
class MilestoneListTransformPatientJourney extends ListTransform {
    constructor(object) {
        object.filterKey = 'patientJourney'
        super(object)
        this.patientJourneys = (object.patient || {}).pathwayJourneys
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem('keyDatesColumnPathway'),
            valueLabels: (this.patientJourneys || []).map(patientJourney => {
                return {
                    value: patientJourney.patientJourneyId.toString(),
                    label: patientJourney.summaryText
                }
            }),
            addAll: true
        }

        return config
    }

    // Filter and return the list of rows (milestones), based on the selected PatientJourney (id).
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0 || filterStrings[0].length == 0) {
            return rows
        }

        // row is Milestone, string is PatientJourney.patientJourneyId
        return rows.filter(row =>
            filterStrings.includes(((row.patientJourney || {}).patientJourneyId || '').toString())
        )
    }
}

export default MilestoneListTransformPatientJourney
