<template lang="pug">
extends SurveyStepTemplate.pug
block input
    <div class="survey-question-step">
        //- All choices
        <survey-question-step-choice v-if="shouldShowChoices" v-for="(choice, i) in step.choices" :key="`choice-${i}`" :delegate="getDelegate" :choiceIndex="i" :isSelected="choicesSelected[i]" />
        //- Free text
        template(v-if="showFreeTextArea")
            <p v-if="requiredTextLabel">{{ requiredTextLabel }}</p>
            <textarea class="survey-question-step__free-text" v-model="stepResult.freeTextValue" rows="4" :placeholder="getLangItem('patientSurveyTextareaPlaceholder')" />
        //- Date picker, when text field has type 'date'
        <mr-datepicker v-if="showFreeTextDate && dateFormat" @update:model-value="v => stepResult.freeTextValue = v" :date-format="dateFormat" name="date" :placeholder="getLangItem('patientSurveyFieldPlaceholder')" />
        //- Slider, when text field has type 'height' or 'weight'
        <div v-if="showFreeTextHeight || showFreeTextWeight" class="survey-slider-scale-step__slider">
            <div class="survey-slider-scale-step__slider__minmax">{{ minValue }}</div>
            <v-app class="survey-slider-scale-step__slider__slider">
                <v-slider class="survey-slider-scale-step__slider__vslider" v-model="value" :min="minValue" :max="maxValue" :step="stepValue" track-color="#c0c0c0" />
            </v-app>
            <div class="survey-slider-scale-step__slider__minmax">{{ maxValue }}</div>
        </div>
        <div style="display: flex;">
            <div class="survey-slider-scale-step__footer__labels-compact" style="text-align: left;">{{ step.sliderLeftText }}</div>
            <div class="survey-slider-scale-step__footer__value-compact"><span>{{ displayValue }}</span></div>
            <div class="survey-slider-scale-step__footer__labels-compact" style="text-align: right;">{{ step.sliderRightText }}</div>
        </div>
    </div>
</template>

<script>
import MrDatepicker from '@comp/MrDatePicker.vue'
import StringHelper from '@serv/StringHelper'
import SurveyQuestionStepChoice from '@comp/websurvey/SurveyQuestionStepChoice.vue'
import SurveySliderMixin from '@mixins/SurveySliderMixin'
import SurveyStepMixin from '@mixins/SurveyStepMixin'
import TaskHandler from '@serv/TaskHandler'

export default {
    name: 'SurveyQuestionStep',
    inject: ['$bus', '$config'],
    mixins: [SurveySliderMixin, SurveyStepMixin],
    components: {
        MrDatepicker,
        SurveyQuestionStepChoice
    },
    data() {
        return {
            // Array matching the Step.choices, with boolean state of each
            choicesSelected: [],
            showFreeTextArea: false,
            showFreeTextDate: false,
            dateFormat: undefined,
            showFreeTextHeight: false,
            showFreeTextWeight: false,
            sliderValue: undefined
        }
    },
    computed: {
        getDelegate() {
            return this
        },
        getImageBlobUrlFull() {
            let url = undefined
            if (this.step.keyValues.image) {
                const mediaImage = this.content[this.step.keyValues.image]
                if (mediaImage) {
                    url = mediaImage.blobUrlFull
                }
            }

            return url
        },
        isMulti() {
            return this.step.minSelected != undefined
        },
        shouldShowChoices() {
            return !this.step.isPureFreeText
        },
        // For height/weight slider - display metric and imperial value
        displayValue() {
            if (this.active) {
                if (this.showFreeTextHeight) {
                    return StringHelper.getMetricHeightInAllUnits(this.value)
                }
                if (this.showFreeTextWeight) {
                    return StringHelper.getMetricWeightInAllUnits(this.value)
                }
            }

            return undefined
        },
        // For height/weight slider - step value
        stepValue() {
            return this.showFreeTextHeight ? 0.01 : 1
        },
        requiredTextLabel() {
            return this.taskHandler.currentStep.requiredTextField || ''
        }
    },
    methods: {
        onChoiceClicked(choiceIndex) {
            if (this.taskHandler.isSkipping) {
                return
            }
            if (this.isMulti) {
                if (this.choicesSelected[choiceIndex]) {
                    // Always allow unselection
                    this.choicesSelected[choiceIndex] = false
                } else {
                    // Bound num selected
                    const numSelected = this.choicesSelected.filter(x => x).length
                    if (numSelected < this.step.maxSelected) {
                        this.choicesSelected[choiceIndex] = true
                    }
                }
            } else {
                // Toggle this one, turn all others off
                this.choicesSelected.forEach((value, i) => {
                    this.choicesSelected[i] =
                        i == choiceIndex ? !this.choicesSelected[i] : false
                })
            }
            this.updateResult()
            this.updateShowFreeText()
            this.taskHandler.calcNextButtonStatus()
            // Called AFTER state fully updated
            this.$bus.$emit('surveyQuestionStepChoice')
            this.handleAutoNext()
        },
        handleAutoNext() {
            // Auto skip to next step?
            if (
                this.taskHandler.isAutoNext &&
                this.taskHandler.nextButtonStatus ==
                    TaskHandler.NextButtonStatus.next &&
                !this.isMulti &&
                !this.step.optionalTextField &&
                !this.step.requiredTextField &&
                !this.taskHandler.isLastStep
            ) {
                this.taskHandler.isSkipping = true // prevent input during delay
                const _this = this
                setTimeout(() => {
                    _this.taskHandler.isSkipping = false
                    // Sending a DOM click event means we get any button animation
                    // _this.$refs.nextButton.click()
                    // This works, but we still do not see the animation
                    const elem = document.getElementById('nextButton')
                    elem.click()
                    //this.taskHandler.nextStep()
                }, 300)
            }
        },
        updateResult() {
            const selectedChoiceValues = this.choicesSelected
                .map((value, i) => {
                    return value ? this.step.choices[i].value : undefined
                })
                .filter(x => x)
            if (this.isMulti) {
                this.stepResult.setResultValue(selectedChoiceValues)
            } else {
                const choice =
                    selectedChoiceValues.length == 1
                        ? selectedChoiceValues[0]
                        : undefined
                this.stepResult.setResultValue(choice)
            }
        },
        updateShowFreeText() {
            if (this.taskHandler.isSkipping) {
                return
            }
            let choiceIndex
            if (this.step.optionalTextField != undefined) {
                choiceIndex = this.step.optionalTextFieldChoiceIndex || 0
            } else if (this.step.requiredTextField != undefined) {
                choiceIndex = this.step.requiredTextFieldChoiceIndex || 0
            }
            if (choiceIndex != undefined) {
                const dateTypes = ['date', 'yyyy-mm-dd', 'yyyy-mm', 'yyyy']
                const specialValues = [...dateTypes, 'height', 'weight']
                this.showFreeTextArea = this.choicesSelected[choiceIndex]
                if (
                    this.showFreeTextArea &&
                    specialValues.includes(this.step.choices[choiceIndex].value)
                ) {
                    this.showFreeTextArea = false
                    if (dateTypes.includes(this.step.choices[choiceIndex].value)) {
                        this.initAsDate(this.step.choices[choiceIndex].value)
                    } else if (this.step.choices[choiceIndex].value == 'height') {
                        this.initAsHeight()
                    } else if (this.step.choices[choiceIndex].value == 'weight') {
                        this.initAsWeight()
                    }
                }

                return
            }
            this.showFreeTextArea = false
            this.showFreeTextDate = false
            this.showFreeTextHeight = false
            this.showFreeTextWeight = false
        },
        initAsDate(dateFormat) {
            this.dateFormat = dateFormat
            this.showFreeTextDate = true
        },
        initAsHeight() {
            this.showFreeTextHeight = true
            this.minValue = 0.5 // m
            this.maxValue = 3.5 // m
            this.isQuestionStepFreeTextValue = true
            this.init() // SurveySliderMixin
        },
        initAsWeight() {
            this.showFreeTextWeight = true
            this.isQuestionStepFreeTextValue = true
            this.minValue = 40 // kg
            this.maxValue = 280 // kg
            this.isQuestionStepFreeTextValue = true
            this.init() // SurveySliderMixin
        }
    },
    created() {
        // Initialise state from result
        if (this.isMulti) {
            if (Array.isArray(this.stepResult.value)) {
                const choiceIndices = []
                this.step.choices.forEach((choice, i) => {
                    if (this.stepResult.value.includes(choice.value)) {
                        choiceIndices.push(i)
                    }
                })
                this.choicesSelected = Array.from(
                    { length: this.step.choices.length },
                    (_, i) => choiceIndices.includes(i)
                )
            } else {
                this.choicesSelected = new Array(this.step.choices.length).fill(false)
            }
        } else {
            if (this.stepResult.value != undefined) {
                // All array elements false, except the one that matches the stored value
                let choiceIndex = 0
                for (; choiceIndex < this.step.choices.length; choiceIndex++) {
                    if (this.step.choices[choiceIndex].value == this.stepResult.value) {
                        break
                    }
                }
                this.choicesSelected = Array.from(
                    { length: this.step.choices.length },
                    (_, i) => i == choiceIndex
                )
            } else {
                this.choicesSelected = new Array(this.step.choices.length).fill(false)
            }
        }
        // Initialise value if a single choice with free text
        if (this.step.isPureFreeText) {
            this.choicesSelected[0] = true
            this.updateResult()
        }
        this.updateShowFreeText()
    },
    watch: {
        'stepResult.freeTextValue': function () {
            this.taskHandler.calcNextButtonStatus()
        }
    }
}
</script>
