import ListColumn from '@model/list/ListColumn'
import Storage from '@serv/Storage'

/**
 * Owner model merging Provider.keyValues and the user.organisation.keyValues
 */
class Owner {
    constructor(object) {
        this.slug = object.slug
        this.keyValues = object.keyValues || {}

        // Ensure we have all of the basic keyValues
        this.keyValues.dash = this.keyValues.dash || {}
        this.keyValues.featureFlags = this.keyValues.featureFlags || {}
        this.keyValues.milestones = this.keyValues.milestones || {}
        this.keyValues.supportedInviteMethods = this.keyValues.supportedInviteMethods || ['2fa']
        this.keyValues.stringMap = this.keyValues.stringMap || {}

        const dashObjectKeys = ['charts', 'exercises', 'messaging', 'overviewPage', 'reports']
        for (const key of dashObjectKeys) {
            this.keyValues.dash[key] = this.keyValues.dash[key] || {}
        }
        const dashArrayKeys = [
            'clinicalMilestoneSpecs',
            'clinicianListColumns',
            'clinicianPageTabs',
            'consentTypes',
            'departmentListColumns',
            'departmentPageTabs',
            'locales',
            'overviewCptKpis',
            'mockUsers',
            'modelRequiredTags',
            'patientListColumns',
            'patientPageTabs'
        ]
        for (const key of dashArrayKeys) {
            this.keyValues.dash[key] = this.keyValues.dash[key] || []
        }
    }

    // Does this owner support multijourney?
    get hasMultiJourney() {
        return this.hasFeatureFlag('hasMultiJourney')
    }

    get hasChat() {
        return this.hasFeatureFlag('hasChat')
    }

    /**
     * Return true if the Owner configuration has any requirement to calculate PJ.scheduleEvents.
     * Currently these are used only by ListColumn.Type.surveysStatus
     */
    get requiresPatientJourneyScheduleEvents() {
        const listColumns = this.keyValues.dash.patientListColumns || []

        return (
            this.hasFeatureFlag('hasScheduleEvents') ||
            !!listColumns.find(column => column.type == ListColumn.Type.surveysStatus)
        )
    }

    /**
     * Does this user have a read-only dash, for patients?
     * If so, they will not be able to:
     * - Add patients
     * - Edit the patient fields that typically are provided through an integration
     */
    get hasReadOnlyPatientDash() {
        const storageValue = Storage.get('hasReadOnlyPatientDash')

        return !!this.keyValues.dash.hasReadOnlyPatientDash && storageValue !== false
    }

    // Does this owner use the PJ list (as opposed to the legacy Patient list)?
    get hasPatientJourneyList() {
        return this.hasFeatureFlag('hasPatientJourneyList')
    }

    /**
     * Return the "patient list version" as follows:
     * - If the owner uses original "patient list", return 1
     * - If the owner uses original "PJ list", return 2
     * - If the owner uses new-style "Goddard PJ list", return 3
     *
     * If localStorage.patientListVersion is defined, simply return that value. This is
     * designed to allow easy A/B testing between versions 2 and 3.
     */
    get patientListVersion() {
        const storageValue = Storage.get('patientListVersion')
        if ([1, 2, 3].includes(storageValue)) {
            return storageValue
        }

        const pjListKeyValues = this.keyValues?.dash?.patientJourneyList
        const pjListRoleConfigs = pjListKeyValues?.roleConfigs
        const patientListVersion = this.keyValues?.featureFlags?.patientListVersion

        if (patientListVersion === 3 && pjListKeyValues && pjListRoleConfigs) {
            return 3
        }

        if (this.hasPatientJourneyList) {
            return 2
        }

        return 1
    }

    get isGoddard() {
        return this.patientListVersion == 3
    }

    // Does this owner have a feature flag set?
    hasFeatureFlag(flag) {
        const storageValue = Storage.get(`featureFlag${flag}`)

        return typeof storageValue == 'boolean' ? storageValue : !!this.keyValues.featureFlags[flag]
    }

    hasSurveyResultFlag(flag) {
        if (this.keyValues.dash.charts.surveyResult) {
            return this.keyValues.dash.charts.surveyResult[flag]
        }
    }

    // Does this owner have a supported invite method?
    hasSupportedInviteMethod(method) {
        const storageValue = Storage.get(`supportedInviteMethod${method}`)

        return typeof storageValue == 'boolean' ? storageValue : this.keyValues.supportedInviteMethods.includes(method)
    }

    getTasksByType(type) {
        const tasks = this.keyValues.dash.tasks
        if (tasks) {
            return tasks.filter(task => task.type == type)
        }
    }

    getTaskByType(type) {
        const tasks = this.keyValues.dash.tasks

        if (tasks) {
            return tasks.find(task => task.type == type)
        }
    }

    // Get patient page component config
    getPatientPageTabCptConfig(classname) {
        const tabs = this.keyValues.dash.patientPageTabs
        if (tabs) {
            for (const tab of tabs) {
                const config = (tab.components || []).find(cpt => cpt.class == classname)

                if (config) {
                    return config
                }
            }
        }
    }
}

export default Owner
