import Logging from '@serv/Logging'

/**
 * Base class object for all data transforms.
 * A data transform provides functions to filter lists.
 */
class DataTransform {
    constructor(object) {
        if (object.filterSetKey == undefined || object.filterKey == undefined) {
            Logging.error('DataTransform does not specify filterSetKey and filterKey')
        }
        // Required: Unique index within store.state.data.filterSets
        this.filterSetKey = object.filterSetKey

        // Required: Unique index within FilterSet
        this.filterKey = object.filterKey

        this.filterLabel = object.filterLabel
        this.filterTypeAheadDataSourceReport = object.filterTypeAheadDataSourceReport
        this.filterTypeAheadPlaceholder = object.filterTypeAheadPlaceholder

        // Optional: Unique index within row
        // Some derived classes will ignore this, and do custom look-up of the row properties
        this.property = object.property

        this.clinicianList = object.clinicianList
        this.departmentList = object.departmentList
    }

    // Return the Vue component to use with this transform
    get getFilterComponent() {
        Logging.warn('Derived class should override')

        return undefined
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        return keyStrings.join(',')
    }

    /**
     * Filter and return the list of rows, based on an array of strings.
     * Filter passes if row property equals any provided string.
     */
    filterRows(rows, filterStrings) {
        if (this.property == undefined || filterStrings.length == 0) {
            return rows
        }

        return rows.filter(row => filterStrings.includes(row[this.property]))
    }
}

export default DataTransform
