import Locale from '@serv/Locale'
import moment from 'moment/moment'
import store from '@/store'
import Utils from '@serv/Utils'

export function _resolvePatientJourneyListColumnOfType_rtmReviewReminder(column, patientJourney, row) {
    const date = row.rtmReviewReminderNextDate
    const journey = store.state.resources.journeys[patientJourney.journeySlug]

    column.cellText = ''

    if (journey && journey.hasRtm) {
        if (date) {
            const nowMoment = moment().startOf('day')
            const dateMoment = moment(date)
            const diffText = Utils.getMomentDiffSummary(nowMoment, dateMoment)
            column.cellText = dateMoment.format(Utils.readableDateFormat)
            column.cellSubtitle = `(${diffText})`
        } else {
            column.cellText = Locale.getLanguageItem('notApplicable')
        }
    }
}
