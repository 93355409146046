import ConfigManager from '@config/ConfigManager'
import Locale from '@serv/Locale'
import { isAndroid, isIOS, isMobileOnly } from 'mobile-device-detect'
import { mapGetters, mapState } from 'vuex'

/**
 * Provides useful functions for components related to web surveys.
 */
export default {
    name: 'SurveysMixin',
    computed: {
        ...mapGetters([
            'clinicianCompleteForPatient',
            'content',
            'journeys',
            'patientJourneys',
            'patientJourneysSurveys',
            'taskHandler',
            'teams',
            'users',
            'user'
        ]),
        ...mapState({
            popupClass: state => state.popup.class,
            popupConfig: state => state.popup.config
        }),
        isPhone() {
            if (ConfigManager.devConfig.patientSurveysRandomAccess) {
                return !(this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl)
            }

            return isMobileOnly
        },
        isAndroid() {
            return ConfigManager.devConfig.mockDevice == 'Android' || isAndroid
        },
        isIOS() {
            return ConfigManager.devConfig.mockDevice == 'iOS' || isIOS
        },
        patient() {
            return this.clinicianCompleteForPatient || this.user
        },
        lead() {
            const team = this.teams[this.patient.firstJourney.teamId]
            if (team) {
                const lead = this.users[team.leadId]
                if (lead) {
                    return lead
                }
            }

            return undefined
        },
        leadName() {
            const leadName = this.lead ? this.lead.titledFullName : Locale.getLanguageItem('contentYourClinicianLc')

            return leadName
        },
        // Get the first content item with keyValues indicating a welcome info
        getWelcomeInfo() {
            if (this.content) {
                const welcomeInfos = Object.values(this.content).filter(
                    content => content.keyValues.template == 'welcome'
                )
                if (welcomeInfos.length > 0) {
                    return welcomeInfos[0]
                }

                return undefined
            }

            return undefined
        },
        // Get the banner MediaImage from the welcome Info
        getClinicianBannerMediaImage() {
            const welcomeInfo = this.getWelcomeInfo
            if (welcomeInfo && welcomeInfo.keyValues.banner) {
                return this.content[welcomeInfo.keyValues.banner]
            }

            return undefined
        },
        // Get the logo MediaImage from the welcome Info
        getClinicianLogoMediaImage() {
            const welcomeInfo = this.getWelcomeInfo
            if (welcomeInfo && welcomeInfo.keyValues.logo) {
                return this.content[welcomeInfo.keyValues.logo]
            }

            return undefined
        }
    },
    methods: {
        dismissPopup() {
            this.$store.commit('popup/dismiss')
        }
    }
}
