import Locale from '@serv/Locale'

const state = {
    registrationFormData: undefined,
    ownerConfig: undefined
}

const getters = {
    selfRegistrationFormData: state => {
        return state.selfRegistrationFormData
    },
    ownerConfig: state => {
        return state.ownerConfig
    }
}

const mutations = {
    setPatientRegistrationFormData(state, data) {
        state.registrationFormData = data
    },
    appendsPatientRegistrationFormData(state, data) {
        state.registrationFormData = { ...state.registrationFormData, ...data }
    },
    setOwnerConfig(state, data) {
        state.ownerConfig = data

        // Pass any string map values to Locale
        if (state.ownerConfig.keyValues?.stringMap) {
            Locale.stringMap = state.ownerConfig.keyValues.stringMap
        }
    }
}

const actions = {
    showNoActiveSurveyModal({ commit, state }) {
        commit(
            'popup/setConfig',
            {
                text: Locale.getLanguageItem('selfReferralSurveyNotFound'),
                buttonText: Locale.getLanguageItem('ok'),
                onButton: () => (window.location.href = state.ownerConfig.keyValues?.client?.patientPortalLandingLink)
            },
            {
                root: true
            }
        )
        commit('popup/setClass', 'PopupOneButton', {
            root: true
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true
}
