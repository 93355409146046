/**
 * Model a data series to be passed into SurveyResultsChart.
 * Holds the array of objects to be plotted, as well as things such as colour, type (line, column).
 *
 * slug: unique slug used to pair series with y axis and enabled state. Usually a content item slug, e.g. survey or step, or a journey slug
 * title: visible string that will be used as the series title, e.g. within the legend
 * type: 'line' or 'column' (optional). Defaults to 'line'
 * colour: hex string value. Used for lines, bullets, and series-specific y axis
 * fieldX: the data object property to use for the x axis. Defaults to 'time'
 * fieldY: the data object property to use for the y axis. Defaults to 'score'
 * data: array of objects, e.g. SurveyResults. Each must hold a minimal set of fields (see below)
 * minY: y axis min value
 * maxY: y axis max value
 * flipY: y axis should be inverted
 * patient: patient reference, if chart is for a single patient (e.g. used when opening survey result dialog)
 * visible: false if the line series should default to being hidden (toggled off)
 * valueToLabel: if provided, add a y axis adapter to map values to labels
 *
 * The objects themselves must have the following properties:
 * <fieldX>: whatever is specified in SurveyResultsChartSeries.fieldX must be a valid property on every object
 * <fieldY>: whatever is specified in SurveyResultsChartSeries.fieldY must be a valid property on every object
 */
class SurveyResultsChartSeries {
    // Empty constructor. Instead, we will call init() with a config object.
    constructor(object) {
        this.slug = object.slug
        this.title = object.title
        this.type = object.type || 'line'
        this.colour = object.colour
        this.fieldX = object.fieldX || 'time'
        this.fieldY = object.fieldY || 'score'
        this.data = object.data
        this.minY = object.minY
        this.maxY = object.maxY
        this.flipY = object.flipY
        this.patient = object.patient
        this.visible = object.visible == undefined ? true : object.visible
        this.valueToLabel = object.valueToLabel
    }
}

/**
 * Available baselines.
 */
SurveyResultsChartSeries.Baseline = {
    all: 'all',
    department: 'department',
    clinician: 'clinician',
    provider: 'provider',
    region: 'region'
}

export default SurveyResultsChartSeries
