// Functions for fetching and setting feature flags from the API
import NotifyService from '@serv/NotifyService'
import Request from '@serv/Request'
import { useFeatureFlags } from '@composables/state/useFeatureFlags'

class FeatureFlagService {
    constructor() {
        const { setFeatureFlags } = useFeatureFlags()
        this.setFeatureFlags = setFeatureFlags
    }

    fetchFeatureFlags() {
        const url = Request.Stem.featureFlags

        return Request.get(url)
            .then(response => {
                this.setFeatureFlags(response.data)
            })
            .catch(error => {
                NotifyService.error(error)

                return Promise.resolve()
            })
    }
}

export default new FeatureFlagService()
