import Logging from '@serv/Logging'
import Request from '@serv/Request'

/**
 * The TabCptMixin requestDetails() method returns an array of these objects.
 * Each object specifies a request to be made, and the component 'store' key to copy if successful.
 */
class TabCptRequestDetails {
    constructor(object) {
        this.verb = object.verb || Request.Verb.patch

        if (object.url == undefined) {
            Logging.error(`TabCptRequestDetails does not specify url: ${JSON.stringify(object)}`)
        }
        this.url = object.url

        const verbsRequiringPayload = [Request.Verb.patch, Request.Verb.post, Request.Verb.put]
        if (verbsRequiringPayload.includes(this.verb) && object.payload == undefined) {
            Logging.error(`TabCptRequestDetails does not specify payload: ${JSON.stringify(object)}`)
        }
        this.payload = object.payload
        this.config = object.config

        // If undefined, we copy the whole store
        this.storeKey = object.storeKey
    }
}

export default TabCptRequestDetails
