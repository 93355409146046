import Logging from '@serv/Logging'
import store from '@/store'

/**
 * A department within a provider.
 * These sit within state.resourecs (mapped by slug), and Providers reference these by slug.
 */
class Department {
    constructor(object, tags) {
        Object.keys(object).forEach(key => (this[key] = object[key]))

        this.leadIds = this.leadIds || []

        if (tags) {
            tags.map(tag => {
                this[tag.dimension] = tag.value
            })
        }
    }

    get region() {
        const provider = store.state.resources.providers[this.providerSlug]
        if (!provider) {
            Logging.error(`Could not find provider: ${this.providerSlug}`)
        } else {
            return provider.region
        }

        return undefined
    }

    get getStringKeyForRegion() {
        const provider = store.state.resources.providers[this.providerSlug] || {}

        return provider.getStringKeyForRegion
    }

    // Return true if the Department represents a whole Provider.
    get isProvider() {
        return this.slug == this.providerSlug
    }
}

export default Department
