import BaseContent from '@model/content/BaseContent'
import Step from '@model/content/Step'

class InfoStep extends Step {
    constructor(object) {
        object.type = BaseContent.Type.infoStep
        super(object)
    }
}

export default InfoStep
