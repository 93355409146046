const state = {
    isShowChatPane: false
}

const getters = {
    isChatPaneVisible: state => {
        return state.isShowChatPane
    }
}

const mutations = {
    showChat(state, value) {
        state.isShowChatPane = value
    }
}

export default {
    state,
    getters,
    mutations
}
