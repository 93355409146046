import Milestone from '@model/Milestone'
import ReviewMilestone from '@model/ReviewMilestone'

/*
A survey review milestone object, created when a user clicks "I have reviewed" on a survey detail popup.
*/
class SurveyReviewMilestone extends ReviewMilestone {
    constructor(object) {
        object.type = Milestone.Type.surveyReview
        object.slug = object.slug || 'survey-review'
        super(object)
        this.resultPrmId = object.resultPrmId
        this.reviewerId = object.reviewerId
        this.notes = object.notes
    }
}

export default SurveyReviewMilestone
