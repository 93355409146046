import DataService from '@serv/DataService'
import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import Schedule from '@model/Schedule'
import StringHelper from '@serv/StringHelper'
import Utils from '@serv/Utils'

/**
 * List filter for date column.
 */
class ListTransformDate extends ListTransform {
    constructor(object) {
        super(object)
        if (object.filterSetKey == 'patientJourneyList' && this.column.dateMilestone) {
            this.filterKey = this.column.dateMilestone
        }

        // Typically we are comparing against 'now', but for testing purposes we allow passing in a separate moment
        this.todayMoment = object.nowMoment
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: [],
            addAll: true
        }
        if (this.column.filter) {
            if (this.column.filter.schedules) {
                // Schedules explicitly specified
                this.column.filter.schedules.forEach(scheduleSlug => {
                    config.valueLabels.push({
                        value: scheduleSlug,
                        label: StringHelper.capitalize(Locale.getLanguageItem(scheduleSlug))
                    })
                })
            } else {
                // Default - All, before, after
                const beforeLabel = DataService.getDateFilterStringForKeyAndMilestoneSlug(
                    'patientListFilterDateBefore',
                    this.column.dateMilestone
                )
                const afterLabel = DataService.getDateFilterStringForKeyAndMilestoneSlug(
                    'patientListFilterDateAfter',
                    this.column.dateMilestone
                )
                config.valueLabels.push({
                    value: 'before',
                    label: beforeLabel
                })
                config.valueLabels.push({
                    value: 'after',
                    label: afterLabel
                })
            }
        }

        return config
    }

    /**
     * Sort the list of rows in place.
     * Null dates are always at the bottom, regardless of sort direction.
     */
    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        const reverse = this.column.subtype == 'daysSince' || this.column.subtype == 'weeksSince'
        const momentFuture = new moment(Utils.dateFarFuture)
        rows.sort((a, b) => {
            const valueA = a.listColumns[columnIndex].moment || momentFuture
            const valueB = b.listColumns[columnIndex].moment || momentFuture

            // If column is displaying "time since", then reverse the order
            return reverse ? valueB.diff(valueA) : valueA.diff(valueB)
        })
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return `${this.column.dateMilestone}_date`
    }

    reverseSort() {
        return this.column.subtype == 'weeksSince'
    }

    /**
     * We want null dates to be always at the bottom.
     */
    reverse(rows) {
        DataService.reverseRowsByDate(rows, this.column)
    }

    /**
     * Filter and return the list of rows, based on input strings.
     * The string is the dashstring key corresponding to all, before date, after date.
     * The date is this.column.moment
     */
    filterRows(rows, filterStrings) {
        const todayMoment = (this.todayMoment || moment()).startOf('day')
        if (filterStrings.length == 0) {
            return rows
        }
        if (filterStrings.length > 1) {
            Logging.warn('ListTransformDate.filterRows is being passed more than 1 string, using 1st only')
        }
        const filterString = filterStrings[0]
        const columnIndex = this.column.columnIndex
        let rowMoment

        // Assume ListColumn.type == date
        if (filterString == 'after') {
            const filtered = rows.filter(row => {
                // Date is set, and we are after it
                rowMoment = row.listColumns[columnIndex].moment

                return rowMoment && todayMoment >= rowMoment
            })

            return filtered
        }
        if (filterString == 'before') {
            const filtered = rows.filter(row => {
                // Date is NOT set, or date is set and we are before it
                rowMoment = row.listColumns[columnIndex].moment

                return rowMoment == undefined || todayMoment <= rowMoment
            })

            return filtered
        }
        if (filterString == 'all') {
            // Unfiltered
        } else if (filterString == 'date-undefined') {
            // Only those with no date set
            const filtered = rows.filter(row => row.listColumns[columnIndex].moment == undefined)

            return filtered
        } else {
            // Try schedule
            const schedule = Schedule.get(filterString)
            if (schedule) {
                const filtered = rows.filter(row => {
                    // Return true if 'now' is within this schedule, assuming the referenced moment is the row.moment
                    const rowMoment = row.listColumns[columnIndex].moment
                    const valid = schedule.withMilestoneMomentContainsMoment(rowMoment, todayMoment)

                    return valid
                })

                return filtered
            }
        }

        return rows
    }
    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        if (keyStrings.length > 0) {
            const filter = keyStrings[0]
            if (filter == 'date-undefined' || filter == 'before' || filter == 'reg-not-exists') {
                return { exists: false }
            }

            if (filter == 'after' || filter == 'reg-exists') {
                return { exists: true }
            }

            const schedule = Schedule.get(filter)
            const startDate = new moment().subtract(schedule.endOffset, 'days').format(Utils.serialisedDateFormat)
            const endDate = new moment().subtract(schedule.startOffset, 'days').format(Utils.serialisedDateFormat)

            const dateRange = {
                startDate: startDate,
                endDate: endDate
            }

            return isGoddard ? { dateRange } : dateRange
        }
    }
}

export default ListTransformDate
