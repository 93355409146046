import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'

export default function _resolveObjectListColumnOfType_milestoneAcceptance(column, row) {
    if (!(row instanceof Milestone)) {
        Logging.error(`ListColumn.Type.milestoneAcceptance can only be used on objects of type: Milestone`)

        return
    }
    const milestone = row
    if (milestone.type == Milestone.Type.referral && milestone.status) {
        column.cellText = Locale.getLanguageItemForModelEnum('referralStatus', milestone.status)
    } else if (milestone.type == Milestone.Type.appointment && milestone.acceptance) {
        column.cellText = Locale.getLanguageItemForModelEnum('appointmentStatus', milestone.acceptance)
    }
}
