import Milestone from '@model/Milestone'
import ReviewMilestone from '@model/ReviewMilestone'

class TaskReviewMilestone extends ReviewMilestone {
    constructor(object) {
        object.type = Milestone.Type.taskReview
        object.slug = object.slug || 'custom-task'
        super(object)
        this.title = object.title
        this.notes = object.notes
        this.reviewedDate = object.reviewedDate
    }
}

export default TaskReviewMilestone
