<template>
    <div class="survey-outro-step-header-score-section">
        <span class="survey-outro-step-header-score-section-label">
            {{ `${scoreSection.name}: ` }}
        </span>
        <span class="survey-outro-step-header-score-section-value">
            {{ scoreSection.score }}
        </span>
        <span class="survey-outro-step-header-score-section-slash">/</span>
        <span class="survey-outro-step-header-score-section-max">
            {{ scoreSection.maxScore }}
        </span>
    </div>
</template>

<script>
// A single subscore row to display on the survey outro page.
export default {
    name: 'SurveyOutroStepHeaderScoreSection',
    props: {
        scoreSection: { type: Object, required: true }
    }
}
</script>

<style lang="scss">
@import '../../styles/websurvey';

.survey-outro-step-header-score-section {
    padding-top: 2vh;
    width: 100%;
    margin: 0;
    line-height: 1;
    display: flex;
    justify-content: center;
    font-size: 2vh;
    font-family: $defaultFontMed;

    &-label {
        color: white;
        display: inline-block;
        align-self: flex-end;
        margin-right: 20px;
    }

    &-value {
        color: white;
        display: inline-block;
        align-self: flex-end;
    }

    &-slash {
        width: 20px;
        color: $mediumGrey;
        display: inline-block;
        align-self: flex-end;
    }

    &-max {
        color: $mediumGrey;
        display: inline-block;
        align-self: flex-end;
    }
}
</style>
