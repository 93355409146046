<template>
    <div class="six wide column loginback prelogin">
        <div class="login__choices">
            <div class="loginback__image">
                <p>{{ getLangItem('corinWelcomeTo') }}</p>

                <img
                    :src="getImageUrl('corin-rpm-tm-logo.png')"
                    alt=""
                />
            </div>

            <div class="login__choice">
                <h3>{{ getLangItem('corinIAmSurgeon') }}</h3>

                <button
                    id="button-login-corin"
                    class="ui button primary fluid mb-medium"
                    @click.prevent="redirectToPortal"
                >
                    {{ getLangItem('corinLoginWithConnect') }}&trade;
                </button>
            </div>

            <div class="login__choice">
                <h3>{{ getLangItem('corinPartOfSurgeonsTeam') }}</h3>

                <button
                    id="button-login-standard"
                    class="ui button primary fluid"
                    @click.prevent="redirectToSecondStep = true"
                >
                    {{ getLangItem('login') }}
                </button>
            </div>
            <p class="versiontag">
                {{ version }}
            </p>
        </div>
    </div>
</template>

<script>
import Auth from '@serv/Auth'
import Config from '@serv/Config'
import Redirect from '@serv/Redirect'
import useImage from '@composables/useImage'

export default {
    name: 'PreLogin',
    setup() {
        const { getImageUrl } = useImage()

        return {
            getImageUrl
        }
    },
    emits: ['goToNextStep'],
    data: () => ({
        redirectToSecondStep: false
    }),
    computed: {
        version() {
            return Config.version
        }
    },
    methods: {
        redirectToPortal() {
            Auth.doAuth()
        }
    },
    mounted: () => {
        if (Auth.session && Auth.session.idToken) {
            Redirect.gotoPath('corin-redirect')
        }
    },
    watch: {
        redirectToSecondStep(val) {
            this.$emit('goToNextStep', val)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors';
@import '../styles/fonts';

.login__choices {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    height: 833px;
    width: 375px !important;
    margin-left: 100px;

    .login__choice {
        width: 302px;
    }

    .loginback__image {
        p {
            color: #000;
            margin-bottom: 0;
            line-height: 1;
            font-size: 1.7rem;
        }

        img {
            max-width: 320px;
            margin-bottom: 50px;
        }
    }

    .ui.button {
        white-space: nowrap;
        display: block;
        padding: 10px 20px 15px;
        box-shadow: 0 4px 6px rgb(50 50 93 / 0.11), 0 1px 3px rgb(0 0 0 / 0.08);
        background: $green;
        border-radius: 3px;
        color: $white;
        text-decoration: none;
        transition: all 0.15s ease;
        border: none;
        outline: none;
        position: relative;
        width: 100%;
        font-size: 16px;
        font-family: Omnes Semibold;
        cursor: pointer;

        &:hover {
            color: $white;
            background-color: $green;
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgb(50 50 93 / 0.1), 0 3px 6px rgb(0 0 0 / 0.08);
            text-decoration: none;
        }

        &.mb-medium {
            margin-bottom: 55px;
        }
    }

    h3 {
        margin: 0 0 15px;
        font-size: 1.5rem;
        line-height: 1.5;
        font-family: $defaultFont;
    }
}
</style>
