import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'

// Mapping from start offset to canonical slug
const startOffsetPeriods = [
    { offset: 0, slug: 'post' },
    { offset: 6 * 7, slug: '6w-post' },
    { offset: 3 * 30, slug: '3m-post' },
    { offset: 6 * 30, slug: '6m-post' },
    { offset: 365, slug: '1y-post' },
    { offset: 2 * 365, slug: '2y-post' },
    { offset: 3 * 365, slug: '3y-post' },
    { offset: 4 * 365, slug: '4y-post' },
    { offset: 5 * 365, slug: '5y-post' },
    { offset: 10 * 365, slug: '10y-post' }
]

class Schedule {
    /**
     * A schedule object. Ported from the mr-content-script schedule.py
     */
    constructor(object) {
        if (typeof object === 'undefined') {
            Logging.log('Cannot initialise Schedule from undefined')
        } else if (typeof object === 'string') {
            this._setupFromSlug(object)
        } else {
            this.slug = object.slug
            this.milestone = object.milestone
            this.type = object.type
            this.startOffset = object.startOffset
            this.endOffset = object.endOffset
            this.interval = object.interval === undefined ? 1 : object.interval
            this.intervalStartOffset = object.intervalStartOffset === undefined ? 0 : object.intervalStartOffset
            this.intervalEndOffset = object.intervalEndOffset === undefined ? 1 : object.intervalEndOffset

            this.qualifier = object.qualifier
            this.filterMilestoneSlug = object.filterMilestoneSlug
            this.milestoneSubtype = object.milestoneSubtype
        }
        this.fixedStartDayOfWeek = this.slug ? this.calculateFixedStartDayOfWeek(this.slug) : undefined
    }

    /**
     * Get or create Schedule from slug.
     */
    static get(slug) {
        let schedule = schedules[slug]
        if (schedule === undefined) {
            // Create Schedule from slug
            schedule = new Schedule(slug)
            if (schedule.milestone == undefined) {
                schedule = undefined
            } else {
                schedules[slug] = schedule
            }
        }

        return schedule
    }

    /**
     * Get the number of Schedule objects currently stored.
     */
    static numSchedules() {
        return Object.keys(schedules).length
    }

    /**
     * Sort a list of schedule slugs, increasing by start offset.
     */
    static sortScheduleSlugsByStartOffset(slugs) {
        return slugs.sort(function (slugA, slugB) {
            const scheduleA = Schedule.get(slugA)
            const scheduleB = Schedule.get(slugB)
            if (!scheduleA) {
                Logging.error(`Could not create schedule: ${slugA}`)
            }
            if (!scheduleB) {
                Logging.error(`Could not create schedule: ${slugB}`)
            }
            if (!scheduleA || !scheduleB) {
                return 0
            }

            return scheduleA.startOffset - scheduleB.startOffset
        })
    }

    get summary() {
        let scheduleSummary = Locale.getLanguageItemOrUndefined(this.slug)
        if (!scheduleSummary) {
            const milestoneText = Locale.getLanguageItemForModelEnum('milestone', this.milestone)
            if (this.startOffset < 0 && this.endOffset <= 0) {
                // Pre
                if (this.milestone == 'operation') {
                    scheduleSummary = Locale.getLanguageItemOrUndefined('pre-op')
                } else {
                    // If not pre-op, generally we would expect the exact schedule slug to be stringmapped
                }
            } else if (this.startOffset >= 0) {
                // Post
                const startOffsetSlug = this.getStartOffsetSlug('operation')
                const startOffsetSummary = Locale.getLanguageItemOrUndefined(startOffsetSlug)
                if (this.isRepeating) {
                    // e.g. 0y-2y-post-reg-rep...
                    scheduleSummary = undefined
                } else if (this.startOffset == 0) {
                    // e.g. "Registration"
                    scheduleSummary = milestoneText
                } else if (this.milestone == 'operation') {
                    // special case, e.g. "6m"
                    scheduleSummary = `${startOffsetSummary}`
                } else {
                    // e.g. "3m Discharge"
                    scheduleSummary = `${startOffsetSummary} ${milestoneText}`
                }
            } else {
                // Span - generally we would expect the exact schedule slug to be stringmapped
                scheduleSummary = milestoneText
            }
        }

        return scheduleSummary
    }

    /**
     * Get the canonical slug related to the schedule start offset. This can also be used as a string key.
     * We allow passing a milestoneSlug that differs from this.milestone. This is so that, even for schedules
     * not using 'operation', we can generate string keys like '6m-post-op' to get localised strings like '6 months'...
     * these are still useful prefixes for items scheduled at 6m-post any milestone.
     */
    getStartOffsetSlug(milestoneSlug) {
        let period = 'pre'
        for (let i = startOffsetPeriods.length - 1; i >= 0; i--) {
            const offsetPeriod = startOffsetPeriods[i]
            if (this.startOffset >= offsetPeriod.offset) {
                period = offsetPeriod.slug
                break
            }
        }
        const abbr = Milestone.slugToAbbr(milestoneSlug || this.milestone)

        return `${period}-${abbr}`
    }

    /**
     * Get the schedule width in days.
     */
    get width() {
        return this.endOffset - this.startOffset
    }

    // Is this schedule pre-op?
    get isPreOp() {
        return this.milestone == 'operation' && this.endOffset <= 0
    }

    // Is this schedule post-op?
    get isPostOp() {
        return this.milestone == 'operation' && this.startOffset >= 0
    }

    // Is this schedule post-reg?
    get isPostReg() {
        return this.milestone == 'registration' && this.startOffset >= 0
    }

    // Is this schedule 'never' ?
    get isNever() {
        return this.slug == 'never'
    }

    /**
     * From a string of form 1d, 2w, 3m, 4y, 12d, 34w etc. return the number of days.
     */
    _getNumDaysFromStr(part, slug) {
        let index = -1
        let mult = 0
        if (part.indexOf('d') > 0) {
            mult = 1
            index = part.indexOf('d')
        } else if (part.indexOf('w') > 0) {
            mult = 7
            index = part.indexOf('w')
        } else if (part.indexOf('m') > 0) {
            mult = 30
            index = part.indexOf('m')
        } else if (part.indexOf('y') > 0) {
            mult = 365
            index = part.indexOf('y')
        } else {
            Logging.warn(`Invalid numeric part: ${part} within schedule slug: ${slug}`)

            return
        }

        return parseInt(part.substring(0, index)) * mult
    }

    // Is a schedule part a date offset?
    isPartDateOffset(part) {
        const chars = ['d', 'w', 'm', 'y']
        let ofs =
            part.length >= 2 &&
            part[0] >= '0' &&
            part[0] <= '9' &&
            chars.includes(part[part.length - 1]) &&
            (part.length == 2 || (part[part.length - 2] >= '0' && part[part.length - 2] <= '9'))

        return ofs
    }

    /**
     * Return true only if this schedule entirely contains that specified.
     * HACK: If our slug includes 'always', we return true. This allows us to assume containment
     * without having to evaluate non-matching milestones, e.g. registration/operation.
     */
    contains(schedule) {
        return (
            this.slug.includes('always') ||
            (this.milestone == schedule.milestone &&
                this.startOffset <= schedule.startOffset &&
                this.endOffset >= schedule.endOffset)
        )
    }

    /**
     * Return true only if this schedule overlaps that specified.
     * HACK: If either slug includes 'always', we return true. This allows us to assume containment
     * without having to evaluate non-matching milestones, e.g. registration/operation.
     */
    overlaps(schedule) {
        return (
            this.slug.includes('always') ||
            schedule.slug.includes('always') ||
            (this.milestone == schedule.milestone &&
                // https://stackoverflow.com/questions/3269434/whats-the-most-efficient-way-to-test-if-two-ranges-overlap
                this.startOffset < schedule.endOffset &&
                schedule.startOffset <= this.endOffset)
        )
    }

    // Is schedule repeating?
    get isRepeating() {
        return this.type == Schedule.Type.repeatingRelative
    }

    /**
     * Return true only if this schedule, with specified milestone date moment, contains the specified
     * date moment.
     * HACK: If slug includes 'always', we return true. This allows us to assume containment
     * without having to evaluate non-matching milestones, e.g. registration/operation.
     */
    withMilestoneMomentContainsMoment(milestoneMoment, inMoment) {
        if (this.slug.includes('always')) {
            return true
        }
        if (milestoneMoment == undefined) {
            return false
        }
        const offsetDays = inMoment.diff(milestoneMoment, 'days')

        return offsetDays >= this.startOffset && offsetDays < this.endOffset
    }

    /**
     * Calculate any fixed start day of week, from a slug.
     * Returns an integer [0..6] or undefined.
     */
    calculateFixedStartDayOfWeek(slug) {
        const daySuffices = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
        const parts = slug.split('-')
        let index
        if (parts.length) {
            const lastPart = parts[parts.length - 1]
            index = daySuffices.findIndex(suffix => suffix == lastPart)
        }

        return index >= 0 ? index : undefined
    }

    /**
     * From a given moment, and our fixedStartDayOfWeek, calculate a day offset [0..6]
     */
    calculateDayOfWeekOffsetFromMoment(dateMoment) {
        if (this.fixedStartDayOfWeek != undefined) {
            // Schedule is forcing fixed day of week
            const dateOffset = (dateMoment.day() + 6) % 7 // day of week index, 0=mon, 6=sun

            return (this.fixedStartDayOfWeek + 7 - dateOffset) % 7
        }

        // Schedule is not forcing fixed day of week
        return 0
    }

    /**
     * Initialise purely from a slug, using the familiar form e.g. '30d-1d-pre-op'
     * If we cannot initialise, we set type to None, and calling code should check this.
     * 07/10/21: _ofs offset are now ignored.
     */
    _setupFromSlug(slug) {
        let orgSlug = slug
        if (slug.indexOf('_ofs') > 0) {
            const parts = slug.split('_ofs')
            slug = parts[0]
        }

        // Deal with plus minus schedule logic
        let pomDays = undefined
        if (slug.indexOf('_pom') > 0) {
            const parts = slug.split('_pom')
            const part = parts[parts.length - 1]
            slug = parts[0]
            pomDays = this._getNumDaysFromStr(part, slug)
        }

        let startDays = undefined
        let endDays = undefined
        let side = undefined
        let milestone = undefined
        let type = 'rel'
        let interval = 1
        let intervalEndOffset = 1
        let qualifier = undefined
        let filterMilestoneSlug = undefined
        let toFilter = undefined
        let milestoneSubtype = undefined

        const parts = slug.split('-')
        parts.forEach(part => {
            if (this.isPartDateOffset(part)) {
                let numDays = this._getNumDaysFromStr(part, slug)
                if (startDays == undefined) {
                    startDays = numDays
                } else {
                    endDays = numDays
                }
            } else if (part == 'pre' || part == 'post' || part == 'span') {
                side = part
            } else if (part.startsWith('rep')) {
                type = 'rep'
                interval = parseInt(part.substring(3))
                if (isNaN(interval)) {
                    interval = 1
                }
            } else if (part.startsWith('dur')) {
                intervalEndOffset = parseInt(part.substring(3))
                if (isNaN(intervalEndOffset)) {
                    intervalEndOffset = 1
                }
            } else if (milestone == undefined) {
                // If part contains underscore, we assume 2nd section is milestone_subtype
                const underscoreIndex = part.indexOf('_')
                if (underscoreIndex >= 0) {
                    milestoneSubtype = part.substring(underscoreIndex + 1)
                    part = part.substring(0, underscoreIndex)
                }
                // 12/11/22 If we can't map the part (abbr) to a milestone slug, we assume the part IS the slug
                // This allows us to accept things like MOD content-bundle-milestones.
                milestone = Milestone.abbrToSlug(part) || part
            } else if (milestone && Object.values(Schedule.Qualifier).includes(part)) {
                qualifier = part
            } else if (milestone && part == 'filter') {
                toFilter = true
            } else if (toFilter) {
                toFilter = false
                filterMilestoneSlug = Milestone.abbrToSlug(part)
            }
        })

        // Validate side
        let sign
        if (side == 'pre') {
            sign = -1
        } else if (side == 'post') {
            sign = 1
        } else if (side == 'span') {
            sign = -1
        } else {
            // Assume single day of milestone
            startDays = 0
            endDays = 1
            sign = 1
        }

        // Validate start
        if (startDays == undefined) {
            if (side == 'span') {
                Logging.warn(`Schedule slug specifies span, but no start offset: ${slug}`)
            } else if (side == 'pre') {
                startDays = 10 * 365 // pure "pre-op" will give us a window of 10 years pre-op
                endDays = 0
            } else {
                startDays = 0
                endDays = 10 * 365 // pure "post-op" will give us a window of 10 years pre-op
            }
        }

        // Validate end
        startDays = startDays * sign
        if (endDays == undefined) {
            if (side == 'span') {
                Logging.warn(`Schedule slug specifies span, but no start offset: ${slug}`)
            }
            // If end not specified, assume 1d past start
            if (pomDays != undefined) {
                endDays = startDays + pomDays
                startDays = startDays - pomDays
            } else {
                endDays = startDays + 1
            }
        } else if (side != 'span') {
            endDays = endDays * sign
        }

        // Validate milestone
        if (milestone == undefined) {
            // Logging.warn('Could not determine milestone from schedule slug: ' + slug)
            return
        }
        if (startDays > endDays) {
            Logging.warn(`Schedule startDays > endDays: ${slug}`)

            return
        }

        // Validate type
        type = type == 'rel' ? 'relative' : 'repeatingRelative'
        this.slug = orgSlug
        this.type = type
        this.milestone = milestone
        this.startOffset = startDays
        this.endOffset = endDays
        this.interval = interval
        this.intervalStartOffset = 0
        this.intervalEndOffset = intervalEndOffset
        this.qualifier = qualifier
        this.filterMilestoneSlug = filterMilestoneSlug
        this.milestoneSubtype = milestoneSubtype
    }
}

// Schedule types
Schedule.Type = {
    relative: 'relative',
    repeatingRelative: 'repeatingRelative'
}

// Schedule qualifiers
Schedule.Qualifier = {
    earliest: 'earliest',
    latest: 'latest'
}

// Map from slug to object
let schedules = {
    always: new Schedule({
        slug: 'always',
        type: Schedule.Type.relative,
        milestone: 'registration',
        startOffset: -365,
        endOffset: 10 * 365
    }),
    'always-rep': new Schedule({
        slug: 'always-rep',
        type: Schedule.Type.repeatingRelative,
        milestone: 'registration',
        startOffset: -2,
        endOffset: 10 * 365
    }),
    never: new Schedule({
        slug: 'never',
        type: Schedule.Type.relative,
        milestone: 'registration',
        startOffset: -1,
        endOffset: -1
    })
}

export default Schedule
