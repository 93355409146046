/**
 * Static functions used by web survey JS interface.
 */
let singleton

class ResultsManager {
    static get() {
        return singleton
    }
}
singleton = new ResultsManager()

export default ResultsManager
