import chat from './modules/chat'
import content from './modules/content'
import { createStore } from 'vuex'
import data from './modules/data'
import popup from './modules/popup'
import portal from './modules/portal'
import portalRegistration from './modules/portalRegistration'
import resources from './modules/resources'
import selfReferral from './modules/selfReferral'
import user from './modules/user'
import VuexReset from '@ianwalter/vuex-reset' // https://github.com/ianwalter/vuex-reset

const store = createStore({
    plugins: [VuexReset()],
    modules: {
        chat,
        content,
        data,
        popup,
        portalRegistration,
        portal,
        resources,
        selfReferral,
        user
    },
    mutations: {
        // A no-op mutation must be added to serve as a trigger for a reset. The
        // name of the trigger mutation defaults to 'reset' but can be specified
        // in options, e.g. VuexReset({ trigger: 'data' }).
        // eslint-disable-next-line no-empty-function
        reset: () => {}
    }
})

export default store
