import Logging from '@serv/Logging'
import Step from '@model/content/Step'
import Utils from '@serv/Utils'

/*
answerFormat is ignored - everything is stored in the ExerciseSettings attached to the ExerciseRoutine.
*/
class ExerciseStep extends Step {
    constructor(object) {
        super(object)

        if (object.video) {
            this.videoSlug = object.video.$link
        }
        if (this.videoSlug == undefined && !Utils.isInTest) {
            Logging.error(`ExerciseStep ${this.slug} has undefined videoSlug`)
        }

        // Add exercise title as tag
        if (this.title && !this.tags.includes(this.title)) {
            this.tags.push(this.title)
        }
    }

    resolve(slugToObject) {
        if (this.video == undefined) {
            this.video = slugToObject[this.videoSlug]
            if (this.video == undefined) {
                Logging.error(`ExerciseStep ${this.slug} could not resolve videoSlug: ${this.videoSlug}`)
            }
        }
        delete this.videoSlug
    }
}

export default ExerciseStep
