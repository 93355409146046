<template>
    <div class="mr-popup">
        <div
            v-if="!hideOverlay"
            class="mr-popup__overlay"
        />
        <div :class="['mr-popup__content', contentCss()]">
            <div
                :class="[
                    'mr-popup__header',
                    {
                        'mr-popup__header--no-bg': noHeaderBg,
                        'mr-popup__header--large': hasSlot('title') && !hasSlot('subtitle')
                    }
                ]"
            >
                <i
                    class="mr-popup__close mr-close"
                    @click="$emit('close')"
                />

                <div
                    v-if="hasSlot('title')"
                    class="mr-popup__title"
                >
                    <slot name="title" />
                </div>

                <div
                    v-if="hasSlot('subtitle')"
                    :class="['mr-popup__subtitle', subtitleCss()]"
                >
                    <slot name="subtitle" />
                </div>
            </div>

            <div
                v-if="hasSlot('summary')"
                :class="['mr-popup__summary', summaryCss()]"
            >
                <slot name="summary" />
            </div>

            <div
                v-if="hasSlot('body')"
                :class="['mr-popup__body', bodyCss()]"
            >
                <slot name="body" />
            </div>

            <div
                v-if="hasSlot('footer')"
                class="mr-popup__footer"
            >
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MrPopup',
    emits: ['close'],
    props: {
        hideOverlay: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            validator: value => ['right-center', 'center'].includes(value),
            default: 'center'
        },
        width: {
            type: String,
            validator: value => ['auto', 'regular', 'wide', 'full-screen'].includes(value),
            default: 'regular'
        },
        subtitlePosition: {
            type: String,
            validator: value => ['right'].includes(value),
            default: 'right'
        },
        noHeaderBg: {
            type: Boolean,
            default: false
        },
        fluidContent: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        hasSlot(name) {
            return !!this.$slots[name]
        },
        contentCss() {
            return `mr-popup__content--${this.position} mr-popup__content--${this.width}`
        },
        subtitleCss() {
            return `mr-popup__subtitle--${this.subtitlePosition}`
        },
        summaryCss() {
            if (this.noPadding) {
                return 'mr-popup__summary--wide'
            }
        },
        bodyCss() {
            if (this.fluidContent) {
                return 'mr-popup__body--fluid'
            }

            if (this.noPadding) {
                return 'mr-popup__body--no-padding'
            }

            if (!this.hasSlot('footer')) {
                return 'mr-popup__body--no-footer'
            }
        }
    }
}
</script>
