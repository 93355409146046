import ClinicalMilestone from '@model/ClinicalMilestone'
import ContentService from '@serv/ContentService'
import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import store from '@/store'
import StringHelper from '@serv/StringHelper'

/**
 * List filter for clinical milestone column.
 */
class ListTransformClinicalMilestone extends ListTransform {
    constructor(object) {
        if (object.filterSetKey == 'patientJourneyList' && object.column.reportProperty) {
            const valueScoreSection = object.column.valueScoreSection || 'Score'
            const reportProperty = `${StringHelper.capitalize(
                StringHelper.slugToCamelCase(object.column.reportProperty)
            )}${valueScoreSection}`

            object.filterKey = `latest${reportProperty}Value`
        }
        super(object)

        // For sorting clinical milestone columns
        this.clinicalMilestoneStatusToSortValue = {}
        this.clinicalMilestoneStatusToSortValue[ClinicalMilestone.Status.pending] = 0
        this.clinicalMilestoneStatusToSortValue[ClinicalMilestone.Status.pendingCompletable] = 1
        this.clinicalMilestoneStatusToSortValue[ClinicalMilestone.Status.complete] = 2
        this.clinicalMilestoneStatusToSortValue[ClinicalMilestone.Status.past] = 3
        this.clinicalMilestoneStatusToSortValue[ClinicalMilestone.Status.future] = 4
        this.clinicalMilestoneStatusToSortValue[ClinicalMilestone.Status.unknown] = 5
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        if (this.column.hasExplicitValue && !!this.column.valueFilterOptions) {
            return this.column.getExplicitValueFilterConfig()
        }
        // Complete, Not complete, Pending
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: [
                {
                    value: ClinicalMilestone.Status.complete,
                    label: Locale.getLanguageItem('clinicalMilestoneStatusSummaryComplete')
                },
                {
                    value: ClinicalMilestone.Status.past,
                    label: Locale.getLanguageItem('clinicalMilestoneStatusSummaryExpired')
                },
                {
                    value: ClinicalMilestone.Status.pending,
                    label: Locale.getLanguageItem('clinicalMilestoneStatusSummaryPending')
                }
            ],
            addAll: true
        }

        return config
    }

    /**
     * Sort the list of rows in place.
     */
    sortRows(rows) {
        // Use the status on the associated object in patient.listColumns
        // Default: show complete, then pending, etc.
        const columnIndex = this.column.columnIndex
        const minNumber = -9999
        if (this.column.hasExplicitValue) {
            // Cell explicitly configured - assume valueNumberMap was defined, so number property set
            rows.sort((a, b) => {
                let valueA = a.listColumns[columnIndex].number
                valueA = valueA == undefined ? minNumber : valueA
                let valueB = b.listColumns[columnIndex].number
                valueB = valueB == undefined ? minNumber : valueB

                return valueA - valueB
            })

            return
        }
        const isStepSlug = !!this.column.stepSlug
        const isNumber = this.column.numeralFormat || (this.column.qualifier || '').includes('latestScore')
        const minString = '_'
        rows.sort((a, b) => {
            if (isStepSlug) {
                // Sort by question step answer value
                let valueA = a.listColumns[columnIndex].stepResultValue
                valueA = valueA == undefined ? minString : valueA
                let valueB = b.listColumns[columnIndex].stepResultValue
                valueB = valueB == undefined ? minString : valueB

                return valueA.localeCompare(valueB)
            }
            if (isNumber) {
                // Sort by number
                let valueA = a.listColumns[columnIndex].number
                valueA = valueA == undefined ? minNumber : valueA
                let valueB = b.listColumns[columnIndex].number
                valueB = valueB == undefined ? minNumber : valueB

                return valueA - valueB
            }
            // Sort by status
            let valueA = a.listColumns[columnIndex].status
            valueA = this.clinicalMilestoneStatusToSortValue[valueA]
            let valueB = b.listColumns[columnIndex].status
            valueB = this.clinicalMilestoneStatusToSortValue[valueB]

            return valueA - valueB
        })
    }
    // Get the SQL report parameter to sort by
    getSortParameter() {
        let surveySlug
        const valueScoreSection = this.column.valueScoreSection
            ? StringHelper.camelCaseToSnakeCase(this.column.valueScoreSection).toLowerCase()
            : 'score'
        const owner = store.state.user.owner

        if (this.column.reportProperty && owner.hasPatientJourneyList) {
            const sortBy = `latest_${StringHelper.slugToSnakeCase(
                this.column.reportProperty
            )}_${valueScoreSection}`.toLowerCase()

            return this.column.sortedBy == 'latestDate' ? `${sortBy}_date` : `${sortBy}_value`
        }

        if (this.column.contentTag) {
            const surveySlugs = ContentService.getSurveySlugsWithTag(this.column.contentTag)
            surveySlug = surveySlugs.length > 0 ? surveySlugs[0] : undefined
        } else if (this.column.contentSlug) {
            surveySlug = this.column.contentSlug
        }

        if (surveySlug) {
            let surveyProperty = StringHelper.slugToCamelCase(surveySlug.slice(0, surveySlug.length - 5))
            surveyProperty = StringHelper.replaceAll(`latest_${surveyProperty}_${valueScoreSection}_value`, '-', '_')

            return surveyProperty
        }
    }

    /**
     * Filter and return the list of rows, based on an array of input strings.
     * Default is an exact string match of the previously-resolved cellText.
     */
    filterRows(rows, filterStrings) {
        const columnIndex = this.column.columnIndex
        if (filterStrings.length == 0) {
            return rows
        }
        if (this.column.hasExplicitValue && this.column.valueFilterOptions) {
            return rows.filter(row => filterStrings.includes(row.listColumns[columnIndex].filterValue))
        }

        return rows.filter(row => filterStrings.includes(row.listColumns[columnIndex].status))
    }

    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        if (this.column.valueFilterOptions) {
            const values = keyStrings.map(key => {
                const option = this.column.valueFilterOptions[key]

                return Array.isArray(option) ? option[0] : option
            })

            return isGoddard ? { values } : values
        }

        return isGoddard ? { values: keyStrings } : keyStrings.join(',')
    }
}

export default ListTransformClinicalMilestone
