import { capitalize, indexFormat, lang, langOrInput, returnNA } from '@comp/Filters'

export default {
    name: 'Vue2CompatabilityMixin',
    methods: {
        getLangItem: lang,
        langOrInput,
        capitalize,
        indexFormat,
        returnNA
    }
}
