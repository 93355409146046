<template lang="pug">
extends PopupTemplate.pug
block input
    <h3 v-if=!!config.title class="popup__title">{{ config.title }}</h3>
    <div class="summary">{{ config.text }}</div>
</template>

<script>
import PopupMixin from '@mixins/PopupMixin'
export default {
    name: 'PopupSummary',
    mixins: [PopupMixin]
}
</script>

<style lang="scss">
@import '../../styles/mixins';

// @import '../../styles/popup.scss';
.summary {
    /* stylelint-disable-next-line at-rule-no-unknown */
    @include mr-scrollbar;

    max-height: 650px;
    min-height: 350px;
    overflow: visible auto;
    padding-bottom: 25px;
    padding-right: 20px;
    white-space: pre-wrap;
    font-size: 18px;
    line-height: 1.5;
    text-align: left;
}
</style>
