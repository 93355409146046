/*
State to manage modal popup that can appear in various locations.
*/

const state = {
    isActive: false,
    patient: undefined,
    surveyResult: undefined,
    class: undefined,
    config: undefined
}

const getters = {
    popupConfig: state => {
        return state.config
    }
}

const actions = {}

const mutations = {
    setActive(state, value) {
        state.isActive = value
    },
    setPatient(state, value) {
        state.patient = value
    },
    setSurveyResult(state, value) {
        state.surveyResult = value
    },
    setClass(state, value) {
        state.class = value
    },
    dismiss(state) {
        state.class = undefined
    },
    setConfig(state, value) {
        state.config = value
    }
}

export default {
    namespaced: true, // TODO do we want this?
    state,
    getters,
    actions,
    mutations
}
