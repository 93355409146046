import Logging from '@serv/Logging'
import Patient from '@model/Patient'

export function _resolveObjectListColumnOfType_patientJourneyKeyValue(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.messages can only be used on objects of type: Patient`)

        return
    }
    const patient = row
    const keyValue = patient.firstJourney.keyValues[column.key]
    // The keyValue is assumed to be a stringKey
    let stringKey = keyValue
    if (!keyValue) {
        if (column.defaultFromSurveyResult) {
            // We are defining a fallback value to be read from a survey result score section value
            const surveySlug = column.defaultFromSurveyResult.surveySlug
            const surveyResult = patient.surveyResults.find(surveyResult => surveyResult.surveySlug == surveySlug)
            if (surveyResult) {
                const scoreSection = surveyResult.scoreMap[column.defaultFromSurveyResult.scoreSection]
                if (scoreSection && column.defaultFromSurveyResult.scoreSectionValueMap) {
                    stringKey = column.defaultFromSurveyResult.scoreSectionValueMap[scoreSection.value]
                }
            }
        }
    }
    if (stringKey) {
        column.setExplicitValueConfig(stringKey)
    }
}

export function _resolvePatientJourneyListColumnOfType_patientJourneyKeyValue(
    column,
    patientJourney,
    row,
    errors,
    isGoddard
) {
    const keyValue = isGoddard ? row[column.id].value : row[`${column.key}Value`]

    if (column.hasExplicitValue && keyValue) {
        column.setExplicitValueConfig(keyValue)
    } else {
        column.cellText = keyValue || ''
    }
}
