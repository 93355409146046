import ListTransform from '@model/list/ListTransform'

/**
 * List transform for tasks column.
 */
class ListTransformTasks extends ListTransform {
    constructor(object) {
        super(object)
    }

    /**
     * Sort the list of rows in place.
     */
    sortRows(rows) {
        rows.sort((a, b) => {
            const columnIndex = this.column.columnIndex
            const valueA = a.listColumns[columnIndex].value
            const valueB = b.listColumns[columnIndex].value

            return valueA - valueB
        })
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        const subTypeToParameter = {
            careNavigatorTasks: 'num_care_navigator_tasks',
            jhubTasks: 'num_jhub_tasks',
            surveyReviewTasks: 'num_reviewable_surveys',
            rtmTasks: 'num_rtm_tasks'
        }
        if (this.column && subTypeToParameter[this.column.subtype]) {
            return subTypeToParameter[this.column.subtype]
        }
    }
}

export default ListTransformTasks
