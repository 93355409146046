<template>
    <MrPopup
        width="wide"
        @close="onClose"
    >
        <template #title>
            {{ getLangItem('browserCompatibilityPopupTitle') }}
        </template>
        <template #body>
            <div class="mr-text--linewrap">
                {{ getLangItem('browserCompatibilityPopupDescription') }}
            </div>
        </template>
        <template #footer>
            <div class="mr-btn-group">
                <button
                    class="mr-btn mr-btn--primary"
                    @click="onClose"
                >
                    {{ getLangItem('ok') }}
                </button>
            </div>
        </template>
    </MrPopup>
</template>

<script>
import MrPopup from '@comp/popups/MrPopup.vue'
import PopupMixin from '@mixins/PopupMixin'

export default {
    name: 'PopupBrowserCompatibility',
    mixins: [PopupMixin],
    components: {
        MrPopup
    }
}
</script>
