import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

// Do not remove locales! This will break the app and the tests.
import 'numeral/locales/en-gb'
import 'numeral/locales/de'
import 'numeral/locales/es'
import 'numeral/locales/fi'
import 'numeral/locales/fr'
import 'numeral/locales/hu'
import 'numeral/locales/it'
import 'numeral/locales/nl-nl'

import Config from '@serv/Config'
import Logging from '@serv/Logging'
import Storage from '@serv/Storage'
import StringHelper from '@serv/StringHelper'

/**
 * The Locale service sets and gets the active locale.
 *
 * This sets the locale in moment.js and numeral.js which
 * are used extensively across the app for displaying things like
 * dates, time, currency, numbers, etc.
 */
class Locale {
    constructor() {
        this._activeLocale = null
        this._languageMap = {}
        // Locale to /lang file map - determines the supported locales
        this._localeFileMap = {
            de: 'de',
            en: 'en',
            es: 'es',
            fi: 'fi',
            fr: 'fr',
            hu: 'hu',
            it: 'it',
            nl: 'nl',
            test: 'test',
            xx: 'xx'
        }
        this._numeralLocaleFileMap = {
            en: 'en-gb',
            nl: 'nl-nl'
        }

        // Any stringMap set from the Owner (or Journeys)
        this.stringMap = {}
    }

    /**
     * Detect locale from the user-agent.
     * Do an intersection between the UA's locales and the locales
     * that we support and produce the first option.
     *
     * `navigator.languages` is not standardised yet.
     */
    detectLocale() {
        if (Array.isArray(navigator.languages)) {
            let availableLocales = navigator.languages
                .map(l => this._parseLocaleString(l))
                .filter(l => this._localeFileMap.hasOwnProperty(l))

            if (availableLocales.length) {
                return availableLocales[0]
            }

            return false
        }
        if (navigator.language) {
            let lang = this._parseLocaleString(navigator.language)

            return this._localeFileMap.hasOwnProperty(lang) ? lang : false
        }

        return false
    }

    /**
     * Refresh when a language change is detected in the browser.
     */
    _setWindowRefresh() {
        window.onlanguagechange = function () {
            window.location.reload()
        }
    }

    /**
     * Parse a standard locale string like `en-GB` to
     * the locale used by our app like: `en`.
     */
    _parseLocaleString(locale) {
        return String(locale).toLowerCase().split('-')[0]
    }

    /**
     * Auto-set the detected locale from the user-agent.
     */
    async autoSetLocale() {
        const locale = Storage.get('mr_locale') || this.detectLocale()
        await this.setLocale(locale || 'en-gb')
        this._setWindowRefresh()
    }

    /**
     * Set active Locale.
     * @param {String}
     */
    async setLocale(locale) {
        let appLocale = this._parseLocaleString(locale)
        if (this._localeFileMap.hasOwnProperty(appLocale)) {
            let localeFile = this._localeFileMap[appLocale]
            const jsonModule = await import(`@lang/${localeFile}.json`)
            this._languageMap = jsonModule.default
        } else {
            throw new Error('Unknown locale', appLocale)
        }

        Logging.log('App Locale:', appLocale, ', Active Locale:', locale)
        this._activeLocale = locale

        moment.locale(locale)

        // Must change our locale identifier into a valid numeral locale identifier
        let numeralLocale
        if (this._numeralLocaleFileMap.hasOwnProperty(appLocale)) {
            numeralLocale = this._numeralLocaleFileMap[appLocale]
        } else {
            numeralLocale = appLocale
        }
        Logging.log('Numeral Locale:', numeralLocale)
        numeral.locale(numeralLocale)
    }

    /**
     * Get the active locale
     * @returns {String}
     */
    getActiveLocale() {
        return this._activeLocale
    }

    getAvailableLocales() {
        const locales = _.values(this._localeFileMap).filter(v => {
            return !_.includes(['xx', 'test'], v)
        })

        return locales
    }
    /**
     * String.replace can take a regex and a custom replacer function.
     * The func params are the matches and the captured groups.
     */
    _replacerFn(params, match, capt) {
        return params[capt - 1]
    }

    /**
     * Get item from the Language map and populate with params Array.
     * If not item exists for the key, return undefined.
     */
    getLanguageItemOrUndefined(key, params) {
        const stringMappedKeyType = typeof this.stringMap[key]
        let langItem = undefined
        if (stringMappedKeyType == 'string') {
            // Remap to another ID
            const mappedKey = this.stringMap[key]
            if (this._languageMap.hasOwnProperty(mappedKey)) {
                langItem = this._languageMap[mappedKey]
            }
        } else if (stringMappedKeyType == 'object') {
            // Remap to a string value, by locale
            const localeMap = this.stringMap[key]
            if (localeMap.hasOwnProperty(this._activeLocale)) {
                langItem = localeMap[this._activeLocale]
            }
        } else if (this._languageMap.hasOwnProperty(key)) {
            // Simple lookup
            langItem = this._languageMap[key]
        }

        if (langItem && params) {
            langItem = langItem.replace(/\${(\d)}/g, this._replacerFn.bind(null, params))
        }
        // Allow use of '\n' in source text
        if (langItem) {
            langItem = StringHelper.replaceAll(langItem, '\\n', '\n')
        }

        return langItem
    }

    /**
     * Get item from the Language map and populate with params Array.
     * If not item exists for the key, return a 'TRANS' placeholder.
     */
    getLanguageItem(key, params) {
        const value = this.getLanguageItemOrUndefined(key, params)
        const env = Config.environment
        if (value != undefined) {
            return value
        }
        if (env != 'production') {
            return `TRANS? (${key})`
        }

        return ' '
    }

    getLowercaseForLocale(text) {
        const activeLocale = this.getActiveLocale()
        if (activeLocale == 'de') {
            return text
        }

        return text.toLowerCase()
    }

    /**
     * Returns string without hyphens and capitalized.
     *
     * @param {slug} @type {String}
     *
     * @args (3m-post-op-visit, milestones)
     * @return '3mPostOpVisit' @type {String}
     */
    getStringIdFromSlug(slug, stringIdPrefix) {
        return stringIdPrefix + slug.replace(/(^|-)./g, s => s.slice(-1).toUpperCase())
    }

    /**
     * Get a string ID for a model enum.
     * For example, pass 'milestone' and 'discharge' to get 'milestoneDischarge'.
     */
    getStringIdForModelEnum(modelString, enumString) {
        return modelString + StringHelper.capitalize(enumString)
    }

    /**
     * Get a localised value for a model enum.
     * For example, pass 'milestone' and 'discharge' to get 'Discharge'.
     */
    getLanguageItemForModelEnum(modelString, enumString) {
        const stringId = this.getStringIdForModelEnum(modelString, enumString)

        return this.getLanguageItem(stringId)
    }

    /**
     * Get a localised value for a model enum or undefined if not found.
     * For example, pass 'milestone' and 'discharge' to get 'Discharge'.
     */
    getLanguageItemForModelEnumOrUndefined(modelString, enumString) {
        const stringId = this.getStringIdForModelEnum(modelString, enumString)

        return this.getLanguageItemOrUndefined(stringId)
    }

    /**
     * Get a list of 'language' objects of the form (id=locale, title=label)
     */
    getAvailableLanguages() {
        return this.getAvailableLocales().map(locale => {
            return {
                id: locale,
                title: this.getLanguageItemForModelEnum('locale', locale)
            }
        })
    }

    // Get a list of string keys that match the specified prefix.
    getStringKeysWithPrefix(prefix) {
        const activeLocale = this.getActiveLocale()
        const ownerStringKeys = Object.keys(this.stringMap)
            .filter(key => key.startsWith(prefix))
            .map(key => {
                return { id: this.stringMap[key][activeLocale], title: key }
            })
        const languageMapStringKeys = Object.keys(this._languageMap)
            .filter(key => key.startsWith(prefix))
            .map(key => {
                return { id: this._languageMap[key], title: key }
            })

        return ownerStringKeys.concat(languageMapStringKeys).sort(function (a, b) {
            return a.id.localeCompare(b.id)
        })
    }
}

export default new Locale()
