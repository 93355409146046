import Logging from '@serv/Logging.js'

const svgIcons = import.meta.glob('@images/*.svg', { eager: true })

export default function useImage() {
    const getIconUrl = name => {
        if (!name.endsWith('.svg')) {
            Logging.error(`Only SVG icons are supported: ${name}`)
        }

        const icon = svgIcons[`/src/images/${name}`]

        if (!icon) {
            Logging.error(`Can't find icon: ${name}`)

            return ''
        }

        return icon.default
    }

    const getImageUrl = image => {
        if (image.endsWith('.svg')) {
            return getIconUrl(image)
        }

        return new URL(`/src/images/${image}?format=webp`, import.meta.url).href // should use relative path
    }

    return {
        getIconUrl,
        getImageUrl
    }
}
