import Milestone from '@model/Milestone'
import moment from 'moment'

/*
An referral milestone object.
*/
class ReferralMilestone extends Milestone {
    constructor(object) {
        object.type = Milestone.Type.referral
        object.slug = object.slug || 'referral'
        super(object)
        this.referredFromProviderSlug = object.referredFromProviderSlug
        this.referredFromId = object.referredFromId
        this.referredToProviderSlug = object.referredToProviderSlug
        this.referredToId = object.referredToId
        this.service = object.service
        this.urgency = object.urgency
        this.setEndDate(object.endDate)
        this.referralReason = object.referralReason
        this.rejectionReason = object.rejectionReason
        this.notes = object.notes
        this.status = object.status
        this.needsReview = object.needsReview
        this.acceptedDate = object.acceptedDate
        this.acceptedById = object.acceptedById
    }

    /**
     * Set the end date from a string.
     */
    setEndDate(date) {
        this.endDate = date // string in standard format
        this.endMoment = date ? new moment(date) : undefined
    }
}

ReferralMilestone.Status = {
    offered: 'offered',
    accepted: 'accepted',
    rejected: 'rejected',
    discharged: 'discharged'
}

export default ReferralMilestone
