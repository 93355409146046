<template>
    <div class="mr-avatar">
        <img
            v-if="!!(avatarUrl || user.avatarUrl)"
            :class="['mr-avatar__container', avatarSize]"
            :src="avatarUrl || user.avatarUrl"
        />

        <div
            v-else
            :class="['mr-avatar__container', avatarSize]"
            :style="{
                backgroundColor: backgroundColor($colors, user)
            }"
        >
            <div :class="['mr-avatar__initials', avatarInitials]">
                {{ userInitials }}
            </div>
        </div>
    </div>
</template>

<script>
import Utils from '@serv/Utils'

export default {
    name: 'MrAvatar',
    props: {
        user: { type: Object, required: true },
        // eslint-disable-next-line vue/require-default-prop
        avatarUrl: { type: String },
        size: {
            type: String,
            default: '',
            validator: value => ['', 'small', 'smallest'].indexOf(value) !== -1
        }
    },
    inject: ['$colors'],
    computed: {
        userInitials() {
            return this.user.getInitials()
        },
        avatarSize() {
            if (this.size) {
                return `mr-avatar__container--${this.size}`
            }

            return ''
        },
        avatarInitials() {
            if (this.size) {
                return `mr-avatar__initials--${this.size}`
            }

            return ''
        }
    },
    methods: {
        backgroundColor(config, user) {
            return Utils.backgroundColor(config, user)
        }
    }
}
</script>
