<template>
    <div class="clinician-banner">
        <img
            class="clinician-banner__poster"
            :src="getBannerSrc"
        />
        <img
            v-if="logoMediaImage"
            class="clinician-banner__logo"
            :src="getLogoSrc"
        />
    </div>
</template>

<script>
export default {
    name: 'ClinicianBanner',
    props: {
        bannerMediaImage: { type: Object, required: true },
        // eslint-disable-next-line vue/require-default-prop
        logoMediaImage: { type: Object }
    },
    computed: {
        getBannerSrc() {
            return this.bannerMediaImage.blobUrlFull
        },
        getLogoSrc() {
            return this.logoMediaImage.blobUrlFull
        }
    }
}
</script>

<style lang="scss" scoped>
.clinician-banner {
    position: relative;
    padding: 0 !important;

    &__poster {
        max-width: 100%;
    }

    &__logo {
        position: absolute;
        width: 14vh;
        right: 2vh;
        bottom: 3vh;
    }
}
</style>
