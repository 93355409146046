import store from '@/store'
import User from '@model/User.js'

export function _resolvePatientJourneyListColumnOfType_ptUser(column, patientJourney, row, errors, isGoddard) {
    if (isGoddard && row[column.id]) {
        const user = new User(row[column.id])

        if (user) {
            column.cellText = user.titledFullName
        }
    }

    if (row.ptClinicianId) {
        const user = store.state.user.users[row.ptClinicianId]

        if (user) {
            column.ptUser = user
            column.cellText = user.titledFullName
        }
    }
}
