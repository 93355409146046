import AppointmentMilestone from '@model/AppointmentMilestone'
import Logging from '@serv/Logging'
import moment from 'moment/moment'
import Patient from '@model/Patient'

export function _resolveObjectListColumnOfType_appointments(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.appointments can only be used on objects of type: Patient`)

        return
    }
    const patient = row
    const nowMoment = new moment()

    // Store future appointments on column, to assist with sorting
    column.futureAppointments = patient
        .getMilestonesOfType('appointment')
        .filter(milestone => milestone.moment > nowMoment)
        .sort((a, b) => {
            const valueA = a.date
            const valueB = b.date

            return valueA.localeCompare(valueB) // lexical sort of YYYY-MM-DD format IS chrono sort!
        })

    if (column.futureAppointments.length > 3) {
        column.futureAppointments.length = 3
    }

    column.cellText = column.futureAppointments.map(milestone => milestone.summary).join(', ')
}

export function _resolvePatientJourneyListColumnOfType_appointments(column, patientJourney, row) {
    const futureAppointments = []

    for (let i = 0; i < 3; i++) {
        const appointmentDate = row[`appointment${i}Date`]
        const appointmentType = row[`appointment${i}Subtype`]

        if (appointmentDate) {
            futureAppointments.push(
                new AppointmentMilestone({
                    date: appointmentDate,
                    appointmentType: appointmentType
                })
            )
        }
    }

    column.cellText = futureAppointments.map(milestone => milestone.summary).join(', ')
}
