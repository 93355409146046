import BaseContent from '@model/content/BaseContent'
import Step from '@model/content/Step'
import TextChoice from '@model/content/TextChoice'

/*
    "answerFormat": {
        "type": "integer"
    }
*/

class SliderScaleStep extends Step {
    constructor(object) {
        object.type = object.type || BaseContent.Type.sliderScale
        super(object)
        this.sliderMinValue = object.answerFormat.sliderMinValue
        this.sliderMaxValue = object.answerFormat.sliderMaxValue
        this.sliderLeftText = object.answerFormat.sliderLeftText
        this.sliderRightText = object.answerFormat.sliderRightText
        this.hideNumbers = object.answerFormat.hideNumbers

        this.createTextChoices()
    }

    /**
     * Set up TextChoice array, like QuestionStep.
     * This allows us to map the slider scale range to buckets with labels, e.g. for experience chart.
     */
    createTextChoices() {
        this.choices = []
        this.choiceValueToText = {}

        // Create ranges
        let ranges = []

        // Some special cases
        if (this.sliderMinValue == 0 && this.sliderMaxValue == 100) {
            for (let i = 0; i < 9; i++) {
                ranges.push(`${i * 10}-${i * 10 + 9}`)
            }
            ranges.push('90-100')
        } else {
            for (let v = this.sliderMinValue; v <= this.sliderMaxValue; v++) {
                ranges.push(v.toString())
            }
        }
        // Convert ranges to text choices
        for (let i = 0; i < ranges.length; i++) {
            const range = ranges[i]
            let text
            if (i == 0) {
                text = this.sliderLeftText || range
            } else if (i == ranges.length - 1) {
                text = this.sliderRightText || range
            } else {
                text = range
            }
            const textChoice = new TextChoice({ value: range, text: text })
            this.choices.push(textChoice)
            this.choiceValueToText[textChoice.value] = textChoice.text
        }
    }
}

export default SliderScaleStep
