<template>
    <div class="patient-surveys-root">
        <template v-if="!!allLoaded">
            <v-row>
                <SurveysListView v-if="!isPhone && !isSingleSurvey" />
                <SurveysDetailView
                    :full-width="isSingleSurvey || isPhone"
                    :single-survey="isSingleSurvey"
                    :without-info-pages="withoutInfoPages"
                />
            </v-row>
        </template>
        <Loader v-else />
        <NotifyMsg />
        <component
            v-if="!!popupClass"
            :is="popupClass"
            :config="popupConfig"
        />
    </div>
</template>

<script>
import ActivityListService from '@serv/ActivityListService'
import LegacyBrowserMixin from '@mixins/LegacyBrowserMixin'
import Loader from '@comp/MrLoader.vue'
import LoaderMixin from '@mixins/LoaderMixin'
import Logging from '@serv/Logging'
import { mapGetters } from 'vuex'
import NotifyMsg from '@comp/NotifyMsg.vue'
import RegistrationService from '@serv/RegistrationService'
import SurveysDetailView from '@comp/websurvey/SurveysDetailView.vue'
import SurveyService from '@serv/SurveyService'
import SurveysListView from '@comp/websurvey/SurveysListView.vue'
import SurveysMixin from '@mixins/SurveysMixin'

/**
 * This is the root layout for the patient web surveys UX.
 * The left pane (on desktop and when isSingleSurvey = false) is the "list view", listing all surveys to be completed.
 * The right pane is the "detail view", which can show either:
 * - The Welcome page
 * - An active survey
 * - The Done page
 *
 * The component uses the LoaderMixin to block mounting of the list and details views
 * until all dependent resources are loaded.
 *
 * The component also checks to see if the route or props are specifying a particular survey. If
 * so, we immediately jump into that survey (skipping the Welcome page).
 */
export default {
    name: 'SurveysRoot',
    mixins: [LegacyBrowserMixin, LoaderMixin, SurveysMixin],
    components: {
        Loader,
        NotifyMsg,
        SurveysDetailView,
        SurveysListView
    },
    props: {
        activitySlug: {
            type: String,
            default: undefined
        },
        patientJourneyId: {
            type: [String, Number],
            default: undefined
        },
        singleSurvey: {
            type: Boolean,
            default: false
        },
        withoutInfoPages: {
            type: Boolean,
            default: false
        },
        surveyResult: {
            type: Object,
            default: undefined
        },
        amendResult: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            requiredState: ['content', 'journeys', 'teams', 'user']
        }
    },
    computed: {
        ...mapGetters(['isWebSurveysInitCompleted', 'patientJourneysSurveys']),
        isSingleSurvey() {
            return this.singleSurvey || this.$route.query.singleSurvey
        }
    },
    methods: {
        getWebSurveyFromRoute() {
            const patientJourneyId = this.patientJourneyId || this.$route.params.patientJourneyId
            const activitySlug = this.activitySlug || this.$route.params.activitySlug
            if (!patientJourneyId || !activitySlug) {
                Logging.log(`No specific survey route, starting at Welcome page.`)

                return
            }
            const patientJourney = this.patientJourneys[patientJourneyId]
            const journey = this.journeys[patientJourney.journeySlug]
            const activity = journey?.activitiesMap[activitySlug] || ActivityListService.getActivityBySlug(activitySlug)
            if (!activity) {
                Logging.error(`Could not find Activity: ${activitySlug}`)

                return
            }
            // Create TaskHandler and set on state
            // This is async - TaskHandler set on state when complete
            SurveyService.initialiseTaskHandlerForPatientJourneyActivity(
                this.patient,
                patientJourney,
                activity,
                this.user,
                this.surveyResult,
                this.amendResult
            )
        },
        initSurveys() {
            if (!this.isWebSurveysInitCompleted && !this.isSingleSurvey) {
                SurveyService.initWebSurveys()
            }
            this.getWebSurveyFromRoute()
        }
    },
    created() {
        Logging.log('SurveysRoot created')

        if (!this.clinicianCompleteForPatient && this.patient) {
            // Handle loading content packages
            this.requiredState.push('activityLists')
            this.isLoaded.activityLists = false
            this.updateAllLoaded()
            ActivityListService.ensureActivityListsLoaded(this.patient.allJourneys()).then(() => {
                this.isLoaded.activityLists = true
                this.updateAllLoaded()
            })
        }

        RegistrationService.ensureJourneysContentTeamsUsers()
        if (this.allLoaded) {
            this.initSurveys()
        }
    },
    mounted() {
        Logging.log('SurveysRoot mounted')
        if (this.$route.path == '/') {
            // Clear any TaskHandler, which puts us back at the Welcome page
            SurveyService.unloadAllJsFiles(true)
        }
    },
    watch: {
        allLoaded() {
            this.initSurveys()
        }
    }
}
</script>
