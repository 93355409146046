import User from '@model/User'

/**
 * A user that has oversight of Clinicians (within Departments) and their aggregated results.
 * They generally do not see identifiable patient data, but can do with the correct Capability.
 */
class ProviderExec extends User {
    constructor(object) {
        object.persona = User.Persona.providerExec
        super(object)
    }

    get adminListProperties() {
        let properties = `isRegistered: ${this.isRegistered}`
        if (this.phone) {
            properties += `, phone: ${this.phone}`
        }

        return properties
    }
}

export default ProviderExec
