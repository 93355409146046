import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import store from '@/store'

export default function _resolveObjectListColumnOfType_milestoneToTeam(column, row) {
    if (!(row instanceof Milestone)) {
        Logging.error(`ListColumn.Type.milestoneToTeam can only be used on objects of type: Milestone`)

        return
    }
    const milestone = row
    if (milestone.type == Milestone.Type.referral && milestone.referredToProviderSlug) {
        column.cellText = store.state.resources.providers[milestone.referredToProviderSlug]?.title || ''
    } else if (milestone.type == Milestone.Type.appointment) {
        if (milestone.referralId) {
            const referralMilestone = row.patientJourney.getMilestoneOfId(milestone.referralId)
            column.cellText = store.state.resources.providers[referralMilestone.referredToProviderSlug]?.title || ''
        } else {
            const team = store.state.user.teams[row.patientJourney.teamId]
            column.cellText = store.state.resources.providers[team.providerSlug]?.title || ''
        }
    }
}
