import Milestone from '@model/Milestone'
import PeriodMilestone from '@model/PeriodMilestone'

class CarePeriodMilestone extends PeriodMilestone {
    constructor(object) {
        object.type = Milestone.Type.carePeriod
        object.slug = object.slug || 'care-period'
        super(object)
        this.time = object.time // admission time
        this.providerSlug = object.providerSlug // facility
        this.plannedEndDate = object.plannedEndDate
    }
}

export default CarePeriodMilestone
