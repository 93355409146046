import BaseContent from '@model/content/BaseContent'
import InfoStep from '@model/content/InfoStep'
import QuestionStep from '@model/content/QuestionStep'
import Task from '@model/content/Task'

class Survey extends Task {
    constructor(object) {
        object.type = BaseContent.Type.survey
        super(object)
        this.name = object.name
        this.minScore = object.minScore
        this.maxScore = object.maxScore

        // For storing practice average values, indexed at each Schedule.startOffset
        this.schedulePracticeAverageScore =
            object.schedulePracticeAverageScore === undefined ? {} : object.schedulePracticeAverageScore
        this.schedulePracticeAverageNumPatients =
            object.schedulePracticeAverageNumPatients === undefined ? {} : object.schedulePracticeAverageNumPatients
    }

    // Get keys for logging
    get logKeys() {
        return [...super.logKeys, 'maxScore', 'minScore', 'name']
    }

    get isChecklistSurvey() {
        return this.containsTag(Survey.checklistSurveyTag)
    }

    get isClinicalSurvey() {
        return this.containsTag(Survey.clinicalSurveyTag)
    }
    get isFeedbackSurvey() {
        return this.containsTag(Survey.feedbackSurveyTag)
    }
    get isGoalsSurvey() {
        return this.containsTag(Survey.goalsSurveyTag)
    }
    get isPainSurvey() {
        return this.containsTag(Survey.painSurveyTag)
    }
    get isPremSurvey() {
        return this.containsTag(Survey.premSurveyTag)
    }
    get isPromSurvey() {
        return this.containsTag(Survey.promSurveyTag)
    }
    get isTrackerSurvey() {
        return this.containsTag(Survey.trackerSurveyTag)
    }
    get hasReviewNotes() {
        return this.containsTag(Survey.reviewNotesTag)
    }

    // Get overall Score section, or undefined.
    // If onlyDisplayed, filter by display: true
    getOverallScoreSection(onlyDisplayed) {
        const section = (this.keyValues.scoreSections || []).find(ss => ss.name == 'Score')
        if (section) {
            return !onlyDisplayed || section.display !== false ? section : undefined
        }

        return undefined
    }
    // Get overall Score section, or undefined.
    // If onlyDisplayed, filter by display: true
    getOtherScoreSections(onlyDisplayed) {
        const sections = (this.keyValues.scoreSections || []).filter(ss => ss.name != 'Score')
        if (onlyDisplayed) {
            return sections.filter(ss => ss.display !== false)
        }

        return sections
    }
    /**
     * Get all score sections, as "slugSections", e.g. "foo-surv Physical".
     * By default we remove those that are configured as "display: false"
     */
    getScoreSlugSections(includeHidden) {
        const sectionSlugs = (this.keyValues.scoreSections || [{ name: 'Score' }])
            .filter(ss => ss.display !== false || !!includeHidden)
            .map(ss => `${this.slug} ${ss.name}`)

        return sectionSlugs
    }

    /**
     * Return an (ordered) list of the survey's stepSlugs, only for "real questions".
     * This includes all steps that are not InfoSteps.
     */
    get questionStepSlugs() {
        return this.steps.filter(step => !(step instanceof InfoStep)).map(step => step.slug)
    }

    // Should we flip the Y axis when charting?
    get flipY() {
        return this.tags.includes('lower-score-better')
    }

    mockStepsFromSlugs(stepSlugs) {
        this.steps = []
        this.stepSlugToStep = {}
        stepSlugs.forEach(stepSlug => {
            const stepObject = {
                slug: stepSlug,
                title: `Step title for ${stepSlug}`,
                text: `Step text for ${stepSlug}`,
                answerFormat: {
                    type: 'textChoice',
                    values: []
                }
            }
            stepObject.answerFormat.values.push({ text: 'Never', value: '0' })
            stepObject.answerFormat.values.push({ text: 'Sometimes', value: '1' })
            stepObject.answerFormat.values.push({ text: 'Always', value: '2' })
            stepObject.answerFormat.values.push({ text: 'Never', value: '3' })
            stepObject.answerFormat.values.push({ text: 'Sometimes', value: '4' })
            stepObject.answerFormat.values.push({ text: 'Always', value: '5' })
            stepObject.answerFormat.values.push({ text: 'Never', value: '6' })
            stepObject.answerFormat.values.push({ text: 'Sometimes', value: '7' })
            stepObject.answerFormat.values.push({ text: 'Always', value: '8' })
            stepObject.answerFormat.values.push({ text: 'Forever', value: '9' })
            const step = new QuestionStep(stepObject)
            this.steps.push(step)
            this.stepSlugToStep[stepSlug] = step
        })
    }

    /**
     * Return all MediaFile objects for JS files.
     */
    get jsFiles() {
        const jsFiles = this.items.filter(item => item.mimeType.includes('javascript'))

        return jsFiles
    }
}

// Survey tags: types
Survey.checklistSurveyTag = 'checklist-survey'
Survey.clinicalSurveyTag = 'clinical-survey'
Survey.dashSurveyTag = 'dash-survey'
Survey.feedbackSurveyTag = 'feedback-survey'
Survey.goalsSurveyTag = 'goals-survey'
Survey.painSurveyTag = 'pain-survey'
Survey.premSurveyTag = 'prem-survey'
Survey.promSurveyTag = 'prom-survey'
Survey.trackerSurveyTag = 'tracker-survey'
// Survey tags: other
Survey.bilateralTag = 'duplicate-for-bilateral'
Survey.reviewNotesTag = 'has-review-notes'

export default Survey
