import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import store from '@/store'
import User from '@model/User'

export function _resolveObjectListColumnOfType_rtmUser(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.rtmUser can only be used on objects of type: Patient`)

        return
    }

    _resolvePatientJourneyListColumnOfType_rtmUser(column, row.firstJourney, row)
}

export function _resolvePatientJourneyListColumnOfType_rtmUser(column, patientJourney, row, errors, isGoddard) {
    if (isGoddard && row[column.id]) {
        const user = new User(row[column.id])

        if (user) {
            column.cellText = user.titledFullName
        }
    }

    if (row.rtmClinicianId) {
        const user = store.state.user.users[row.rtmClinicianId]

        if (user) {
            column.rtmUser = user
            column.cellText = user.titledFullName
        }
    }
}
