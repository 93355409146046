import store from '@/store'

let singleton

/**
 * Static functions used by web survey JS interface.
 */
class UserManager {
    static get() {
        return singleton
    }

    getUser() {
        const patient = store.state.user.user

        return {
            dob: patient.dob,
            sex: patient.sex
        }
    }
}
singleton = new UserManager()

export default UserManager
