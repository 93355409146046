import ContentService from '@serv/ContentService'
import { isAndroid } from 'mobile-device-detect'
import Step from '@model/content/Step'
import StepResult from '@model/StepResult'
import SurveysMixin from '@mixins/SurveysMixin'
import TaskUi from '@serv/TaskUi'
import VueMarkdown from 'vue3-markdown-it'

/**
 * Provides useful functions for survey step components.
 */
export default {
    name: 'SurveyStepMixin',
    mixins: [SurveysMixin],
    components: {
        VueMarkdown
    },
    props: {
        step: { type: Step, required: true },
        stepResult: { type: StepResult, required: true }
    },
    data: () => ({
        isBottom: true
    }),
    computed: {
        getTitle() {
            const title = ContentService.sanitiseText(TaskUi.get().getStepTitle() || '', {
                patient: this.patient,
                stripMarkdown: false
            })

            return title
        },
        getText() {
            const text = ContentService.sanitiseText(TaskUi.get().getStepText() || '', {
                patient: this.patient,
                stripMarkdown: false
            })

            return text
        },
        getText2() {
            return ''
        },
        getImageBlobUrlFull() {
            return undefined
        },
        isHealthStep() {
            return false
        },
        getFooterText() {
            return this.step.keyValues.footer
        },
        headerClass() {
            return undefined
        },
        titleCss() {
            return 'websurvey__step__title'
        },
        // Get CSS classes for the main body text
        textCss() {
            return this.taskHandler && this.taskHandler.isOutroStep
                ? 'websurvey__step__text text-center'
                : 'websurvey__step__text'
        },
        // Get CSS classes for the main body text, when used on the HealthStep
        textHealthCss() {
            return 'websurvey__step__text-health'
        },
        // Get CSS classes for the lower (coloured) outro step footer
        textLowerCss() {
            return this.taskHandler && this.taskHandler.isOutroStep
                ? 'websurvey__step__text-lower text-center'
                : 'websurvey__step__text-lower'
        },
        innerContainerHeight() {
            if (this.isPhone) {
                return isAndroid ? '76vh' : '61vh'
            }

            return '76vh'
        }
    },
    methods: {
        getElement() {
            return document.getElementsByClassName('websurvey-inner-container')[0]
        },
        isBottomReached() {
            let element = this.getElement()
            if (element) {
                return (this.isBottom = element.scrollHeight - element.scrollTop === element.clientHeight)
            }
        }
    },
    mounted() {
        let element = this.getElement()
        this.isBottom = element.scrollHeight > element.clientHeight
        this.isBottomReached()
        document.addEventListener(
            'scroll',
            () => {
                this.isBottomReached()
            },
            true
        )
    }
}
