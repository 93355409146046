import ListTransform from '@model/list/ListTransform'

/**
 * List filter for number column.
 */
class ListTransformNumber extends ListTransform {
    constructor(object) {
        super(object)
    }

    /**
     * Sort the list of rows in place.
     */
    sortRows(rows) {
        const maxValue = 999999
        rows.sort((a, b) => {
            const columnIndex = this.column.columnIndex
            let valueA = a.listColumns[columnIndex].number
            let valueB = b.listColumns[columnIndex].number
            valueA = valueA == undefined ? maxValue : valueA
            valueB = valueB == undefined ? maxValue : valueB

            return valueA - valueB
        })
    }

    /**
     * We want undefined values to be always at the bottom.
     */
    reverse(rows) {
        const columnIndex = this.column.columnIndex
        const rowsUndefined = rows.filter(row => row.listColumns[columnIndex].number == undefined)
        const rowsDefined = rows.filter(row => row.listColumns[columnIndex].number != undefined)
        rows.length = 0
        rows.push(...rowsDefined.reverse())
        rows.push(...rowsUndefined)
    }
}

export default ListTransformNumber
