import PatientService from '@serv/PatientService'
import store from '@/store'
import TaskService from '@serv/TaskService'
import { computed, ref } from 'vue'

const taskCount = ref({})

export function usePatientTaskCount() {
    const user = computed(() => store.state.user.user)

    const setPatientTaskCount = (patient, patientJourney) => {
        setComponentTaskCount('GrmaTabCptProcedureDetails', TaskService.isPreassessmentSurveyTask(patient) ? 1 : 0)
        setComponentTaskCount('TabCptProcedureDetails', TaskService.isSurgicalJourneyTask(patientJourney) ? 1 : 0)
        setComponentTaskCount('TabCptAdminDates', getKeyDatesTaskCount(patient, patientJourney))
    }

    const getKeyDatesTaskCount = (patient, patientJourney) => {
        let count = 0

        if (TaskService.hasAppointmentTask()) {
            count += TaskService.getAppointmentNumTasks(patientJourney)
        }

        if (user.value.isHcpCareNavigator) {
            count += PatientService.getPatientCareNavigatorNumTasks(patient)
        }

        return count
    }

    const setComponentTaskCount = (componentClass, count) => {
        taskCount.value[componentClass] = count
    }

    const hasTabTasks = tab => {
        return tab.components.some(component => taskCount.value[component.class])
    }

    const getTaskCountForTab = tab => {
        return tab.components.reduce((count, component) => {
            return count + (taskCount.value[component.class] || 0)
        }, 0)
    }

    return {
        getTaskCountForTab,
        hasTabTasks,
        setComponentTaskCount,
        setPatientTaskCount
    }
}
