/**
 * A survey result score section.
 */
class ScoreSection {
    constructor(object) {
        this.section = object.section
        this.value = object.value
        this.displayValue = object.displayValue
    }
}

export default ScoreSection
