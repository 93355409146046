import _ from 'lodash'
import Logging from '@serv/Logging'
import StringHelper from '@serv/StringHelper'

/**
 * Named set of filters.
 * Each filter is a named set of string arrays.
 */
class FilterSet {
    constructor(object) {
        this.key = object.key

        // If 'transforms' is specified, construct the maps
        if (object.transforms) {
            object.filterKeyStrings = {}
            object.filterKeyTransforms = {}
            object.transforms.forEach(transform => {
                object.filterKeyStrings[transform.filterKey] = []
                object.filterKeyTransforms[transform.filterKey] = transform
            })
        }
        // map of ListColumn.columnIndex (or other unique key) to array of strings
        this.filterKeyStrings = object.filterKeyStrings || {}
        // map of same key to DataTransform
        this.filterKeyTransforms = object.filterKeyTransforms || {}
        this.listHash = undefined // the hash that the associated list was built from

        this.isLogging = object.isLogging ? true : false
    }

    /**
     * Build a query params object from all filters and their values.
     * A filter only contributes if not at the default setting.
     */
    getQueryParamsObject(isGoddard = false) {
        let object = {}
        Object.keys(this.filterKeyStrings).forEach(filterKey => {
            const keyStrings = this.filterKeyStrings[filterKey]
            if (Array.isArray(keyStrings) && keyStrings.length > 0) {
                const transform = this.filterKeyTransforms[filterKey]
                const key = isGoddard
                    ? transform.column?.id || transform?.getGoddardFilterKey(keyStrings) || filterKey
                    : filterKey

                object[key] = transform.getQueryParamValueFromKeyStrings(keyStrings, isGoddard)
            }
        })
        // For each transform, add any fixed filterPayload, regardless of filter settings
        Object.values(this.filterKeyTransforms).forEach(transform => {
            object = _.merge(object, transform.column?.filterPayload || {})
        })

        return object
    }

    log() {
        if (this.isLogging) {
            Logging.log(`FilterSet '${this.key}' filterKeyStrings: ${JSON.stringify(this.filterKeyStrings, null, 2)}`)
        }
    }

    // Get the (unordered) list of DataTransforms
    getTransforms() {
        return Object.values(this.filterKeyTransforms)
    }

    // Set the map of unique key to string array (sets the selectedValue of filter)
    setStringsForKey(filterKey, strings) {
        this.filterKeyStrings[filterKey] = strings
        this.log()
    }

    // Get the string array for the unique key, or undefined
    getStringsForKey(filterKey) {
        return this.filterKeyStrings[filterKey]
    }

    // Get the string array for the unique key, or empty array
    getStringsForKeyOrEmptyArray(filterKey) {
        return this.filterKeyStrings[filterKey] || []
    }

    // Get the single string array for the unique key, or undefined
    getSingleStringForKey(filterKey) {
        const strings = this.filterKeyStrings[filterKey]

        return strings == undefined || strings.length == 0 ? undefined : strings[0]
    }

    // Are all filters at their default values?
    get isDefault() {
        for (const keyStrings of Object.values(this.filterKeyStrings)) {
            if (Array.isArray(keyStrings) && keyStrings.length > 0) {
                return false
            }
        }

        return true
    }

    // Get the DataTransform for the specified key
    getDataTransformForKey(filterKey) {
        return this.filterKeyTransforms[filterKey]
    }

    // Get the DataTransform for the specified ListColumn.Type
    getDataTransformForListColumnType(type) {
        return Object.values(this.filterKeyTransforms).find(
            transform => transform.column && transform.column.type == type
        )
    }

    /**
     * Return an integer hash of all string values set on the FilterSet.
     * This is useful for passing a 'key' to Vue components that needs to change whenever a filter value changes.
     */
    getHashFromAllFilterStrings() {
        const allStrings = Object.values(this.filterKeyStrings).flat()

        return StringHelper.hashStringsToInt(allStrings)
    }

    /**
     * Filter rows by our transform, and associated stored filter strings.
     */
    filterRows(rows) {
        Object.values(this.filterKeyTransforms).forEach(transform => {
            const filterStrings = this.filterKeyStrings[transform.filterKey] || []
            rows = transform.filterRows(rows, filterStrings)
        })

        return rows
    }
}

export default FilterSet
