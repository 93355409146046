import _ from 'lodash'
import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import store from '@/store'

import User from '@model/User'

/**
 * List transform for clincians patient is referred to.
 */
class ListTransformReferees extends ListTransform {
    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    // Get a list of all users that could be referred to
    get getFilterConfig() {
        let teamMembers = [...Object.values(store.state.user.users)]
        teamMembers = teamMembers.filter(user => {
            // TODO Why are we not using 'canBeReferredTo' ?
            //            return user.inTeam && user.has(User.Capability.canBeReferredTo)
            return user.has(User.Capability.canViewPatientData)
        })
        teamMembers = _.orderBy(teamMembers, ['lastName', 'firstName'])
        const teamMemberTitles = teamMembers.map(teamLead =>
            this.column.hideRole ? teamLead.titledFullName : teamLead.titledFullNameWithRole
        )
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: teamMemberTitles.map(value => {
                return { value: value, label: value }
            }),
            addAll: true
        }

        return config
    }

    // Each string is a titledFullNameWithRole/titledFullName of a potential referee. Compare against all patient active ReferralMilestones.
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex

        return rows.filter(row =>
            filterStrings.some(filterString => {
                if (row.listColumns[columnIndex].cellText.includes(filterString)) {
                    return true
                }
                if (_.isEmpty(row.listColumns[columnIndex].cellText)) {
                    // If patient is not referred, but still in patient list, we assume it's there because the dash user
                    // invited them. So match on the filter being the dash user
                    const user = store.state.user.user

                    return filterString == this.column.hideRole ? user.titledFullName : user.titledFullNameWithRole
                }

                return false
            })
        )
    }
}

export default ListTransformReferees
