class SupportService {
    /**
     * Get an object specifying string keys and links to use to populate instances of the support page.
     * Pass in a support type, e.g. 'notFound'
     */
    getSupportContent(supportType) {
        let supportContent = {
            notFound: {
                image: 'support-lady-at-comp.png',
                header: 'supportNotFoundTitle',
                subheader: 'supportNotFoundSubtitle',
                paragraph: 'supportNotFoundText',
                buttons: [{ text: 'supportNotFoundButton', link: '/' }]
            },
            genericError: {
                image: 'support-lady-at-comp.png',
                header: 'supportGenericErrorTitle',
                subheader: 'supportGenericErrorSubtitle',
                paragraph: 'supportGenericErrorText'
            },
            selfRegComplete: {
                image: 'support-success.png',
                header: 'supportSelfRegistrationCompleteTitle',
                paragraph: 'supportSelfRegistrationCompleteText',
                paragraphTwo: 'supportSelfRegistrationCompleteText2',
                buttons: [
                    {
                        text: 'supportDownloadAppIos',
                        link: 'https://itunes.apple.com/app/id1199952761'
                    },
                    {
                        text: 'supportDownloadAppAndroid',
                        link: 'https://play.google.com/store/apps/details?id=fhw.com.myrecovery'
                    }
                ]
            },
            success: {
                image: 'support-success.png',
                header: 'supportPasswordResetSuccessTitle',
                subheader: 'supportPasswordResetSuccessSubtitle',
                buttons: [{ text: 'supportPasswordResetSuccessButton', link: '/' }]
            },
            unsubscribe: {
                image: 'support-lady-at-comp.png',
                header: 'supportUnsubscribeTitle',
                subheader: 'supportUnsubscribeSubtitle',
                paragraph: 'supportUnsubscribeText',
                buttons: [{ text: 'yes' }, { text: 'no' }]
            },
            unsubscribeComplete: {
                image: 'support-lady-at-comp.png',
                header: 'supportUnsubscribeCompleteTitle',
                paragraph: 'supportUnsubscribeCompleteText'
            },
            unsubscribeCancelled: {
                image: 'support-success.png',
                header: 'supportUnsubscribeCancelledTitle',
                subheader: 'supportUnsubscribeCancelledSubtitle',
                paragraph: 'supportUnsubscribeCancelledText'
            },
            serviceUnavailable: {
                header: 'supportServiceUnavailableTitle',
                subheader: 'supportServiceUnavailableSubitle',
                paragraph: 'supportServiceUnavailableText',
                image: 'support-lady-at-comp.png',
                buttons: [{ text: 'ok', link: '/' }]
            }
        }
        if (!supportType) {
            return supportContent['notFound']
        }

        if (!Object.keys(supportContent).includes(supportType)) {
            throw new Error("support type doesn't exist")
        }

        return supportContent[supportType]
    }
}

export default new SupportService()
