<template lang="pug">
extends SurveyStepTemplate.pug
block input
    <v-row class="survey-health-scale-step">
        //- Top label, right half
        <v-col cols="6" class="survey-health-scale-step__leftcol" />
        <v-col cols="6" class="survey-health-scale-step__rightcol" >
            //- "The best health..."
            <div class="survey-health-scale-step__slider__label">{{ getLangItem( 'contentHealthTopScoreText' ) }}</div>
            //- "100"
            <div class="survey-health-scale-step__slider__minmax">{{ maxValue }}</div>
        </v-col>

        <v-col cols="6" class="survey-health-scale-step__leftcol survey-health-scale-step__content" >
            //- Large box
            <div class="survey-health-scale-step__box">
                <div class="survey-health-scale-step__box__text">{{ getBoxText }}</div>
                <div class="survey-health-scale-step__box__value">{{ getBoxValue }}</div>
            </div>
        </v-col>
        <v-col cols="6" class="survey-health-scale-step__rightcol survey-health-scale-step__content" >
            //- Slider
            <div class="survey-health-scale-step__slider">
                <v-slider class="survey-health-scale-step__slider__vslider" direction="vertical" :step="1" v-model="value" :min="step.sliderMinValue" :max="maxValue" track-color="#c0c0c0" />
            </div>
        </v-col>

        //- Bottom label, right half
        <v-col cols="6" class="survey-health-scale-step__leftcol" />
        <v-col cols="6" class="survey-health-scale-step__rightcol" >
            //- "0"
            <div class="survey-health-scale-step__slider__minmax">{{ minValue }}</div>
            //- "The worst health..."
            <div class="survey-health-scale-step__slider__label">{{ getLangItem( 'contentHealthBottomScoreText' ) }}</div>
        </v-col>
    </v-row>
</template>

<script>
import Locale from '@serv/Locale'
import SurveySliderMixin from '@mixins/SurveySliderMixin'
import SurveyStepMixin from '@mixins/SurveyStepMixin'

export default {
    name: 'SurveyHealthScaleStep',
    mixins: [SurveySliderMixin, SurveyStepMixin],
    data() {
        return {
            boxTextKey: ''
        }
    },
    computed: {
        getBoxText() {
            return Locale.getLanguageItem(this.boxTextKey)
        },
        getBoxValue() {
            const valueString = this.displayValue != undefined ? this.displayValue : ''

            return valueString
        },
        isHealthStep() {
            return true
        }
    },
    created() {
        this.minValue = 0
        this.maxValue = 100
        this.init()

        // HACK PORTED FROM MOBILE HealthScaleStepViewCell
        // Vary fixed labels, if the YAML definition indicates we want the value 6 months post-op, rather than today.
        // Originally put in for Corin. Need a better solution, i.e. properly defining this text within the step YAML.
        if (this.step.text.includes('6')) {
            this.step.text = Locale.getLanguageItem('contentHealthDescription6m')
            this.boxTextKey = 'contentHealthScoreText6m'
        } else {
            this.step.text = Locale.getLanguageItem('contentHealthDescription')
            this.boxTextKey = 'contentHealthScoreText'
        }
    }
}
</script>
