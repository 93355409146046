<template lang="pug">
extends PopupTemplate.pug
block input
    <div class="popup__text">{{ config.text }}</div>
</template>

<script>
import PopupMixin from '@mixins/PopupMixin'

export default {
    name: 'PopupNoButton',
    mixins: [PopupMixin]
}
</script>
