import BaseContent from '@model/content/BaseContent'
import ExerciseRoutine from '@model/content/ExerciseRoutine'
import ExerciseStep from '@model/content/ExerciseStep'
import HealthScaleStep from '@model/content/HealthScaleStep'
import Info from '@model/content/Info'
import InfoStep from '@model/content/InfoStep'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import MediaFile from '@model/content/MediaFile'
import MediaImage from '@model/content/MediaImage'
import MediaVideo from '@model/content/MediaVideo'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import PainScaleStep from '@model/content/PainScaleStep'
import QuestionStep from '@model/content/QuestionStep'
import Schedule from '@model/Schedule'
import SliderScaleStep from '@model/content/SliderScaleStep'
import store from '@/store'

import StringHelper from '@serv/StringHelper'
import Survey from '@model/content/Survey'
import Utils from '@serv/Utils'

const contentTypeToClass = {}

/**
 * Load and process Content objects.
 */
class ContentService {
    constructor() {
        // From Content.Type
        contentTypeToClass[BaseContent.Type.exerciseRoutine] = ExerciseRoutine
        contentTypeToClass[BaseContent.Type.file] = MediaFile
        contentTypeToClass[BaseContent.Type.image] = MediaImage
        contentTypeToClass[BaseContent.Type.info] = Info
        contentTypeToClass[BaseContent.Type.survey] = Survey
        contentTypeToClass[BaseContent.Type.video] = MediaVideo
        // From Step.Type
        contentTypeToClass[BaseContent.Type.exercise] = ExerciseStep
        contentTypeToClass[BaseContent.Type.healthScale] = HealthScaleStep
        contentTypeToClass[BaseContent.Type.infoStep] = InfoStep
        contentTypeToClass[BaseContent.Type.painScale] = PainScaleStep
        contentTypeToClass[BaseContent.Type.question] = QuestionStep
        contentTypeToClass[BaseContent.Type.sliderScale] = SliderScaleStep
    }

    // Create (but do not resolve) any BaseContent object from JSON.
    constructObjectFromJson(json) {
        const Class = contentTypeToClass[json.type]
        if (Class == undefined) {
            // Logging.warn(`Unrecognised Content type: ${json.type}`)
        } else {
            return new Class(json)
        }
    }

    // Parse a JSON array, and return created Content objects as a map of slug->object.
    parseContentArray(objectArray) {
        // Parse
        const slugToObject = {}
        objectArray.forEach(json => {
            const content = this.constructObjectFromJson(json)
            if (content) {
                slugToObject[content.slug] = content
            }
        })
        // Resolve
        Object.values(slugToObject).forEach(content => {
            content.resolve(slugToObject)
        })

        return slugToObject
    }

    // Filter a list of content objects by the specified tags - each item must have ALL tags.
    filterContentByAllTags(content, tags) {
        const objects = content.filter(object => {
            let hasAllTags = tags.every(v => object.tags.includes(v))

            return hasAllTags
        })

        return objects
    }

    // Filter a list of content objects by the specified tags - each item must have ANY tag.
    filterContentByAnyTags(content, tags) {
        if (!Array.isArray(content)) {
            content = [content]
        }

        const objects = content.filter(object => {
            let hasAnyTag = tags.some(v => object.tags.includes(v))

            return hasAnyTag
        })

        return objects
    }

    // Get all surveys tagged as prom-survey.
    getPromSurveys() {
        const allSurveys = Object.values(store.state.content.surveys || {})

        return allSurveys.filter(survey => survey.isPromSurvey)
    }

    // Get the slugs for all surveys tagged as prom-survey.
    getPromSurveySlugs() {
        return this.getPromSurveys().map(survey => survey.slug)
    }

    // Get all surveys with specifiec tag.
    getSurveysWithTag(tag) {
        return Object.values(store.state.content.surveys || {}).filter(survey => survey.containsTag(tag))
    }
    getSurveySlugsWithTag(tag) {
        return this.getSurveysWithTag(tag).map(survey => survey.slug)
    }

    /**
     * Sanitise a content text field, for example by:
     * - Evaluating text templates
     * - Removing markdown tokens such as ##
     *
     * Certain text templates can be evaluated more closely to what the patient actually saw, if context is passed in
     * such as the patient, content item, etc. Valid config params:
     * - patient (Object)
     * - patientJourney (Object)
     * - journey (Object)
     * - clinician (Object)
     * - removeTemplates ... true to remove any templates we could not resolve
     * - stripMarkdown ... true to remove H levels and <u>
     * - activity (Object)
     */
    sanitiseText(text, config = {}) {
        // Default params
        if (!config.hasOwnProperty('removeTemplates')) {
            config.removeTemplates = true
        }
        if (!config.hasOwnProperty('stripMarkdown')) {
            config.stripMarkdown = true
        }

        // Context
        const providers = store.state.resources.providers || {}
        const journeys = store.state.resources.journeys || {}
        const teams = store.state.user.teams || {}
        const patient = config.patient
        const patientJourney = config.patientJourney || (patient ? patient.firstJourney : undefined)
        let journey = config.journey
        if (patientJourney && !journey) {
            const journeySlug = patientJourney.journeySlug
            journey = journeys[journeySlug]
        }

        // Template evaluation
        const replacements = []

        // Template evaluation: clinician (with defaults)
        let clinicianFullName = Locale.getLanguageItem('contentYourClinician')
        let clinicianFullNameLc = Locale.getLanguageItem('contentYourClinicianLc')
        let clinicianLastName = Locale.getLanguageItem('contentYourClinician')
        let clinicianLastNameLc = Locale.getLanguageItem('contentYourClinicianLc')
        if (config.clinician) {
            clinicianFullName = config.clinician.titledFullName
            clinicianFullNameLc = config.clinician.titledFullName
            clinicianLastName = config.clinician.titledLastName
            clinicianLastNameLc = config.clinician.titledLastName
        }
        replacements.push({ from: '{{surgeon.TitledFullName}}', to: clinicianFullName })
        replacements.push({
            from: '{{surgeon.titledFullName}}',
            to: clinicianFullNameLc
        })
        replacements.push({ from: '{{surgeon.TitledLastName}}', to: clinicianLastName })
        replacements.push({
            from: '{{surgeon.titledLastName}}',
            to: clinicianLastNameLc
        })

        // Template evaluation: journey (with defaults)
        let jointText = Locale.getLanguageItem('jointGeneric')
        let journeyTitle = ''
        if (journey) {
            journeyTitle = journey.titleShortened
            if (journey.jointSlug) {
                jointText = Locale.getLanguageItemForModelEnum('joint', journey.jointSlug)
            }
        }
        replacements.push({ from: '{{journey.joint}}', to: jointText.toLowerCase() })
        replacements.push({ from: '{{journey.title}}', to: journeyTitle })

        // Template evaluation: patient (defaults all empty string)
        if (patient) {
            replacements.push({ from: '{{patient.age}}', to: patient.age || '' })
            replacements.push({ from: '{{patient.dob}}', to: patient.readableDob || '' })
            replacements.push({ from: '{{patient.hospitalNumber}}', to: patient.hospitalNumberDisplay || '' })
            replacements.push({ from: '{{patient.externalId}}', to: patient.readableExternalId })
            replacements.push({ from: '{{patient.sex}}', to: patient.sexString || '' })
        }
        // Template evaluation: patientJourney (defaults all empty string)
        if (patientJourney) {
            const patientSide = Locale.getLanguageItemForModelEnum('formSide', patientJourney.side || 'none')
            replacements.push({ from: '{{patient.side}}', to: patientSide })
            replacements.push({ from: '{{operationJourney.side}}', to: patientSide })
            replacements.push({ from: '{{patientJourney.externalId}}', to: patientJourney.readableExternalId || '' })

            const team = teams[patientJourney.teamId]
            const provider = providers[team?.providerSlug || '']
            replacements.push({ from: '{{provider.title}}', to: provider?.title || '' })

            let bundleType
            const payorValue = patientJourney.keyValues.payor
            if (payorValue) {
                bundleType = Locale.getLanguageItemOrUndefined('patientBundleType', [
                    Locale.getLanguageItemOrUndefined(patientJourney.keyValues.payor)
                ])
            }
            replacements.push({
                from: '{{patientJourney.keyValues.payor}}',
                to: bundleType || ''
            })

            let bundleEndDateString
            const dischargeDate = patientJourney.getMilestoneOfSlugDate('discharge')
            if (dischargeDate) {
                const bundleEndDate = new moment(dischargeDate).add(90, 'days')
                bundleEndDateString = Locale.getLanguageItemOrUndefined('patientBundleEndDate', [
                    bundleEndDate.format(Utils.readableDateFormat)
                ])
            }
            replacements.push({
                from: '{{patientJourney.bundleEndDate}}',
                to: bundleEndDateString || ''
            })
        }

        // Template evaluation: user (defaults all empty string)
        const user = store.state.user.user
        if (user) {
            replacements.push({ from: '{{user.firstName}}', to: user.firstName })
        }

        // Activity
        if (config.activity) {
            const schedule = Schedule.get(config.activity.scheduleSlug)
            replacements.push({ from: '{{schedule.summary}}', to: schedule.summary })
        }

        // Support JS templates
        var regex = /{{js\.(\w+)}}/g
        var replacedText = text.replace(regex, (match, functionName) => {
            try {
                const globals = Utils.isInTest ? global : window
                if (globals && globals[functionName]) {
                    return globals[functionName]()
                }
            } catch (error) {
                Logging.error(`Error evaluating function ${functionName}:`, error)
            }
        })
        text = replacedText

        // stringKeys
        regex = /{{stringKey\.(\w+)}}/g
        replacedText = text.replace(regex, (match, stringKey) => {
            try {
                return Locale.getLanguageItem(stringKey)
            } catch (error) {
                Logging.error(`Error localised stringKey ${stringKey}:`, error)
            }
        })
        text = replacedText

        // Strip markdown
        if (config.stripMarkdown) {
            const removePrefixes = ['#### ', '### ', '## ', '# ', '####', '###', '##', '#']
            for (const prefix of removePrefixes) {
                if (text.startsWith(prefix)) {
                    text = text.slice(prefix.length)
                }
            }
            replacements.push({ from: '<u>', to: '' })
            replacements.push({ from: '</u>', to: '' })
        }

        // Make removals/replacements
        for (const detail of replacements) {
            text = text.replace(new RegExp(detail.from, 'g'), detail.to)
        }
        // Remove empty bracket pair
        text = text.replace('()', '')

        // Remove everything remaining inside {{...}}
        if (config.removeTemplates) {
            text = text.replace(/\{\{(.*?)}}/g, '')
        }

        return text
    }

    /**
     * Get all ExerciseRoutine objects being used as templates, excluding any 'favourites' template.
     */
    getExerciseTemplates() {
        return Object.values(store.state.content.content).filter(
            content =>
                content.type == BaseContent.Type.exerciseRoutine &&
                content.tags.includes('template') &&
                !content.tags.includes('favourites')
        )
    }

    /**
     * Generate unique slug for ExerciseRoutine template.
     */
    generateUserExerciseTemplateSlug(user, type, title) {
        const prefix = type == ExerciseRoutine.TemplateType.user ? user.slugPrefix : user.providerSlugs[0]
        const titleSlug = StringHelper.stringToSlug(title).substring(0, 32)
        const uuid = nanoid()
        const slug = `${prefix}-${titleSlug}-${uuid}-tpl-routine`

        return slug
    }

    getSurveyStepTitleText(stepSlug, config) {
        const content = store.state.content.content
        const step = content[stepSlug]

        return step ? this.sanitiseText(step.title, config) : stepSlug
    }

    // Get the readable survey step question text from a stepSlug.
    getSurveyStepQuestionText(stepSlug, config, version) {
        const content = store.state.content.content
        const step = this.getContentByVersion(content[stepSlug], version)

        return step ? this.sanitiseText(step.text, config) : stepSlug
    }

    /**
     * Get the readable survey step result answer text.
     * This may include the readable answer value(s), plus any additional free-text value, which may require
     * formatting (e.g. date/height/weight).
     */
    getSurveyStepResultAnswerText(stepResult, version) {
        const content = store.state.content.content
        const step = this.getContentByVersion(content[stepResult.stepSlug], version)

        const lines = []
        if (step instanceof QuestionStep) {
            // stepResult.value may be an array, for multi-selection.
            if (step.isPureFreeText) {
                // If a pure free-text question, add nothing for the single selection itself
            } else if (Array.isArray(stepResult.value)) {
                const valueStrings = stepResult.value.map(value => step.choiceValueToText[value])
                lines.push(valueStrings.join(', '))
            } else {
                lines.push(step.choiceValueToText[stepResult.value])
            }
            // Also free-text?
            let freeText
            if (stepResult.freeTextValue) {
                const choiceIndex = step.textFieldChoiceIndex
                if (choiceIndex >= 0) {
                    const choiceValue = step.choices[choiceIndex].value
                    if (choiceValue == 'date') {
                        freeText = moment(stepResult.freeTextValue).format(Utils.readableDateFormat)
                    } else if (choiceValue == 'height') {
                        const metric = StringHelper.numberStringToFloat(stepResult.freeTextValue)
                        if (!metric) {
                            freeText = ''
                        } else {
                            freeText = store.state.user.owner.hasSurveyResultFlag('showImperialUnits')
                                ? StringHelper.getMetricHeightInAllUnits(metric)
                                : `${metric.toFixed(2)}m`
                        }
                    } else if (choiceValue == 'weight') {
                        const metric = StringHelper.numberStringToFloat(stepResult.freeTextValue)
                        if (!metric) {
                            freeText = ''
                        } else {
                            freeText = store.state.user.owner.hasSurveyResultFlag('showImperialUnits')
                                ? StringHelper.getMetricWeightInAllUnits(metric)
                                : `${metric.toFixed(0)}kg`
                        }
                    } else {
                        freeText = stepResult.freeTextValue
                    }
                    lines.push(freeText)
                }
            }

            return lines.join(' ... ')
        }
        if (step instanceof SliderScaleStep) {
            const intValue = stepResult.valueInt ? stepResult.valueInt.toString() : stepResult.value.toString()
            const minValue = step.sliderMinValue.toString()
            const maxValue = step.sliderMaxValue.toString()

            return `${intValue} (${minValue}...${maxValue})`
        }

        // Fallback
        return '?'
    }

    getContentByVersion(content, version) {
        if (!version || !content.versions) {
            return content
        }

        const contentVersion =
            content.version == version ? content : content.versions.find(content => content.version == version)
        if (!contentVersion) {
            Logging.warn(`ContentService.getContentByVersion: Could not find version ${version} for ${content.slug}`)

            return content
        }

        return contentVersion
    }
}

export default new ContentService()
