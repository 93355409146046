import _ from 'lodash'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import momentTz from 'moment-timezone'
import StringHelper from '@serv/StringHelper'
import { browserName, browserVersion, isIOS, isMacOs } from 'mobile-device-detect'

class Utils {
    get dateFarPast() {
        return '1970-01-01'
    }

    get dateFarFuture() {
        return '2999-12-31'
    }

    // Return true only if the specified object is an Object with no class prototype.
    isPlainObject(obj) {
        return !!obj && typeof obj === 'object' && obj.constructor === Object
    }

    backgroundColor(config, user) {
        const index = user.userId % config.length

        return config[index]
    }

    /**
     * From an array of scalar values, return an array of index ranges where the array value matches that
     * specified. Each range is returned as an object:
     * startIndex: index of first matching value (inclusive)
     * endIndex: index of last matching value (inclusive)
     * length: 1 + endIndex - startIndex
     *
     * Example:
     * getIndexRangesOfValueInArray([true, false, true, true, false]) ->
     * [{ startIndex: 0, endIndex: 0, length: 1}, { startIndex: 2, endIndex: 3, length: 2}]
     */
    getIndexRangesOfValueInArray(array, value) {
        const ranges = []
        let range = {}
        array.forEach((arrayValue, i) => {
            if (arrayValue == value) {
                if (range.startIndex == undefined) {
                    range.startIndex = i
                }
            } else {
                if (range.startIndex != undefined) {
                    range.endIndex = i - 1
                    range.length = 1 + range.endIndex - range.startIndex
                    ranges.push(range)
                    range = {}
                }
            }
        })
        if (range.startIndex != undefined) {
            range.endIndex = array.length - 1
            range.length = 1 + range.endIndex - range.startIndex
            ranges.push(range)
        }

        return ranges
    }

    /**
     * Our standard readable date format, e.g. "03 Jan 1971", "10 Dec 2020"
     */
    get readableDateFormat() {
        return 'DD MMM YYYY'
    }

    get readableDateTimeFormat() {
        return 'DD MMM YYYY HH:mm'
    }

    get readableTimeDateFormat() {
        return 'hh:mma DD MMM YYYY'
    }

    /**
     * Our standard serialised date format, e.g. "1971-01-03", "2020-12-10"
     */
    get serialisedDateFormat() {
        return 'YYYY-MM-DD'
    }

    /**
     * Our standard serialised datetime format, e.g. "2020-12-10T12:30:59"
     */
    get serialisedDateTimeFormat() {
        return 'YYYY-MM-DDTHH:mm:ssZ'
    }

    /**
     * Get the date 'now' in our standard serialised date format.
     */
    get serialisedDateNow() {
        const nowMoment = new moment()

        return nowMoment.format(this.serialisedDateFormat)
    }

    /**
     * Get the datetime 'now' in our standard serialised date format.
     */
    get serialisedDateTimeNow() {
        const nowMoment = new moment()

        return nowMoment.format(this.serialisedDateTimeFormat)
    }

    /**
     * Get a date string YYYY-MM-DD from a datetime string YYYY-MM-DDTHH-MM-SS
     * Returns undefined if datetime string in the wrong format.
     */
    getDateStringFromDateTimeString(dateTimeString) {
        const parts = dateTimeString.split('T')

        return parts.length == 2 ? parts[0] : undefined
    }

    /**
     * From an array of objects, find the single object with the specified key-value.
     * If not exactly 1 match found, optionally error (if errorString provided).
     * 11/08/21 If more than 1 match found, return the first match.
     */
    findSingleObjectWithKeyValue(objects, key, value, errorString) {
        const matches = Object.values(objects).filter(object => object[key] == value)
        if (matches.length != 1 && errorString) {
            Logging.error(`Found ${matches.length} ${errorString} objects with ${key}=${value}`)
        }
        if (matches.length > 0) {
            return matches[0]
        }
    }

    /**
     * From an array of objects, find the (first) array index with the specified key-value.
     * Optionally error (if errorString provided).
     */
    findObjectIndexWithKeyValue(objects, key, value, errorString) {
        for (let i = 0; i < objects.length; i++) {
            if (objects[i][key] == value) {
                return i
            }
        }
        if (errorString) {
            Logging.error(`No ${errorString} object with ${key}=${value}`)
        }
    }

    /**
     * Sort a list of users, first by last name, then by first name.
     */
    sortUsersByLastThenFirstNames(users) {
        users.sort((a, b) => {
            return a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName)
        })
    }

    /**
     * Permute all value combinations for a provided list of arrays, calling a function with each combination.
     * A provided object context can also be passed to the function, which takes inputs (keyValues, object)
     * The value combinations are provided as named arrays, in the form of a keyValues object.
     *
     * For example:
     * const keyValues = {
     *   foo: [ 'fooOne', 'fooTwo' ],
     *   bar: [ 'barOne', 'barTwo' ]
     * }
     * The function will be called 4 times with inputs:
     * keyValues = { foo: 'fooOne', bar: 'barOne' }
     * keyValues = { foo: 'fooTwo', bar: 'barOne' }
     * keyValues = { foo: 'fooOne', bar: 'barTwo' }
     * keyValues = { foo: 'fooTwo', bar: 'barTwo' }
     */
    callFunctionForEachArrayCombination(keyValues, object, fn) {
        // Strip empty arrays, set up indices
        const indexMap = {}
        Object.keys(keyValues).forEach(key => {
            if (keyValues[key].length == 0) {
                delete keyValues[key]
            } else {
                indexMap[key] = 0
            }
        })
        // Iterate indices
        const keys = Object.keys(keyValues).sort()
        const numKeys = keys.length

        // For each combination
        let doneAll = false
        while (!doneAll) {
            // Compose keyValues with correct value combo, to pass to function
            const fnKeyValues = {}
            for (let i = 0; i < numKeys; i++) {
                const key = keys[i]
                const index = indexMap[key]
                const value = keyValues[key][index]
                fnKeyValues[key] = value
            }
            // Call function with values
            fn(fnKeyValues, object)

            // Increase indices, starting from the 0th one, and tripping others as we go
            let i = 0
            let doing = true
            while (doing) {
                const key = keys[i]
                //const index = indexMap[key]
                indexMap[key] += 1
                if (indexMap[key] != keyValues[key].length) {
                    // No tripping
                    break
                }
                indexMap[key] = 0
                if (++i == keys.length) {
                    // Done all combos
                    doneAll = true
                    break
                }
            }
        }
    }

    /**
     * Get the top-level store properties that differ between a "new" and "old" object.
     * Return the difference as an object with the property names, and the new values.
     * This can be used directly as a patch payload.
     */
    getObjectsDiff(newObject, oldObject) {
        const diff = {}
        Object.keys(newObject).forEach(key => {
            if (!_.isEqual(newObject[key], oldObject[key])) {
                diff[key] = newObject[key]
            }
        })

        return diff
    }

    /**
     * Given a moment, return the moment of the first Monday before or equal to it.
     */
    getFirstMondayBeforeOrAt(inMoment) {
        const firstDayIndex = (inMoment.day() + 6) % 7 // convert from 0=Sunday to 0=Monday
        const outMoment = inMoment.clone().subtract(firstDayIndex, 'days')

        return outMoment
    }

    /**
     * Get a readable summary of the difference between two dates.
     * For differences <= 30 days, we display in days.
     * For differences <= 60 days, we display in weeks (rounded to nearest).
     * Otherwise we display in months (rounded to nearest).
     */
    getMomentDiffSummary(fromMoment, toMoment) {
        const diff = toMoment.startOf('day').diff(fromMoment.startOf('day'), 'days')

        return this.getDaysDiffSummary(diff)
    }
    getDaysDiffSummary(diff) {
        let stringKey
        if (diff == 0) {
            stringKey = 'today'
        } else if (diff < 0) {
            // '...ago'
            diff = -diff
            if (diff > 60) {
                diff = Math.floor((diff + 15) / 30)
                stringKey = diff == 1 ? 'dateDifferenceMonthAgo' : 'dateDifferenceMonthsAgo'
            } else if (diff > 30) {
                diff = Math.floor((diff + 3) / 7)
                stringKey = diff == 1 ? 'dateDifferenceWeekAgo' : 'dateDifferenceWeeksAgo'
            } else {
                stringKey = diff == 1 ? 'dateDifferenceDayAgo' : 'dateDifferenceDaysAgo'
            }
        } else {
            // 'in...'
            if (diff > 60) {
                diff = Math.floor((diff + 15) / 30)
                stringKey = diff == 1 ? 'dateDifferenceMonthFuture' : 'dateDifferenceMonthsFuture'
            } else if (diff > 30) {
                diff = Math.floor((diff + 3) / 7)
                stringKey = diff == 1 ? 'dateDifferenceWeekFuture' : 'dateDifferenceWeeksFuture'
            } else {
                stringKey = diff == 1 ? 'dateDifferenceDayFuture' : 'dateDifferenceDaysFuture'
            }
        }

        return Locale.getLanguageItem(stringKey, [diff])
    }

    /**
     * Round a number to a specified precision, in decimal places.
     * NOTE: If the number is already an integer, we do NOT generate a point with trailing zeros.
     *
     */
    roundNumberToDecimalPlaces(value, precision) {
        var multiplier = Math.pow(10, precision || 0)

        return Math.round(value * multiplier) / multiplier
    }

    // Return the union of two sets.
    setUnion(setA, setB) {
        let _union = new Set(setA)
        for (const elem of setB) {
            _union.add(elem)
        }

        return _union
    }

    /**
     * From a time picker selection of format HH:mm, return a UTC offset string suitable for appending on the
     * right-hand side of the 'T' character in a full datetime string.
     *
     * If zone parameter is not supplied, we use the browser timezone (typically it's only supplied for tests).
     */
    getUtcOffsetTimeFromHoursMins(hoursMins, zone) {
        zone = zone || Intl.DateTimeFormat().resolvedOptions().timeZone // e.g. 'Europe/London'
        const offset = moment(momentTz.tz(zone)).format('Z') // e.g. '+00:00'

        return `${hoursMins}:00${offset}`
    }

    // Return true only if running within mocha tests: https://stackoverflow.com/questions/29183044/how-to-detect-if-a-mocha-test-is-running-in-node-js
    // This is not great practice, but avoids using JSDOM or the like. Use sparingly!
    isInTest() {
        const result = typeof global.it === 'function'

        return result
    }

    // Download a text file, given a string and filename.
    downloadTextFile(text, filename) {
        const blob = new Blob([text])
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename)
        } else {
            let a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, {
                type: 'text/plain'
            })
            a.download = filename
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }
    }

    // Download a file, given a full URL.
    downloadFileFromUrl(url, filename) {
        fetch(url)
            .then(resp => resp.blob())
            .then(blobObject => {
                const blob = window.URL.createObjectURL(blobObject)
                const anchor = document.createElement('a')
                anchor.style.display = 'none'
                anchor.href = blob
                anchor.download = filename
                document.body.appendChild(anchor)
                anchor.click()
                window.URL.revokeObjectURL(blob)
            })
            .catch(() => Logging.error(`Error downloading filename ${filename} from URL: ${url}`))
    }

    // Rename object key from keyFrom to keyTo.
    renameObjectKey(object, keyFrom, keyTo) {
        if (keyFrom !== keyTo) {
            Object.defineProperty(object, keyTo, Object.getOwnPropertyDescriptor(object, keyFrom))
            delete object[keyFrom]
        }
    }

    // Recursively trim whitespace from all strings in the object.
    trimObjectStrings(object, recurse) {
        // Iterate all keys and potentially recurse
        Object.keys(object).forEach(key => {
            const value = object[key]
            if (typeof value == 'string') {
                object[key] = object[key].trim()
            } else if (recurse && Array.isArray(value)) {
                this.trimArrayStrings(value, recurse)
            } else if (recurse && _.isObject(value)) {
                this.trimObjectStrings(value, recurse)
            }
        })
    }

    // Recursively trim whitespace from all strings in the array.
    trimArrayStrings(array, recurse) {
        // Iterate all array items and recurse
        array.forEach((item, index) => {
            if (typeof item == 'string') {
                array[index] = array[index].trim()
            } else if (recurse && Array.isArray(item)) {
                this.trimArrayStrings(item, recurse)
            } else if (recurse && _.isObject(item)) {
                this.trimObjectStrings(item, recurse)
            }
        })
    }

    // Get browser details, for POSTing.
    getBrowser() {
        let aKeys = ['MSIE', 'Firefox', 'Safari', 'Chrome', 'Opera'],
            sUsrAg = navigator.userAgent,
            nIdx = aKeys.length - 1

        while (nIdx > -1 && sUsrAg.indexOf(aKeys[nIdx]) === -1) {
            nIdx--
        }

        return aKeys[nIdx]
    }

    isBrowserVersionSupported() {
        switch (browserName) {
            case 'Chrome':
            case 'Edge':
                // For Win 7 the latest supported version for both browsers is 109.
                return !(browserVersion < '109')
            case 'Firefox':
                return !(browserVersion < '112')
            case 'Safari':
            case 'Mobile Safari':
                return isIOS || isMacOs ? !(browserVersion < '15.6.1') : !(browserVersion < '15')
            default:
                // Unsupported browser
                return false
        }
    }

    /**
     * Given a URL (which may be parameterised), infer the local @public JSON filename.
     */
    urlToMockJsonFilename(requestUrl, isWriting, mockSet) {
        let filename = requestUrl
        let startIndex
        if (filename.includes('oauth2')) {
            startIndex = filename.indexOf('oauth2')
        } else {
            startIndex = filename.startsWith('/') ? 1 : 0
        }
        const endIndex = filename.endsWith('/') ? filename.length - 1 : filename.length
        filename = filename.slice(startIndex, endIndex)
        filename = StringHelper.replaceAll(filename, '/?', '-')
        filename = StringHelper.replaceAll(filename, '?', '-')
        filename = StringHelper.replaceAll(filename, '/', '-')
        filename = StringHelper.replaceAll(filename, '=', '-')
        if (isWriting) {
            filename = `${filename}.json`
        } else {
            filename = `/mock/${mockSet}/${filename}.json`
        }
        filename = StringHelper.replaceAll(filename, '/-', '/')
        filename = StringHelper.replaceAll(filename, '-.', '.')

        return filename
    }

    // Given a string and a character index, replace every character from that index with 'X'.
    // Works with endIndex < startIndex, and negative endIndex (which is treated as relative to string end).
    deidentifyStringFromIndex(options) {
        const { string, startIndex, replacementChar = 'X' } = options
        let { endIndex = string.length } = options

        if (startIndex < 0 || startIndex >= string.length) {
            Logging.warn('deidentifyStringFromIndex: startIndex must be >= 0 and < string length')

            return string
        }

        if (endIndex < 0) {
            endIndex += string.length
        }

        if (endIndex > string.length || startIndex > endIndex) {
            Logging.warn('deidentifyStringFromIndex: endIndex must be within string bounds and >= startIndex')

            return string
        }

        const prefix = string.substring(0, startIndex)
        const suffix = string.substring(endIndex, string.length)
        const deidentifiedSection = replacementChar.repeat(endIndex - startIndex)

        return prefix + deidentifiedSection + suffix
    }

    // For object keys within a defined list, change the values to 'XXX'
    // This is used to redact sensitive data from logs.
    redactObjectKeys(object) {
        const keysToRedact = ['firstName', 'lastName', 'dob', 'hospitalNumber']
        // Iterate all keys and recurse
        Object.keys(object).forEach(key => {
            const value = object[key]
            if (typeof value == 'string') {
                if (keysToRedact.includes(key)) {
                    object[key] = this.deidentifyStringFromIndex({
                        string: value,
                        startIndex: 1
                    })
                }
            } else if (Array.isArray(value)) {
                this.redactArrayOfObjectsKeys(value)
            } else if (_.isObject(value)) {
                this.redactObjectKeys(value)
            }
        })

        return object
    }

    redactArrayOfObjectsKeys(array) {
        // Iterate all array items and recurse
        array.forEach(item => {
            this.redactObjectKeys(item)
        })
    }

    /**
     * Returns the start date for given period within provided date range
     */
    getPeriodStartDateBetweenDates(startDate, endDate, periodInDays = 30) {
        if (moment(endDate).diff(moment(startDate), 'days') > periodInDays) {
            startDate = moment(endDate).subtract(periodInDays, 'days').format(this.serialisedDateFormat)
        }

        return startDate
    }
}

export default new Utils()
