import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
import store from '@/store'

export function _resolveObjectListColumnOfType_grmaStocPreOpEducationAppt(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error('ListColumn.Type.grmaStocPreOpEducationAppt can only be used on objects of type: Patient')
    }

    const keyValue = row.firstJourney.keyValues[column.key]

    if (keyValue) {
        column.cellText = Locale.getLanguageItemForModelEnum('patientPreOpEducationStatus', keyValue)
    } else {
        const owner = store.state.user.owner
        const isAppointmentRequired = PatientService.isPreOpEducationAppointmentRequired(row, row.firstJourney, owner)

        column.cellText = Locale.getLanguageItemForModelEnum(
            'patientPreOpEducationStatus',
            isAppointmentRequired ? 'required' : 'notRequired'
        )
    }
}

export function _resolvePatientJourneyListColumnOfType_grmaStocPreOpEducationAppt(column, patientJourney, row) {
    const keyValue = row[column.key]
    column.cellText = keyValue ? Locale.getLanguageItemForModelEnum('patientPreOpEducationStatus', keyValue) : ''
}
