import ListTransform from '@model/list/ListTransform'

class ListTransformCarePeriodPac extends ListTransform {
    constructor(object) {
        object.filterKey = 'carePeriodPac'
        super(object)
    }

    get getFilterConfig() {
        return this.column.getExplicitValueFilterConfig()
    }

    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        rows.sort((a, b) => {
            const valueA = a.listColumns[columnIndex].number || -1
            const valueB = b.listColumns[columnIndex].number || -1

            return valueA - valueB
        })
    }
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex

        return rows.filter(row => filterStrings.includes(row.listColumns[columnIndex].filterValue))
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        // Each keyString is 'grey', 'green' or 'red'
        return keyStrings
    }
    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'care_period_pac'
    }
}

export default ListTransformCarePeriodPac
