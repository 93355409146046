import _ from 'lodash'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import moment from 'moment'
import ReferralMilestone from '@model/ReferralMilestone'
import store from '@/store'
import StringHelper from '@serv/StringHelper'
import UserService from '@serv/UserService'

/**
 * Functions for assisting with Milestones and PatientJourneyMilestones.
 */
class MilestoneService {
    get milestonesConfig() {
        return store.state.user.owner.keyValues.milestones
    }

    // Return all available milestone slugs, from the keyValues.milestones config.
    getMilestonesSlugs() {
        return Object.keys(this.milestonesConfig).filter(key => !key.startsWith('_'))
    }

    getMilestoneConfigOptions(type, options) {
        return this.milestonesConfig[type]?.[options] || []
    }

    // Is this milestone slug one that lives on the global journey?
    isMilestoneSlugGlobal(slug) {
        return Milestone.globalSlugs.includes(slug)
    }

    _isTaskReviewTypeMilestone(milestone) {
        return milestone.type == Milestone.Type.taskReview
    }

    /**
     * Get a readable title for the milestone.
     * If milestone has a subtype, we append from a composed stringkey, otherwise we use just the milestone slug.
     * For example:
     * 1. Milestone with slug=operation
     *  - milestoneOperation => 'Operation'
     * 2. AppointMilestone with slug=appointment, appointmentType=orthoFootAnkle
     *  - milestoneAppointment => 'Appointment'
     *  - appointmentTypeOrthoFootAnkle => 'Foot & ankle'
     *  - result = 'Appointment: Foot & ankle'
     */
    getMilestoneTitle(milestone) {
        let title = Locale.getLanguageItemForModelEnum('milestone', milestone)
        if (milestone.subtypeKey) {
            title = `${title}: ${Locale.getLanguageItemForModelEnum(milestone.subtypeKey, milestone.subtype)}`
        }

        return title
    }

    /**
     * Returns a data source for populating a dropdown list of all selectable milestones, from the keys of the
     * TabCptAdminDates.milestoneSlugs or keyValues.milestones config. e.g. "Appointment", "Injury", "Operation", "Referral".
     */
    getMilestonesSelectDataSource(milestoneSlugs) {
        let titleValues = []
        const user = store.state.user.user
        if (user.isAdmin) {
            titleValues = [
                'activity-list',
                'invitation',
                'registration',
                'operation',
                'discharge',
                'rtm-period-review',
                'rtm-discharge',
                'custom-task',
                'met'
            ].map(slug => {
                return {
                    title: Locale.getLanguageItemForModelEnum('milestone', StringHelper.slugToCamelCase(slug)),
                    value: slug
                }
            })
        } else {
            const slugs = milestoneSlugs || this.getMilestonesSlugs()

            titleValues = slugs?.map(slug => {
                return {
                    title: Locale.getLanguageItemForModelEnum('milestone', StringHelper.slugToCamelCase(slug)),
                    value: slug
                }
            })
        }

        return titleValues.sort((a, b) => a.title.localeCompare(b.title))
    }

    getOptions(type, owner) {
        const milestonesConfig = this.milestonesConfig[type]

        if (!milestonesConfig) {
            Logging.error(
                `Fixtures: Missing milestone config for "${type}" in owner ${owner.slug} 'keyValues.milestones.${type}'. Displaying no options`
            )

            return []
        }

        const milestoneTypes = milestonesConfig[`${type}Types`]
        if (!milestoneTypes) {
            Logging.error(
                `Fixtures: Missing config for "${type}Types" in owner ${owner.slug} 'keyValues.milestones.${type}.${type}Types'. Displaying no options`
            )

            return []
        }

        const options = milestoneTypes.map(string => {
            if (string) {
                return {
                    title: Locale.getLanguageItemForModelEnum(`${type}Type`, string),
                    value: string
                }
            }
        })

        return options
    }

    /**
     * Returns true only if the specified milestone should be editable by the dash user.
     * Always false for milestones on the global journey.
     */
    isMilestoneEditable(milestone) {
        const user = store.state.user.user
        if (user.isAdmin) {
            return true
        }
        if (Milestone.globalSlugs.includes(milestone.slug)) {
            return false
        }

        const typeConfig = this.milestonesConfig[milestone.type] || this.milestonesConfig[milestone.slug] || {}

        return typeConfig.editableInDash === undefined ? true : typeConfig.editableInDash
    }

    /**
     * Returns true only if the specified milestone should be deletable by the dash user.
     * Always false for milestones on the global journey, or if primary.
     */
    isMilestoneDeletable(milestone) {
        const user = store.state.user.user
        if (user.isAdmin) {
            return true
        }
        if (Milestone.globalSlugs.includes(milestone.slug)) {
            return false
        }
        if (milestone.isPrimary) {
            return false
        }

        if (this._isTaskReviewTypeMilestone(milestone) && !!milestone.reviewedDate) {
            return false
        }

        const typeConfig = this.milestonesConfig[milestone.type] || this.milestonesConfig[milestone.slug] || {}

        return typeConfig.deletableInDash === undefined ? true : typeConfig.deletableInDash
    }

    sortMilestonesByMoment(milestones) {
        const nowMoment = moment()
        milestones.sort((a, b) => (a.moment || nowMoment).diff(b.moment || nowMoment))
    }

    /**
     * Returns an array of objects ("items") that can be used to draw ranges on a data axis, corresponding to a specific
     *  PJ.
     * Only PJMs that match the specified milestoneSlugs are included. Also:
     * - In general there is a single item for each milestoneSlug
     * - However, if the slug corresponds to a milestone with subtype, we return an item for each subtype where there is
     * existing PJM
     * - The PJ primaryMilestone is split into its own item, and returned first
     * - Item label is the subtype label (if milestone has subtypes) else the milestone label
     * - Other than the primaryMilestone item, items are sorted alphabetically by label
     * - primaryMilestone item is drawn in a distinct colour, the rest in the same colour
     * - primaryMilestone item is by default enabled, others are by default disabled
     *
     * If milestoneSlugs is an empty array, no items will be created.
     * If milestoneSlugs is undefined, we add a single entry for the patientJourney.primaryMilestoneSlug
     *
     * NOTE: This is here rather than ChartService because the latter has amCharts imports which mean it can't have
     * pure Mocha tests.
     */
    getPatientJourneyMilestoneRangeItems(patient, patientJourney, milestoneSlugs) {
        let items = []
        let itemFirst
        if (milestoneSlugs == undefined) {
            milestoneSlugs = [patientJourney.primaryMilestoneSlug]
        }
        milestoneSlugs.forEach(slug => {
            const isGlobal = this.isMilestoneSlugGlobal(slug)
            let milestones
            if (isGlobal) {
                milestones = patient.globalJourney.getMilestonesOfSlug(slug)
            } else {
                milestones = patientJourney.getMilestonesOfSlug(slug)
            }
            // Split by subtype (if any)
            const primaryColString = '#6996b3'
            const otherColString = '#93d3ff'
            const subTypeToMilestones = _.groupBy(milestones, 'subtype')
            for (const subtype of Object.keys(subTypeToMilestones)) {
                let milestones = subTypeToMilestones[subtype]
                const slug = milestones[0].slug
                const label =
                    subtype != 'undefined'
                        ? Locale.getLanguageItemForModelEnum(`${slug}Type`, subtype)
                        : Locale.getLanguageItemForModelEnum('milestone', slug)
                // If milestones contains primaryMilestone, extract into own item
                if (milestones.includes(patientJourney.primaryMilestone)) {
                    itemFirst = {
                        colour: primaryColString,
                        label: Locale.getLanguageItemForModelEnum('milestone', slug),
                        milestones: [patientJourney.primaryMilestone],
                        isEnabled: true,
                        isPrimary: true
                    }
                    const index = milestones.indexOf(patientJourney.primaryMilestone)
                    milestones.splice(index, 1)
                }
                if (milestones.length) {
                    items.push({
                        colour: otherColString,
                        label: label,
                        milestones: milestones,
                        isEnabled: false,
                        isPrimary: false
                    })
                }
            }
        })
        items.sort((a, b) => a.label.localeCompare(b.label))
        if (itemFirst) {
            items = [itemFirst, ...items]
        }

        return items
    }

    canReviewReferral(milestone) {
        if (milestone.status == ReferralMilestone.Status.offered) {
            return UserService.isUserMemberOfProviderTeams(milestone.referredToProviderSlug)
        }

        if ([ReferralMilestone.Status.rejected, ReferralMilestone.Status.discharged].includes(milestone.status)) {
            return UserService.isUserMemberOfProviderTeams(milestone.referredFromProviderSlug)
        }

        return false
    }
}

export default new MilestoneService()
