import ActivityListService from '@serv/ActivityListService'

/**
 * An ActivityList object, which is a list of Activity slugs.
 */
class ActivityList {
    constructor(object) {
        this.slug = object.slug
        this.activitySlugs = object.activitySlugs
    }

    get activities() {
        if (!this.activitySlugs) {
            return []
        }

        return this.activitySlugs.map(slug => ActivityListService.getActivityBySlug(slug))
    }
}

export default ActivityList
