import Milestone from '@model/Milestone'
import moment from 'moment'

// A period (course) milestone object.
class PeriodMilestone extends Milestone {
    constructor(object) {
        object.type = object.type || Milestone.Type.period
        object.slug = object.slug || 'period'
        super(object)
        this.periodType = object.periodType
        this.setEndDate(object.endDate)
        this.notes = object.notes
        this.time = object.time
    }

    get subtypeKey() {
        return 'periodType'
    }

    // Set the end date from a string (or undefined).
    setEndDate(date) {
        this.endDate = date // string in standard format
        this.endMoment = date ? new moment(date) : undefined
    }
}

export default PeriodMilestone
