<template lang="pug">
extends SurveyStepTemplate.pug
block input
    <div class="survey-slider-scale-step">
        <div class="survey-slider-scale-step__slider">
            <div v-if="!step.hideNumbers" class="survey-slider-scale-step__slider__minmax">{{ minValue }}</div>
            <v-app class="survey-slider-scale-step__slider__slider">
                <v-slider class="survey-slider-scale-step__slider__vslider" :step="1" v-model="value" :min="step.sliderMinValue" :max="maxValue" track-color="#c0c0c0" />
            </v-app>
            <div v-if="!step.hideNumbers" class="survey-slider-scale-step__slider__minmax">{{ maxValue }}</div>
        </div>
        <div style="display: flex;">
            <div class="survey-slider-scale-step__footer__labels" style="text-align: left;">{{ step.sliderLeftText }}</div>
            <div class="survey-slider-scale-step__footer__value"><span>{{ displayValue }}</span></div>
            <div class="survey-slider-scale-step__footer__labels" style="text-align: right;">{{ step.sliderRightText }}</div>
        </div>
    </div>
</template>

<script>
import SurveySliderMixin from '@mixins/SurveySliderMixin'
import SurveyStepMixin from '@mixins/SurveyStepMixin'

export default {
    name: 'SurveySliderScaleStep',
    mixins: [SurveySliderMixin, SurveyStepMixin],
    created() {
        this.minValue = this.step.sliderMinValue
        this.maxValue = this.step.sliderMaxValue
        this.init()
    }
}
</script>
