import Logging from '@serv/Logging'
import Storage from '@serv/Storage'

export class StorageCache {
    set(key, entry) {
        Storage.set(key, entry)
        if (entry.body) {
            Storage.set('mr_session', entry.body)
            Storage.set('auth_source', 'auth0')
        }
    }

    get(key) {
        try {
            return Storage.get(key)
        } catch (e) {
            Logging.error(e)
        }
    }

    remove(key) {
        Storage.remove(key)
    }

    allKeys() {
        return Object.keys(Storage.getStorage())
            .filter(key => key.startsWith('@@'))
            .concat(['mr_session', 'auth_source'])
    }
}
