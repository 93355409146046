import _ from 'lodash'
import BaseContent from '@model/content/BaseContent'
import ExerciseService from '@serv/ExerciseService'
import ExerciseSettings from '@model/content/ExerciseSettings'
import Task from '@model/content/Task'

class ExerciseRoutine extends Task {
    constructor(object) {
        object.type = BaseContent.Type.exerciseRoutine
        super(object)
        this.activeDays = ExerciseService.activeDaysEnumsToBools(object.activeDays)
        this.workoutsPerDay = object.workoutsPerDay || 1

        this.exerciseSettings = []
        if (object.exerciseSettings) {
            object.exerciseSettings.forEach(json => {
                const exerciseSettings = new ExerciseSettings(json)
                this.exerciseSettings.push(exerciseSettings)
            })
        }
    }

    /**
     * If used as a template, get the single groupLabel to copy into all ExerciseSettings.
     */
    get groupLabel() {
        return this.exerciseSettings.length > 0 ? this.exerciseSettings[0].groupLabel : ''
    }

    /**
     * Get a payload to PUT or POST when saving an ExerciseRoutine (as a template).
     * {'slug': 'jhub-eegg-test-tpl-routine', 'title': 'Emily Egg test template', 'tags': ['jhub-eegg', 'template', 'user', 'exercise-routine'], 'type': 'exerciseRoutine', 'stepSlugs': ['jhub-knee-flex-stan-ex', 'jhub-squa-ex'], 'workoutsPerDay': 1, 'exerciseSettings': [{'exercise': 'jhub-knee-flex-stan-ex', 'groupLabel': 'ACLR Phase 1.4'}, {'exercise': 'jhub-squa-ex', 'groupLabel': 'ACLR Phase 1.4'}], 'version': '1', 'locale': 'en', 'slugVersionLocale': 'jhub-eegg-test-tpl-routine.v1.en'}
     */
    get requestPayload() {
        const exerciseSettingsOptional = ['title', 'tempo', 'rest', 'intensity', 'text']

        return {
            type: this.type,
            slug: this.slug,
            title: this.title,
            tags: this.tags,
            workoutsPerDay: this.workoutsPerDay,
            exerciseSettings: this.exerciseSettings.map(settings => {
                const object = {
                    exercise: settings.stepSlug,
                    groupLabel: settings.groupLabel || ExerciseSettings.emptyGroupLabel
                }
                // Must be set, but we can use 0 which will deserialise back to undefined
                object.sets = settings.sets || 0
                object.reps = settings.reps

                if (settings.reps == undefined && settings.duration == undefined) {
                    object.reps = 0
                }
                // Round load to integer
                if (settings.load != undefined) {
                    object.load = Math.round(settings.load)
                }
                // Add further fields only if specified
                exerciseSettingsOptional.forEach(field => {
                    if (field) {
                        object[field] = settings[field]
                    }
                })
                if (settings.activeDays && settings.activeDays.includes(true)) {
                    object.activeDays = ExerciseService.activeDaysBoolsToEnums(settings.activeDays)
                }

                return object
            }),
            version: '1',
            locale: 'en',
            slugVersionLocale: `${this.slug}.v1.en`
        }
    }

    getExerciseSettingsWithStepSlug(stepSlug) {
        return this.exerciseSettings.find(settings => settings.stepSlug == stepSlug)
    }

    setExerciseSettings(exerciseSettings) {
        const index = this.exerciseSettings.findIndex(settings => settings.stepSlug == exerciseSettings.stepSlug)
        if (index != -1) {
            // Do NOT reassign the ExerciseSettings - instead copy all properties
            _.extend(this.exerciseSettings[index], exerciseSettings)
        } else {
            this.exerciseSettings.push(exerciseSettings)
        }
    }

    /**
     * Assuming this object is being used as an exercise template, return the template type,
     * based on the tags, or undefined if no type found.
     */
    getTemplateType() {
        for (const type of Object.values(ExerciseRoutine.TemplateType)) {
            if (this.tags.includes(type)) {
                return type
            }
        }
    }
}

/**
 * Template types, when ExerciseRoutine is used as a template.
 * Exactly one of these is set as a tag on every template.
 */
ExerciseRoutine.TemplateType = {
    provider: 'provider',
    owner: 'owner',
    user: 'user'
}

export default ExerciseRoutine
