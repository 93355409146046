import ContentService from '@serv/ContentService'
import ExerciseStep from '@model/content/ExerciseStep'
import Survey from '@model/content/Survey'
import Task from '@model/content/Task'

const state = {
    content: undefined,
    hasContent: false,
    exercises: undefined,
    surveys: undefined
}

const getters = {
    content: state => {
        return state.content
    },
    exercises: state => {
        return state.exercises
    },
    hasContent: state => {
        return state.hasContent
    },
    surveys: state => {
        return state.surveys
    }
}

function updateExercises(state) {
    state.exercises = state.exercises || {}
    // Build exercises map
    const exerciseObjects = Object.values(state.content).filter(object => object instanceof ExerciseStep)
    exerciseObjects.forEach(object => {
        state.exercises[object.slug] = object
    })
}

function updateSurveys(state) {
    state.surveys = state.surveys || {}
    // Build surveys map
    const surveyObjects = Object.values(state.content).filter(object => object instanceof Survey)
    surveyObjects.forEach(object => {
        state.surveys[object.slug] = object
    })
}

const actions = {}

const mutations = {
    setContent(state, contentArray) {
        state.content = state.content || {}
        const contentMap = ContentService.parseContentArray(contentArray)
        Object.entries(contentMap).forEach(([key, newContent]) => {
            let existingContent = state.content[key]

            if (!existingContent) {
                state.content[key] = newContent

                return
            }

            // If this version hasn't been stored yet, and is not nested within the versions array of the existing content
            let existingVersion = existingContent.versions?.find(version => version.version == newContent.version)
            if (existingContent.version != newContent.version && !existingVersion) {
                existingContent.versions = existingContent.versions || []
                existingContent.versions.push(newContent)
            }
        })

        updateExercises(state)
        updateSurveys(state)
        state.hasContent = true
    },
    // Set a single survey - here to support surveys mocked during development
    setSurvey(state, survey) {
        survey.resolve()
        state.content[survey.slug] = survey
        survey.steps.forEach(step => {
            state.content[step.slug] = step
        })
        updateSurveys(state)
    },
    /**
     * Called only when all original requests (resources and reports) are completed.
     * We can use this to create references between returned objects.
     */
    resolveContent(state) {
        Object.values(state.content).forEach(object => {
            if (object instanceof Task) {
                object.postProcess()
            }
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
