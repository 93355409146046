<template>
    <div class="survey-outro-step-header">
        <!-- No scores defined - display tick -->
        <div
            v-if="viewModel.mode == 'displayNoScoresOk'"
            class="survey-outro-step-header__tick tick-size svg--white"
        >
            <img :src="getIconUrl('icon-tick-scalable.svg')" />
        </div>
        <!-- Scores defined but cannot calculate any - display message -->
        <h1
            v-if="viewModel.mode == 'displayNoScoresError'"
            class="survey-outro-step-header__message"
        >
            {{ getLangItem('patientSurveyOutroCannotCalculate') }}
        </h1>
        <!-- Scores defined and can calculate some - show all displayable scores -->
        <div
            v-if="viewModel.mode == 'displayScores'"
            class="survey-outro-step-header__scores"
        >
            <!-- Overall score -->
            <div v-if="viewModel.overallScore">
                <h1 class="survey-outro-step-header__overall-label">
                    {{ getLangItem('patientSurveyOverallScoreTitle') }}
                </h1>
                <div class="survey-outro-step-header__overall-score">
                    <span class="survey-outro-step-header__overall-score-value">
                        {{ viewModel.overallScore.score }}
                    </span>
                    <span class="survey-outro-step-header__overall-score-slash">/</span>
                    <span class="survey-outro-step-header__overall-score-max">
                        {{ viewModel.overallScore.maxScore }}
                    </span>
                </div>
            </div>
            <!-- Other scores -->
            <div v-if="viewModel.otherScores.length > 0">
                <SurveyOutroStepHeaderScoreSection
                    v-for="(scoreSection, index) in viewModel.otherScores"
                    :key="index"
                    :score-section="scoreSection"
                />
            </div>
        </div>
        <!-- All text is controlled through the component text properties, and the SurveyStepTemplate.pug -->
    </div>
</template>

<script>
import SurveyOutroStepHeaderScoreSection from '@comp/websurvey/SurveyOutroStepHeaderScoreSection.vue'
import SurveyStepMixin from '@mixins/SurveyStepMixin'
import useImage from '@composables/useImage'

// This is not a step component, but is passed props step and stepResult, so can use SurveyStepMixin
export default {
    name: 'SurveyOutroStepHeader',
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    mixins: [SurveyStepMixin],
    components: {
        SurveyOutroStepHeaderScoreSection
    },
    data() {
        return {
            viewModel: undefined,
            isNextButtonEnabled: true
        }
    },
    computed: {
        /**
         * Get the overall score anx maxScore as an object. This is only valid if:
         * - The Survey.keyValues define an overall Score section to be displayed, OR the survey is a clinical-survey
         * - AND the surveyResult.score has a value
         */
        getOverallScoreAndMax() {
            const survey = this.taskHandler.survey
            const scoreSection = survey.getOverallScoreSection(true)
            const score = this.taskHandler.surveyResult.score
            if (
                (scoreSection ||
                    (survey.keyValues.scoreSections == undefined &&
                        (survey.isClinicalSurvey || survey.isPainSurvey))) &&
                !isNaN(score)
            ) {
                const precisionDp = scoreSection ? scoreSection.precisionDp || 1 : 1
                const maxScore = scoreSection ? scoreSection.maxScore : survey.maxScore

                return {
                    score: Number.isInteger(score) ? score : score.toFixed(precisionDp),
                    maxScore: maxScore
                }
            }

            return undefined
        },
        // Return all other displayable score sections.
        getOtherScoreSections() {
            const scoreSections = this.taskHandler.survey.getOtherScoreSections(true)

            return scoreSections
        }
    },
    created() {
        this.viewModel = this.taskHandler.getOutroHeaderViewModel()
    }
}
</script>

<style lang="scss">
@import '../../styles/websurvey';

.survey-outro-step-header {
    text-align: center;
    width: 100%;
    background-image: url('../../images/slide-background.svg'); // relative path from components/websurvey
    background-size: cover;

    &__tick {
        width: 16vh;
        height: 16vh;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3vh;
    }

    &__message {
        width: 40vh;
        color: white;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 3vh 0;
    }

    &__scores {
        padding-bottom: 3vh;
    }

    &__overall {
        &-label {
            color: white;
            padding-top: 3vh;
        }

        &-score {
            padding-top: 0.5vh;
            line-height: 1 !important;
            text-align: center;
            font-family: $defaultFontMed;
            display: flex;
            justify-content: center;

            &-value {
                font-size: 4vh !important;
                color: white;
                display: inline-block;
                align-self: flex-end;
            }

            &-slash {
                font-size: 2vh !important;
                width: 20px !important;
                color: $mediumGrey;
                display: inline-block;
                align-self: flex-end;
                margin-bottom: 0.7vh;
            }

            &-max {
                font-size: 2vh !important;
                color: $mediumGrey;
                display: inline-block;
                align-self: flex-end;
                margin-bottom: 0.5vh;
            }
        }
    }
}
</style>
