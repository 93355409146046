import _ from 'lodash'
import moment from 'moment'

const abbrToSlug = {
    appt: 'appointment',
    dis: 'discharge',
    goal: 'goal',
    inj: 'injury',
    inv: 'invitation',
    op: 'operation',
    optdis: 'outpatient-discharge',
    pifu: 'pifu',
    refer: 'referral',
    reg: 'registration',
    rjahscreening: 'rjah-screening',
    rtmdischarge: 'rtm-discharge',
    rtmenrol: 'rtm-enrol',
    treat: 'treatment'
}

let slugToAbbr = undefined // inverted from the above

/*
A milestone object.
*/
class Milestone {
    constructor(object) {
        this.id = object.patientJourneyMilestoneId || object.id
        this.type = object.type || Milestone.Type.default

        // If milestoneId is provided, we'll resolve to slug using resources.milestones in resolvePatients()
        // slug was provided only by BE versions before March 2023
        this.milestoneId = object.milestoneId
        this.slug = object.slug

        this.isPrimary = !!object.isPrimary // default to false
        this.isActive = object.isActive == undefined ? true : !!object.isActive // default to true
        this.setDate(object.date)
    }

    // Set the date from a string.
    setDate(date) {
        this.date = date // string in standard format
        this.moment = date ? moment(date) : undefined
    }

    get subtype() {
        return this.subtypeKey ? this[this.subtypeKey] : undefined
    }

    // Override to provide subtype
    get subtypeKey() {
        return undefined
    }

    // Is the milestone on the global journey?
    get isGlobal() {
        return Milestone.globalSlugs.includes(this.slug)
    }

    /**
     * Get a milestone slug from an abbreviation.
     * Normally this mapping would come from the client having an array of Milestone objects.
     * For simplicity, currently we hard-code the mapping for the only common milestones we need.
     */
    static abbrToSlug(abbr) {
        return abbrToSlug[abbr]
    }
    static slugToAbbr(slug) {
        slugToAbbr = slugToAbbr || _.invert(abbrToSlug)

        return slugToAbbr[slug]
    }
    /**
     * From a milestone slug, get the milestone type.
     * This is 'default' unless the slug is a known type.
     */
    static slugToType(slug) {
        const specialCases = {
            customTask: 'taskReview'
        }

        slug = _.camelCase(slug)
        if (Milestone.Type[slug]) {
            return slug
        }

        if (specialCases[slug]) {
            return specialCases[slug]
        }

        return 'default'
    }
}

/**
 * All Milestone types.
 */
Milestone.Type = {
    activityList: 'activityList',
    appointment: 'appointment',
    carePeriod: 'carePeriod',
    default: 'default',
    goal: 'goal',
    monthlyReview: 'monthlyReview',
    period: 'period',
    referral: 'referral',
    rtmPeriodReview: 'rtmPeriodReview',
    scheduledReview: 'scheduledReview',
    surveyReview: 'surveyReview',
    taskReview: 'taskReview'
}

/**
 * Milestone slugs that are always on the global PatientJourney.
 */
Milestone.globalSlugs = ['goal', 'invitation', 'registration']

export default Milestone
