import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import store from '@/store'
import StringHelper from '@serv/StringHelper'

/**
 * List transform for arbitrary key value.
 */
class ListTransformKeyValue extends ListTransform {
    constructor(object) {
        super(object)
        this.sortKey = this.column.keyValue || this.column.key
        if (object.filterSetKey == 'patientJourneyList') {
            this.filterKey = `${this.sortKey}Value`
        }
    }

    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    get getFilterConfig() {
        if (this.column.hasExplicitValue) {
            return this.column.getExplicitValueFilterConfig()
        }

        // Start with all values stored on local models
        const key = this.column.keyValue
        const patients = Object.values(store.state.user.patients || {})
        let allValues = patients.map(patient => patient.keyValues[key])

        const reportKey = this.column.filterTypeAheadDataSourceReport
        let mappedValues = {}
        if (reportKey) {
            if (store.state.resources.reports[reportKey]) {
                // We have a report, add all values for this key
                const datasetValues = store.state.resources.reports[reportKey].dataset
                    .filter(row => row.key == key)
                    .map(row => row.value)
                allValues = [...allValues, ...datasetValues]

                const uniqueValues = [...new Set(allValues)].filter(value => value && value.length > 0).sort()
                // DataFilterStringArray <voerro-tags-input> requires a MAP
                uniqueValues.forEach(value => (mappedValues[value] = value))
            } else {
                Logging.error(
                    `ListColumn specifies filterTypeAheadDataSourceReport but found no report with key ${reportKey}`
                )
            }
        } else {
            const uniqueValues = [...new Set(allValues)].filter(value => value && value.length > 0).sort()
            // DataFilterStringArray <select> requires an ARRAY
            mappedValues = uniqueValues.map(value => {
                return { value: value, label: value }
            })
        }
        const uniqueValues = [...new Set(allValues)].filter(value => value && value.length > 0).sort()
        // Convert list to map
        const valuesMap = {}
        uniqueValues.map(value => (valuesMap[value] = value))
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: valuesMap,
            filterTypeAheadDataSourceReport: reportKey,
            filterTypeAheadPlaceholder: Locale.getLanguageItemOrUndefined(this.column.filterTypeAheadPlaceholder)
        }

        return config
    }
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        let filtered
        if (this.column.hasExplicitValue) {
            const columnIndex = this.column.columnIndex
            filtered = rows.filter(row => {
                // Filter string value is assumed match column filterValue
                return filterStrings.includes(row.listColumns[columnIndex].filterValue)
            })
        } else {
            filtered = rows.filter(row => {
                // Readable filter string is assumed to match the stored keyValue
                const value = row.keyValues[this.column.keyValue]

                return filterStrings.includes(value)
            })
        }

        return filtered
    }

    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        if (this.column.valueFilterOptions) {
            const values = keyStrings.map(key => {
                const option = this.column.valueFilterOptions[key]

                return Array.isArray(option) ? option[0] : option
            })

            return isGoddard ? { values } : values
        }

        return isGoddard ? { values: keyStrings } : keyStrings
    }

    getSortParameter() {
        return `${StringHelper.camelCaseToSnakeCase(this.sortKey)}_value`
    }
}

export default ListTransformKeyValue
