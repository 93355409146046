<template>
    <div
        :key="`survey-${resetIndex}`"
        class="survey"
    >
        <!-- Main step UX -->
        <component
            :key="`step-${taskHandler.stepIndex}`"
            :is="stepClass"
            :step="taskHandler.currentStep"
            :step-result="taskHandler.currentStepResult"
        />
        <div
            class="survey-footer"
            :class="{ 'survey-footer--fixed': isFooterFixed }"
        >
            <ProgressBar
                v-if="taskHandler.lastStepIndex > 2"
                class="survey-footer-bar"
            />
            <!-- TODO make this a component -->
            <div
                v-if="showNavigation"
                class="survey-footer-nav"
            >
                <v-btn
                    v-if="!taskUi.shouldHidePrevButton"
                    id="prevButton"
                    class="survey-footer-nav__prev"
                    variant="text"
                    color="fail"
                    :disabled="!taskHandler.isPrevButtonEnabled"
                    @click="prevStep()"
                >
                    <img
                        class="left svg--brandA"
                        :src="getIconUrl('chevron-left.svg')"
                    />
                    {{ prevButtonLabel }}
                </v-btn>
                <v-btn
                    id="nextButton"
                    class="survey-footer-nav__next"
                    variant="text"
                    :disabled="taskHandler.nextButtonStatus == 'disabled'"
                    @click="nextStep()"
                >
                    {{ nextButtonLabel }}
                    <img
                        class="right svg--brandA"
                        :src="getIconUrl('chevron-right.svg')"
                    />
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import Analytics from '@serv/Analytics'
import BaseContent from '@model/content/BaseContent'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import PopupTwoButton from '@comp/popups/PopupTwoButton.vue'
import ProgressBar from '@comp/websurvey/ProgressBar.vue'
import Redirect from '@serv/Redirect'
import SurveyService from '@serv/SurveyService'
import SurveysMixin from '@mixins/SurveysMixin'
import TaskUi from '@serv/TaskUi'
import useImage from '@composables/useImage'
import VueMarkdown from 'vue3-markdown-it'
import { mapGetters, mapState } from 'vuex'

import SurveyHealthScaleStep from '@comp/websurvey/SurveyHealthScaleStep.vue'
import SurveyInfoStep from '@comp/websurvey/SurveyInfoStep.vue'
import SurveyPainScaleStep from '@comp/websurvey/SurveyPainScaleStep.vue'
import SurveyQuestionStep from '@comp/websurvey/SurveyQuestionStep.vue'
import SurveyResultsService from '@serv/SurveyResultsService'
import SurveySliderScaleStep from '@comp/websurvey/SurveySliderScaleStep.vue'

export default {
    name: 'MrSurvey',
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    emits: ['successfullySubmitted'],
    mixins: [SurveysMixin],
    components: {
        ProgressBar,
        SurveyHealthScaleStep,
        SurveyInfoStep,
        SurveyPainScaleStep,
        SurveySliderScaleStep,
        SurveyQuestionStep,
        VueMarkdown
    },
    props: {
        singleSurvey: {
            type: Boolean,
            default: false
        },
        withoutInfoPages: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            resetIndex: 0, // increase each time we start a new survey
            isNextButtonEnabled: true
        }
    },
    computed: {
        ...mapGetters([
            'content',
            'didSelectSingleSurvey',
            'nextWebSurveyScheduleEvent',
            'patientJourneys',
            'taskHandler',
            'user'
        ]),
        ...mapState({
            isWebRegistered: state => state.portalRegistration.isWebRegistered
        }),
        // Return the Vue component class to display the current step
        stepClass() {
            const cptClass =
                SurveyService.stepTypeToComponent[
                    this.taskHandler.currentStep.type
                ]

            return cptClass
        },
        prevButtonLabel() {
            return Locale.getLanguageItem('patientSurveyNavPrev')
        },
        nextButtonLabel() {
            return this.taskHandler.nextButtonLabel
        },
        currentStepResultValues() {
            return {
                value: this.taskHandler.currentStepResult.value,
                freeTextValue: this.taskHandler.currentStepResult.freeTextValue
            }
        },
        showNavigation() {
            return !this.taskHandler.isIntroStep
        },
        taskUi() {
            return TaskUi.get()
        },
        isFooterFixed() {
            return this.isPhone || this.clinicianCompleteForPatient
        }
    },
    methods: {
        onSubmitCancel() {
            this.$store.commit('popup/dismiss')
        },
        // Submit a completed result
        onSubmitConfirm() {
            this.$emit('successfullySubmitted')
            this.$store.commit('popup/dismiss')
            const patientJourney = this.taskHandler.patientJourney
            const activity = this.taskHandler.activity
            if (!this.clinicianCompleteForPatient) {
                Analytics.sendEvent('surveySubmit', {
                    contentSlug: activity.contentSlug,
                    scheduleSlug: activity.scheduleSlug,
                    patientJourneyId: patientJourney.patientJourneyId
                })
            }
            // Mark the StepResult as complete, etc.
            this.taskHandler.complete()

            // POST or PUT result
            SurveyService.onStepResultChanged(this.taskHandler)

            if (this.singleSurvey && !this.clinicianCompleteForPatient) {
                if (this.isWebRegistered) {
                    Redirect.gotoName('PortalRegistrationReferFinish')
                } else {
                    Redirect.gotoName('PortalRegistrationReferSuccess')
                }

                return
            }

            // Re-resolve the ScheduleEvents, now that the SurveyResult has timestamps
            const events = SurveyResultsService.getPatientJourneyScheduleEventsMatchingActivity(patientJourney, activity)
            for (const event of events) {
                event.calculateMatchingSurveyResults(this.patient.surveyResults, patientJourney.surveyResults, patientJourney)
            }

            // Clinicians can only complete one survey at a time
            if (this.clinicianCompleteForPatient) {
                SurveyService.unloadAllJsFiles(true)

                return
            }

            // Move to next survey, or Done page, or return to Welcome page
            const nextPatientJourneyScheduleEvent = this.nextWebSurveyScheduleEvent
            if (
                !nextPatientJourneyScheduleEvent || this.didSelectSingleSurvey
            ) {
                SurveyService.unloadAllJsFiles(true) // also clears TaskHandler

                if (this.didSelectSingleSurvey) {
                    // Redirect back to main route, for Welcome page
                    Redirect.gotoPath('/')
                } else {
                    Logging.log(`No web surveys to complete`)
                    // nextPatientJourneyScheduleEvent being undefined will trigger appearance of Done component
                }

                return
            }

            // Create TaskHandler and set on state
            this.reset()
            const nextSurveyPatientJourneyId = nextPatientJourneyScheduleEvent[0]
            const nextSurveyActivity = nextPatientJourneyScheduleEvent[1].activity
            // This is async - TaskHandler set on state when complete
            SurveyService.initialiseTaskHandlerForPatientJourneyActivity(
                this.patient,
                this.patientJourneys[nextSurveyPatientJourneyId],
                nextSurveyActivity
            )
        },
        // Called when pressing Next or Submit
        nextStep() {
            if (this.taskHandler.isSkipping) {
                return
            }
            if (this.taskHandler.isLastStep) {
                if (this.taskHandler.numSkippedSteps > 0) {
                    // Skipped steps, display confirmation dialog
                    const text = Locale.getLanguageItem(
                        'patientSurveySubmitSkippedStepsText',
                        [
                            this.taskHandler.numSkippedSteps,
                            Locale.getLanguageItem('cancel'),
                            Locale.getLanguageItem('patientSurveyNavPrev'),
                            Locale.getLanguageItem(
                                'patientSurveySubmitSkippedStepsConfirm'
                            )
                        ]
                    )
                    this.$store.commit('popup/setConfig', {
                        title: Locale.getLanguageItem(
                            'patientSurveySubmitSkippedStepsTitle'
                        ),
                        text: text,
                        leftButtonText: Locale.getLanguageItem(
                            'patientSurveySubmitSkippedStepsConfirm'
                        ),
                        rightButtonText: Locale.getLanguageItem('cancel'),
                        onLeftButton: this.onSubmitConfirm,
                        onRightButton: this.onSubmitCancel,
                        onCancel: this.onSubmitCancel
                    })
                    this.$store.commit('popup/setClass', PopupTwoButton)
                } else {
                    // Submit now
                    this.onSubmitConfirm()
                }
            } else {
                this.taskHandler.nextStep()
            }
        },
        prevStep() {
            if (this.taskHandler.isSkipping) {
                return
            }
            this.taskHandler.prevStep()
        },
        reset() {
            this.resetIndex++
        }
    },
    created() {
        Analytics.sendEvent('surveyEnter', {
            contentSlug: this.taskHandler.activity.contentSlug,
            scheduleSlug: this.taskHandler.activity.scheduleSlug,
            patientJourneyId: this.taskHandler.patientJourney.patientJourneyId
        })
        this.reset()

        if (this.withoutInfoPages) {
            while (this.stepClass == SurveyService.stepTypeToComponent[BaseContent.Type.infoStep] && !this.taskHandler.isLastStep) {
                this.taskHandler.nextStep()
            }
        }
    }
}
</script>
