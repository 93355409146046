<template>
    <div>
        <!-- Title -->
        <div class="websurvey__banner-title d-flex justify-center --spacer">
            <span>
                {{ getLangItem('patientSurveyWelcomeTitle' , user.firstName) }}
            </span>
        </div>
        <!-- Video -->
        <v-row class="websurvey__video justify-center">
            <v-col
                class="justify-center pa-0"
                cols="8"
                xs="12"
                sm="12"
                md="12"
                lg="8"
                xl="8"
            >
                <div v-if="video">
                    <video
                        v-if="video && showVideo"
                        class="patient-surveys-welcome__video"
                        :src="video.blobUrlFull"
                        controls
                        autoplay
                    />
                    <div
                        v-if="!showVideo"
                        class="patient-surveys-welcome__poster"
                        @click="showVideo = true"
                    >
                        <img
                            class="patient-surveys-welcome__poster__background"
                            :src="getVideoPosterImage"
                        />
                        <img
                            class="patient-surveys-welcome__poster__play"
                            :src="getIconUrl('icon-play.svg')"
                        />
                        <img />
                    </div>
                </div>
                <ClinicianBanner
                    v-else-if="isPhone && getClinicianBannerMediaImage"
                    :banner-media-image="getClinicianBannerMediaImage"
                    :logo-media-image="getClinicianLogoMediaImage"
                />
            </v-col>
        </v-row>
        <!-- Text -->
        <div class="patient-surveys-welcome__text">
            <VueMarkdown :source="getWelcomeText" />
        </div>

        <!-- Begin button -->
        <div class="patient-surveys-welcome__bottom text-center">
            <v-btn
                variant="outlined"
                @click="onBeginSurveys"
            >
                {{ getLangItem('patientSurveyWelcomeButton') }}
            </v-btn>
        </div>
        <!-- For mobile only, display list view underneath -->
        <SurveysListView v-if="isPhone" />
    </div>
</template>

<script>
import Analytics from '@serv/Analytics'
import ClinicianBanner from '@comp/ClinicianBanner.vue'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import { mapGetters } from 'vuex'
import Redirect from '@serv/Redirect'
import StringHelper from '@serv/StringHelper'
import SurveyService from '@serv/SurveyService'
import SurveysListView from '@comp/websurvey/SurveysListView.vue'
import SurveysMixin from '@mixins/SurveysMixin'
import useImage from '@composables/useImage'
import VueMarkdown from 'vue3-markdown-it'

export default {
    name: 'SurveysWelcome',
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    mixins: [SurveysMixin],
    components: {
        ClinicianBanner,
        SurveysListView,
        VueMarkdown
    },
    data() {
        return {
            showVideo: false,
            video: undefined,
            nextSurveyPatientJourneyId: undefined,
            nextSurveyActivitySlug: undefined
        }
    },
    computed: {
        ...mapGetters([
            'nextWebSurveyScheduleEvent',
            'selfSurveyActivity', // TODO needs to be a ScheduleEvent
            'patientJourneysSurveys',
            'teams',
            'user',
            'users'
        ]),
        getWelcomeText() {
            const numSurveys = SurveyService.getNumIncompleteSurveys()
            let text = Locale.getLanguageItem('patientSurveyWelcomeText', [
                this.leadName,
                numSurveys,
                Locale.getLanguageItem('patientSurveyWelcomeButton')
            ])
            text = StringHelper.replaceAll(text, '\\n', '\n')

            return text
        },
        getVideoPosterImage() {
            const url = this.video.posterStartImage.blobUrlFull

            return url
        }
    },
    methods: {
        onBeginSurveys() {
            this.$store.commit('setDidSelectSingleSurvey', false)
            Analytics.logSurveysBegin(this.patientJourneysSurveys)
            Redirect.gotoPath(
                `patient-web-surveys/${this.nextSurveyPatientJourneyId}/${this.nextSurveyActivitySlug}/`,
                {}
            )
        }
    },
    created() {
        this.video = SurveyService.getFirstSurveyVideo()

        // Get details of 1st survey, if any
        const nextPatientJourneyScheduleEvent = this.nextWebSurveyScheduleEvent // TODO  || this.selfSurveyActivity
        if (!nextPatientJourneyScheduleEvent) {
            Logging.error(`No web surveys to complete`)
        } else {
            this.nextSurveyPatientJourneyId = nextPatientJourneyScheduleEvent[0]
            this.nextSurveyActivitySlug = nextPatientJourneyScheduleEvent[1].activitySlug
        }
    }
}
</script>
