import Config from '@serv/Config'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Redirect from '@serv/Redirect'
import Request from '@serv/Request'
import { useStore } from 'vuex'

export const addOwnerAndRegionQueryParams = (redirectName, to, from, next) => {
    const store = useStore()
    const ownerConfig = store.state.selfReferral.ownerConfig || store.state.portalRegistration.ownerConfig

    if (!to.query.owner || !to.query.region) {
        const onCloseModal = () => {
            if (to.query.owner) {
                const ownerUrl = Request.Stem.owner.replace('{ownerSlug}', to.query.owner)
                Config.configure(to.query.region || 'gb')
                const baseUrl = Config.base_url

                Request.get(`${baseUrl}/${ownerUrl}`)
                    .then(({ data }) => {
                        if (data?.keyValues?.client?.selfRegistrationLandingPage) {
                            window.location.href = data?.keyValues?.client?.selfRegistrationLandingPage
                        } else {
                            Redirect.gotoName('Login')
                        }
                    })
                    .catch(() => {
                        Logging.error(`NHS STW flow, owner: '${to.query.owner}'. Request for owner config failed.`)

                        Redirect.gotoName('Login')
                    })
            } else {
                Logging.error(
                    "NHS STW flow, patient does not have query params for redirect. Can't be redirected to start of the flow."
                )

                Redirect.gotoName('Login')
            }
        }

        store.commit('popup/setConfig', {
            title: Locale.getLanguageItem('selfRegisterErrorModalTitle'),
            text: Locale.getLanguageItem('selfRegisterErrorModalText'),
            buttonText: Locale.getLanguageItem('ok'),
            onCancel: onCloseModal,
            onButton: onCloseModal
        })

        store.commit('popup/setClass', 'PopupOneButton')

        return
    }

    if (!ownerConfig) {
        Redirect.gotoPageWithParams(redirectName, to.params, {
            owner: to.query.owner,
            region: to.query.region
        })

        return
    }

    next()
}
