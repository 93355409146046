import AppointmentMilestone from '@model/AppointmentMilestone'
import ListColumn from '@model/list/ListColumn'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment/moment'
import Patient from '@model/Patient'
import PeriodMilestone from '@model/PeriodMilestone'
import StringHelper from '@serv/StringHelper'
import Utils from '@serv/Utils'

export function _resolveObjectListColumnOfType_date(column, row) {
    if (row instanceof Patient) {
        const milestones = row.getMilestonesOfSlug(column.dateMilestone)
        if (milestones.length == 0) {
            // No milestone found with this slug
        } else {
            if (milestones.length > 1) {
                // User date qualifier to find single milestone
                if (column.dateQualifier == ListColumn.Qualifier.earliest) {
                    milestones.sort((a, b) => a.moment - b.moment)
                } else if (column.dateQualifier == ListColumn.Qualifier.latest) {
                    milestones.sort((a, b) => b.moment - a.moment)
                }
            }
            column.date = milestones[0].date
        }
    } else {
        const property = column.property
        if (!property) {
            Logging.error(`ListColumn of type date must specify property`)

            return
        }
        column.date = row[property]
    }

    // Add date and moment properties
    if (column.date) {
        column.moment = moment(column.date)
        const date = column.moment.format(Utils.readableDateFormat)
        let momentType = date
        if (column.subtype == 'weeksSince') {
            momentType = moment().diff(column.moment, 'weeks')
        } else if (column.subtype == 'daysSince') {
            momentType = moment().diff(column.moment, 'days')
        }
        if (row.time && (row instanceof AppointmentMilestone || row instanceof PeriodMilestone)) {
            column.cellText = `${momentType} (${moment(row.time, 'HH:mm').format('HH:mm')})`
        } else {
            column.cellText = momentType
        }
    } else {
        // Construct placeholder text, if string key defined
        // Placeholder string key is column label key + 'Null'
        const stringKey = `${column.label}Null`
        const text = Locale.getLanguageItemOrUndefined(stringKey)
        column.cellText = text ? text : ''
        column.moment = undefined
    }
}
export function _resolvePatientJourneyListColumnOfType_date(column, patientJourney, row) {
    if (column.subtype) {
        const property = `${column.subtype}${StringHelper.capitalize(column?.dateQualifier) || ''}${StringHelper.capitalize(column.dateMilestone)}Date`
        column.cellText = row[property] || ''

        return
    }

    const property = `${column.dateMilestone}Date`
    column.date = row[property] // date string or undefined

    // Add date and moment properties
    if (column.date) {
        column.moment = moment(column.date)
        column.cellText = column.moment.format(Utils.readableDateFormat)
        if (column.subtype == 'weeksSince') {
            column.cellText = moment().diff(column.moment, 'weeks')
        } else if (column.subtype == 'daysSince') {
            column.cellText = moment().diff(column.moment, 'days')
        }
    } else {
        // Construct placeholder text, if string key defined
        // Placeholder string key is column label key + 'Null'
        const stringKey = `${column.label}Null`
        const text = Locale.getLanguageItemOrUndefined(stringKey)
        column.cellText = text ? text : ''
        column.moment = undefined
    }
}
