import Locale from '@serv/Locale'
import Logging from '@serv/Logging'

export function _resolveObjectListColumnOfType_properties(column, row) {
    if (!column.properties || column.properties.length == 0) {
        Logging.error(`ListColumn of type properties must specify properties`)

        return
    }

    const properties = []

    column.properties.forEach(property => {
        if (row[property] != undefined) {
            properties.push(row[property])
        }
    })

    column.cellText = properties.length > 0 ? Locale.getLanguageItem(column.cellText, [...properties]) : ''
}

export function _resolvePatientJourneyListColumnOfType_properties(column, patientJourney, row) {
    const properties = []

    column.properties.forEach(property => {
        if (row[property] != undefined) {
            properties.push(row[property])
        }
    })

    column.cellText = properties.length > 0 ? Locale.getLanguageItem(column.cellText, [...properties]) : ''
}

// eslint-disable-next-line no-unused-vars
export function _resolvePatientJourneyListColumnOfType_videosCompleted(column, patientJourney, row, errors) {
    column.cellText = Locale.getLanguageItem(column.cellText, [
        row[column.id]?.completed || 0,
        row[column.id]?.total || 0
    ])
}
