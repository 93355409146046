import _ from 'lodash'
import ClinicalMilestone from '@model/ClinicalMilestone'
import ClinicalMilestoneService from '@serv/ClinicalMilestoneService'
import ContentService from '@serv/ContentService'
import ListColumn from '@model/list/ListColumn'
import ListService from '@serv/ListService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment/moment'
import numeral from 'numeral'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
import Schedule from '@model/Schedule'
import store from '@/store'
import StringHelper from '@serv/StringHelper'
import Survey from '@model/content/Survey'
import Utils from '@serv/Utils'

/**
 * Resolve a ListColumn of type clinicalMilestone for a Patient.
 * Can set column properties cellText, number, status
 *
 * The logic here is reasonably involved and warrants a full explanation. There are essentially 3 properties
 * that need evaluating:
 * CLINICAL MILESTONES: These are the *patient clinical milestones*, i.e. the patient results that have previously been found to match
 * the clinicalMilestoneSpecs
 * COLUMN ACTIVITIES: These are all activities from the patient's primary journey, that match the column spec. This means that content tag/slug must match,
 * AND the activity schedule must match or overlap the COLUMN SCHEDULE (see below)
 * COLUMN SCHEDULE: This is the calculated column schedule. If the column spec scheduleSlug == 'current', we determine which of the standard promSchedules overlaps 'now'
 * for the patient, and use this. Otherwise we assume the column spec scheduleSlug is a valid schedule, and use this.
 */
export function _resolveObjectListColumnOfType_clinicalMilestone(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.clinicalMilestone can only be used on objects of type: Patient`)

        return
    }
    const patient = row
    if (ListService.isLoggingVerbose) {
        ListService._logClinicalMilestoneColumnForPatient(column, patient)
    }
    const clinicalMilestones = patient.clinicalMilestones
    const columnScheduleSlug = ListService._getClinicalMilestoneColumnScheduleForPatient(column, patient)
    if (columnScheduleSlug == undefined) {
        const pmMoment = patient.firstJourney.primaryMilestoneMoment
        const pmDate = pmMoment ? pmMoment.format(Utils.serialisedDateFormat) : 'not set'
        Logging.log(
            `Could not determine column schedule: scheduleSlug ${column.scheduleSlug}, primaryDate: ${pmDate}, contentSlug: ${column.contentSlug}, contentTag: ${column.contentTag}, patientId: ${patient.personaId}`
        )

        return
    }
    const nowMoment = moment()
    const columnActivities = ListService._getColumnScheduleActivitiesForPatientAtMoment(
        column,
        columnScheduleSlug,
        patient,
        nowMoment
    )

    let matchColumn = _.clone(column)
    matchColumn.scheduleSlug = columnScheduleSlug

    // Get all patient clinical milestones (results) that we think are relevant for this column spec
    column.clinicalMilestones = ClinicalMilestoneService.getClinicalMilestonesMatching(
        clinicalMilestones || [],
        matchColumn
    )
    if (ListService.isLoggingVerbose) {
        Logging.log(
            `patient.clinicalMilestones scheduleSlugs matching matchColumn: ${column.clinicalMilestones.map(
                cm => cm.scheduleSlug
            )}`
        )
    }

    /**
     * 11/08/21 If column is a survey window, filter out any patient clinical milestones where the milestone schedule overlaps
     * the column schedule, but the milestone SurveyResult is actually outside the column schedule.
     * These may have initially been included because the window was larger, for example:
     * - Column spec is 6m-1y-post-op
     * - Patient had a survey scheduled 6w-1y-post-op, so clinical milestone overlaps
     * - Patient result is actually 7w post-op, so should not qualify
     */
    const oldLength = column.clinicalMilestones.length
    if (matchColumn.type == ListColumn.Type.clinicalMilestone) {
        column.clinicalMilestones = ClinicalMilestoneService.filterPatientClinicalMilestonesOutsideScheduleWindow(
            patient,
            column.clinicalMilestones,
            columnScheduleSlug
        )
        const newLength = column.clinicalMilestones.length
        if (ListService.isLoggingVerbose && newLength != oldLength) {
            Logging.log(
                `Amended patient.clinicalMilestones scheduleSlugs matching matchColumn: ${column.clinicalMilestones.map(
                    cm => cm.scheduleSlug
                )}`
            )
        }
    }

    // Handle case where we are considering a single question
    if (column.stepSlug) {
        ListService._evaluateColumnForStepSlug(column)

        return
    }

    let numeralFormat = column.numeralFormat
    let surveyResult
    if (column.clinicalMilestones.length > 0) {
        const clinicalMilestone = column.clinicalMilestones[0]
        surveyResult = clinicalMilestone.surveyResult
        if (surveyResult && surveyResult.score != undefined) {
            if (ListService.isLoggingVerbose) {
                Logging.log(
                    `SurveyResult at ${surveyResult.moment.format(Utils.serialisedDateFormat)}, score: ${
                        surveyResult.score
                    }`
                )
            }
            // We will display (and sort by) the score value
            column.number = surveyResult.score
            if (numeralFormat) {
                const survey = store.state.content.surveys[surveyResult.surveySlug]
                numeralFormat = numeralFormat.replace('{{survey.maxScore}}', survey.maxScore)
            }
        }
    }

    // Get basic score text
    let scoreText = undefined
    if (column.number != undefined) {
        if (numeralFormat) {
            const numeralParts = numeralFormat.split('/')
            numeralParts[0] = numeral(column.number).format(numeralParts[0])
            scoreText = numeralParts.join('/').replace('/', ' / ')
        } else {
            scoreText = column.number.toString()
        }
    }

    // Display patient current schedule slug?
    let columnSchedule
    if (column.scheduleSlug == 'current') {
        columnSchedule = Schedule.get(columnScheduleSlug)
        if (columnSchedule == undefined) {
            Logging.error(`Could not get schedule from slug: ${columnScheduleSlug}`)
            // For debugging:
            // schedule = Schedule.get(matchColumn.scheduleSlug)
        } else {
            const subtitle =
                Locale.getLanguageItemOrUndefined(columnSchedule.slug) ||
                Locale.getLanguageItem(columnSchedule.getStartOffsetSlug())
            column.cellSubtitle = subtitle
        }
    }

    // Calculate status for icon
    column.status = ClinicalMilestoneService.getStatusFromClinicalMilestones(
        column.clinicalMilestones,
        !!column.requireAllComplete
    )
    // Hover text if multiple relevant columnActivities
    let numAvailable = 0,
        numComplete = 0
    if (columnActivities.length > 0 && !!column.requireAllComplete) {
        const columnActivitiesWithMatchingCompleteResult = columnActivities.filter(activity => {
            const completeResults = clinicalMilestones.filter(
                cm =>
                    cm.contentSlug == activity.contentSlug &&
                    cm.scheduleSlug == activity.scheduleSlug &&
                    cm.status == ClinicalMilestone.Status.complete
            )

            return completeResults.length > 0
        })
        numAvailable = columnActivities.length
        numComplete = columnActivitiesWithMatchingCompleteResult.length
    }
    column.cellHoverText = ClinicalMilestoneService.getStatusHoverText(
        column.status,
        numComplete,
        numAvailable,
        column.scheduleSlug == 'current'
    )

    if (column.qualifier && column.qualifier.startsWith('latestScore')) {
        if (column.qualifier == 'latestScoreAndChange' && column.clinicalMilestones.length > 0) {
            // Display most recent result, and change
            const surveyResultPrev = column.clinicalMilestones[0].surveyResultPrev
            if (surveyResult && surveyResultPrev) {
                const scoreDiff = surveyResult.score - surveyResultPrev.score
                let diffSymbol = '-'
                if (scoreDiff > 0) {
                    diffSymbol = '\u2191' // up arrow
                } else if (scoreDiff < 0) {
                    diffSymbol = '\u2193' // down arrow
                }
                column.cellText = `${scoreText} ${diffSymbol}`
            } else if (surveyResult) {
                column.cellText = scoreText
            }
        } else if (
            column.qualifier == 'latestScoreAndAge' &&
            column.clinicalMilestones.length > 0 &&
            column.clinicalMilestones[0].moment &&
            nowMoment >= column.clinicalMilestones[0].moment // ignore future results, which can happen with mock data
        ) {
            // Display most recent result, and age of result
            const ageText = Utils.getMomentDiffSummary(nowMoment, column.clinicalMilestones[0].moment)
            column.cellText = scoreText
            column.cellSubtitle = `(${ageText})`
        } else if (column.clinicalMilestones.length > 0) {
            column.cellText = scoreText
        }
    } else {
        if (column.hasExplicitValue) {
            // Explicit configuration, based upon some value (e.g. ScoreSection value), and a series of mappings
            column.cellText = ''
            column.subtitleText = ''
            column.cellHoverText = ''
            let explicitValue
            if (column.clinicalMilestones.length > 0) {
                const surveyResult = column.clinicalMilestones[0].surveyResult
                if (surveyResult) {
                    if (column.valueScoreSection) {
                        const scoreSection = surveyResult.scoreMap[column.valueScoreSection]
                        if (scoreSection) {
                            if (column.valueScoreSectionDisplayValue) {
                                explicitValue = scoreSection.displayValue
                                column.cellText = explicitValue
                            } else {
                                explicitValue = scoreSection.value.toString()
                            }
                        }
                    }
                }
                // 23/11/22: We now call this even if there was no SurveyResult, and explicitValue can be undefined
                let startMoment, endMoment
                if (column.scheduleSlug) {
                    const result = PatientService.getPatientScheduleStartEndMoments(patient, column.scheduleSlug)
                    startMoment = result[0]
                    endMoment = result[1]
                }
                column.setExplicitValueConfig(explicitValue, {
                    surveyResult: surveyResult,
                    startMoment: startMoment,
                    endMoment: endMoment
                })
            }
        }
        // column.status = ClinicalMilestoneService.getStatusFromClinicalMilestones(
        //     column.clinicalMilestones
        // )
        // Calculate status for icon
        else if (column.status == ClinicalMilestone.Status.complete && numeralFormat) {
            // Display as pure text
            column.cellText = scoreText
        } else if (
            column.status == ClinicalMilestone.Status.pending &&
            column.contentTag == Survey.dashSurveyTag &&
            columnSchedule
        ) {
            // Column is 'dash-survey', patient has a matching active schedule, and pending result
            column.status = ClinicalMilestone.Status.pendingCompletable
            const dashSurveyActivities = columnActivities.filter(
                activity => activity.scheduleSlug == columnScheduleSlug
            )
            if (dashSurveyActivities.length > 0) {
                column.activitySlug = dashSurveyActivities[0].slug
            }
        }
    }
}

/**
 * PatientJourneyList function supports:
 * - Displaying score of most recent result, from latest{surveySlugPrefix}Value
 * - Displaying age of recent result, from latest{surveySlugPrefix}Date
 */
export function _resolvePatientJourneyListColumnOfType_clinicalMilestone(column, patientJourney, row) {
    let reportProperty
    let surveySlug
    const valueScoreSection = column.valueScoreSection || 'Score'

    if (column.contentTag) {
        const surveySlugs = ContentService.getSurveySlugsWithTag(column.contentTag)
        surveySlug = surveySlugs.length > 0 ? surveySlugs[0] : undefined
    } else if (column.contentSlug) {
        surveySlug = column.contentSlug
    }
    if (surveySlug) {
        reportProperty = StringHelper.capitalize(
            StringHelper.slugToCamelCase(surveySlug.slice(0, surveySlug.length - 5))
        )
        reportProperty = `${reportProperty}${valueScoreSection}`
    }

    if (column.reportProperty) {
        reportProperty = `${StringHelper.capitalize(
            StringHelper.slugToCamelCase(column.reportProperty)
        )}${valueScoreSection}`
    }

    const latestValue = row[`latest${reportProperty}Value`]
    let scoreText

    if (latestValue != undefined) {
        /**
         * If filter options are not provided in the owner config but filter is set to true, dash displays default
         * ClinicalMilestone.Status options. In this case column should display icon instead of string text
         */
        if (!column.valueFilterOptions && column.filter) {
            column.status = latestValue
        } else {
            column.number = latestValue

            const maxScore = row[`latest${reportProperty}Max`]
            let numeralFormat = column.numeralFormat

            if (numeralFormat && maxScore) {
                numeralFormat = numeralFormat.replace('{{survey.maxScore}}', maxScore)

                const numeralParts = numeralFormat.split('/')
                numeralParts[0] = numeral(column.number).format(numeralParts[0])
                scoreText = numeralParts.join(' / ')
            } else {
                scoreText = column.number.toString()
            }

            column.cellText = scoreText

            column.cellTextCss =
                (
                    (column.valueColourMap || []).find(
                        item => column.number >= item.minScore && (!item.maxScore || item.maxScore >= column.number)
                    ) || {}
                ).colour || ''

            if (column.valueFilterOptions) {
                column.setExplicitValueConfig(scoreText)
            }
        }
    }

    const latestDate = row[`latest${reportProperty}Date`]
    if (latestDate && column.qualifier == 'latestScoreAndChange') {
        // Display most recent result, and change
        const prevScore = row[`penultimate${reportProperty}Value`]
        if (prevScore != undefined) {
            const scoreDiff = column.number - prevScore
            let diffSymbol = '-'
            if (scoreDiff > 0) {
                diffSymbol = '\u2191' // up arrow
            } else if (scoreDiff < 0) {
                diffSymbol = '\u2193' // down arrow
            }
            column.cellText = `${scoreText} ${diffSymbol}`
        }
    } else if (latestDate && column.qualifier == 'latestScoreAndAge') {
        const latestMoment = new moment(latestDate)
        const nowMoment = new moment()
        // Ignore future results, which can happen with mock data
        if (nowMoment >= latestMoment) {
            // Display most recent result, and age of result
            const ageText = Utils.getMomentDiffSummary(nowMoment, latestMoment)
            column.cellSubtitle = `(${ageText})`
        }
    } else if (latestDate && column.qualifier == 'latestScoreAndDate') {
        column.cellSubtitle = moment(latestDate).format(Utils.readableDateFormat)
    }
}

export function _resolvePatientJourneyListColumnOfType_surveyResult(column, patientJourney, row) {
    const latestValue = row[column.id]?.value
    let scoreText
    if (latestValue != undefined) {
        /**
         * If filter options are not provided in the owner config but filter is set to true, dash displays default
         * ClinicalMilestone.Status options. In this case column should display icon instead of string text
         */
        if (!column.valueFilterOptions && column.filter) {
            column.status = latestValue
        } else {
            column.number = latestValue

            const maxScore = row[column.id].max
            let numeralFormat = column.numeralFormat

            if (numeralFormat && maxScore) {
                numeralFormat = numeralFormat.replace('{{survey.maxScore}}', maxScore)

                const numeralParts = numeralFormat.split('/')
                numeralParts[0] = numeral(column.number).format(numeralParts[0])
                scoreText = numeralParts.join(' / ')
            } else {
                scoreText = column.number.toString()
            }

            column.cellText = scoreText

            column.cellTextCss =
                (
                    (column.valueColourMap || []).find(
                        item => column.number >= item.minScore && (!item.maxScore || item.maxScore >= column.number)
                    ) || {}
                ).colour || ''

            if (column.valueFilterOptions) {
                column.setExplicitValueConfig(scoreText)
            }
        }
    }

    const latestDate = row[column.id]?.date

    if (latestDate && column.qualifier) {
        switch (column.qualifier) {
            case 'latestScoreAndChange': {
                const scoreDiff = row[column.id]?.delta

                if (scoreDiff != undefined) {
                    let diffSymbol = '-'
                    if (scoreDiff > 0) {
                        diffSymbol = '\u2191' // up arrow
                    } else if (scoreDiff < 0) {
                        diffSymbol = '\u2193' // down arrow
                    }
                    column.cellText = `${scoreText} ${diffSymbol}`
                }

                break
            }

            case 'latestScoreAndAge': {
                const latestMoment = moment(latestDate)
                const nowMoment = moment()

                if (nowMoment >= latestMoment) {
                    const ageText = Utils.getMomentDiffSummary(nowMoment, latestMoment)
                    column.cellSubtitle = `(${ageText})`
                }

                break
            }

            case 'latestScoreAndDate': {
                column.cellSubtitle = moment(latestDate).format(Utils.readableDateFormat)

                break
            }
        }
    }
}

// eslint-disable-next-line no-unused-vars
export function _resolvePatientJourneyListColumnOfType_surveyStatus(column, patientJourney, row, errors) {
    column.status = row[column.id]?.status

    if (row[column.id].date) {
        column.cellSubtitle = moment(row[column.id].date).format(Utils.readableDateFormat)
    }
}
