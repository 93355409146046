import * as Sentry from '@sentry/vue'
import _ from 'lodash'
import Analytics from '@serv/Analytics'
import axios from 'axios'
import ConfigManager from '@config/ConfigManager'
import Locale from '@serv/Locale'
import Redirect from '@serv/Redirect'
import Request from '@serv/Request'
import Storage from '@serv/Storage'
import store from '@/store'

import StringHelper from '@serv/StringHelper'
import User from '@model/User'
import UserFactory from '@serv/UserFactory'

/**
 * Services related to the currently logged in user.
 * All methods are async since they rely on `getActiveUser()`
 */
class Whoami {
    constructor() {
        this._activeUser = null
        this.isRequesting = false
        this.userObject = undefined
    }

    // Return Promise to get milestones resources
    getMilestonesPromise() {
        return Request.get(Request.Stem.milestones)
            .then(response => {
                store.commit('setMilestones', response.data)
            })
            .catch(error => {
                return Promise.reject(`Error getting milestones: ${error}`)
            })
    }
    // Return Promise to get User object
    getUserPromise() {
        return Request.get(Request.Stem.users)
            .then(response => {
                // Sets Owner before owner reliant fields on User inherited models are called
                this.userObject = response.data
                store.commit('setOwnerFromUserObject', this.userObject)
            })
            .catch(error => {
                return Promise.reject(`Error getting user: ${error}`)
            })
    }

    /**
     * Get the current active user from the server.
     * Returns a Promise - calling code should always handle both 'then' and 'catch'.
     */
    getActiveUser() {
        const storageSession = Storage.get('mr_session')
        if (!this._activeUser) {
            if (storageSession && storageSession.configURL) {
                // We are spoofing
                axios.defaults.baseURL = storageSession.configURL
            }

            const currentRoute = Redirect.router.currentRoute.value

            // If not using an unauthenticated token, do nothing if no mr_session in local storage.
            const fullPath = (currentRoute || {}).fullPath || ''
            const isLocalAuth = fullPath.includes('oken=') && fullPath.includes('region=')
            if (!isLocalAuth && !storageSession && !ConfigManager.isMockingServer) {
                return Promise.reject('No active user.')
            }

            // Return a promise based on network request
            this.isRequesting = true
            const promises = [this.getMilestonesPromise(), this.getUserPromise()]

            return Promise.all(promises)
                .then(() => {
                    if (!this.userObject) {
                        return Promise.reject(`Error: this.userObject undefined`)
                    }
                    // Create User superclass object, e.g. Clinician, ProducerExec etc.
                    const persona = UserFactory.constructObjectFromJson(this.userObject)

                    // Store in Vuex (this is the only place this happens)
                    store.commit('setUser', persona)

                    // Always set user ID on 3rd party services immediately
                    Analytics.setUser(persona)
                    Sentry.setUser({ id: persona.userId })

                    this._activeUser = persona
                    this.isRequesting = false

                    return persona
                })
                .catch(error => {
                    this.isRequesting = false

                    return Promise.reject(`Error: ${error}`)
                })
        }

        // We have a stored user
        return Promise.resolve(this._activeUser)
    }
    /**
     * Get navigation links.
     */
    getNavigation(client) {
        // Initial list, will be modified by user persona/role below...
        let navigationLinks = {
            admin: {
                Navigation: [
                    { name: 'AdminUsers', title: 'sidebarAdminUsers' },
                    { name: 'AdminContent', title: 'sidebarAdminContent' }
                    // { name: 'Developer', title: 'sidebarAdminDeveloper' }
                ],
                HomePage: 'AdminUsers'
            },
            clinician: {
                Navigation: [
                    { name: 'PatientList', title: 'sidebarPatientList' },
                    { name: 'Dashboard', title: 'sidebarPracticeOverview' },
                    { name: 'ExerciseTemplates', title: 'sidebarExerciseTemplates' },
                    { name: 'Team', title: 'sidebarTeam' },
                    { name: 'Scorecard', title: 'sidebarScorecard' },
                    { name: 'Settings', title: 'sidebarSettings' },
                    { name: 'Support', title: 'sidebarSupport' }
                ],
                HomePage: 'PatientList'
            },
            patient: {
                Navigation: [],
                HomePage: 'Dashboard'
            },
            producerExec: {
                Navigation: [
                    { name: 'Dashboard', title: 'sidebarDashboard' },
                    { name: 'ClinicianList', title: 'sidebarSurgeonList' },
                    { name: 'Settings', title: 'sidebarSettings' },
                    { name: 'Support', title: 'sidebarSupport' }
                ],
                HomePage: 'Dashboard'
            },
            providerExec: {
                Navigation: [
                    { name: 'Dashboard', title: 'sidebarDashboard' },
                    { name: 'DepartmentList', title: 'sidebarDepartmentList' },
                    { name: 'ClinicianList', title: 'sidebarSurgeonList' },
                    { name: 'PatientList', title: 'sidebarPatientList' },
                    { name: 'Settings', title: 'sidebarSettings' },
                    { name: 'Support', title: 'sidebarSupport' }
                ],
                HomePage: 'Dashboard'
            }
        }

        // Modify by user persona/role
        return this.getActiveUser().then(user => {
            if (user.owner?.keyValues?.dash?.sidebar?.entries) {
                const links = []

                user.owner.keyValues.dash.sidebar.entries.forEach(item => {
                    if (item == 'scorecard' && !user.keyValues?.scorecardReport) {
                        return
                    }

                    item = StringHelper.capitalize(item)
                    links.push({ name: item, title: Locale.getStringIdForModelEnum('sidebar', item) })
                })
                const home = navigationLinks[user.persona]?.HomePage
                const customLinks = {
                    Navigation: links,
                    HomePage: home || links[0].name
                }
                if (user.owner.keyValues.featureFlags.hasOwnProperty('hasPatientJourneyList')) {
                    if (customLinks.HomePage == 'PatientList') {
                        customLinks.HomePage = 'PatientJourneyList'
                    }
                    customLinks.Navigation.forEach(routeLabel => {
                        if (routeLabel.name == 'PatientList') {
                            routeLabel.name = 'PatientJourneyList'
                        }
                    })
                }

                return customLinks
            }

            if (!user.persona) {
                throw new Error('No persona found for this user')
            }
            if (client == 'corin' && (user.isProducerExec || user.isSurgeon)) {
                Object.keys(navigationLinks).forEach(persona => {
                    let arrayOfLinks = navigationLinks[persona].Navigation
                    Object.values(
                        arrayOfLinks.map(link => {
                            if (link.name == 'Settings') {
                                let index = arrayOfLinks.indexOf(link)
                                arrayOfLinks.splice(index, 1)
                            }
                        })
                    )
                })
            }
            // Adjust for user capabilities
            if (!user.keyValues?.scorecardReport) {
                navigationLinks.clinician.Navigation = this.filterOutNavigation(
                    navigationLinks,
                    'Scorecard',
                    'clinician'
                )
            }
            if (
                user.isClinician &&
                !user.has(User.Capability.canViewPatientData) &&
                !(user.owner.keyValues.dash.overviewPage?.components?.length <= 1)
            ) {
                navigationLinks.clinician.Navigation = this.filterOutNavigation(
                    navigationLinks,
                    'Dashboard',
                    'clinician'
                )
            }
            if (user.isProviderExec && !user.isOutcomesExec) {
                navigationLinks.providerExec.Navigation = this.filterOutNavigation(
                    navigationLinks,
                    'PatientList',
                    'providerExec'
                )
            }
            if (user.isClinician && !user.has(User.Capability.canEditExerciseTemplates)) {
                navigationLinks.clinician.Navigation = navigationLinks.clinician.Navigation.filter(
                    row => row.name != 'ExerciseTemplates'
                )
            }
            // Post-process
            Object.values(navigationLinks).forEach(personaObject => {
                if (user.owner?.keyValues?.featureFlags?.hasOwnProperty('hasPatientJourneyList')) {
                    if (personaObject.HomePage == 'PatientList') {
                        personaObject.HomePage = 'PatientJourneyList'
                    }
                    personaObject.Navigation.forEach(routeLabel => {
                        if (routeLabel.name == 'PatientList') {
                            routeLabel.name = 'PatientJourneyList'
                        }
                    })
                }
            })

            return navigationLinks[user.persona]
        })
    }

    filterOutNavigation(navigationLinks, routeNameString, type) {
        return navigationLinks[type].Navigation.filter(row => row.name != routeNameString)
    }

    /**
     * Get per route permissions.
     * Use a blacklist for disallowed routes.
     */
    getPermissions(routes) {
        const checkIfWhitelisted = (route, user) => {
            if (route.meta && route.meta.requiresAuth) {
                const whitelisted = route.meta.whitelistUsers
                if (
                    _.isArray(whitelisted) &&
                    !_.isEmpty(whitelisted) &&
                    !(whitelisted.includes(user.persona) || whitelisted.includes(user.role))
                ) {
                    return route
                }
            }
        }

        return this.getActiveUser().then(user => {
            let resultRoutes = []

            routes.forEach(route => {
                if (route.children) {
                    route.children.forEach(childRoute => {
                        resultRoutes.push(checkIfWhitelisted(childRoute, user))
                    })
                } else {
                    resultRoutes.push(checkIfWhitelisted(route, user))
                }
            })

            return _.compact(resultRoutes).map(singleRoute => singleRoute.name)
        })
    }

    /**
     * Invalidate the cached user account details.
     */
    invalidate() {
        this._activeUser = null
        this.userObject = null
    }
}

export default new Whoami()
