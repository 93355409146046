import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import store from '@/store'

function resolveColumnFromNumDistinctDays(column, numDistinctDays) {
    if (numDistinctDays >= 16) {
        column.cellText = Locale.getLanguageItem('patientListSufficientData')
    } else {
        column.cellText = Locale.getLanguageItem('patientListRtmInsufficientDataDays', [numDistinctDays])
    }
}
export function _resolveObjectListColumnOfType_rtmDataReview(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.rtmDataReview can only be used on objects of type: Patient`)

        return
    }
    if (row.isRegistered) {
        const report = store.state.resources.reports && store.state.resources.reports['rtm-eligible-distinct-days']
        const rtmDischargeMilestone = row.firstJourney.getMilestoneOfSlug('rtm-discharge')

        if (rtmDischargeMilestone) {
            column.cellText = Locale.getLanguageItem('patientListRtmDischarge')
        } else if (report) {
            const numDistinctDayObj = report.dataset.filter(data => data.patientId == row.patientId)[0]
            if (numDistinctDayObj && numDistinctDayObj.numDistinctDays) {
                resolveColumnFromNumDistinctDays(column, numDistinctDayObj.numDistinctDays)
            }
        }
    }
}
export function _resolvePatientJourneyListColumnOfType_rtmDataReview(column, patientJourney, row) {
    // if (row.registrationDate) {
    if (row.rtmDischargeDate) {
        column.cellText = Locale.getLanguageItem('patientListRtmDischarge')
    } else if (row.rtmNumDistinctDays) {
        resolveColumnFromNumDistinctDays(column, row.rtmNumDistinctDays)
    }
    // }
}
