<template>
    <form @submit.prevent="validate()">
        <slot
            :errors="errors"
            :meta="meta"
            :values="values"
        />
    </form>
</template>

<script setup>
import { useForm } from 'vee-validate'
import { getCurrentInstance, inject } from 'vue'

const registerForm = inject('registerForm')
const { errors, meta, resetForm, setErrors, values, validate } = useForm()

// pass the data we need upwards
registerForm({
    uid: getCurrentInstance().parent.uid,
    errors,
    meta,
    resetForm,
    setErrors,
    values,
    validate
})

defineExpose({
    errors,
    resetForm,
    setErrors,
    validate
})
</script>
