<template>
    <div :class="[isFullWidthBackground ? 'mr-login' : 'ui grid']">
        <LoginBackground :full-width-bg="isFullWidthBackground" />

        <!-- The form -->
        <PreLogin
            v-if="preStepActive"
            @go-to-next-step="goToNextStep"
        />

        <div
            v-else
            :class="[
                isFullWidthBackground
                    ? 'mr-login__card mr-login__card--sm'
                    : 'five wide column loginback'
            ]"
        >
            <Form
                ref="loginForm"
                v-slot="{ meta }"
                id="login-form"
                :class="[
                    isFullWidthBackground
                        ? 'mr-login__body mr-login__form mr-form'
                        : 'ui form'
                ]"
                tag="form"
                :autocomplete="['production', 'qa'].includes($env) ? 'off' : 'on'"
                @submit="validateAndLogin(login)"
            >
                <img
                    v-if="$config.client === 'stry_aus'"
                    :src="getImageUrl('client-login-form-logo.png')"
                    class="login-form-logo"
                />
                <h2
                    :class="[
                        isFullWidthBackground ? 'mr-login__title' : 'title font-medium'
                    ]"
                >
                    {{ getLangItem('login') }}
                </h2>
                <p
                    :class="[
                        isFullWidthBackground ? 'mr-login__text' : 'form-description'
                    ]"
                >
                    {{ getLangItem('loginIntro') }}
                </p>
                <!-- Email -->
                <validated-input
                    name="email"
                    :label="getLangItem('formEmail')"
                    :placeholder="getLangItem('formEmailPlaceholder')"
                    rules="required|email"
                    type="text"
                    :field-wrapper-class="{ 'mr-form__field': isFullWidthBackground }"
                    data-cy="login-email"
                    v-model="login.email"
                />
                <!-- Password -->
                <validated-input
                    name="password"
                    :label="getLangItem('formPassword')"
                    :placeholder="getLangItem('formPasswordPlaceholder')"
                    rules="required|no_spaces"
                    type="password"
                    :field-wrapper-class="{ 'mr-form__field': isFullWidthBackground }"
                    data-cy="login-password"
                    v-model="login.password"
                />
                <!-- Forgot password -->
                <div class="field link-field">
                    <router-link
                        :to="{ name: 'ResetPassword' }"
                        :class="[
                            isFullWidthBackground ? 'mr-link' : 'ui notregistered'
                        ]"
                    >
                        {{ getLangItem('loginForgotPassword') }}
                    </router-link>
                </div>

                <div class="mr-login__btn-group mr-login__btn-group--reverse">
                    <!-- Login button -->
                    <button
                        id="login"
                        data-cy="login-btn"
                        :class="[
                            isFullWidthBackground
                                ? 'mr-btn mr-btn--primary'
                                : 'ui button primary right floated'
                        ]"
                        :disabled="!meta.valid || isRequesting"
                    >
                        {{ getLangItem('login') }}
                    </button>
                    <!-- Register button -->
                    <router-link
                        :to="{ name: 'Register' }"
                        :class="[
                            isFullWidthBackground
                                ? 'mr-btn mr-btn--secondary'
                                : 'ui button right floated register-button'
                        ]"
                        data-cy="register-btn"
                    >
                        {{ getLangItem('registerTitle') }}
                    </router-link>
                </div>
                <p class="versiontag">
                    {{ version }}
                </p>
            </Form>
            <div
                v-if="$config.loginFooter"
                class="login-footer"
            >
                <p>{{ $config.loginFooter }}</p>
            </div>
        </div>
        <NotifyMsg />
        <component
            v-if="!!popupClass"
            :is="popupClass"
            :config="popupConfig"
        />
    </div>
</template>

<script>
import _ from 'lodash'
import Auth from '@serv/Auth'
import Config from '@serv/Config'
import ConfigOptions from '@config/ConfigOptions'
import { Form } from 'vee-validate'
import LegacyBrowserMixin from '@mixins/LegacyBrowserMixin'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import LoginBackground from '@comp/LoginBackground.vue'
import LoginService from '@serv/LoginService'
import NotifyMsg from '@comp/NotifyMsg.vue'
import NotifyService from '@serv/NotifyService'
import PreLogin from '@comp/PreLogin.vue'
import Storage from '@serv/Storage'
import useImage from '@composables/useImage'
import ValidationMixin from '@mixins/ValidationMixin'
import { checkUser, redirect } from '@mixins/LoginMixin'

/**
 * Login into the PRM.
 * Do the Auth, connect to websockets, set a session expiry timeout.
 */
export default {
    name: 'MrLogin',
    setup() {
        const { getImageUrl } = useImage()

        return {
            getImageUrl
        }
    },
    inject: ['$bus', '$config', '$env'],
    mixins: [LegacyBrowserMixin, ValidationMixin],
    components: {
        Form,
        LoginBackground,
        NotifyMsg,
        PreLogin
    },
    data() {
        return {
            preStepActive: false,
            login: {},
            isRequesting: false
        }
    },
    computed: {
        version() {
            return Config.version || '??'
        },
        hasPreLogin() {
            return this.$config.preLogin
        },
        isFullWidthBackground() {
            return this.$config.branding.fullWidthBackground
        }
    },
    methods: {
        goToNextStep() {
            this.preStepActive = false
        },
        validateAndLogin(loginData) {
            this.isRequesting = true

            return this.$refs.loginForm.validate().then(success => {
                if (success) {
                    return this.doLogin(loginData)
                }
                this.isRequesting = false
            })
        },
        async doLogin(loginData) {
            try {
                await LoginService.logout({ ignoreNotification: true })
            } catch (error) {
                Logging.warn(error)
            }
            const token = loginData.password

            if (token.startsWith('spoof-')) {
                this.doSpoof(token, loginData)
            } else {
                return Auth.doAuth(
                    loginData.email.trim().toLowerCase(),
                    loginData.password
                ).then(
                    res => {
                        this.$bus.$emit('login')
                        checkUser({ redirectPath: this.$route.query.redirect })

                        return res
                    },
                    errorCollection => {
                        this.isRequesting = false
                        const contains403 = _.filter(
                            errorCollection,
                            error =>
                                error && error.response && error.response.status == 403
                        )
                        // Account locked out condition
                        if (contains403.length > 0) {
                            NotifyService.error(
                                contains403[0].response.data.detail,
                                Logging.warn(errorCollection),
                                5000
                            )
                        } else {
                            NotifyService.error(
                                Locale.getLanguageItem('accountLogInFailure'),
                                Logging.warn(errorCollection)
                            )
                        }
                    }
                )
            }
        },
        doSpoof(token, loginData) {
            // Email has to follow the format of : {environment}-{country}@env.com
            // Password needs to contain : spoof-{tokenInput}
            const cleanedEnvironmentInput = loginData.email.replace('@spoof.mr', '')
            const [environment, country] = cleanedEnvironmentInput.split('-')
            const configURL = ConfigOptions[environment][country].base_url
            const access_token = token.replace('spoof-', '')

            Config.setBaseUrl(configURL)
            Config.setEnv(environment)
            Config.setCountry(country)
            Config.setSpoof()
            Auth._setHeader(access_token)

            Storage.set(
                'mr_session',
                this.generateSpoofToken(access_token, configURL, environment, country),
                Storage.get('mr_session_expires') === 'never' ? 'never' : 60 * 60
            )
            Storage.set('spoof', true)
            checkUser({ spoof: true, redirectPath: this.$route.query.redirect })
            this.$bus.$emit('login')
        },
        generateSpoofToken(token, configURL, environment, country) {
            return {
                configURL: configURL,
                access_token: token,
                environment: environment,
                country: country,
                expires_in: 3153600000,
                scope: 'dash client',
                token_type: 'Bearer'
            }
        }
    },
    beforeMount() {
        if (Auth.hasSession()) {
            redirect(this.$route.query.redirect)
        }
    },
    mounted() {
        this.preStepActive = this.hasPreLogin
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors';

.ui.grid {
    background-color: $white !important;

    .five.wide.column.loginback {
        width: 375px !important;
        margin-left: 100px;
    }

    #login-form {
        padding: 195px 24px 80px 0;

        .link-field {
            text-align: right;
            margin: 44px 0 37px;

            .notregistered {
                color: $brandB;
            }
        }

        .ui.button.primary {
            margin-left: 1em;
        }

        .login-form-logo {
            height: 28px;
        }

        .title {
            color: $brandB;
            margin-bottom: -11px;
            padding-bottom: 10px;
        }

        .register-button {
            color: $brandA;

            &:hover {
                color: $white;
            }
        }
    }

    .login-footer {
        p {
            font-size: 2vh;
        }

        position: relative;
        width: 580px;
        top: 31px;
    }
}

@media (max-width: 1750px) {
    .ui.grid {
        width: 1440px;
    }
}
</style>
