import ListTransform from '@model/list/ListTransform'

class ListTransformJhubMilestoneToAt extends ListTransform {
    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        rows.sort((a, b) => {
            if (a.listColumns[columnIndex].user && b.listColumns[columnIndex].user) {
                let valueA = a.listColumns[columnIndex].user
                let valueB = b.listColumns[columnIndex].user

                return (
                    valueA.lastName.localeCompare(valueB.lastName) || valueA.firstName.localeCompare(valueB.firstName)
                )
            }
            let valueA = a.listColumns[columnIndex].cellText || ''
            let valueB = b.listColumns[columnIndex].cellText || ''

            return valueA.localeCompare(valueB)
        })
    }
}
export default ListTransformJhubMilestoneToAt
