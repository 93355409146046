import BaseContent from '@model/content/BaseContent'
import Config from '@serv/Config'
import ConfigManager from '@config/ConfigManager'

class Media extends BaseContent {
    constructor(object) {
        super(object)
        this.uuid = object.src.$blob
        this.mimeType = object.src.mimeType
    }

    get blobUrl() {
        return `cms/v2/blob/${this.uuid}`
    }
    get blobUrlFull() {
        const blobBaseUrl = ConfigManager.devConfig.blobBaseUrl || Config.base_url

        return `${blobBaseUrl}/${this.blobUrl}`
    }
}

export default Media
