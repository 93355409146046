import BaseContent from '@model/content/BaseContent'
import SliderScaleStep from '@model/content/SliderScaleStep'

class HealthScaleStep extends SliderScaleStep {
    constructor(object) {
        super({
            slug: object.slug,
            type: BaseContent.Type.healthScale,
            title: object.title,
            text: object.text,
            answerFormat: {
                sliderMinValue: 0,
                sliderMaxValue: 100,
                hideNumbers: false
            }
        })
    }
}

export default HealthScaleStep
