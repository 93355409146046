import _ from 'lodash'
import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import store from '@/store'

import User from '@model/User'

/**
 * List transform for clinicians patient is lead by or referred to.
 */
class ListTransformTeamMembers extends ListTransform {
    constructor(object) {
        super(object)
        if (object.filterSetKey == 'patientJourneyList') {
            this.filterKey = 'teamMemberIds'
        }
    }

    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    // Get a list of all team members (that can view patient data) from dash user's teams.
    get getFilterConfig() {
        let memberIds = []
        const user = store.state.user.user
        for (const team of Object.values(store.state.user.teams)) {
            if (user.personaId == team.leadId || team.memberIds.includes(user.personaId)) {
                // Belt-and-braces: only add memberIds that we can resolve to User objects
                const validMemberIds = team.memberIds.filter(personaId => {
                    const user = store.state.user.users[personaId]
                    if (!user) {
                        Logging.error(`From team ${team.id}, could not find member persona ${personaId}`)

                        return false
                    }

                    return true
                })
                memberIds = [...memberIds, ...validMemberIds]
            }
        }
        let teamMembers = _.uniq(memberIds).map(personaId => store.state.user.users[personaId])
        teamMembers = teamMembers.filter(user => {
            return user.has(User.Capability.canViewPatientData)
        })
        teamMembers = _.orderBy(teamMembers, ['lastName', 'firstName'])
        const teamMemberTitles = teamMembers.map(user =>
            this.column.hideRole ? user.titledFullName : user.titledFullNameWithRole
        )
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: teamMemberTitles.map(value => {
                return { value: value, label: value }
            }),
            addAll: true
        }

        return config
    }

    // Sort by team lead lastName, firstName
    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        const sortedBy = this.column.sortedBy
        const fakeLead = { firstName: 'Zzz', lastName: 'Zzz' }
        rows.sort((a, b) => {
            const valueA = a.listColumns[columnIndex].teamLead || fakeLead
            const valueB = b.listColumns[columnIndex].teamLead || fakeLead
            if (sortedBy == 'firstName') {
                return (
                    valueA.firstName.localeCompare(valueB.firstName) || valueA.lastName.localeCompare(valueB.lastName)
                )
            }

            return valueA.lastName.localeCompare(valueB.lastName) || valueA.firstName.localeCompare(valueB.firstName)
        })
    }

    /**
     * Each filterString is a titledFullNameWithRole/titledFullName of a potential clinician.
     * Simply do a substring match against the cellText, which is assumed to be a list of relevant clinciains.
     */
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex

        return rows.filter(row =>
            filterStrings.some(filterString => {
                if (row.listColumns[columnIndex].cellText.includes(filterString)) {
                    return true
                }
                if (_.isEmpty(row.listColumns[columnIndex].cellText)) {
                    // If patient row cellText does not include any of the selected clinician names,
                    // we assume their existence in the patient list is because the dash user
                    // invited them. So match on the filter being the dash user
                    const user = store.state.user.user

                    return filterString == this.column.hideRole ? user.titledFullName : user.titledFullNameWithRole
                }

                return false
            })
        )
    }

    getQueryParamValueFromKeyStrings(keyStrings) {
        const personaIds = []
        keyStrings.forEach(userTitle => {
            const user = this.column.hideRole
                ? User.getUserByTitledFullName(userTitle)
                : User.getUserByTitledFullNameWithRole(userTitle)
            if (user) {
                personaIds.push(user.personaId)
            }
        })

        return personaIds
    }

    getSortParameter() {
        return 'lead_name'
    }
}

export default ListTransformTeamMembers
