import ListTransform from '@model/list/ListTransform'

class ListTransformRtmStatus extends ListTransform {
    constructor(object) {
        object.filterKey = 'rtmStatus'
        super(object)
    }

    get getFilterConfig() {
        return this.column.getExplicitValueFilterConfig()
    }

    getQueryParamValueFromKeyStrings(keyStrings) {
        if (this.column.valueFilterOptions) {
            const values = keyStrings.map(key => {
                const option = this.column.valueFilterOptions[key]

                return Array.isArray(option) ? option[0] : option
            })

            return { values }
        }

        return { values: keyStrings }
    }
}

export default ListTransformRtmStatus
