/**
 * Handle Route redirection.
 *
 * Since the Router is decoupled from Vue we can have this
 * service where the router API can be called directly.
 */
class Redirect {
    /**
     * Initialise with a `router` instance.
     */
    init(routerInstance) {
        this.router = routerInstance
    }
    /**
     * Go to named route with params e.g. {id: 9087, patientJourneyId: 15693}
     */
    gotoPageWithParams(name, paramsObj = {}, query = {}) {
        return this.router.push({ name, params: paramsObj, query })
    }
    /**
     * Go to named route
     */
    gotoName(name, to = undefined, query = {}) {
        return this.router.push({ name, params: { redirect: to }, query })
    }

    /**
     * Go to path route
     */
    gotoPath(path, params = {}, query = {}) {
        return this.router.push({ path, params, query })
    }

    /**
     * Go to PatientListPage or PatientJourneyListPage, according to owner config.
     */
    gotoPatientListPageForOwner(owner) {
        const name = owner.hasPatientJourneyList ? 'PatientJourneyList' : 'PatientList'
        this.gotoName(name)
    }

    /**
     * Replace current with path route
     */
    replacePath(path) {
        return this.router.replace({ path: path })
    }

    /**
     * Get params / query for the existing route
     */
    getParamsQuery(params, query = {}) {
        return this.router.push({ params, query })
    }
}

export default new Redirect()
