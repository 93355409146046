<template>
    <VueDatePicker
        ref="datePicker"
        data-cy="mr-datepicker"
        :placeholder="placeholder || currentConfig?.inputConfig?.format || currentConfig?.typing"
        :text-input="currentConfig?.inputConfig"
        :teleport="true"
        :format="currentConfig?.inputConfig?.format"
        utc="preserve"
        v-bind="{ ...currentConfig?.props }"
        :min-date="dateLow"
        position="left"
        :week-start="isUs ? 0 : 1"
        :max-date="dateHigh"
        :start-date="dateStart"
        :model-value="value"
        :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
        @text-submit="onTextSubmit"
        @text-input="onTextInput"
        @invalid-select="onInvalidSelect"
        @cleared="onChange"
        v-on="{ ...currentConfig?.actions }"
    />
</template>

<script setup>
import { Mask } from 'maska'
import moment from 'moment'
import { useStore } from 'vuex'
import Utils from '@serv/Utils'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed, nextTick, onMounted, ref } from 'vue'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
    modelValue: {
        type: [String, undefined],
        default: undefined
    },
    dateFormat: {
        type: String,
        default: 'yyyy-mm-dd',
        // 'date' is an option just to have backward compatibility. Means same as 'yyyy-mm-dd'.
        validator: (v) => ['date', 'yyyy-mm-dd', 'yyyy-mm', 'yyyy'].includes(v)
    },
    placeholder: {
        type: String,
        default: ''
    },
    rangeLow: { type: [Number, Date], default: 0 },
    rangeHigh: { type: [Number, Date], default: 0 }
})

// update model value and emit to parent
const datePicker = ref()
const value = ref()

onMounted(() => {
    if (props.modelValue) {
        value.value = props.modelValue
    }
})

const onChange = (newValue) => {
    if (newValue) {
        /*
        By default, vue-datepicker works with 'select' and 'cancel' buttons. Buttons removed to be consistent with the existing DatePicker, which is working without control buttons.
        To control model change, we are using @dateUpdate and @updateMonthYear. These events emit values earlier than overwrite the internal modelValue.
        nextTick() is to wait for the next event loop cycle and update the modelValue on the next iteration of the event loop.
         */
        nextTick().then(() => {
            datePicker.value.selectDate()
            let dateMoment = moment(newValue)
            value.value = dateMoment.format(currentConfig.value.format)

            if (dateMoment.isValid()) {
                emits('update:modelValue', value.value)
            }
        })
    } else {
        nextTick().then(() => {
            value.value = undefined

            emits('update:modelValue', value.value)
        })
    }
}

// month year DatePicker.
const monthAndYear = ref({ year: undefined, month: undefined })

const onChangeMonthYear = (value) => {
    /**
     vue-datepicker provides only a method to update AND year AND month, and it calls it when user change year by arrows.
     Skip update model when the year changes in a month picker to prevent model change and closing calendar.
     */
    if (
        props.dateFormat === 'yyyy-mm' &&
        monthAndYear.value?.year &&
        value?.year !== monthAndYear.value?.year
    ) {
        monthAndYear.value = value

        return
    }

    monthAndYear.value = value
    onChange(value)
}

// configuration map for different picker types
const store = useStore()
const user = computed(() => store.getters.user)

const locale = computed(() => {
    if (user.value && ['au', 'gb', 'us'].includes(user.value.countryIso)) {
        return `en-${user.value.countryIso.toUpperCase()}`
    }

    return navigator ? navigator.language : `en-GB`
})

const isUs = computed(() => {
    const event = new Date(Date.UTC(2021, 0, 31)) // 31 Jan 2021
    const dateFormat = event.toLocaleDateString(locale.value)

    return !dateFormat.startsWith('31')
})

const datePickerConfig = computed(() => {
    return {
        'yyyy-mm-dd': {
            mask: '##/##/####',
            format: Utils.serialisedDateFormat,
            inputConfig: { format: isUs.value ? 'MM/dd/yyyy' : 'dd/MM/yyyy', enterSubmit: true },
            props: {
                enableTimePicker: false
            },
            actions: {
                dateUpdate: onChange
            }
        },
        'yyyy-mm': {
            typing: 'MM/yyyy',
            mask: '##/####',
            format: 'yyyy-mm',
            props: {
                monthPicker: true
            },
            actions: {
                updateMonthYear: onChangeMonthYear
            }
        },
        'yyyy': {
            typing: 'yyyy',
            mask: '####',
            format: 'yyyy',
            props: {
                yearPicker: true
            },
            actions: {
                updateMonthYear: onChangeMonthYear
            }
        }
    }
})

const currentConfig = computed(() => {
    return datePickerConfig.value[props.dateFormat] || datePickerConfig.value['yyyy-mm-dd']
})

// Low High and start dates
const dateLow = computed(() => {
    if (props.rangeLow) {
        if (typeof props.rangeLow == 'number') {
            return new Date(moment()
                .subtract(props.rangeLow, 'years')
                .toISOString())
        }

        if (moment(props.rangeLow).isValid()) {
            return props.rangeLow
        }
    }

    return null
})

const dateHigh = computed(() => {
    if (props.rangeHigh) {
        if (typeof props.rangeHigh == 'number') {
            return new Date(moment()
                .subtract(props.rangeHigh, 'years')
                .toISOString()
            )
        }

        if (moment(props.rangeHigh).isValid()) {
            return props.rangeHigh
        }
    }

    return null
})

const dateStart = computed(() => {
    const now = moment()
    if (dateLow.value && dateHigh.value) {
        if (now.isBetween(dateLow.value, dateHigh.value, undefined, [])) {
            return now.toDate()
        }

        return dateHigh.value
    }

    return now.toDate()
})

// action on type date manually in input
const onTextSubmit = () => {
    onChange(value.value)
}

const onTextInput = (event) => {
    invalidDate.value = false

    if (props.dateFormat === 'yyyy' || props.dateFormat === 'yyyy-mm') {
        return
    }
    const mask = new Mask({ mask: currentConfig.value.mask })

    if (!event.target.value && value.value) {
        onChange(undefined)

        return
    }

    if (mask.completed(event.target.value)) {
        const selectedDate = moment(mask.masked(event.target.value), currentConfig.value.inputConfig.format.toUpperCase()).toDate()
        if (moment(selectedDate).isValid()) {
            const oldValue = value.value
            datePicker.value.updateInternalModelValue(selectedDate)

            // check is date fit range
            datePicker.value.selectDate()

            if (!invalidDate.value) {
                onChange(selectedDate)

                invalidDate.value = false
            } else {
                onChange(oldValue)
            }
        }
    }
}

// invalid selection
const invalidDate = ref(false)

const onInvalidSelect = () => {
    invalidDate.value = true
}
</script>
