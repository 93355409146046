import User from '@model/User'

/**
 * An internal admin user.
 */
class Admin extends User {
    constructor(object) {
        object.persona = User.Persona.admin
        super(object)
    }
}

export default Admin
