import _ from 'lodash'
import BaseContent from '@model/content/BaseContent'
import ClinicianMockService from '@serv/ClinicianMockService'
import Logging from '@serv/Logging'
import moment from 'moment'
import Schedule from '@model/Schedule'
import StepResult from '@model/StepResult'
import store from '@/store'

import StringHelper from '@serv/StringHelper'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'
import Utils from '@serv/Utils'

/**
 * Functions for managing the patient profile, e.g. helpers for Patient Page components.
 * Keep things functional to allow testing without state.
 */
class SurveyMockService {
    constructor() {
        this.resultPrmId = 1
    }

    /**
     * For testing: add mock patients with results.
     * Must be called only after all resources/reports loaded.
     */
    addMockPatientJourneySurveyStepResults(patient, patientJourney, survey, time, rangeFrac, surveyResult) {
        surveyResult.stepResults = []
        survey.steps.forEach(step => {
            let value, freeTextValue
            switch (step.type) {
                default:
                    value = ''
                    break
                case BaseContent.Type.question:
                    // If textChoice that matches any required or optional text choice, mock a free-text value
                    var choice
                    if (survey.slug.includes('netprom')) {
                        // Prefer high values
                        choice = _.sample(step.choices.slice(-4))
                    } else {
                        choice = _.sample(step.choices)
                    }
                    value = choice.value
                    if (choice.text == step.requiredTextField || choice.text == step.optionalTextField) {
                        if (value == 'date') {
                            // Readable date, 3-6 months ago
                            const dateMoment = moment().subtract(30 + Math.random() * 30, 'days')
                            freeTextValue = dateMoment.format(Utils.readableDateFormat)
                        } else if (value == 'height') {
                            freeTextValue = ((100 + Math.random() * 100) / 100).toFixed(2)
                        } else if (value == 'weight') {
                            freeTextValue = ((40 + Math.random() * 100) / 1).toFixed(0)
                        } else {
                            freeTextValue = 'Lorem ipsum...'
                        }
                    }
                    break
                case BaseContent.Type.sliderScale:
                    var range = step.sliderMaxValue - step.sliderMinValue
                    var score = step.sliderMinValue + rangeFrac * range
                    score += -range / 6 + (Math.random() * range) / 3
                    score = Math.min(Math.max(score, step.sliderMinValue), step.sliderMaxValue)
                    value = Math.floor(score).toString()
                    break
            }
            const stepResult = new StepResult({
                resultPrmId: surveyResult.resultPrmId,
                stepSlug: step.slug,
                type: step.type,
                value: value,
                freeTextValue: freeTextValue,
                endTime: time
            })
            surveyResult.stepResults.push(stepResult) // NOTE: array may be out of order
            surveyResult.stepSlugToStepResult[stepResult.stepSlug] = stepResult
        })
        // Marker that results have been loaded
        patient.surveyStepResults = [patient.surveyStepResults || [], ...surveyResult.stepResults]
    }

    /**
     * If a custom callback function exists for the specified survey slug, call it to return a mocked array of
     * ScoreSections. Else return undefined.
     */
    getScoreSectionsCallbackForSurvey(surveySlug) {
        const fnName = `_mockScoreSectionsForSurvey_${StringHelper.replaceAll(surveySlug, '-', '_')}`
        if (this[fnName]) {
            return this[fnName](this)
        }
    }

    /**
     * Get an array of  mock SurveyResult for a specific PatientJourney and Activity.
     * If the schedule is repeating, we may get many results, otherwise we get a single reult.
     */
    getMockPatientJourneyActivitySurveyResults(patient, patientJourney, activity) {
        const primaryMilestone = patientJourney.primaryMilestone
        const primaryMilestoneDate = primaryMilestone ? primaryMilestone.date : undefined

        // Get associated clinician
        const team = store.state.user.teams[patient.teamId]
        const clinician = store.state.user.users[team.leadId] || {}

        // Iterate over journey activities for patient
        const nowMoment = new moment()
        const maxDaysPost = ClinicianMockService.hasPostPromSchedulesBefore1y ? 365 : 730

        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        if (!journey) {
            Logging.error(`Could not find journey: ${patientJourney.journeySlug}`)

            return
        }

        const survey = store.state.content.surveys[activity.contentSlug]
        const surveyResults = []
        if (survey) {
            if (
                survey.isPromSurvey ||
                survey.isPremSurvey ||
                survey.isClinicalSurvey ||
                survey.isPainSurvey ||
                survey.isTrackerSurvey
            ) {
                const schedule = Schedule.get(activity.scheduleSlug)

                // NOTE: Need to check on globalJourney OR patientJourney
                const milestone =
                    patient.globalJourney.getMilestoneOfSlug(schedule.milestone) ||
                    patientJourney.getMilestoneOfSlug(schedule.milestone)

                // Ignore stuff beyond a certain point
                if (milestone && schedule.startOffset <= maxDaysPost) {
                    let minDaysOffset, maxDaysOffset
                    if (survey.isPainSurvey) {
                        // Pain: start close to 7d-post-op
                        const momentsDiff = primaryMilestoneDate
                            ? moment(primaryMilestoneDate).diff(milestone.moment, 'days')
                            : 0
                        minDaysOffset = momentsDiff - schedule.startOffset + 6
                        maxDaysOffset = momentsDiff - schedule.startOffset + 9
                    } else {
                        minDaysOffset = Math.max(schedule.startOffset, -30)
                        maxDaysOffset = Math.min(schedule.endOffset, 30)
                    }
                    let resultMoment
                    if (schedule.slug == 'post-reg') {
                        // Set 'post-reg' result to be on reg date
                        resultMoment = milestone.moment
                    } else {
                        resultMoment = milestone.moment
                            .clone()
                            .add(minDaysOffset, 'days')
                            .add(Math.floor(Math.random() * (maxDaysOffset - minDaysOffset)), 'days')
                    }
                    let score
                    const singleSection = [
                        {
                            name: 'Score',
                            minScore: survey.minScore,
                            maxScore: survey.maxScore
                        }
                    ]
                    // Multiple results if schedule is repeating
                    const numResults = schedule.isRepeating ? 10 : 1
                    let i = -1
                    while (++i < numResults) {
                        let scoreArray = this.getScoreSectionsCallbackForSurvey(survey.slug)
                        if (!scoreArray) {
                            // Iterate all ScoreSections
                            scoreArray = []
                            for (const scoreSection of survey.keyValues.scoreSections || singleSection) {
                                const scoreRange = scoreSection.maxScore - scoreSection.minScore
                                let scoreMin = scoreSection.minScore + (scoreRange * 4) / 8
                                let scoreVar = (scoreRange * 3) / 8

                                // HACK for Stryker Mako - TODO better approach!
                                if (journey.title.includes('Mako')) {
                                    scoreVar = scoreRange - scoreMin
                                }
                                if (schedule.isRepeating) {
                                    score = Math.floor(scoreMin + Math.random() * ((scoreVar * i) / numResults))
                                    // Should values decrease over time?
                                    if (survey.isPainSurvey) {
                                        score = survey.maxScore - score
                                    }
                                    scoreArray.push({
                                        section: scoreSection.name,
                                        value: score
                                    })
                                } else {
                                    // Schedule is not repeating
                                    if (survey.isPromSurvey) {
                                        // Ramp up score along timeline as schedule.startOffset approaches maxDaysOffset
                                        score = Math.floor(
                                            scoreMin +
                                                (scoreVar * schedule.startOffset * 3) / (maxDaysPost * 4) +
                                                (Math.random() * scoreVar) / 4
                                        )
                                    } else if (survey.slug.includes('netprom')) {
                                        // Higher values are better
                                        score = Math.floor(scoreMin + scoreVar - (Math.random() * scoreVar) / 4)
                                    } else {
                                        // Default - allow whole range
                                        score = Math.floor(
                                            scoreSection.minScore +
                                                Math.random() * (scoreSection.maxScore - scoreSection.minScore)
                                        )
                                    }
                                    scoreArray.push({
                                        section: scoreSection.name,
                                        value: score
                                    })
                                }
                            }
                        }
                        // Add result
                        const surveyResult = new SurveyResult({
                            patientId: patient.personaId,
                            patientJourneyId: patientJourney.patientJourneyId,
                            personaUid: clinician.personaUid,
                            journeySlug: journey.slug,
                            departmentSlug: team.departmentSlug,
                            scheduleSlug: activity.scheduleSlug,
                            surveySlug: survey.slug,
                            activitySlug: activity.slug,
                            resultPrmId: this.resultPrmId++,
                            endTime: resultMoment.format(Utils.serialisedDateTimeFormat),
                            scoreArray: scoreArray,
                            status: 'complete',
                            countryIso: 'gb',
                            primaryMilestoneDate: primaryMilestoneDate
                        })
                        const provider = store.state.resources.providers[team.providerSlug]
                        if (provider) {
                            surveyResult[provider.regionDimension] = provider.region
                        }

                        if (schedule.isRepeating) {
                            resultMoment = resultMoment.clone().add(Math.floor(20 + Math.random() * 10), 'days')
                            this.addMockPatientJourneySurveyStepResults(
                                patient,
                                patientJourney,
                                survey,
                                resultMoment.format(Utils.serialisedDateTimeFormat),
                                i / numResults,
                                surveyResult
                            )
                            if (resultMoment.isBefore(nowMoment)) {
                                surveyResults.push(surveyResult)
                            }
                        } else {
                            this.addMockPatientJourneySurveyStepResults(
                                patient,
                                patientJourney,
                                survey,
                                resultMoment.format(Utils.serialisedDateTimeFormat),
                                1,
                                surveyResult
                            )
                            // This will prevent any future results being stored.
                            if (resultMoment.isBefore(nowMoment)) {
                                surveyResults.push(surveyResult)
                            }
                        }
                    }
                }
            }
        }

        return surveyResults
    }

    // Add mock SurveyResults for the specified PatientJourney.
    addMockPatientJourneySurveyResults(patient, patientJourney) {
        // Get associated clinician
        // const team = store.state.user.teams[patient.teamId]
        // const clinician = store.state.user.users[team.leadId] || {}

        // // Iterate over journey activities for patient
        let surveyResults = []
        // const nowMoment = new moment()
        // const maxDaysPost = ClinicianMockService.hasPostPromSchedulesBefore1y
        //     ? 365
        //     : 730
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        if (!journey) {
            Logging.error(`Could not find journey: ${patientJourney.journeySlug}`)

            return
        }
        for (const activity of journey.activities) {
            if (Math.random() < 0.1) {
                // Add no result
                continue
            }
            const results = this.getMockPatientJourneyActivitySurveyResults(patient, patientJourney, activity)
            surveyResults = [...surveyResults, ...results]
        }
        // Set survey results on Patient and PatientJourney
        patientJourney.surveyResults = surveyResults
        patient.surveyResults = [...(patient.surveyResults || []), ...surveyResults]

        // Append survey results globally
        SurveyResultsService.addPatientPracticeSurveyResults(surveyResults)
    }

    //------------------------------------------------------------------------------------------------------------------
    // Survey-specific custom score section mocking
    //------------------------------------------------------------------------------------------------------------------
    _mockScoreSectionsForSurvey_grma_penn_preas_surv() {
        const rag = _.sample([1, 2, 3]) // green, amber, red
        const scoreSections = [
            {
                section: 'RAG',
                value: rag
            }
        ]
        // For 'amber', add random indications
        if (rag == 2) {
            const amberIndications = [
                'amberIndicationGtnSpray',
                'amberIndicationMyocardial',
                'amberIndicationHeartRheumatic',
                'amberIndicationPacemaker',
                'amberIndicationCardiologist',
                'amberIndicationHeartFailureBreathless',
                'amberIndicationManyPillows',
                'amberIndicationAsthmaHospital',
                'amberIndicationBreathingSteroids',
                'amberIndicationSleepApnoea',
                'amberIndicationRenalKidney',
                'amberIndicationBloodClots',
                'amberIndicationStroke',
                'amberIndicationBackThroat',
                'amberIndicationLowerTeeth'
            ]
            amberIndications.forEach(name => {
                if (Math.random() > 0.75) {
                    scoreSections.push({
                        section: name,
                        value: 1
                    })
                }
            })
        }

        return scoreSections
    }
}

export default new SurveyMockService()
