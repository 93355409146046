<template lang="pug">
extends SurveyStepTemplate.pug
block input
    <div class="text-center">
        <v-btn v-if="taskHandler.isIntroStep" variant="outlined" @click="onButton">{{ buttonText }}</v-btn>
    </div>
</template>

<script>
import Locale from '@serv/Locale'
import SurveyStepMixin from '@mixins/SurveyStepMixin'
// Custom header components
import SurveyOutroStepHeader from '@comp/websurvey/SurveyOutroStepHeader.vue'

export default {
    name: 'SurveyInfoStep',
    mixins: [SurveyStepMixin],
    components: {
        SurveyOutroStepHeader
    },
    computed: {
        headerClass() {
            return this.taskHandler.isOutroStep ? 'SurveyOutroStepHeader' : undefined
        },
        titleCss() {
            const css = this.taskHandler.isOutroStep
                ? 'websurvey__banner-title'
                : 'websurvey__step__title'

            return css
        },
        getText2() {
            if (this.taskHandler.isOutroStep) {
                if (this.taskHandler.numSkippedSteps > 0) {
                    return Locale.getLanguageItem('patientSurveyOutroFooterSkipped', [
                        this.taskHandler.numSkippedSteps,
                        Locale.getLanguageItem('patientSurveyNavPrev')
                    ])
                }

                return Locale.getLanguageItem('patientSurveyOutroFooter', [
                    Locale.getLanguageItem('patientSurveyNavSubmit'),
                    Locale.getLanguageItem('patientSurveyNavPrev')
                ])
            }

            return ''
        },
        buttonText() {
            return this.step.keyValues.buttonText || Locale.getLanguageItem('ok')
        }
    },
    methods: {
        onButton() {
            this.taskHandler.nextStep()
        }
    }
}
</script>
