import Admin from '@model/Admin'
import Clinician from '@model/Clinician'
import Patient from '@model/Patient'
import ProducerExec from '@model/ProducerExec'
import ProviderExec from '@model/ProviderExec'
import Surgeon from '@model/Surgeon'
import User from '@model/User'

const userPersonaRoleToClass = {}

// Factory for constructing User objects of derived types.
class UserFactory {
    constructor() {
        // Personas
        userPersonaRoleToClass[User.Persona.admin] = Admin
        userPersonaRoleToClass[User.Persona.clinician] = Clinician
        userPersonaRoleToClass[User.Persona.producerExec] = ProducerExec
        userPersonaRoleToClass[User.Persona.providerExec] = ProviderExec
        userPersonaRoleToClass[User.Persona.patient] = Patient
        // Roles
        userPersonaRoleToClass[User.Role.surgeon] = Surgeon
    }

    constructObjectFromJson(json) {
        const Class = userPersonaRoleToClass[json.role] || userPersonaRoleToClass[json.persona]
        if (Class == undefined) {
            throw new Error(`Unrecognised User persona: ${json.persona} and role: ${json.role}`)
        } else {
            return new Class(json)
        }
    }

    /**
     * From a serialised User/Persona object, get a single Persona enum.
     * This was created to deal with the variance in old/new responses, and /users vs /teammembers responses.
     */
    getPersonaFromJson(object) {
        let persona = object.persona
        if (persona == 'Clinician') {
            persona = User.Persona.clinician
        } else if (persona == 'ProducerExec') {
            persona = User.Persona.producerExec
        }

        return persona
    }

    parseUsersArray(objectArray, indexWithUid) {
        // Parse
        const idToObject = {}
        objectArray.forEach(json => {
            const user = this.constructObjectFromJson(json)
            if (indexWithUid) {
                idToObject[user.personaUid] = user
            } else {
                idToObject[user.personaId] = user
            }
        })

        return idToObject
    }
}

export default new UserFactory()
