import Logging from '@serv/Logging'

/*
State to manage data transforms across the dash.
*/
const state = {
    filterSets: {}, // map of unique string to FilterSet
    tableSettings: {}, // map of unique string to TableSettings
    isExercisesGridView: true, // exercises results using grid not list view
    selectedPatientJourney: undefined // if undefined, reset to Patient.firstJourney on entry to PatientPage
}

const getters = {
    filterSets: state => {
        return state.filterSets
    },
    tableSettingsMap: state => {
        return state.tableSettings
    },
    tableSettings: state => tableKey => {
        return state.tableSettings[tableKey]
    }
}

const actions = {}

const mutations = {
    addFilterSet(state, object) {
        state.filterSets[object.key] = object
    },
    setFilterStrings(state, object) {
        if (object.transform) {
            // Can specify 'transform' instead of these 2 properties directly
            object.filterSetKey = object.transform.filterSetKey
            object.filterKey = object.transform.filterKey
        }
        const filterSet = state.filterSets[object.filterSetKey]
        if (!filterSet) {
            Logging.error(`Could not find FilterSet with filterSetKey: ${object.filterSetKey}`)

            return
        }
        filterSet.filterKeyStrings[object.filterKey] = object.filterStrings || []
        filterSet.log()
    },
    clearFilterStrings(state, object) {
        if (object.transform) {
            // Can specify 'transform' instead of these 2 properties directly
            object.filterSetKey = object.transform.filterSetKey
            object.filterKey = object.transform.filterKey
        }
        const filterSet = state.filterSets[object.filterSetKey]
        filterSet.filterKeyStrings[object.filterKey] = undefined
        filterSet.log()
    },
    addTableSettings(state, object) {
        state.tableSettings[object.key] = object
    },
    onClickTableSettingsColumnIndex(state, { tableKey, sortedColumnIndex, sortedAscending }) {
        const tableSettings = state.tableSettings[tableKey]
        if (tableSettings == undefined) {
            Logging.warn(`Could not find TableSettings with key: ${tableKey}`)
        } else {
            tableSettings.sortedColumnIndex = sortedColumnIndex
            tableSettings.sortedAscending = sortedAscending
            tableSettings.updatedSorting = true
        }
    },
    // Used for PJ list
    onClickListSettingsColumnIndex(state, { tableKey, sortedColumnIndex, sortedAscending }) {
        const tableSettings = state.tableSettings[tableKey]
        if (tableSettings == undefined) {
            Logging.warn(`Could not find TableSettings with key: ${tableKey}`)
        } else {
            tableSettings.sortedColumnIndex = sortedColumnIndex
            tableSettings.sortedAscending = sortedAscending
            tableSettings.updatedSorting = true
        }
    },
    setExercisesGridView(state) {
        state.isExercisesGridView = true
    },
    setExercisesListView(state) {
        state.isExercisesGridView = false
    },
    setSelectedPatientJourney(state, patientJourney) {
        state.selectedPatientJourney = patientJourney
    },
    setTotalRows(state, { tableKey, totalRows }) {
        state.tableSettings[tableKey].setTotalRows(totalRows)
    },
    setSorting(state, { tableKey, sortedColumnIndex, sortedAscending }) {
        state.tableSettings[tableKey].sortedColumnIndex = sortedColumnIndex
        state.tableSettings[tableKey].sortedAscending = sortedAscending
    },
    resetPage(state, tableKey) {
        state.tableSettings[tableKey].resetPage(0)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
