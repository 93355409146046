import _ from 'lodash'
import Activity from '@model/Activity'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Schedule from '@model/Schedule'
import store from '@/store'

// For non-patient logins, these are the only keyValues.stringMap key substrings that should be poked into our global
// Locale.stringMap
const stringMapGlobalKeySubstrings = ['appointmentType', 'rom']

/**
 * A Journey object.
 */
class Journey {
    constructor(object) {
        this.id = object.id
        this.isLegacy = object.isLegacy
        this.keyValues = object.keyValues || {}
        this.procedureCode = object.procedureCode

        // These can come in as tuples or as a string
        this.procedureLabels = (object.procedureLabels || []).map(label =>
            typeof label == 'string' ? label : label[0]
        )

        this.providerSlugs = object.providerSlugs

        this.slug = object.slug
        this.jointSlug = object.jointSlug || object.joint
        this.isBilateral = object.isBilateral

        // TEMP to handle null title
        if (object.title) {
            this.titleRaw = object.title
        } else if (object.procedureCode) {
            this.titleRaw = `Unknown (${object.procedureCode})`
        } else {
            this.titleRaw = 'Unknown'
        }

        this.zones = object.zones != undefined ? object.zones : [object.zone]

        // Construct Activity objects
        const objects = object.activities || []
        this.activitiesMap = {}
        objects.forEach(object => {
            // If the activity scheduleSlug can't be understood - for example, the milestone abbreviation relates
            // to a content bundle milestone - then filter it out
            const schedule = Schedule.get(object.scheduleSlug)
            if (schedule != undefined) {
                const activity = new Activity(object)
                this.activitiesMap[activity.slug] = activity
            } /* else {
                Logging.warn(
                    `Could not create schedule from slug: ${object.scheduleSlug}`
                )
            }*/
        })

        // this.keyValues.patientPageTabs will be merged with any Owner.keyValues.dash.patientPageTabs and cached here
        this.patientPageTabs = undefined

        this.rtmStartSchedule = object.rtmStartSchedule
        this.rtmEndSchedule = object.rtmEndSchedule
        this.rtmReminderSchedules = object.rtmReminderSchedules || []

        this.closePreOpSchedule = Schedule.get('2w-0w-pre-op') // may be modified by postProcess()
    }

    // Get an array of activity objects.
    get activities() {
        return Object.values(this.activitiesMap)
    }

    // Get an array of activity slugs.
    get activitySlugs() {
        return Object.keys(this.activitiesMap)
    }

    /**
     * Called from resources.setJourneys(), after initial login (also from mockJourneys).
     * Currently adds "post-reg" Activity objects, paired with certain clinical survey Activities.
     *
     * Note that these "post-reg" objects may or may not apply, in the context of each patient. Therefore when getting
     * a Journey list of Activities for a patient, always use
     * PatientService.getPatientJourneyActivities(patient, patientJourney)
     */
    postProcess() {
        this.patchPreOpClinicalSurveyActivities()

        if (store.state.user.user?.isPatient) {
            this.patchNotAppSurveyActivities()
        }
    }

    /**
     * If a pre-op clinical survey, add matching post-reg Activity.
     * This is a super-lightweight version of the mobile app
     * Journey.patchPreOpClinicalSurveyActivities(), which is done at the patient level and
     * references PJMs.
     */
    patchPreOpClinicalSurveyActivities() {
        // If there are any PROM pre-op windows not strictly "pre-op", then use this as the "close pre-op window",
        // else our own one 2w-0w-pre-op
        const contentMap = store.state.content.content
        for (const act of this.activities) {
            const content = contentMap[act.contentSlug]
            const schedule = Schedule.get(act.scheduleSlug)
            if (content && content.isClinicalSurvey && act.scheduleSlug.endsWith('-pre-op') && !schedule.isRepeating) {
                this.closePreOpSchedule = schedule
                break
            }
        }
        // Change all "pre-op" to the new schedule
        this.activities.forEach(act => {
            const content = contentMap[act.contentSlug]
            if (content && content.isClinicalSurvey && act.scheduleSlug == 'pre-op') {
                act.scheduleSlug = this.closePreOpSchedule.slug
                Logging.log(`Changing Activity ${act.slug} schedule to ${act.scheduleSlug}`)
            }
        })
        /**
         * For each pre-op clinical survey, add one post-reg.
         * NOTE: When considering Journey.activities in the context of a Patient, we should call
         * PatientService.getPatientJourneyActivities() which will selectively filter out post-reg Activities depending
         * on the Patient PJMs.
         */
        // Do we want to add dynamic post-reg instances?
        if (this.keyValues.postRegSurveys === false) {
            return
        }

        const allPreOpActivities = this.activities.filter(act => {
            const content = contentMap[act.contentSlug]
            const schedule = Schedule.get(act.scheduleSlug)

            return content && content.isClinicalSurvey && schedule.isPreOp
        })
        const allPreOpPromSlugs = _.uniq(allPreOpActivities.map(act => act.contentSlug))
        const allPostRegActivities = this.activities.filter(act => {
            const content = contentMap[act.contentSlug]
            const schedule = Schedule.get(act.scheduleSlug)

            return content && content.isClinicalSurvey && schedule.isPostReg
        })
        const allPostRegPromSlugs = _.uniq(allPostRegActivities.map(act => act.contentSlug))
        for (const promSlug of allPreOpPromSlugs) {
            // Ignore if already scheduled post-reg
            if (allPostRegPromSlugs.includes(promSlug)) {
                continue
            }
            const content = contentMap[promSlug]
            if (content?.tags?.includes('no-post-reg')) {
                continue
            }
            const promActivity = allPreOpActivities.find(act => act.contentSlug == promSlug)
            const slug = `post-reg-${promSlug}`
            // Logging.log(`Adding post-reg Activity to ${this.slug} for survey: ${promSlug}`)
            this.activitiesMap[slug] = new Activity({
                slug: slug,
                contentSlug: promSlug,
                scheduleSlug: 'post-reg',
                redoType: promActivity ? promActivity.redoType : Activity.RedoType.noneAndHide,
                allowPartial: promActivity ? promActivity.allowPartial : true,
                order: promActivity ? promActivity.order : 0,
                isPostRegClinicalSurvey: true
            })
        }
    }

    patchNotAppSurveyActivities() {
        const contentMap = store.state.content.content

        this.activities.forEach(activity => {
            const content = contentMap[activity.contentSlug]

            if (content?.tags?.includes('not-app-survey')) {
                delete this.activitiesMap[activity.slug]
            }
        })
    }

    /**
     * Get journey title.
     * If legacy, add prefix.
     */
    get title() {
        return this.isLegacy ? Locale.getLanguageItem('patientJourneyLegacyTitle', [this.titleRaw]) : this.titleRaw
    }
    get titleLocalised() {
        return this.title
    }

    /**
     * Get journey title, to the right of initial : symbol.
     * If legacy, add prefix.
     */
    get titleShortened() {
        const title = this.titleRaw.search(':') < 0 ? this.titleRaw : this.titleRaw.split(':')[1].trim()

        return this.isLegacy ? Locale.getLanguageItem('patientJourneyLegacyTitle', [title]) : title
    }

    /**
     * Check is RTM period set for the journey
     */
    get hasRtm() {
        return this.rtmStartSchedule && this.rtmEndSchedule
    }

    /**
     * Return true if the journey slug matches the specified mask.
     */
    matchesSlugMask(mask) {
        return this.slug.includes(mask) || mask == '*'
    }

    // Apply certain keyValues.stringMap items to the global Locale.stringMap
    applyStringMapToLocaleForUser(user) {
        const stringMap = this.keyValues?.stringMap || {}
        Object.keys(stringMap).forEach(key => {
            if (user.isPatient || stringMapGlobalKeySubstrings.some(substring => key.includes(substring))) {
                Locale.stringMap[key] = stringMap[key]
            }
        })
    }
}

// Journey laterality.
Journey.Side = {
    both: 'both',
    left: 'left',
    right: 'right'
}

export default Journey
