import Auth0Config from '@config/Auth0Config'
import Config from '@serv/Config'
import { createAuth0 } from '@auth0/auth0-vue'
import Logging from '@serv/Logging'
import { StorageCache } from '@serv/StorageCache'

const params = new URLSearchParams(window.location.search)
const region = params?.get('region')
const organization = params?.get('organization')

if (window.location.pathname.includes('auth') && !region) {
    Logging.error('Auth0: Cannot get region from URL')
}

const env = Config.environment
const auth0Config = region && Auth0Config[env] ? Auth0Config[env][region] : {}

auth0Config.redirectUri = auth0Config?.redirectUri
    ?.replace('{{baseUrl}}', window.location.origin)
    ?.replace('{{region}}', region)

if (auth0Config.redirectUri && organization) {
    auth0Config.redirectUri = `${auth0Config.redirectUri}&org=${organization}`
}

const authorizationParams = {
    redirect_uri: auth0Config.redirectUri,
    audience: auth0Config.audience,
    scope: auth0Config.scope
}

if (organization) {
    authorizationParams.organization = organization
}

export const auth0 = createAuth0({
    domain: auth0Config.domain,
    clientId: auth0Config.clientId,
    cache: new StorageCache(),
    authorizationParams: authorizationParams
})
