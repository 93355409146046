export default [
    '#E02020',
    '#20E020',
    '#2020E0',
    '#E0E020',
    '#E020E0',
    '#20E0E0',
    '#E0A0A0',
    '#A0E0A0',
    '#8080E0',
    '#808000',
    '#D2691E',
    '#DA70D6',
    '#00BFFF',
    '#DB7093',
    '#7F0000',
    '#FFA500'
]
