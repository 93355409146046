import DataTransform from '@model/data/DataTransform'
import Locale from '@serv/Locale'

/**
 * Base class object for all list transforms.
 * A list transform provides functions to sort and filter lists.
 */
class ListTransform extends DataTransform {
    constructor(object) {
        if (object.filterKey == undefined && object.column) {
            object.filterKey = object.column.columnIndex
        }
        super(object)
        this.column = object.column
        this.hideFilter = object.hideFilter
    }

    // We can only provide a filter config if explicitly configured.
    // Currently we assume this will be single-selection.
    get getFilterComponent() {
        return this.column && this.column.valueFilterOptions ? 'DataFilterString' : undefined
    }

    // We can only provide a filter config if explicitly configured.
    get getFilterConfig() {
        let config = undefined
        if (this.column && this.column.valueFilterOptions) {
            const valueLabels = this.column.valueFilterOptions.map(option => {
                const text = Locale.getLanguageItem(option)

                return { value: text, label: text }
            })
            config = {
                title: Locale.getLanguageItem(this.column.label),
                valueLabels: this.column.sortValueLabels(valueLabels),
                addAll: true
            }
        }

        return config
    }

    get goddardSortParameter() {
        return this.column.id
    }

    getGoddardFilterKey() {
        return null
    }

    /**
     * Sort the list of rows in place.
     * Default is a localeCompare of the previously-resolved cellText.
     */
    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        const minNumber = -9999
        rows.sort((a, b) => {
            const isNumber =
                a.listColumns[columnIndex].number !== undefined || b.listColumns[columnIndex].number !== undefined
            if (isNumber) {
                let valueA = a.listColumns[columnIndex].number
                valueA = valueA == undefined ? minNumber : valueA
                let valueB = b.listColumns[columnIndex].number
                valueB = valueB == undefined ? minNumber : valueB

                return valueA - valueB
            }
            const valueA = a.listColumns[columnIndex].cellText || ''
            const valueB = b.listColumns[columnIndex].cellText || ''

            return valueA.localeCompare(valueB)
        })
    }

    reverseSort() {
        return false
    }

    /**
     * Reverse the sort order of a group of rows in place.
     * In most cases, we just reverse the list - but in some cases we may always want
     * certain rows to be at the top or bottom, regardless of sort direction.
     */
    reverse(rows) {
        rows.reverse()
    }

    /**
     * Filter and return the list of rows, based on an array of input strings.
     * Default is an exact string match of the previously-resolved cellText.
     */
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex

        return rows.filter(row => filterStrings.includes(row.listColumns[columnIndex].cellText))
    }
}

export default ListTransform
