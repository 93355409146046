import _ from 'lodash'
import Activity from '@src/models/Activity'
import ActivityList from '@src/models/ActivityList'
import Logging from '@serv/Logging'
import Milestone from '@src/models/Milestone'
import NotifyService from '@serv/NotifyService'
import Request from '@serv/Request'
import ResourceService from '@serv/ResourceService'
import store from '@src/store/index'

/*
Functions for creating and managing ActivityLists
 */
class ActivityListService {
    ensureActivityListsLoaded(patientJourneys) {
        const activityListMilestones = _.uniqBy(this.getActivityListMilestones(patientJourneys), 'activityListSlug')
        const activityListsToLoad = this.getActivityListsToLoad(activityListMilestones)

        if (activityListsToLoad.length == 0) {
            return Promise.resolve()
        }

        return this.getActivityLists(activityListsToLoad)
            .then(activityLists => {
                this.setActivityListActivities(activityLists)

                return this.getActivityContent(activityListsToLoad)
            })
            .catch(error => {
                NotifyService.error(error)

                return Promise.reject(error)
            })
    }

    getActivityListsToLoad(activityListMilestones) {
        if (!activityListMilestones) {
            return []
        }

        return activityListMilestones.reduce((allActivityLists, activityListMilestone) => {
            const activityList = this.getActivityListBySlug(activityListMilestone.activityListSlug)
            if (activityList && !activityList.activitySlugs) {
                allActivityLists.push(activityList)
            }

            return allActivityLists
        }, [])
    }

    setActivityListActivities(activityListsResponse) {
        store.commit('ensureActivities')
        activityListsResponse.forEach(activityList => {
            if (!activityList.slug) {
                Logging.warn('ActivityList has no slug')

                return
            }

            const activityListActivitySlugs = []
            if (!activityList.activities || activityList.activities.length == 0) {
                Logging.warn(`ActivityList ${activityList.slug} has no activities`)

                return
            }

            activityList.activities.forEach(activity => {
                if (!activity.slug) {
                    Logging.warn(`Activity in ActivityList ${activityList.slug} has no slug`)

                    return
                }

                activityListActivitySlugs.push(activity.slug)
                if (this.getActivityBySlug(activity.slug)) {
                    return
                }

                const activityObj = new Activity(activity)
                store.commit('setActivity', activityObj)
            })
            store.state.resources.activityLists[activityList.slug].activitySlugs = activityListActivitySlugs
        })
    }

    getActivityListBySlug(activityListSlug) {
        return store.state.resources.activityLists[activityListSlug]
    }

    getActivityListMilestones(journeys) {
        if (!journeys) {
            return []
        }

        if (!Array.isArray(journeys)) {
            journeys = [journeys]
        }

        return journeys.reduce((allActivityListMilestones, journey) => {
            const activityListMilestones = journey.milestones.filter(
                milestone => milestone.type == Milestone.Type.activityList
            )

            return allActivityListMilestones.concat(activityListMilestones)
        }, [])
    }

    /*
     * Get an array of activity slugs from an array of ActivityLists.
     */
    getActivityMilestoneActivityListSlugs(activityListMilestones) {
        if (!activityListMilestones) {
            return []
        }

        if (!Array.isArray(activityListMilestones)) {
            activityListMilestones = [activityListMilestones]
        }

        activityListMilestones = _.uniqBy(activityListMilestones, 'activityListSlugs')

        return activityListMilestones.reduce((activityListMilestoneSlugs, activityListMilestone) => {
            const slugs = store.state.resources.activityLists[activityListMilestone.activityListSlug]?.activitySlugs

            return slugs ? activityListMilestoneSlugs.concat(slugs) : activityListMilestoneSlugs
        }, [])
    }

    /*
     * Fetch the content for the activities in the activityLists that have
     * not been fetched yet.
     */
    getActivityContent(activityLists) {
        const contentSlugs = this.getContentSlugsToFetch(activityLists)
        if (!contentSlugs || contentSlugs.length == 0) {
            return
        }

        return ResourceService.requestContentBySlugVersion(contentSlugs).then(contentBySlugVersion => {
            store.commit('setContent', contentBySlugVersion.data)
        })
    }

    /*
     * Return the content slugs for the activities in the activityLists
     * that have not been fetched yet.
     */
    getContentSlugsToFetch(activityLists) {
        if (!activityLists) {
            return []
        }

        if (!Array.isArray(activityLists)) {
            activityLists = [activityLists]
        }

        return activityLists.flatMap(activityList =>
            (activityList.activitySlugs || []).flatMap(activitySlug => {
                const activity = this.getActivityBySlug(activitySlug)
                if (!activity) {
                    return []
                }
                const contentSlug = activity.content ? null : activity.contentSlug

                return contentSlug ? [contentSlug] : []
            })
        )
    }

    /*
     * Query the API for the activityLists with the given slug.
     */
    getActivityLists(activityLists) {
        if (!Array.isArray(activityLists)) {
            activityLists = [activityLists]
        }

        const activityListSlugs = activityLists.map(activityList => activityList.slug)
        const url = Request.Stem.activityLists.replace('{slugs}', activityListSlugs.join(','))

        return Request.get(url).then(res => {
            if (!res.data) {
                Logging.warn(`No activity lists found for the given slugs: ${activityListSlugs.join(', ')}`)

                return Promise.resolve()
            }

            return res.data
        })
    }

    // Create a new ActivityList object and add it to the store if one doesn't already exist
    setActivityListInStore(activityListSlug) {
        store.commit('ensureActivityLists')
        const activityLists = store.state.resources.activityLists
        if (activityLists[activityListSlug]) {
            return
        }

        const activityList = new ActivityList({ slug: activityListSlug })
        store.commit('setActivityList', activityList)
    }

    getActivityBySlug(activitySlug) {
        if (!store.state.resources.activities) {
            return
        }

        return store.state.resources.activities[activitySlug]
    }
}

export default new ActivityListService()
