import Locale from '@serv/Locale'
import ReferralMilestone from '@model/ReferralMilestone'

export function _resolvePatientJourneyListColumnOfType_referrals(column, patientJourney, row) {
    const provider = row.referralProviderTitle || ''

    switch (row.referralStatus) {
        case ReferralMilestone.Status.offered:
            column.cellText = Locale.getLanguageItem('patientListReferralPendingTriage', [provider])
            break
        case ReferralMilestone.Status.accepted:
            column.cellText = Locale.getLanguageItem('patientListReferralAccepted', [provider])
            break
        default:
            column.cellText = Locale.getLanguageItem('patientListNoActiveReferrals')
    }
}
