import ActivityListService from '@serv/ActivityListService'
import Locale from '@serv/Locale'
import Milestone from '@model/Milestone'
import store from '@/store'

/**
 * An ActivityListMilestone object, which references an ActivityList.
 */
class ActivityListMilestone extends Milestone {
    constructor(object) {
        super(object)
        this.activityListSlug = object.activityListSlug

        ActivityListService.setActivityListInStore(this.activityListSlug)
    }

    get activityList() {
        return store.state.resources.activityLists[this.activityListSlug]
    }

    get title() {
        let title = Locale.getLanguageItem('keyDatesActivityListTitle', [this.activityListSlug])
        const activities = this.activityList?.activities

        if (!activities) {
            return title
        }

        for (let activity of activities) {
            if (activity.content && activity.slug.endsWith('-menu')) {
                title = activity.content.title
                break
            }
        }

        return title
    }
}

export default ActivityListMilestone
