<template>
    <nav v-if="$route.meta.requiresAuth">
        <div
            id="main-menu"
            class="mr ui menu"
        >
            <div
                v-if="isSpoofing"
                class="spoofing-warning"
            >
                <p>{{ getLangItem('spoofingWarning') }}</p>
            </div>

            <div
                id="main-menu-top-items"
                class="one wide column"
            >
                <div class="nav-link-box">
                    <User />

                    <router-link
                        v-for="(link, idx) in navLinks"
                        :key="idx"
                        class="nav-link"
                        :to="{ name: link.name }"
                        :class="{ experimental: link.experimental }"
                    >
                        <div class="nav-wrapper">
                            <img
                                :src="getNavIcon(link.name)"
                                class="nav-img"
                                :class="{
                                    'svg--brandB': isActiveRoute(link),
                                    'svg--grey': !isActiveRoute(link)
                                }"
                            />

                            <div class="nav-button">
                                {{ getLangItem(link.title) }}
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="nav-footer">
                    <div class="brands nav-item">
                        <div class="brand">
                            <a
                                :href="$config.clientUrl"
                                target="_blank"
                            >
                                <img
                                    :src="getImageUrl('client-footer.png')"
                                    alt="logo"
                                    class="logo mrfooter"
                                />
                            </a>
                        </div>
                    </div>

                    <div class="logout nav-item">
                        <router-link
                            :to="{ name: 'Logout' }"
                            class="link-logout svg--white"
                        >
                            <img :src="getIconUrl('nav-side-bar-logout.svg')" />
                            <p>{{ getLangItem('accountLogOut') }}</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import Auth from '@serv/Auth'
import Config from '@serv/Config'
import { mapGetters } from 'vuex'
import Storage from '@serv/Storage'
import useImage from '@composables/useImage'
import User from '@comp/MrUser.vue'
import Whoami from '@serv/Whoami'

export default {
    name: 'MrNavigation',
    components: {
        User
    },
    inject: ['$bus', '$config'],
    data() {
        return {
            Config: Config,
            navLinks: null,
            homeLink: null,
            colors: null,
            brandedFooter: null,
            isSpoofing: false,
            // Map of Routes.js name to sidebar image
            navMap: {
                AdminContent: 'nav-side-bar-terms.svg',
                AdminUsers: 'nav-side-bar-users.svg',
                ClinicianList: 'nav-side-bar-clinician.svg',
                Dashboard: 'nav-side-bar-home.svg',
                DepartmentList: 'nav-side-bar-department.svg',
                ExerciseTemplates: 'nav-side-bar-exercise-templates.svg',
                PatientList: 'nav-side-bar-patient.svg',
                PatientJourneyList: 'nav-side-bar-patient.svg',
                Scorecard: 'nav-side-bar-monitoring.svg',
                Settings: 'nav-side-bar-settings.svg',
                Team: 'nav-side-bar-users.svg',
                FAQ: 'nav-side-bar-question.svg',
                Support: 'nav-side-bar-message.svg',
                users: 'nav-side-bar-icon.svg'
            }
        }
    },
    setup() {
        const { getIconUrl, getImageUrl } = useImage()

        return {
            getIconUrl,
            getImageUrl
        }
    },
    computed: {
        ...mapGetters(['user']),
        brandingBorder() {
            return this.$config.branding.colors.brandA
        }
    },
    methods: {
        isActiveRoute(link) {
            return this.$route.name === link.name ? true : false
        },
        /**
         * Get the active user, permissions.
         */
        getActiveUserAndPermissions() {
            if (Auth.hasSession()) {
                Whoami.getNavigation(this.$config.client).then(perm => {
                    this.navLinks = perm.Navigation
                    this.homeLink = perm.HomePage
                })
            }
        },
        getNavIcon(name) {
            return this.getIconUrl(
                this.navMap.hasOwnProperty(name)
                    ? this.navMap[name]
                    : 'ms-tick.svg'
            )
        }
    },
    /**
     * Trigger the getActiveUserAndPermissions method on both
     * the Login event and on mount.
     *
     * Triggering on Login is important because otherwise the nav will
     * not show right after logging-in since the component has already
     * been mounted.
     */
    mounted() {
        this.$bus.$off()

        this.$bus.$on('login', this.getActiveUserAndPermissions)
        this.$bus.$on('register', this.getActiveUserAndPermissions)
        this.getActiveUserAndPermissions()
        this.isSpoofing = Storage.get('spoof') || false

        // When reloading page nothing is getting destroyed and unmounted() will not be called.
        // Defining onbeforeunload function globally to remove listeners on page reload
        window.onbeforeunload = () => {
            this.$bus.$off()
        }
    },
    unmounted() {
        this.$bus.$off()

        document.removeEventListener('login', this.getActiveUserAndPermissions)
        document.removeEventListener('register', this.getActiveUserAndPermissions)
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors';

#main-menu-top-items {
    margin-top: 50px;
}

#main-menu {
    z-index: 999;
    position: fixed;
    border-radius: 0 !important;
    width: 106px;
    box-shadow: $boxShadow;
    display: block;
    height: 100%;
    overflow: hidden;
    transition: width 0.3s;

    .router-link-active {
        .nav-wrapper {
            border-right: 3px solid $brandA;
        }
    }

    .item::before {
        background: none;
    }

    .dash {
        color: $darkGrey;
        box-shadow: $boxShadow;
    }

    .item {
        color: $darkGrey !important;
    }

    & > div.item {
        border-radius: 0 !important;
    }

    .logo-item {
        display: block;
        margin: 24px 48px 30px 28px;

        &.circle {
            background-color: $brandA;
            border-radius: 50% !important;
            width: 40px;
            height: 40px;
        }
    }

    & .dropdown.item .menu {
        margin-top: 8px;
    }

    .nav-img {
        padding: 11px 0;
        height: 62px;
        width: 42px;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .nav-wrapper {
        position: relative;
        height: 62px !important;
        width: 100%;
        transition: width 0.3s;

        &:hover {
            background-color: $white;
        }

        .nav-button {
            position: absolute;
            top: 50%;
            left: 0;
            padding-left: 60px;
            min-width: 269px;
            color: $darkGrey;
            transform: translateY(-50%) scale(1, 1);
            backface-visibility: hidden;
            text-align: left;
            opacity: 0;
            visibility: hidden;
            transition: width 0.3s, opacity 0.3s, visibility 0.3s;
        }

        @media (min-width: 1750px) {
            .nav-button {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .nav-link-box {
        display: flex;
        flex-direction: column;
        padding-left: 32px;

        .nav-link {
            height: 66px;
        }
    }
}

/* stylelint-disable-next-line no-descending-specificity */
.menu {
    border: 0 !important;
    height: 88px;

    .item.logo-item {
        margin: 20px 0 0;
        border: 0;
        padding-left: 50px;
        margin-right: 48px;
    }

    .partner {
        margin: auto;
        display: block;
        width: 40px;
        height: 40px;
    }
}

@media (max-height: 720px) {
    .nav-footer {
        visibility: hidden;
    }
}

.nav-footer {
    position: absolute;
    bottom: 0;

    .nav-item {
        padding-left: 29px;
    }

    img {
        padding-bottom: 16px;
    }

    .logout {
        background: $brandB;
        padding: 15px 15px 15px 41px;
        color: $white;
    }

    .link-logout {
        display: flex;
        align-items: center;
        color: $white;
        filter: none; // removing colouring

        img {
            height: 22px;
            width: auto;
            padding-bottom: 0;
        }

        p {
            margin-left: 29px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
        }
    }

    .brands {
        display: flex;
        margin-bottom: 5px;
        min-width: 311px;
        padding-left: 16px;

        .brand {
            transition: all 0.3s;

            &:not(:first-child) {
                opacity: 0;
                visibility: hidden;
            }

            .logo {
                max-width: 70px;
            }
        }
    }
}

.mr.ui.menu#main-menu:hover {
    width: 311px;

    .nav-wrapper {
        .nav-button {
            opacity: 1;
            visibility: visible;
        }
    }

    .brands {
        .brand {
            &:not(:first-child) {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .link-logout {
        p {
            opacity: 1;
            visibility: visible;
        }
    }
}

.experimental {
    filter: sepia(0%) hue-rotate(10deg) saturate(196%) brightness(104%);

    /* stylelint-disable-next-line no-descending-specificity */
    .nav-wrapper {
        filter: sepia(0%) hue-rotate(128deg) saturate(196%) brightness(104%);
    }
}
</style>
