import axios from 'axios'
import ConfigManager from '@config/ConfigManager'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Utils from '@serv/Utils'

/**
 * This follows the same interface as the Request library
 * but reads data from mock JSON files.
 */
class RequestMock {
    /**
     * Declare the mock map and then parse it to something more useful.
     */
    constructor(config) {
        config = config || {}
        this.mockSet = config.mockSet || ConfigManager.devConfig.mockSet
        axios.defaults.baseURL = undefined
        this.errorCallbackMap = {}
    }

    /**
     * Get custom headers to be set on each request.
     */
    getHeaders() {
        return {
            'Accept-Language': Locale.getActiveLocale()
        }
    }

    /**
     * Set callbacks for error responses
     * @param {Number}
     * @param {Function}
     */
    setErrorCallback(errorCode, callbackFn) {
        Logging.log('Setting callback for error code:', errorCode)
        this.errorCallbackMap[errorCode] = callbackFn
    }

    /**
     * Remove callback for error code
     * @param {Number}
     */
    removeErrorCallback(errorCode) {
        if (this.errorCallbackMap.hasOwnProperty(errorCode)) {
            delete this.errorCallbackMap[errorCode]
        }
    }

    /**
     * Turn params into a querystring, issue request to mock
     */
    _mockGet(requestUrl, params) {
        if (params) {
            requestUrl += `?${$.param(params)}`
        }
        let filename = Utils.urlToMockJsonFilename(requestUrl, false, this.mockSet)
        Logging.log(`Making GET for mock filename: ${filename}`)
        let response = axios.get(filename)

        return response
            .then(resp => {
                let resultsToFilter
                if (Array.isArray(resp.data.results)) {
                    resultsToFilter = resp.data.results
                } else {
                    resultsToFilter = resp // resp.data
                }

                return resultsToFilter
            })
            .catch(err => {
                Logging.warn('axios mock error code:', err.response?.status)
                throw err
            })
    }

    /**
     * Make a series of param replacements within a URL.
     * e.g. if URL contains '{personaId}' and params defines personaId, make the replacement.
     */
    replaceUrlParams(url, params) {
        Object.keys(params || {}).forEach(key => {
            url = url.replace(`{${key}}`, params[key])
        })

        return url
    }

    /**
     * Mock GET from URL.
     * IF corresponding JSON file found on filesystem, resolve and return JSON body - ELSE error.
     */
    get(url, config) {
        config = config || {}
        url = this.replaceUrlParams(url, config.params)

        return this._mockGet(url, config.params)
    }

    /**
     * Mock GET from URL.
     * Always resolve.
     */
    delete(requestUrl /*, params = null*/) {
        return Promise.resolve({ config: { url: requestUrl } })
    }

    /**
     * Turn requestUrl into a querystring, issue request to mock,
     */
    _mockUpsert(requestUrl, method) {
        let filename = Utils.urlToMockJsonFilename(requestUrl, false, this.mockSet)
        Logging.log(`Making ${method} for mock filename: ${filename}`)
        let response = axios.get(filename)

        return response
            .then(resp => {
                let resultsToFilter
                if (Array.isArray(resp.data.results)) {
                    resultsToFilter = resp.data.results
                } else {
                    resultsToFilter = resp // resp.data
                }

                return resultsToFilter
            })
            .catch(err => {
                Logging.warn('axios mock error code:', err.response?.status)
                throw err
            })
    }

    /**
     * Mock POST from URL.
     * Always resolve.
     */
    post(requestUrl /*, data = null*/) {
        return this._mockUpsert(requestUrl, 'POST')
    }

    /**
     * Mock PUT from URL.
     * Always resolve.
     */
    put(requestUrl /*, data = null*/) {
        return this._mockUpsert(requestUrl, 'PUT')
    }

    /**
     * Mock PATCH from URL.
     * Always resolve.
     */
    patch(requestUrl /*, data = null*/) {
        return this._mockUpsert(requestUrl, 'PATCH')
    }

    all(promiseArray) {
        return axios.all(promiseArray)
    }
}

export default RequestMock
