import Milestone from '@model/Milestone'
import moment from 'moment'
import store from '@/store'

import Utils from '@serv/Utils'

let singleton = undefined

/**
 * Static functions used by web survey JS interface.
 */
class JourneyManager {
    static get() {
        return singleton
    }

    // Get all milestones of the specified type
    getMilestonesOfType(type) {
        const patient = store.state.user.user
        type = type || Milestone.Type.default
        let patientJourney
        switch (type) {
            case Milestone.Type.goal:
                patientJourney = patient.globalJourney
                break
            default:
                patientJourney = patient.firstJourney
                break
        }
        const milestones = patientJourney.getMilestonesOfType(type)
        // Sort by increasing date
        milestones.sort((a, b) => a.moment - b.moment)

        return milestones
    }

    // Get all milestones of the specified slug
    getMilestonesOfSlug(slug) {
        const patient = store.state.user.user

        return patient.getMilestonesOfSlug(slug)
    }

    // Get current date string
    getCurrentDate() {
        return moment().format(Utils.serialisedDateFormat)
    }

    // Get the day offset between the specified date and the milestone date.
    dateDaysSinceMilestoneDate(dateString, milestoneSlug) {
        const dateMoment = new moment(dateString)
        const milestones = this.getMilestonesOfSlug(milestoneSlug)
        if (milestones.length == 1 && milestones[0].date) {
            return dateMoment.diff(milestones[0].date, 'days')
        }

        return 999
    }

    getKeyValueBool(key) {
        const patient = store.state.user.user

        return !!patient.firstJourney.keyValues[key]
    }

    // 12/11/22 Return the current app version when this was implemented!
    getAppVersionInt() {
        return 41700
    }
}
singleton = new JourneyManager()

export default JourneyManager
