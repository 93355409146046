// Must not import Request, will lead to circular imports.
let Request

/**
 * Mocking of request responses, which can be conditional on the context of the request.
 * For example, we can mock responses only for patients that have been mocked dynamically in the FE.
 *
 * All callbacks functions take the same inputs as the Request functions themselves, i.e.
 * - url: The Request.Stem value, i.e. WITHOUT parameter replacement
 * - payload: Any payload used for PATCH/POST/PUT (can be null)
 * - config: Any configuration, which may include context to trigger the mock response, e.g. config.patient
 *
 * The callback should return:
 * - IF we do not want to provide any mock response, then false
 * - ELSE a resolved Promise that returns the mock response
 */
class RequestMocker {
    constructor(requestService) {
        Request = requestService
        // PATCH
        this.verbUrlToResponseFn = {
            // DELETE
            [Request.Verb.delete]: {
                [Request.Stem.patientJourney]: 'DELETE_patientSuccess',
                [Request.Stem.patientJourneyMilestone]: 'DELETE_patientSuccess'
            },
            // GET
            [Request.Verb.get]: {
                [Request.Stem.patientExerciseRoutineModifierHistory]: 'GET_patientExerciseRoutineModifierHistory',
                [Request.Stem.patientJourneyMilestones]: 'GET_patientJourneyMilestone'
            },
            // PATCH
            [Request.Verb.patch]: {
                [Request.Stem.patient]: 'PATCH_patientSuccess',
                [Request.Stem.patientJourney]: 'PATCH_patientSuccess',
                [Request.Stem.patientJourneyMilestone]: 'PATCH_patientSuccess'
            },
            // POST
            [Request.Verb.post]: {
                [Request.Stem.patientJourneyMilestones]: 'POST_patientJourneyMilestones'
            },
            // PUT
            [Request.Verb.put]: {}
        }
        this.patientJourneyMilestoneId = 0
    }

    // Return and increase the patientJourneyMilestone ID
    getNewPatientJourneyMilestoneId() {
        this.patientJourneyMilestoneId++

        return this.patientJourneyMilestoneId
    }

    // Return true only if this is a request relating to a mock patient
    isRequestForMockPatient(config) {
        return config && config.patient && config.patient.isMock
    }

    // DELETE - generic success for mock patient
    DELETE_patientSuccess(url, payload, config) {
        if (!this.isRequestForMockPatient(config)) {
            return false
        }

        return Promise.resolve({ config: { url: url }, data: {}, status: 204 })
    }

    // GET exerciseRoutineModifierHistory
    GET_patientExerciseRoutineModifierHistory(url, payload, config) {
        if (!this.isRequestForMockPatient(config)) {
            return false
        }

        return Promise.resolve({ config: { url: url }, data: [], status: 200 })
    }

    // PATCH - generic success for mock patient
    PATCH_patientSuccess(url, payload, config) {
        if (!this.isRequestForMockPatient(config)) {
            return false
        }

        return Promise.resolve({ config: { url: url }, data: {}, status: 200 })
    }

    // POST a new milestone
    POST_patientJourneyMilestones(url, payload, config) {
        if (!this.isRequestForMockPatient(config)) {
            return false
        }

        return Promise.resolve({
            config: { url: url },
            data: { id: this.getNewPatientJourneyMilestoneId() },
            status: 201
        })
    }
}

export default RequestMocker
