import Milestone from '@model/Milestone'
import moment from 'moment'
import Utils from '@serv/Utils'

/*
A goal milestone object.
*/
class GoalMilestone extends Milestone {
    constructor(object) {
        object.type = Milestone.Type.goal
        object.slug = object.slug || 'goal'
        super(object)

        this.title = object.title
        this.isActive = object.isActive == undefined ? true : object.isActive

        if (this.moment) {
            this.readableDate = this.moment.format(Utils.readableDateFormat)
        }

        // Historical target dates - deduplicate and sort
        this.historicalTargetDates = [...new Set(object.historicalTargetDates || [])]
        this.historicalTargetDates.sort((a, b) => moment(a) - moment(b))
    }
}

export default GoalMilestone
