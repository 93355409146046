import User from '@model/User'

/**
 * A user that has oversight of Clinicians and their aggregated results.
 * They do not see identifiable patient data.
 */
class ProducerExec extends User {
    constructor(object) {
        object.persona = User.Persona.producerExec
        super(object)

        this.countryIsos = (object.countryIsos || []).map(iso => iso.toLowerCase())
        this.hasChat = object.hasChat
    }

    get adminListProperties() {
        let properties = `isRegistered: ${this.isRegistered}`
        if (this.phone) {
            properties += `, phone: ${this.phone}`
        }
        properties += `, countryIsos: ${this.countryIsos}`

        return properties
    }
}

export default ProducerExec
