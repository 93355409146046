import ListTransform from '@model/list/ListTransform'

class ListTransformMilestoneToClinician extends ListTransform {
    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        rows.sort((a, b) => {
            if (a.listColumns[columnIndex].user && b.listColumns[columnIndex].user) {
                const valueA = a.listColumns[columnIndex].user
                const valueB = b.listColumns[columnIndex].user

                return (
                    valueA.lastName.localeCompare(valueB.lastName) || valueA.firstName.localeCompare(valueB.firstName)
                )
            }
            const valueA = a.listColumns[columnIndex].cellText || ''
            const valueB = b.listColumns[columnIndex].cellText || ''

            return valueA.localeCompare(valueB)
        })
    }
}
export default ListTransformMilestoneToClinician
