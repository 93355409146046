import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import store from '@/store'

export function _resolveObjectListColumnOfType_provider(column, row) {
    const providers = store.state.resources.providers
    const teams = store.state.user.teams
    if (row instanceof Patient) {
        // Provider slug from PJ Team
        const teamId = row.firstJourney.teamId
        const team = teams[teamId]
        if (!team) {
            Logging.warn(`Could not find Team : ${teamId}`)
            column.cellText = '(Unknown provider)'

            return
        }
        const provider = providers[team.providerSlug]
        if (!provider) {
            Logging.warn(`Could not find Provider : ${team.providerSlug}`)
            column.cellText = '(Unknown provider)'

            return
        }
        column.cellText = provider.title
    } else {
        const property = column.property || 'providerSlugs'
        if (Array.isArray(row[property])) {
            // Provider slugs as array
            var providerTitles = row[property]
                .map(providerSlug => {
                    const provider = providers[providerSlug]
                    if (provider) {
                        return provider.title
                    }
                    Logging.warn(
                        `ListColumn.Type.provider could not find Provider: ${providerSlug}, will display nothing`
                    )

                    return ''
                })
                .filter(provider => {
                    return provider
                })

            column.cellText = providerTitles.join(', ')
        } else if (row[property]) {
            // Provider slug as explicit property
            const providerSlug = row[property]
            column.cellText = providers[providerSlug].title
        }
    }
}
export function _resolvePatientJourneyListColumnOfType_provider(column, patientJourney, row, errors, isGoddard) {
    if (isGoddard && row[column.id]) {
        column.cellText = row[column.id].title
    }

    if (row.teamId) {
        const team = store.state.user.teams[row.teamId]
        if (team) {
            const provider = store.state.resources.providers[team.providerSlug]
            if (provider) {
                column.cellText = provider.title
            }
        }
    }
}
