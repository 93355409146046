import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'

class ListTransformSteps extends ListTransform {
    constructor(object) {
        object.filterKey = 'hasSteps'
        super(object)
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem('patientListSteps'),
            valueLabels: [
                {
                    value: true,
                    label: Locale.getLanguageItem('yes')
                },
                {
                    value: false,
                    label: Locale.getLanguageItem('no')
                }
            ],
            addAll: true
        }

        return config
    }

    sortRows(rows) {
        rows.sort((a, b) => a.hasSteps - b.hasSteps)
    }
    filterRows(rows, filterStrings) {
        if (filterStrings.length > 0) {
            return rows.filter(row => row.isRegistered && filterStrings.includes(row.hasSteps))
        }

        return rows
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        // Each 'keyString' is actually (boolean) true or false
        if (keyStrings.includes(false)) {
            return false
        }

        return true
    }
    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'has_steps'
    }
}

export default ListTransformSteps
