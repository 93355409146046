import TabCptMixin from '@mixins/TabCptMixin'
import Utils from '@serv/Utils'

export default {
    methods: {
        /**
         * This overrides TabCptMixin.onSave(), to perform form validation first, before calling through to
         * TabCptMixin.onSave().
         *
         * If validation passes, we sanitise the top-level store fields, by trimming any string whitespace.
         * NOTE: We cannot recurse here, as the store may contain objects with circular references.
         */
        onSave() {
            if (this.$refs.form) {
                this.$refs.form.validate('store').then(success => {
                    if (this.store) {
                        Utils.trimObjectStrings(this.store, false)
                    }
                    if (success && this.isTabCpt && !this.isCreate()) {
                        TabCptMixin.methods.onSave.call(this)
                    }
                })
            }
        },
        // Is validation required for the specified element?
        validateRequired(elementString, elementStringAsType) {
            if (this.isElementRequired(elementString)) {
                return elementStringAsType ? `required|${elementString}` : 'required'
            }

            return elementStringAsType ? `${elementString}` : ''
        }
    }
}
