import DataTransform from '@model/data/DataTransform'
import Locale from '@serv/Locale'
import store from '@/store'

import StringHelper from '@serv/StringHelper'

/**
 * A DataTransform for TagDimensions.
 */
class DataTransformTagDimension extends DataTransform {
    constructor(object) {
        object.filterKey = object.dimension
        object.property = object.dimension
        super(object)
    }

    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    /**
     * For a tag dimension, get the string key for the specified value.
     * Examples:
     * (gb-county, warwickshire) -> gbCountyWarwickshire
     * (departmentSlug, hampshire-knee-unit) -> departmentHampshireKneeUnit
     */
    static getStringKeyForTagDimensionValue(tag, value) {
        const tagDimValueSlug = `${tag}-${value}`

        return StringHelper.slugToCamelCase(tagDimValueSlug)
    }

    get getTagToStringKey() {
        const user = store.state.user.user
        const tags = user.tags[this.filterKey] || []
        const tagToStringKey = {}
        tags.forEach(tag => {
            const stringKey = DataTransformTagDimension.getStringKeyForTagDimensionValue(this.property, tag)
            tagToStringKey[tag] = stringKey
        })

        return tagToStringKey
    }

    // Return an object that will be passed as a prop to the filter component
    get getFilterConfig() {
        const tagToStringKey = this.getTagToStringKey
        const valueLabels = Object.keys(tagToStringKey).map(tag => {
            return {
                value: tag,
                label: Locale.getLanguageItem(tagToStringKey[tag])
            }
        })
        // dashstring key for filter title is filterTitle<dimension>, e.g. filterTitleAuState or filterTitleCountry
        const titleKey = StringHelper.slugToCamelCase(this.filterKey)

        return {
            title: Locale.getLanguageItemForModelEnum('filterTitle', titleKey),
            valueLabels: valueLabels.sort((a, b) => a.label.localeCompare(b.label))
        }
    }

    /**
     * Filter and return the list of rows, based on an array of strings.
     * Filter passes if row property equals any provided string.
     */
    filterRows(rows, filterStrings) {
        if (this.property == undefined || filterStrings.length == 0) {
            return rows
        }

        return rows.filter(row => filterStrings.includes(row[this.property]))
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        // Get all teams where specified tag (this.property) is in our input list of values
        const teams = Object.values(store.state.user.teams).filter(team => {
            const provider = store.state.resources.providers[team.providerSlug]

            return provider ? keyStrings.includes(provider.tags[this.property]) : false
        })

        return {
            dimension: this.property,
            value: keyStrings,
            teams: teams
        }
    }
}

export default DataTransformTagDimension
