/*
  Define a few global filters.
*/
import _ from 'lodash'
import Locale from '@serv/Locale'
import moment from 'moment'
import numeral from 'numeral'
// import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber'

// let phoneUtil = PhoneNumberUtil.getInstance()

/**
 * Uppercases a string.
 */
export const uppercase = value => {
    if (!value) {
        return ''
    }

    return String(value).toUpperCase()
}

/**
 * Lowercase string.
 */
export const lowercase = value => {
    if (!value) {
        return ''
    }

    return String(value).toLowerCase()
}

/**
 * Capitalizes a string.
 */
export const capitalize = value => {
    if (!value) {
        return ''
    }

    value = String(value).replace(/_/g, ' ')

    return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * Slugify a string.
 */
export const slugify = value => {
    if (!value) {
        return ''
    }

    let newValue = String(value)
        .trim()
        .toLowerCase()
        .replace(/[\s_]/g, '-')
        .replace(/[^\w-]+/g, '')

    return newValue
}

/**
 * Return a yes or a no.
 */
export const yesno = yesno => {
    return yesno == true ? 'yes' : 'no'
}

/**
 * Return the age based on current date.
 */
export const age = date => {
    let age = moment(date)

    return age.isValid() ? age.fromNow().split(' ')[0] : '—'
}

/**
 * Return a string based from the current moment.
 */
export const fromNow = (date, noSuffix = false) => {
    let _fromNow = moment(date)

    return _fromNow.isValid() ? _fromNow.fromNow(!!noSuffix) : '—'
}

/**
 * Return a locale-aware date.
 */
export const date = input => {
    let _date = moment(input)
    if (_date.isValid()) {
        return _date.format('ll')
    }

    return null
}

/**
 * Return a locale-aware datetime.
 */
export const datetime = input => {
    let _date = moment(input)
    if (_date.isValid()) {
        return _date.format('lll')
    }

    return null
}

/**
 * Joins arrays.
 */
export const join = list => {
    return list.join(' ')
}

/**
 * Return value or a dash.
 */
export const orNull = value => {
    return value || '—'
}

/**
 * Return a locale-formatted number.
 */
export const number = val => {
    return numeral(val).format()
}

/**
 * Display value as fraction out of 10.
 */
export const fraction = val => {
    return `${Math.round(val)}/10`
}

/**
 * Display rounded value.
 */
export const round = val => {
    return Math.round(val)
}

/**
 * Display rounded value.
 */
export const decimal = val => {
    if (val !== null) {
        return Math.round(val * 10) / 10
    }
}
/**
 * Return locale-formatted currency.
 */
export const currency = val => {
    return numeral(val).format('$0,0')
}

/**
 * Truncate string to max length.
 */
export const trunc = (value, maxlen = 50) => {
    let str = String(value)
    if (str.length > maxlen) {
        return `${str.substr(0, maxlen - 1)}…`
    }

    return str
}

/**
 * Correctly format apostrophes.
 */
export const apostrophe = val => {
    if (!val) {
        return ''
    }
    val = String(val)
    if (val[val.length - 1] === 's') {
        return `${val}'`
    }

    return `${val}'s`
}

/**
 * Format phone number using the google libphonenumber lib.
 *
 * Since phone numbers can be so complex, the number needs to be
 * parsed into a format understood by the lib before formatting it
 * for display.
 */
// export const phone = number => {
//     if (!number) return
//     let parsedPhone = phoneUtil.parse(number)
//     return phoneUtil.format(parsedPhone, PhoneNumberFormat.INTERNATIONAL)
// }

/**
 * Returns a formatted dob otherwise a dash.
 */
export const dob = inputdob => {
    let dob = moment(inputdob)

    return dob.isValid() ? dob.format('YYYY.MM.DD') : '—'
}

/**
 * Language filter to be used in the templates;
 * can take multiple args as params.
 */
export const lang = (langKey, ...params) => {
    return Locale.getLanguageItem(langKey, params)
}

export const langOrInput = (langKey, ...params) => {
    let translated = Locale.getLanguageItem(langKey, params)
    if (translated.startsWith(' ') || translated.startsWith('TRANS?')) {
        return langKey
    }

    return translated
}

export const startCase = val => {
    return _.startCase(val)
}

/**
 * Mark strings which need translation.
 */
export const needsLang = val => {
    return `**${val}**`
}
/**
 * Filter out 0 from an array created index
 */
export const indexFormat = val => {
    return val == 0 ? 1 : val + 1
}

export const returnNA = val => {
    if (val && val.includes('N/A')) {
        return 'N/A'
    }

    return val
}

export const nonNull = val => {
    return val === null ? '-' : val
}

export const noSymbol = val => {
    return val.replace(/[^a-zA-Z ]/g, '')
}
