<template lang="pug">
extends PopupTemplate.pug
block input
    <h3 v-if=!!config.title class="popup__title">{{ config.title }}</h3>

    <div class="popup__text">{{ config.text }}</div>

    <div class="popup__cta-wrapper text-center">
        <button @click="onButton" class="ui button primary">{{ config.buttonText }}</button>
    </div>
</template>

<script>
import PopupMixin from '@mixins/PopupMixin'

export default {
    name: 'PopupOneButton',
    mixins: [PopupMixin],
    methods: {
        // Override this to provide different styling, e.g. wider popup
        getPopupContentClasses() {
            return 'popup__content'
        },
        onButton() {
            if (this.config && this.config.onButton) {
                this.config.onButton(this.config)
            }
            this.$store.commit('popup/dismiss')
        }
    }
}
</script>
