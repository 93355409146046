<template>
    <div>
        <!-- Title -->
        <div class="patient-surveys-done__title d-flex justify-center --spacer">
            {{ getLangItem('patientSurveyDoneTitle' , user.firstName) }}
        </div>
        <!-- Clinician banner -->
        <ClinicianBanner
            v-if="isPhone && getClinicianBannerMediaImage"
            :banner-media-image="getClinicianBannerMediaImage"
            :logo-media-image="getClinicianLogoMediaImage"
        />
        <!-- Text -->
        <div class="patient-surveys-done__text">
            <VueMarkdown :source="getDoneText" />
        </div>
        <div
            v-if="isPhone"
            class="patient-surveys-done__footer"
        >
            <img
                v-if="isIOS"
                class="button-apple"
                :src="getImageUrl('app-store-cta.png')"
                alt
                @click="redirectTo('apple')"
            />
            <img
                v-if="isAndroid"
                class="button-google"
                :src="getImageUrl('google-play-cta.png')"
                alt
                @click="redirectTo('google')"
            />
        </div>
        <!-- Logout button -->
        <p />
        <div class="text-center">
            <v-btn
                variant="outlined"
                @click="onLogout"
            >
                {{ getLangItem('accountLogOut') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import ClinicianBanner from '@comp/ClinicianBanner.vue'
import Locale from '@serv/Locale'
import PortalRegistrationMixin from '@mixins/PortalRegistrationMixin'
import RegistrationService from '@serv/RegistrationService'
import StringHelper from '@serv/StringHelper'
import SurveysMixin from '@mixins/SurveysMixin'
import useImage from '@composables/useImage'
import VueMarkdown from 'vue3-markdown-it'

export default {
    name: 'SurveysDone',
    setup() {
        const { getImageUrl } = useImage()

        return {
            getImageUrl
        }
    },
    mixins: [PortalRegistrationMixin, SurveysMixin],
    components: {
        ClinicianBanner,
        VueMarkdown
    },
    computed: {
        getDoneText() {
            const appLoginButtonLabel = Locale.getLanguageItem(
                'patientSurveyDoneAppLoginLabel'
            )
            let text = Locale.getLanguageItem('patientSurveyDoneText', [this.leadName])
            if (this.isPhone) {
                text += `\n\n${Locale.getLanguageItem('patientSurveyDoneTextGetApp')}`
            }
            text += `\n\n${Locale.getLanguageItem('patientSurveyDoneTextLogin', [
                appLoginButtonLabel,
                this.user.username
            ])}`
            text = StringHelper.replaceAll(text, '\\n', '\n')

            return text
        }
    },
    methods: {
        redirectTo(type) {
            window.location.href =
                type == 'apple'
                    ? this.owner.keyValues.client.mobileAppAppStoreUrl
                    : this.owner.keyValues.client.mobileAppGooglePlayUrl
        },
        onLogout() {
            RegistrationService.logoutAndRedirect()
        }
    }
}
</script>
