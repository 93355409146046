import _ from 'lodash'
import Analytics from '@serv/Analytics'
import ClinicalMilestone from '@model/ClinicalMilestone'
import ClinicalMilestoneService from '@serv/ClinicalMilestoneService'
import Clinician from '@model/Clinician'
import Department from '@model/Department'
import ListColumn from '@model/list/ListColumn'
import ListTransform from '@model/list/ListTransform'
import ListTransformAppointments from '@model/list/ListTransformAppointments'
import ListTransformBoolean from '@model/list/ListTransformBoolean'
import ListTransformBundleEndDate from '@model/list/ListTransformBundleEndDate'
import ListTransformCareNavigatorUser from '@model/list/ListTransformCareNavigatorUser'
import ListTransformCarePeriodPac from '@model/list/ListTransformCarePeriodPac'
import ListTransformCarePeriodStatus from '@model/list/ListTransformCarePeriodStatus'
import ListTransformClinicalCheck from '@model/list/ListTransformClinicalCheck'
import ListTransformClinicalMilestone from '@model/list/ListTransformClinicalMilestone'
import ListTransformDate from '@model/list/ListTransformDate'
import ListTransformDepartment from '@model/list/ListTransformDepartment'
import ListTransformGrmaStocPreOpEducationAppt from '@model/list/ListTransformGrmaStocPreOpEducationAppt'
import ListTransformHasRtm from '@model/list/ListTransformHasRtm'
import ListTransformHasSteps from '@model/list/ListTransformHasSteps.js'
import ListTransformJhubMilestoneToAt from '@model/list/ListTransformJhubMilestoneToAt'
import ListTransformKeyValue from '@model/list/ListTransformKeyValue'
import ListTransformLastActive from '@model/list/ListTransformLastActive'
import ListTransformMessages from '@model/list/ListTransformMessages'
import ListTransformMilestone from '@model/list/ListTransformMilestone'
import ListTransformMilestoneToClinician from '@model/list/ListTransformMilestoneToClinician'
import ListTransformName from '@model/list/ListTransformName'
import ListTransformNumber from '@model/list/ListTransformNumber'
import ListTransformProcedure from '@model/list/ListTransformProcedure'
import ListTransformProperties from '@model/list/ListTransformProperties'
import ListTransformProvider from '@model/list/ListTransformProvider'
import ListTransformPtUser from '@model/list/ListTransformPtUser'
import ListTransformPtVirtualUser from '@model/list/ListTransformPtVirtualUser'
import ListTransformReferees from '@model/list/ListTransformReferees'
import ListTransformReferrals from '@model/list/ListTransformReferrals'
import ListTransformRegion from '@model/list/ListTransformRegion'
import ListTransformRtmDataReview from '@model/list/ListTransformRtmDataReview'
import ListTransformRtmReviewReminder from '@model/list/ListTransformRtmReviewReminder'
import ListTransformRtmStatus from '@model/list/ListTransformRtmStatus.js'
import ListTransformRtmUser from '@model/list/ListTransformRtmUser'
import ListTransformSteps from '@model/list/ListTransformSteps'
import ListTransformSurveysStatus from '@model/list/ListTransformSurveysStatus'
import ListTransformTasks from '@model/list/ListTransformTasks'
import ListTransformTeamLead from '@model/list/ListTransformTeamLead'
import ListTransformTeamMembers from '@model/list/ListTransformTeamMembers'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import MilestoneListTransformPatientJourney from '@model/list/MilestoneListTransformPatientJourney'
import moment from 'moment'
import numeral from 'numeral'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
import Schedule from '@model/Schedule'
import SliderScaleStep from '@model/content/SliderScaleStep'
import store from '@/store'
import StringHelper from '@serv/StringHelper'
import User from '@model/User'
import Utils from '@serv/Utils'

import { _resolvePatientJourneyListColumnOfType_referrals } from '@serv/ListServiceColumnReferrals'
import { _resolvePatientJourneyListColumnOfType_rtmReviewReminder } from '@serv/ListServiceColumnRtmReviewReminder'

import _resolveObjectListColumnOfType_jhubMilestoneToAt from '@serv/ListServiceColumnJhubMilestoneToAt.js'
import _resolveObjectListColumnOfType_milestoneAcceptance from '@serv/ListServiceColumnMilestoneAcceptance'
import _resolveObjectListColumnOfType_milestoneService from '@serv/ListServiceColumnMilestoneService'
import _resolveObjectListColumnOfType_milestoneToClinician from '@serv/ListServiceColumnMilestoneToClinician'
import _resolveObjectListColumnOfType_milestoneToTeam from '@serv/ListServiceColumnMilestoneToTeam'
import { _resolvePatientJourneyListColumnOfType_careNavigatorUser } from '@serv/ListServiceColumnCareNavigatorUser'
import { _resolvePatientJourneyListColumnOfType_ptUser } from '@serv/ListServiceColumnPtUser'
import { _resolvePatientJourneyListColumnOfType_ptVirtualUser } from '@serv/ListServiceColumnPtVirtualUser'
import { _resolvePatientJourneyListColumnOfType_rtmStatus } from '@serv/ListServiceColumnRtmStatus.js'
import {
    _resolveObjectListColumnOfType_appointments,
    _resolvePatientJourneyListColumnOfType_appointments
} from '@serv/ListServiceColumnAppointments.js'
import {
    _resolveObjectListColumnOfType_boolean,
    _resolvePatientJourneyListColumnOfType_boolean,
    _resolvePatientJourneyListColumnOfType_milestoneExists
} from '@serv/ListServiceColumnBoolean.js'
import {
    _resolveObjectListColumnOfType_bundleEndDate,
    _resolveObjectListColumnOfType_carePeriodFacility,
    _resolveObjectListColumnOfType_carePeriodPac,
    _resolveObjectListColumnOfType_carePeriodPlannedEndDate,
    _resolveObjectListColumnOfType_carePeriodStartDate,
    _resolveObjectListColumnOfType_carePeriodStatus,
    _resolvePatientJourneyListColumnOfType_bundleEndDate,
    _resolvePatientJourneyListColumnOfType_carePeriodFacility,
    _resolvePatientJourneyListColumnOfType_carePeriodPac,
    _resolvePatientJourneyListColumnOfType_carePeriodPlannedEndDate,
    _resolvePatientJourneyListColumnOfType_carePeriodStartDate,
    _resolvePatientJourneyListColumnOfType_carePeriodStatus
} from '@serv/ListServiceColumnCarePeriod.js'
import {
    _resolveObjectListColumnOfType_clinicalMilestone,
    _resolvePatientJourneyListColumnOfType_clinicalMilestone,
    _resolvePatientJourneyListColumnOfType_surveyResult,
    _resolvePatientJourneyListColumnOfType_surveyStatus
} from '@serv/ListServiceColumnClinicalMilestone.js'
import {
    _resolveObjectListColumnOfType_date,
    _resolvePatientJourneyListColumnOfType_date
} from '@serv/ListServiceColumnDate.js'
import {
    _resolveObjectListColumnOfType_grmaStocPreOpEducationAppt,
    _resolvePatientJourneyListColumnOfType_grmaStocPreOpEducationAppt
} from '@serv/ListServiceColumnGrmaStocPreOpEductationAppt.js'
import {
    _resolveObjectListColumnOfType_hopcoClinicalCheck,
    _resolvePatientJourneyListColumnOfType_hopcoClinicalCheck,
    _resolvePatientJourneyListColumnOfType_hopcoMultiSurveyRag
} from '@serv/ListServiceColumnHopcoClinicalCheck.js'
import {
    _resolveObjectListColumnOfType_keyValue,
    _resolvePatientJourneyListColumnOfType_keyValue
} from '@serv/ListServiceColumnKeyValue'
import {
    _resolveObjectListColumnOfType_lastActive,
    _resolvePatientJourneyListColumnOfType_lastActive
} from '@serv/ListServiceColumnLastActive.js'
import {
    _resolveObjectListColumnOfType_milestone,
    _resolvePatientJourneyListColumnOfType_milestone
} from '@serv/ListServiceColumnMilestone.js'
import {
    _resolveObjectListColumnOfType_patientJourneyKeyValue,
    _resolvePatientJourneyListColumnOfType_patientJourneyKeyValue
} from '@serv/ListServiceColumnPatientJourneyKeyValue.js'
import {
    _resolveObjectListColumnOfType_procedure,
    _resolvePatientJourneyListColumnOfType_procedure
} from '@serv/ListServiceColumnProcedure.js'
import {
    _resolveObjectListColumnOfType_properties,
    _resolvePatientJourneyListColumnOfType_properties,
    _resolvePatientJourneyListColumnOfType_videosCompleted
} from '@serv/ListServiceColumnProperties'
import {
    _resolveObjectListColumnOfType_provider,
    _resolvePatientJourneyListColumnOfType_provider
} from '@serv/ListServiceColumnProvider.js'
import {
    _resolveObjectListColumnOfType_rtmDataReview,
    _resolvePatientJourneyListColumnOfType_rtmDataReview
} from '@serv/ListServiceColumnRtmDataReview.js'
import {
    _resolveObjectListColumnOfType_rtmScheduleEnd,
    _resolvePatientJourneyListColumnOfType_rtmScheduleEnd
} from '@serv/ListServiceColumnRtmScheduleEnd.js'
import {
    _resolveObjectListColumnOfType_rtmUser,
    _resolvePatientJourneyListColumnOfType_rtmUser
} from '@serv/ListServiceColumnRtmUser.js'
import {
    _resolveObjectListColumnOfType_steps,
    _resolvePatientJourneyListColumnOfType_hasSteps,
    _resolvePatientJourneyListColumnOfType_steps
} from '@serv/ListServiceColumnSteps.js'
import {
    _resolveObjectListColumnOfType_surveysStatus,
    _resolvePatientJourneyListColumnOfType_surveysCompleted,
    _resolvePatientJourneyListColumnOfType_surveysStatus
} from '@serv/ListServiceColumnSurveysStatus.js'
import {
    _resolveObjectListColumnOfType_tasks,
    _resolvePatientJourneyListColumnOfType_taskCount,
    _resolvePatientJourneyListColumnOfType_tasks
} from '@serv/ListServiceColumnTasks.js'
import {
    _resolveObjectListColumnOfType_teamLead,
    _resolvePatientJourneyListColumnOfType_teamLead
} from '@serv/ListServiceColumnTeamLead.js'
import {
    _resolveObjectListColumnOfType_teamMembers,
    _resolvePatientJourneyListColumnOfType_teamMembers
} from '@serv/ListServiceColumnTeamMembers.js'
import {
    _resolvePatientJourneyListColumnOfType_messages,
    _resolvePatientJourneyListColumnOfType_unreadMessageCount
} from '@serv/ListServiceColumnMessages.js'

// Create ListTransform from column type
const mapListColumnTypeToTransform = {
    [ListColumn.Type.appointments]: ListTransformAppointments,
    [ListColumn.Type.boolean]: ListTransformBoolean,
    [ListColumn.Type.bundleEndDate]: ListTransformBundleEndDate,
    [ListColumn.Type.careNavigatorUser]: ListTransformCareNavigatorUser,
    [ListColumn.Type.carePeriodEndDate]: ListTransform,
    [ListColumn.Type.carePeriodFacility]: ListTransformProvider,
    [ListColumn.Type.carePeriodPac]: ListTransformCarePeriodPac,
    [ListColumn.Type.carePeriodPlannedEndDate]: ListTransformDate,
    [ListColumn.Type.carePeriodStartDate]: ListTransformDate,
    [ListColumn.Type.carePeriodStatus]: ListTransformCarePeriodStatus,
    [ListColumn.Type.clinicalMilestone]: ListTransformClinicalMilestone,
    [ListColumn.Type.consent]: ListTransformBoolean,
    [ListColumn.Type.date]: ListTransformDate,
    [ListColumn.Type.dateDifference]: ListTransform,
    [ListColumn.Type.department]: ListTransformDepartment,
    [ListColumn.Type.grmaStocPreOpEducationAppt]: ListTransformGrmaStocPreOpEducationAppt,
    [ListColumn.Type.hasRtm]: ListTransformHasRtm,
    [ListColumn.Type.hasSteps]: ListTransformHasSteps,
    [ListColumn.Type.hopcoClinicalCheck]: ListTransformClinicalCheck,
    [ListColumn.Type.hopcoMultiSurveyRag]: ListTransformClinicalCheck,
    [ListColumn.Type.jhubMilestoneToAt]: ListTransformJhubMilestoneToAt,
    [ListColumn.Type.keyValue]: ListTransformKeyValue,
    [ListColumn.Type.lastActive]: ListTransformLastActive,
    [ListColumn.Type.messages]: ListTransformMessages,
    [ListColumn.Type.milestone]: ListTransformMilestone,
    [ListColumn.Type.milestoneAcceptance]: ListTransform,
    [ListColumn.Type.milestoneDate]: ListTransformDate,
    [ListColumn.Type.milestoneExists]: ListTransformBoolean,
    [ListColumn.Type.milestoneToTeam]: ListTransform,
    [ListColumn.Type.milestoneToClinician]: ListTransformMilestoneToClinician,
    [ListColumn.Type.milestoneService]: ListTransform,
    [ListColumn.Type.name]: ListTransformName,
    [ListColumn.Type.number]: ListTransformNumber,
    [ListColumn.Type.patientJourney]: MilestoneListTransformPatientJourney,
    [ListColumn.Type.patientJourneyKeyValue]: ListTransformKeyValue,
    [ListColumn.Type.procedure]: ListTransformProcedure,
    [ListColumn.Type.properties]: ListTransformProperties,
    [ListColumn.Type.provider]: ListTransformProvider,
    [ListColumn.Type.ptUser]: ListTransformPtUser,
    [ListColumn.Type.ptVirtualUser]: ListTransformPtVirtualUser,
    [ListColumn.Type.referees]: ListTransformReferees,
    [ListColumn.Type.referrals]: ListTransformReferrals,
    [ListColumn.Type.region]: ListTransformRegion,
    [ListColumn.Type.rtmDataReview]: ListTransformRtmDataReview,
    [ListColumn.Type.rtmReviewReminder]: ListTransformRtmReviewReminder,
    [ListColumn.Type.rtmScheduleEnd]: ListTransform,
    [ListColumn.Type.rtmStatus]: ListTransformRtmStatus,
    [ListColumn.Type.rtmUser]: ListTransformRtmUser,
    [ListColumn.Type.steps]: ListTransformSteps,
    [ListColumn.Type.string]: ListTransform,
    [ListColumn.Type.surveyResult]: ListTransformClinicalMilestone,
    [ListColumn.Type.surveyStatus]: ListTransformClinicalMilestone,
    [ListColumn.Type.surveysCompleted]: ListTransform,
    [ListColumn.Type.surveysStatus]: ListTransformSurveysStatus,
    [ListColumn.Type.taskCount]: ListTransformTasks,
    [ListColumn.Type.tasks]: ListTransformTasks,
    [ListColumn.Type.teamLead]: ListTransformTeamLead,
    [ListColumn.Type.teamMembers]: ListTransformTeamMembers,
    [ListColumn.Type.unreadMessageCount]: ListTransformMessages,
    [ListColumn.Type.videosCompleted]: ListTransform,
    // TODO: After Dash 5.2.0 goes live rtmTasks and careNavigatorTasks can be removed
    [ListColumn.Type.careNavigatorTasks]: ListTransformTasks,
    [ListColumn.Type.rtmTasks]: ListTransformTasks
}

/**
 * Functions for managing the lists.
 */
let clinicianListColumns = []
let departmentListColumns = []
let patientListColumns = []

class ListService {
    constructor() {
        // Useful for development to get full details of how table cell values are calculated.
        this.isLoggingVerbose = false

        this.patientListPayload = {}
    }

    // For testing
    reset() {
        clinicianListColumns = []
        departmentListColumns = []
        patientListColumns = []
    }

    // Turn an array of ListColumn JSON specs into objects.
    getListColumnsFromSpecs(specs) {
        const columns = []
        specs.forEach(spec => {
            spec.columnIndex = columns.length
            columns.push(new ListColumn(spec))
        })

        return columns
    }

    removeDuplicates(rows) {
        const duplicates = new Set()

        return rows.filter(row => {
            if (duplicates.has(row.patientJourneyId)) {
                Logging.error(`Duplicate patient journey removed with patientJourneyId: ${row.patientJourneyId}`)

                return false
            }
            duplicates.add(row.patientJourneyId)

            return true
        })
    }

    getGoddardPayloadFromColumnsAndConfig(columns, roleColumns) {
        const result = []

        for (const colSpec of roleColumns) {
            const colMatch = columns?.find(col => col.id === colSpec.id)

            if (!colMatch) {
                Logging.error(`No column match found for ${colSpec.id} on owner ${store.state.user.owner.slug}`)
            } else {
                result.push(colMatch)
            }
        }

        return result
    }

    /**
     * For the given user and owner, get the appropriate list of columns from the YAML spec.
     * - Check if user defines keyValues.patientListColumns ELSE
     * - Check if owner defines keyValues.patientListColumns ELSE
     * - Return empty array
     *
     * For Goddard, we return the ListColumns (with view keys moved to root level) and a separate payload array, which
     * has the view keys removed.
     */
    getListColumnsForUserAndOwner(user, owner, key) {
        let columnSpecs = []

        if (key == 'patientListColumns' && owner.isGoddard) {
            columnSpecs = this._getGoddardPatientListColumnSpecs(user, owner)
        } else {
            columnSpecs = user.keyValues?.[key] || owner.keyValues?.dash?.[key] || []
        }

        if (key == 'patientListColumns') {
            columnSpecs = this._filterPatientJourneyListColumns(user, owner, columnSpecs)

            if (owner.isGoddard) {
                this._prepareGoddardColumnPayload(columnSpecs)

                // For the columnSpecs that will form the ListColumns, we move all view keys to the root level
                for (const col of columnSpecs) {
                    for (const key in col.view) {
                        col[key] = col.view[key]
                    }
                    delete col.view
                }
            }
        }

        const columns = []
        columnSpecs.forEach(spec => {
            spec.columnIndex = columns.length
            columns.push(new ListColumn(spec))
        })

        return columns
    }

    _getGoddardPatientListColumnSpecs(user, owner) {
        const patientJourneyList = owner.keyValues?.dash?.patientJourneyList
        const roleConfig = patientJourneyList?.roleConfigs?.find(config => config.roles.includes(user.role))

        if (!roleConfig) {
            Logging.error(`No role config found for role ${user.role} for owner ${owner.slug}`)

            return []
        }

        return this.getGoddardPayloadFromColumnsAndConfig(patientJourneyList.columns, roleConfig.columns)
    }

    /**
     * Filter out any columns we don't want to display, e.g. those specifying a contentSlug that doesn't exist.
     */
    _filterPatientJourneyListColumns(user, owner, columnSpecs) {
        columnSpecs = this._filterColumnsByContent(columnSpecs)
        columnSpecs = this._filterColumnsByUserCapabilities(user, owner, columnSpecs)
        columnSpecs = this._filterTeamLeadColumn(user, columnSpecs)
        columnSpecs = this._filterChatColumn(user, owner, columnSpecs)

        return columnSpecs
    }

    _filterColumnsByContent(columnSpecs) {
        const content = store.state.content.content || {}

        return columnSpecs.filter(column => {
            const contentSlugs = ClinicalMilestoneService.getContentSlugsFromSpec(column)
            if (contentSlugs != undefined) {
                for (const contentSlug of contentSlugs) {
                    if (content[contentSlug]) {
                        return true
                    }

                    Logging.warn(`No content found for content slug: ${contentSlug}. Filter out column ${column.id}`)
                }

                return false
            }

            return true
        })
    }

    _filterColumnsByUserCapabilities(user, owner, columnSpecs) {
        if (!user.has(User.Capability.canViewPatientData)) {
            columnSpecs = columnSpecs.filter(column => {
                if (owner.isGoddard && column.type == ListColumn.Type.surveyResult) {
                    Logging.warn(
                        `Filter out survey result column as user cannot view patient data. Column id: ${column.id}`
                    )
                }

                return owner.isGoddard
                    ? column.type !== ListColumn.Type.surveyResult
                    : column.clinicalMilestoneType != ClinicalMilestone.Type.survey
            })
        }

        return columnSpecs
    }

    _filterTeamLeadColumn(user, columnSpecs) {
        const leads = store.state.user.teamLeads || {}
        const leadsValues = Object.values(leads)

        if (leadsValues.length == 1 && leadsValues[0].personaId == user.personaId) {
            columnSpecs = columnSpecs.filter(column => {
                if (column.type == ListColumn.Type.teamLead) {
                    Logging.warn('Filter out team lead column as dash user is the only lead')
                }

                return column.type != ListColumn.Type.teamLead
            })
        }

        return columnSpecs
    }

    _filterChatColumn(user, owner, columnSpecs) {
        if (!owner.isGoddard || owner.hasChat) {
            return columnSpecs
        }

        columnSpecs = columnSpecs.filter(column => {
            if (column.type == ListColumn.Type.unreadMessageCount) {
                Logging.warn(`Filter out chat column as chat is not enabled for this owner. Column id: ${column.id}`)
            }

            return column.type !== ListColumn.Type.unreadMessageCount
        })

        return columnSpecs
    }

    _prepareGoddardColumnPayload(columnSpecs) {
        this.patientListPayload.columns = _.cloneDeep(columnSpecs)
        for (const col of this.patientListPayload.columns) {
            delete col.view
        }
    }

    /**
     * Rewrite columnIndex and value properties (in-place) to match the array.
     */
    updateColumnIndices(columns) {
        for (let i = 0; i < columns.length; i++) {
            columns[i].columnIndex = i
            columns[i].value = columns[i].value && isNaN(columns[i].value) ? columns[i].value : i.toString()
        }
    }

    // For a list of columns, set all column 'width' properties, which is used by the v-table.
    calculateListColumnWidths(columns) {
        const totalWidth = columns.reduce((total, column) => {
            const width = column.relativeWidth || ListColumn.typeWidth[column.type] || 1.0

            return total + width
        }, 0)
        columns.forEach(column => {
            const width = column.relativeWidth || ListColumn.typeWidth[column.type] || 1.0
            column.width = `${((width * 100) / totalWidth).toFixed(2)}%`
        })
    }

    /**
     * Get the patient list columns defined by the owner or lead config.
     */
    getPatientListColumns() {
        if (patientListColumns.length == 0) {
            const user = store.state.user.user
            const owner = store.state.user.owner

            patientListColumns = this.getListColumnsForUserAndOwner(user, owner, 'patientListColumns')

            if (!owner.isGoddard) {
                patientListColumns.forEach(column => {
                    if (
                        column.type == 'rtmTasks' ||
                        column.type == 'careNavigatorTasks' ||
                        column.type == 'jhubTasks'
                    ) {
                        column.subtype = column.type
                        column.type = ListColumn.Type.tasks
                    }
                })
            }

            this.updateColumnIndices(patientListColumns)
        }

        this.calculateListColumnWidths(patientListColumns)

        return patientListColumns
    }

    /**
     * Get the clinician list columns defined by the owner or lead config.
     */
    getClinicianListColumns() {
        if (clinicianListColumns.length == 0) {
            // Evaluate once
            const lead = store.state.user.user
            clinicianListColumns = this.getListColumnsForUserAndOwner(lead, lead.owner, 'clinicianListColumns')
        }

        return clinicianListColumns
    }

    /**
     * Get the clinician list columns defined by the owner or lead config.
     */
    getDepartmentListColumns() {
        if (departmentListColumns.length == 0) {
            // Evaluate once
            const lead = store.state.user.user
            departmentListColumns = this.getListColumnsForUserAndOwner(lead, lead.owner, 'departmentListColumns')
        }

        return departmentListColumns
    }
    /**
     * From an array of ListColumns, get array of ListTransform objects.
     * The first N map 1-1 with the columns.
     */
    getListTransformsFromColumns(filterSetKey, columns, patient) {
        return columns.reduce((validTransforms, column) => {
            const Class = mapListColumnTypeToTransform[column.type]

            if (!Class) {
                Logging.error(
                    `No ListTransform class defined for column type: ${column.type} on owner ${store.state.user.owner.slug} for column: ${column.label}`
                )
            } else {
                validTransforms.push(
                    new Class({
                        filterSetKey: filterSetKey,
                        filterKey: column.columnIndex,
                        column: column,
                        patient: patient
                    })
                )
            }

            return validTransforms
        }, [])
    }

    // Debug logging of patient and column info.
    _logClinicalMilestoneColumnForPatient(column, patient) {
        Logging.log(`Patient ${patient.fullName} (${patient.personaId}): column ${JSON.stringify(column, null, 2)}`)
        const primaryMilestoneMoment = patient.firstJourney.primaryMilestoneMoment
        if (primaryMilestoneMoment) {
            const diffDays = primaryMilestoneMoment.diff(moment(), 'days')
            Logging.log(
                `Primary milestone date: ${primaryMilestoneMoment.format(
                    Utils.serialisedDateFormat
                )}, ${-diffDays} days ago`
            )
        }
    }

    /**
     * From a column spec, a schedule slug, a specified patient and moment, return an array of matching Activity objects, as follows:
     * - Get all activities from the patient primary journey
     * - Filter by content slugs defined by the column spec
     * - Filter by activity schedules that overlap the scheduleSlug
     */
    _getColumnScheduleActivitiesForPatientAtMoment(column, scheduleSlug, patient, atMoment) {
        // Get activities matching column content
        const journey = store.state.resources.journeys[patient.journeySlug]
        if (!journey) {
            Logging.error(
                `Could not resolve journey: ${patient.firstJourney.journeySlug}, teamId: ${patient.firstJourney.teamId}`
            )

            return []
        }
        // Filter activities to those matching our content spec
        const contentSlugs = ClinicalMilestoneService.getContentSlugsFromSpec(column)
        let activities = journey.activities.filter(activity => {
            return contentSlugs.includes(activity.contentSlug)
        })
        if (scheduleSlug != undefined) {
            // Filter activities to those where schedule overlaps column schedule
            activities = activities.filter(activity => {
                const scheduleA = Schedule.get(activity.scheduleSlug)
                if (
                    scheduleA.filterMilestoneSlug &&
                    !_.isEmpty(patient.getMilestonesOfSlug(scheduleA.filterMilestoneSlug))
                ) {
                    // Schedule specifies to filter on milestone, and milestone exists
                    return false
                }
                const scheduleB = Schedule.get(scheduleSlug)

                return scheduleA.overlaps(scheduleB)
            })
        }
        // Filter activities to those where schedule overlaps moment
        activities = activities.filter(activity =>
            PatientService.isPatientScheduleActiveAtMoment(patient, activity.scheduleSlug, atMoment)
        )

        return activities
    }

    /**
     * From a column spec and a specified patient, get a desired scheduleSlug.
     * If the column spec scheduleSlug is NOT 'current', we just return the column spec scheduleSlug.
     * Otherwise we work out which of the standard promSchedules the patient is currently within, and return that scheduleSlug.
     */
    _getClinicalMilestoneColumnScheduleForPatient(column, patient) {
        if (column.scheduleSlug == 'current') {
            // 04/01/22 scheduleSlugs should just be the owner list
            const scheduleSlugs = new Set(store.state.user.owner.keyValues.dash.charts.promSchedules)
            // Find the promSchedules slug that "now" is currently within.
            // NOTE: Assumes there is only one.
            const nowMoment = moment()
            for (const scheduleSlug of scheduleSlugs) {
                if (PatientService.isPatientScheduleActiveAtMoment(patient, scheduleSlug, nowMoment)) {
                    return scheduleSlug
                }
            }
            // 26/10/21 If we failed to find a schedule that we're currently within, find the closest PREVIOUS schedule
            let scheduleSlug = PatientService.getPatientScheduleSlugClosestEndBeforeMoment(
                patient,
                scheduleSlugs,
                nowMoment
            )
            if (!scheduleSlug && scheduleSlugs.has('pre-op')) {
                // If still not found, use 'pre-op'
                scheduleSlug = 'pre-op'
            }

            return scheduleSlug || 'post-reg'
        }

        return column.scheduleSlug
    }

    /**
     * For a specified column with a defined stepSlug, evaluate the column cellText and other
     * properties based on the associated column.clinicalMilestone surveyResults.
     */
    _evaluateColumnForStepSlug(column) {
        const step = store.state.content.content[column.stepSlug]
        const clinicalMilestone = column.clinicalMilestones.find(
            cm => cm.surveyResult && cm.status == ClinicalMilestone.Status.complete
        )
        if (step && clinicalMilestone) {
            const surveyResult = clinicalMilestone.surveyResult
            if (column.choiceValueLabels) {
                // QuestionStep
                let value, freeTextValue
                const stepResult = surveyResult.stepSlugToStepResult[column.stepSlug]
                const scoreSection = surveyResult.scoreMap[column.stepSlug]
                if (stepResult) {
                    // Patient step results loaded (or from mocks), reading from StepResult
                    value = stepResult.value
                    freeTextValue = stepResult.freeTextValue
                } else if (scoreSection) {
                    // Reading from ScoreSection, on SurveyResult object
                    const zeroBasedIndex = scoreSection.value
                    value = step.choices[zeroBasedIndex].value
                    freeTextValue = scoreSection.displayValue
                }
                if (value != undefined) {
                    if (value == 'date' && freeTextValue) {
                        // Conversion of free-text values which are dates to readable format
                        freeTextValue = new moment(freeTextValue).format(Utils.readableDateFormat)
                    }
                    const stringKey = column.choiceValueLabels[value]
                    const text = stringKey == '{{freeTextValue}}' ? freeTextValue : Locale.getLanguageItem(stringKey)
                    column.cellText = text
                    column.stepResultValue = value // used for list sorting
                }
            } else {
                const stepResult = surveyResult.stepSlugToStepResult[column.stepSlug]
                const scoreSection = surveyResult.scoreMap[column.stepSlug]
                if (stepResult) {
                    // Patient step results loaded (or from mocks), reading from StepResult
                    column.stepResultValue = stepResult.value
                } else if (scoreSection) {
                    column.stepResultValue = scoreSection.value.toString()
                }
                column.cellText = column.stepResultValue
            }
            // Support numeral formatting of numbers, including % values using sliderMaxValue
            const number = parseFloat(column.cellText)
            if (!isNaN(number)) {
                if (column.numeralFormat) {
                    column.number = number
                    if (column.numeralFormat.includes('%')) {
                        if (step && step instanceof SliderScaleStep) {
                            column.number = number / step.sliderMaxValue
                        }
                    }
                    column.cellText = numeral(column.number).format(column.numeralFormat)
                }
            }
        }
    }

    /**
     * From an array of ListColumns, build a new array of resolved columns for an object (row).
     * The row object could be a Patient, Clinician, Department, Milestone or some other object.
     */
    getListColumnsResolvedForRow(inColumns, row) {
        const columns = _.cloneDeep(inColumns)
        columns.forEach(column => {
            let fnName = `_resolveObjectListColumnOfType_${column.type}`
            if (this[fnName]) {
                return this[fnName](column, row)
            }
            Logging.error(`No resolve function for ListColumn of type ${column.type}`)
        })

        return columns
    }
    /**
     * From an array of ListColumns, build a new array of resolved columns for a PatientJourney.
     * The 'row' here is a line returned from a PJ list report.
     */
    getListColumnsResolvedForPatientJourneyRow({ inColumns, patientJourney, row, isGoddard = false, errors = {} }) {
        const columns = _.cloneDeep(inColumns)
        columns.forEach(column => {
            let fnName = `_resolvePatientJourneyListColumnOfType_${column.type}`

            // TODO: After Dash 5.2.0 goes live rtmTasks and careNavigatorTasks can be removed
            if (column.type == 'rtmTasks' || column.type == 'careNavigatorTasks' || column.type == 'jhubTasks') {
                fnName = `_resolvePatientJourneyListColumnOfSubtype_${column.type}`
            }

            if (this[fnName] && !column.filterOnly) {
                return this[fnName](column, patientJourney, row, errors, isGoddard)
            }
            if (!column.filterOnly) {
                Logging.error(`No resolve function for ListColumn of type ${column.type}`)
            }
        })

        return columns
    }

    /*
    All ListColumn types:
    appointments: 'appointments',
    boolean: 'boolean',
    clinicalMilestone: 'clinicalMilestone',
    consent: 'consent',
    date: 'date',
    department: 'department',
    keyValue: 'keyValue',
    messages: 'messages',
    name: 'name',
    number: 'number',
    patientJourneyKeyValue: 'patientJourneyKeyValue',
    procedure: 'procedure',
    provider: 'provider',
    referees: 'referees',
    region: 'region',
    string: 'string',
    teamLead: 'teamLead',
    teamMembers: 'teamMembers'
    */
    _resolveObjectListColumnOfType_appointments(column, row) {
        _resolveObjectListColumnOfType_appointments(column, row)
    }

    _resolvePatientJourneyListColumnOfType_appointments(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_appointments(column, patientJourney, row)
    }

    _resolvePatientJourneyListColumnOfType_rtmStatus(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_rtmStatus(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_rtmUser(column, row) {
        _resolveObjectListColumnOfType_rtmUser(column, row)
    }
    _resolvePatientJourneyListColumnOfType_rtmUser(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_rtmUser(column, patientJourney, row, errors, isGoddard)
    }

    _resolveObjectListColumnOfType_tasks(column, row) {
        _resolveObjectListColumnOfType_tasks(column, row)
    }
    _resolvePatientJourneyListColumnOfType_tasks(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_tasks(column, patientJourney, row)
    }
    _resolvePatientJourneyListColumnOfType_taskCount(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_taskCount(column, patientJourney, row, errors, isGoddard)
    }
    _resolveObjectListColumnOfType_boolean(column, row) {
        _resolveObjectListColumnOfType_boolean(column, row)
    }
    _resolvePatientJourneyListColumnOfType_boolean(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_boolean(column, patientJourney, row)
    }
    _resolvePatientJourneyListColumnOfType_milestoneExists(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_milestoneExists(column, patientJourney, row, errors, isGoddard)
    }
    _resolveObjectListColumnOfType_clinicalMilestone(column, row) {
        _resolveObjectListColumnOfType_clinicalMilestone(column, row)
    }
    _resolvePatientJourneyListColumnOfType_clinicalMilestone(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_clinicalMilestone(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_hopcoClinicalCheck(column, row) {
        _resolveObjectListColumnOfType_hopcoClinicalCheck(column, row)
    }
    _resolvePatientJourneyListColumnOfType_hopcoClinicalCheck(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_hopcoClinicalCheck(column, patientJourney, row)
    }

    _resolvePatientJourneyListColumnOfType_hopcoMultiSurveyRag(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_hopcoMultiSurveyRag(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_consent(column, row) {
        if (!(row instanceof Patient)) {
            Logging.error(`ListColumn.Type.consent can only be used on objects of type: Patient`)

            return
        }
        const patient = row
        column.boolean = patient.consentSlugs.includes(column.consentSlug)
        column.cellText = Locale.getLanguageItem(column.boolean ? 'yes' : 'no')
    }

    _resolveObjectListColumnOfType_department(column, row) {
        const slug = row.departmentSlug || row.slug
        const departmentObj = store.state.resources.departments[slug]
        if (departmentObj) {
            column.cellText = departmentObj.title
        }
    }

    _resolveObjectListColumnOfType_date(column, row) {
        _resolveObjectListColumnOfType_date(column, row)
    }
    _resolvePatientJourneyListColumnOfType_date(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_date(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_jhubMilestoneToAt(column, row) {
        _resolveObjectListColumnOfType_jhubMilestoneToAt(column, row)
    }

    _resolveObjectListColumnOfType_milestoneToClinician(column, row) {
        _resolveObjectListColumnOfType_milestoneToClinician(column, row)
    }

    _resolveObjectListColumnOfType_milestoneToTeam(column, row) {
        _resolveObjectListColumnOfType_milestoneToTeam(column, row)
    }

    _resolveObjectListColumnOfType_milestoneService(column, row) {
        _resolveObjectListColumnOfType_milestoneService(column, row)
    }

    _resolveObjectListColumnOfType_milestoneAcceptance(column, row) {
        _resolveObjectListColumnOfType_milestoneAcceptance(column, row)
    }

    _resolveObjectListColumnOfType_keyValue(column, row) {
        _resolveObjectListColumnOfType_keyValue(column, row)
    }

    _resolvePatientJourneyListColumnOfType_keyValue(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_keyValue(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_name(column, row) {
        if (!(row instanceof User)) {
            Logging.error(`ListColumn.Type.name can only be used on objects of type: User`)

            return
        }
        column.cellText = row.titledFullName
    }

    _resolveObjectListColumnOfType_number(column, row) {
        if (!column.property) {
            Logging.error(`ListColumn of type number must specify property`)

            return
        }
        column.number = row[column.property]
        if (column.number != undefined) {
            if (column.numeralFormat) {
                column.cellText = numeral(column.number).format(column.numeralFormat)
            } else {
                column.cellText = column.number.toString()
            }
        } else {
            column.cellText = ''
        }
    }

    _resolveObjectListColumnOfType_patientJourney(column, row) {
        if (!(row instanceof Patient || row instanceof Milestone)) {
            Logging.error(`ListColumn.Type.patientJourney can only be used on objects of types: Patient, Milestone`)

            return
        }
        const patientJourney = row instanceof Patient ? row.firstJourney : row.patientJourney // assume some pre-processing has set this on the Milestone
        column.cellText = patientJourney.summaryText
    }

    _resolveObjectListColumnOfType_grmaStocPreOpEducationAppt(column, row) {
        _resolveObjectListColumnOfType_grmaStocPreOpEducationAppt(column, row)
    }

    _resolvePatientJourneyListColumnOfType_grmaStocPreOpEducationAppt(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_grmaStocPreOpEducationAppt(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_patientJourneyKeyValue(column, row) {
        _resolveObjectListColumnOfType_patientJourneyKeyValue(column, row)
    }

    _resolvePatientJourneyListColumnOfType_patientJourneyKeyValue(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_patientJourneyKeyValue(column, patientJourney, row, errors, isGoddard)
    }

    _resolveObjectListColumnOfType_procedure(column, row) {
        _resolveObjectListColumnOfType_procedure(column, row)
    }
    _resolvePatientJourneyListColumnOfType_procedure(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_procedure(column, patientJourney, row, errors, isGoddard)
    }

    _resolvePatientJourneyListColumnOfType_ptUser(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_ptUser(column, patientJourney, row, errors, isGoddard)
    }

    _resolvePatientJourneyListColumnOfType_ptVirtualUser(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_ptVirtualUser(column, patientJourney, row, errors, isGoddard)
    }

    _resolveObjectListColumnOfType_provider(column, row) {
        _resolveObjectListColumnOfType_provider(column, row)
    }
    _resolvePatientJourneyListColumnOfType_provider(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_provider(column, patientJourney, row, errors, isGoddard)
    }

    _resolveObjectListColumnOfType_referees(column, row) {
        if (!(row instanceof Patient)) {
            Logging.error(`ListColumn.Type.referees can only be used on objects of type: Patient`)

            return
        }
        const patient = row
        const users = PatientService.getPatientReferees(patient)
        Utils.sortUsersByLastThenFirstNames(users)
        column.cellText = users
            .map(user => (column.hideRole ? user.titledFullName : user.titledFullNameWithRole))
            .join(', ')
    }

    _resolveObjectListColumnOfType_region(column, row) {
        // Clinician may have multiple regions
        if (!(row instanceof Clinician || row instanceof Department)) {
            Logging.error(`ListColumn.Type.region can only be used on objects of types: Clinician, Department`)

            return
        }
        if (row instanceof Department) {
            if (column.dimension) {
                const provider = store.state.resources.providers[row.slug]
                if (provider) {
                    column.cellText = Locale.getLanguageItemOrUndefined(provider.getRegionByDimension(column.dimension))

                    return
                }
            }
            column.cellText = Locale.getLanguageItem(row.getStringKeyForRegion)

            return
        }

        const clinician = row
        const providers = store.state.resources.providers
        const regionTitles = clinician.providerSlugs
            .map(providerSlug => {
                const provider = providers[providerSlug]
                if (provider) {
                    return Locale.getLanguageItem(provider.getStringKeyForRegion)
                }
                Logging.error(`Could not find Provider: ${providerSlug}`)

                return ''
            })
            .filter(provider => {
                return provider
            })

        column.cellText = regionTitles.join(', ')
    }

    /**
     * Resolve a row object, using a column spec, to a string value.
     * IF column.properties is defined:
     * - This is an array of objects, each with "property" and "prefix". We iterate through the array:
     * - If row[object.property] is defined, we choose this value, can be in camelCase OR kebab-case format
     * - object.prefix becomes the prefix, must be in camelCase format
     * ELSE:
     * - column.prefix is assumed to be in camelCase format
     * - row[column.property] can be in camelCase OR kebab-case format
     * - These are appended to form a single camelCase stringkey
     */
    _resolveObjectListColumnOfType_string(column, row) {
        if (!column.properties && (!column.property || !column.prefix)) {
            Logging.error(`ListColumn of type string must specify property and prefix or properties`)

            return
        }
        let value
        let prefix

        if (column.properties) {
            for (const obj of column.properties) {
                if (row[obj.property]) {
                    value = row[obj.property]
                    prefix = obj.prefix
                    break
                }
            }
        } else {
            value = row[column.property]
            prefix = column.prefix
        }
        if (value && !prefix) {
            column.cellText = value

            return
        }
        if (value && value.includes('-')) {
            value = StringHelper.slugToCamelCase(value)
        }
        if (value && prefix) {
            const stringKey = prefix + StringHelper.capitalize(value)
            column.cellText = Locale.getLanguageItemOrUndefined(stringKey)
        }
    }

    _resolveObjectListColumnOfType_surveysStatus(column, row) {
        _resolveObjectListColumnOfType_surveysStatus(column, row)
    }
    _resolvePatientJourneyListColumnOfType_surveysStatus(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_surveysStatus(column, patientJourney, row)
    }
    _resolvePatientJourneyListColumnOfType_surveysCompleted(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_surveysCompleted(column, patientJourney, row, errors, isGoddard)
    }
    _resolvePatientJourneyListColumnOfType_surveyStatus(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_surveyStatus(column, patientJourney, row, errors, isGoddard)
    }

    _resolvePatientJourneyListColumnOfType_surveyResult(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_surveyResult(column, patientJourney, row, errors, isGoddard)
    }
    _resolveObjectListColumnOfType_teamLead(column, row) {
        _resolveObjectListColumnOfType_teamLead(column, row)
    }
    _resolvePatientJourneyListColumnOfType_teamLead(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_teamLead(column, patientJourney, row, errors, isGoddard)
    }

    _resolveObjectListColumnOfType_teamMembers(column, row) {
        _resolveObjectListColumnOfType_teamMembers(column, row)
    }

    _resolvePatientJourneyListColumnOfType_teamMembers(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_teamMembers(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_steps(column, row) {
        _resolveObjectListColumnOfType_steps(column, row)
    }
    _resolvePatientJourneyListColumnOfType_steps(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_steps(column, patientJourney, row)
    }
    _resolvePatientJourneyListColumnOfType_hasSteps(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_hasSteps(column, patientJourney, row, errors, isGoddard)
    }

    _resolveObjectListColumnOfType_lastActive(column, row) {
        _resolveObjectListColumnOfType_lastActive(column, row)
    }
    _resolvePatientJourneyListColumnOfType_lastActive(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_lastActive(column, patientJourney, row, errors, isGoddard)
    }

    _resolvePatientJourneyListColumnOfType_referrals(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_referrals(column, patientJourney, row)
    }

    _resolvePatientJourneyListColumnOfType_rtmReviewReminder(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_rtmReviewReminder(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_rtmScheduleEnd(column, row) {
        _resolveObjectListColumnOfType_rtmScheduleEnd(column, row)
    }
    _resolvePatientJourneyListColumnOfType_rtmScheduleEnd(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_rtmScheduleEnd(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_rtmDataReview(column, row) {
        _resolveObjectListColumnOfType_rtmDataReview(column, row)
    }
    _resolvePatientJourneyListColumnOfType_rtmDataReview(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_rtmDataReview(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_dateDifference(column, row) {
        if (!column.startProperty || !column.endProperty) {
            Logging.error(`ListColumn of type dateDifference must specify startProperty and endProperty`)

            return
        }
        column.startProperty = row[column.startProperty]
        column.endProperty = row[column.endProperty]
        if (column.startProperty && column.endProperty) {
            // Convert number to string to include '0' days
            column.cellText = column.endProperty.diff(column.startProperty, column.units).toString()
        }
    }

    _resolveObjectListColumnOfType_carePeriodEndDate(column, row) {
        if (!(row instanceof Milestone)) {
            Logging.error(`ListColumn.Type.carePeriodEndDate can only be used on objects of type: Milestone`)

            return
        }
        const patient = store.state.user.patients[row.patientJourney.patientId]
        if (_.isEqual(patient.firstJourney, row.patientJourney)) {
            if (row.endDate) {
                column.cellText = moment(row.endDate).format(Utils.readableDateFormat)
            } else {
                const nowDate = moment().format(Utils.serialisedDateFormat)

                if (row.plannedEndDate < nowDate) {
                    column.value = '!'
                    column.hoverText = Locale.getLanguageItem('keyDatesEndDateHover')
                }
            }
        }
    }

    _resolveObjectListColumnOfType_carePeriodFacility(column, row) {
        _resolveObjectListColumnOfType_carePeriodFacility(column, row)
    }
    _resolvePatientJourneyListColumnOfType_carePeriodFacility(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_carePeriodFacility(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_carePeriodPlannedEndDate(column, row) {
        _resolveObjectListColumnOfType_carePeriodPlannedEndDate(column, row)
    }
    _resolvePatientJourneyListColumnOfType_carePeriodPlannedEndDate(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_carePeriodPlannedEndDate(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_carePeriodStartDate(column, row) {
        _resolveObjectListColumnOfType_carePeriodStartDate(column, row)
    }
    _resolvePatientJourneyListColumnOfType_carePeriodStartDate(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_carePeriodStartDate(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_carePeriodStatus(column, row) {
        _resolveObjectListColumnOfType_carePeriodStatus(column, row)
    }
    _resolvePatientJourneyListColumnOfType_carePeriodStatus(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_carePeriodStatus(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_bundleEndDate(column, row) {
        _resolveObjectListColumnOfType_bundleEndDate(column, row)
    }
    _resolvePatientJourneyListColumnOfType_bundleEndDate(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_bundleEndDate(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_carePeriodPac(column, row) {
        _resolveObjectListColumnOfType_carePeriodPac(column, row)
    }
    _resolvePatientJourneyListColumnOfType_carePeriodPac(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_carePeriodPac(column, patientJourney, row)
    }

    _resolvePatientJourneyListColumnOfType_careNavigatorUser(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_careNavigatorUser(column, patientJourney, row)
    }

    _resolvePatientJourneyListColumnOfType_messages(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_messages(column, patientJourney, row)
    }

    _resolvePatientJourneyListColumnOfType_unreadMessageCount(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_unreadMessageCount(column, patientJourney, row)
    }

    _resolveObjectListColumnOfType_milestone(column, row) {
        _resolveObjectListColumnOfType_milestone(column, row)
    }
    _resolvePatientJourneyListColumnOfType_milestone(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_milestone(column, patientJourney, row, errors, isGoddard)
    }
    _resolvePatientJourneyListColumnOfType_milestoneDate(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_milestone(column, patientJourney, row, errors, isGoddard)
    }

    _resolveObjectListColumnOfType_properties(column, row) {
        _resolveObjectListColumnOfType_properties(column, row)
    }

    _resolvePatientJourneyListColumnOfType_properties(column, patientJourney, row) {
        _resolvePatientJourneyListColumnOfType_properties(column, patientJourney, row)
    }
    _resolvePatientJourneyListColumnOfType_videosCompleted(column, patientJourney, row, errors, isGoddard) {
        _resolvePatientJourneyListColumnOfType_videosCompleted(column, patientJourney, row, errors, isGoddard)
    }

    /**
     * From an array of ListColumns, get one with defaultSort == true, or undefined.
     */
    getDefaultSortColumn(columns) {
        return columns.find(col => !!col.defaultSort)
    }

    /**
     * From an array of ListTransforms, get one referencing a ListColumn with the specified index, or undefined.
     */
    getTransformWithColumnIndex(transforms, index) {
        // NOTE: Equality below passes even though columnIndex will be string, and index will be integer
        return transforms.find(
            transform => transform.column && (transform.column.columnIndex == index || transform.column.value == index)
        )
    }

    /**
     * From a Vuetify table click event, perform all actions related to sorting.
     * sortedColumnIndex is the string ID of the Vuetify column.
     */
    sortTableFromHeaderClick(sortedColumnIndex, sortedAscending, filterSet, tableKey) {
        if (sortedColumnIndex != undefined) {
            if (store.state.user.owner.hasPatientJourneyList) {
                store.commit('onClickListSettingsColumnIndex', {
                    tableKey: tableKey,
                    sortedColumnIndex: sortedColumnIndex,
                    sortedAscending: sortedAscending
                })
            } else {
                store.commit('onClickTableSettingsColumnIndex', {
                    tableKey: tableKey,
                    sortedColumnIndex: sortedColumnIndex,
                    sortedAscending: sortedAscending
                })
            }
            const transform = filterSet.filterKeyTransforms[sortedColumnIndex]
            const transformColumn = transform ? transform.column || {} : {}
            Analytics.sendEvent('tableHeaderColumnSort', {
                filterSetKey: filterSet.key,
                transformColumnIndex: transformColumn.columnIndex,
                transformColumnLabel: transformColumn.label,
                transformColumnType: transformColumn.type,
                sortedAscending: sortedAscending
            })
        }
    }

    // for inserting message column if owner has hasChat true
    processPatientListColumns(patientListColumns) {
        const owner = store.state.user.owner
        if (owner.hasChat) {
            const col = {
                type: ListColumn.Type.messages,
                label: 'patientListMessages',
                headerValue: 'messageTasks'
            }
            patientListColumns.splice(1, 0, col)
        }
    }
}

export default new ListService()
