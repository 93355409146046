import _ from 'lodash'
import Activity from '@model/Activity'
import BaseContent from '@model/content/BaseContent'
import Logging from '@serv/Logging'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import ScoreSection from '@model/ScoreSection'
import StepResult from '@model/StepResult'
import Utils from '@serv/Utils'

const ignoreProperties = [
    'country',
    'countryIso',
    'endTime',
    'personaUid',
    'score',
    'surgeonUid',
    // Ignore when deserialising existing web survey result
    'eventInfo',
    'hasSynced',
    'type'
]

/**
 * A single patient survey result. Typical constructor object properties:
 * activitySlug
 * country or countryIso
 * departmentSlug
 * endTime
 * journeySlug
 * patientId
 * personaUid or surgeonUid
 * primaryMilestoneDate, patient primary milestone date
 * procedureLabel
 * procedureTitle
 * resultPrmId, used to match with StepResults
 * scheduleSlug, from the ActivityResult Activity.scheduleSlug
 * score
 * ...and any arbitrary tags
 */
class SurveyResult {
    constructor(object) {
        // Copy most properties from object
        Object.keys(object).forEach(key => {
            if (!ignoreProperties.includes(key)) {
                this[key] = object[key]
            }
        })

        // id and resultPrmId fields should match, and can come from either property
        const id = object.id || object.resultPrmId
        this.id = id
        this.resultPrmId = id

        this.isValid = true // Used to invalidate results - the report endpoint should not return invalid results so this is for setting locally only

        // We assume result is complete, unless told otherwise
        this.status = object.status ? object.status : 'complete'

        // this.time is a string, and can be used directly on amCharts DateAxis
        if (object.endTime) {
            this.setEndTime(object.endTime)
        }

        // Set up scoreMap
        this.scoreMap = {}
        if (object.score != undefined) {
            // Legacy
            this.scoreMap['Score'] = new ScoreSection({
                section: 'Score',
                value: object.score
            })
        } else if (Array.isArray(object.scoreArray)) {
            object.scoreArray.forEach(section => {
                if (section.value != undefined) {
                    section.value = Utils.roundNumberToDecimalPlaces(section.value, 1)
                }
                // 03/10/22: Deal with issue where we sometimes get contentSlug instead of section
                const key = section.section || section.contentSlug
                this.scoreMap[key] = new ScoreSection({
                    section: key,
                    value: section.value,
                    displayValue: section.displayValue
                })
            })
        }

        // Country - TODO standardise on countryIso
        if (object.country != undefined) {
            this.country = object.country.toUpperCase()
            this.countryIso = this.country
        } else if (object.countryIso != undefined) {
            this.countryIso = object.countryIso.toUpperCase()
            this.country = this.countryIso
        }

        this.personaUid = object.personaUid || object.surgeonUid // of the lead clinician

        // These will be filled out if/when we request step results
        this.stepResults = []
        this.stepSlugToStepResult = {}

        // Store these when deserialising web survey result
        this.startTime = object.startTime
        this.endTime = object.endTime
        this.multiple = object.multiple
        if (object.stepResults) {
            if (object.stepResults.length > 0 && object.stepResults[0] instanceof StepResult) {
                // We are already passing in StepResult objects
                this.stepResults = object.stepResults
            } else {
                // Deserialise from JSON?
                this.stepResults = this.parseStepResultsArray(object.stepResults)
            }
            this.stepResults.forEach(stepResult => (this.stepSlugToStepResult[stepResult.stepSlug] = stepResult))
        }
    }

    // Get keys for logging
    get logKeys() {
        return ['endTime', 'multiple', 'resultPrmId', 'scoreMap', 'startTime', 'status', 'stepResults', 'surveySlug']
    }

    log() {
        const logKeys = this.logKeys
        const object = {}
        for (const key of logKeys) {
            object[key] = this[key]
        }
        Logging.log(JSON.stringify(object, null, 2))
    }

    setStartTime(time) {
        this.startTime = time
    }

    get startMoment() {
        return moment(this.startTime)
    }

    getEndDate() {
        return this.endTime.slice(0, 10)
    }

    setEndTime(time) {
        this.endTime = time
        this.time = this.endTime
    }

    get endMoment() {
        return moment(this.endTime)
    }

    get moment() {
        return moment(this.endTime)
    }

    get date() {
        return this.time.slice(0, 10)
    }

    // Sets the start time of a StepResult at the specified index.
    setStartTimeForStepAtIndex(index, time) {
        if (index < this.stepResults.length) {
            this.stepResults[index].setStartTime(time)
        }
    }

    // Sets the end time of a StepResult at the specified index.
    setEndTimeForStepAtIndex(index, time) {
        if (index < this.stepResults.length) {
            this.stepResults[index].setEndTime(time)
        }
    }

    // Get a named score section value, or undefined.
    getScore(name) {
        return (this.scoreMap[name] || {}).value
    }

    // Convenience getter for main 'Score' section value
    get score() {
        return this.getScore('Score')
    }

    // Set the overall "Score" value. Mainly used for testing.
    setScore(value, displayValue) {
        this.scoreMap['Score'] = {
            section: 'Score',
            value: value,
            displayValue: displayValue
        }
    }

    // Parse a JSON array, and return created StepResult objects as an array.
    parseStepResultsArray(objectArray) {
        const stepResults = []
        objectArray.forEach(json => {
            const stepResult = new StepResult(json)
            stepResults.push(stepResult)
        })

        return stepResults
    }

    // Return true if we have any StepResult with a defined value.
    hasAnyResults() {
        const firstStepResultWithValue = this.stepResults.find(stepResult => stepResult.value != undefined)

        return firstStepResultWithValue != undefined
    }

    // Find the index of the first StepResult not completed.
    _findFirstNotCompletedIndex(survey) {
        for (let i = 0; i < this.stepResults.length; i++) {
            // Unlike on mobile, our StepResult does not contain the full context of the Step answer format.
            // Therefore we need to use the Step in order to provide the full context when determining
            // whether or not the StepResult is 'complete'
            const step = survey.steps[i]
            const stepResult = this.stepResults[i]
            switch (step.type) {
                // case BaseContent.Type.infoStep:
                //     if (stepResult.endTime == undefined) {
                //         return i
                //     }
                //     break
                case BaseContent.Type.question:
                    // TODO handle requiredTextChoice
                    if (step.minSelected) {
                        // Multi text choice
                        if (Array.isArray(stepResult.value)) {
                            if (stepResult.value.length < step.minSelected) {
                                return i
                            }
                        }
                    } else {
                        // Single text choice
                        if (stepResult.value == undefined) {
                            return i
                        }
                    }
                    break
                case BaseContent.Type.healthScale:
                case BaseContent.Type.painScale:
                case BaseContent.Type.sliderScale:
                    if (stepResult.value == undefined) {
                        return i
                    }
                    break
            }
        }
    }

    // Find the index of the first incomplete StepResult, or undefined if the survey is complete.
    getFirstNonCompleteStepIndex(survey) {
        const index = this._findFirstNotCompletedIndex(survey)
        if (index != undefined) {
            if (index > 0 && this.stepResults[index - 1].hasNoResults()) {
                // Not first step, and previous step is InfoStep
                return index - 1
            }
        }

        return index
    }

    // Returns the value of a result at a given step index.
    getValueForStepAtIndex(index) {
        if (index < this.stepResults.length) {
            return this.stepResults[index].value
        }
    }

    removeAllResultsForStepResultAtIndex(index) {
        if (index < this.stepResults.length) {
            this.stepResults[index].clearAllResults()
        }
    }

    // Mark the result as complete, and set the end time
    complete() {
        this.status = SurveyResult.Status.complete
        this.setEndTime(moment().format(Utils.serialisedDateTimeFormat))
    }

    // From Swift ActivityResult.matches()
    // Does this result match a ScheduleEvent?
    // We use the result time directly to match
    // To handle bilateral case:
    // If the ScheduleEvent.activity has a defined side, the ActivityResult must have the same side in order to match.
    matches({ event, patientJourney, matchJourney = true }) {
        if (matchJourney && patientJourney != event.patientJourney) {
            return false
        }
        if (this.surveySlug != event.content.slug) {
            return false
        }
        const side = event.activity.side
        // NOTE: Ideally we'd use self.eventInfo fields to resolve to an actual Activity.side
        // But that's currently a bit painful, and the activitySlug suffix approach should be solid
        const matchesSide =
            side == Activity.Side.none ||
            (side == Activity.Side.left && this.activitySlug?.endsWith('-left')) ||
            (side == Activity.Side.right && this.activitySlug?.endsWith('-right'))

        if (!matchesSide) {
            return false
        }

        const eventStartDate = event.startDate
        const eventEndDate = event.endDate
        const startDate = this.date
        const endDate = this.getEndDate()
        const matchesTime =
            (startDate >= eventStartDate && startDate < eventEndDate) ||
            (endDate && endDate >= eventStartDate && endDate < eventEndDate)

        if (matchesTime) {
            return true
        }

        // If result was entered by a clinician, this may have been outside the time span of the schedule.
        // In this case, we permit a match if the activity and repeatIndex match.
        if (this.enteredByClinician) {
            return this.activitySlug == event.activitySlug
        }

        return false
    }

    // Return a new object with the same core values.
    getDuplicate() {
        const duplicate = _.cloneDeep(this)
        duplicate.uuid = nanoid()
        duplicate.startTime = Utils.serialisedDateTimeNow
        duplicate.endTime = undefined

        return duplicate
    }
}

/**
 * Survey result statuses.
 */
SurveyResult.Status = {
    complete: 'complete',
    frozen: 'frozen',
    partial: 'partial'
}

export default SurveyResult
