<template lang="pug">
extends PopupTemplate.pug
block input
    <h3 v-if=!!config.title class="popup__title">{{ config.title }}</h3>

    <div class="popup__text">{{ config.text }}</div>

    <div class="popup__cta-wrapper text-center">
        <button @click="handleLeftButtonClick" class="mr-btn popup__cta-wrapper__button" data-cy="submit-btn" :class="leftButtonClasses">{{ config.leftButtonText }}</button>
        <button @click="config.onRightButton(config)" class="mr-btn popup__cta-wrapper__button" data-cy="secondary-btn" :class="rightButtonClasses">{{ config.rightButtonText }}</button>
    </div>
</template>

<script>
import PopupMixin from '@mixins/PopupMixin'

export default {
    name: 'PopupTwoButton',
    mixins: [PopupMixin],
    computed: {
        leftButtonClasses() {
            return {
                'mr-btn--primary': !this.config.isLeftButtonSecondary && !this.config.isLeftButtonAlert,
                'mr-btn--secondary': this.config.isLeftButtonSecondary && !this.config.isLeftButtonAlert,
                'mr-btn--alert': this.config.isLeftButtonAlert && !this.config.isLeftButtonSecondary
            }
        },
        rightButtonClasses() {
            return {
                'mr-btn--primary': !this.config.isRightButtonSecondary && !this.config.isRightButtonAlert,
                'mr-btn--secondary': this.config.isRightButtonSecondary && !this.config.isRightButtonAlert,
                'mr-btn--alert': this.config.isRightButtonAlert && !this.config.isRightButtonSecondary
            }
        }
    }
}
</script>

<style lang="scss" scoped>
/* stylelint-disable-next-line selector-class-pattern */
.popup__cta-wrapper__button {
    margin-top: 20px;
    margin-right: 16px;
}
</style>
