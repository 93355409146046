import Logging from '@serv/Logging'
import Patient from '@model/Patient'
import PatientService from '@serv/PatientService'
export function _resolveObjectListColumnOfType_procedure(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.messages can only be used on objects of type: Patient`)

        return
    }
    const patient = row
    column.cellText = PatientService.getJourneysSummary(patient)
}
export function _resolvePatientJourneyListColumnOfType_procedure(column, patientJourney, row, errors, isGoddard) {
    const object = isGoddard ? row : patientJourney
    column.cellText = PatientService.getPatientJourneySummary(object)
}
