import Logging from '@serv/Logging'
import StringHelper from '@serv/StringHelper'

class BaseContent {
    // Construct object - no not resolve any links.
    constructor(object) {
        this.slug = object.slug
        this.type = object.type
        this.tags = object.tags || []
        this.title = object.title
        this.locale = object.locale
        this.version = object.version
        this.versions = []

        this.text = object.text
        this.keyValues = object.keyValues || {}
        if (this.text) {
            this._sanitiseProperty('text')
        }
        if (this.title) {
            this._sanitiseProperty('title')
        }

        // Items
        this.itemSlugs = []
        this.items = []
        if (object.items != undefined) {
            this.itemSlugs = object.items.map(obj => obj.$link)
        }
    }

    // Get keys for logging
    get logKeys() {
        return ['items', 'keyValues', 'slug', 'tags', 'text', 'title', 'type']
    }

    log() {
        const logKeys = this.logKeys
        const object = {}
        for (const key of logKeys) {
            object[key] = this[key]
        }
        Logging.log(JSON.stringify(object, null, 2))
    }

    // From a string of form-encoded key values, add them to our keyValues.
    _addFormEncodedKeyValuesFromString(formEncodedValues) {
        /**
         * The content script encodes boolean values as "True" and "False", and this is how they appear
         * within the keyValues strings. Mobile app deserialisation seems happy with this, but JSON.parse()
         * will error as illegal JSON. So we replace them here.
         */
        formEncodedValues = StringHelper.replaceAll(formEncodedValues, ': True', ': true')
        formEncodedValues = StringHelper.replaceAll(formEncodedValues, ': False', ': false')
        const parts = formEncodedValues.split('&')
        for (const part of parts) {
            const keyValue = part.split('=')
            if (keyValue.length == 2) {
                let valueString = keyValue[1]
                if (
                    (valueString.startsWith('[') && !valueString.startsWith('[[')) ||
                    (valueString.startsWith('{') && !valueString.startsWith('{{'))
                ) {
                    // Incoming string will have string key and values wrapped in single quotes. These (and ONLY these)
                    // need changing to double quotes before JSON.parse
                    valueString = StringHelper.replaceAll(valueString, "{'", '{"')
                    valueString = StringHelper.replaceAll(valueString, "'}", '"}')
                    valueString = StringHelper.replaceAll(valueString, "':", '":')
                    valueString = StringHelper.replaceAll(valueString, ": '", ': "')
                    valueString = StringHelper.replaceAll(valueString, "',", '",')
                    valueString = StringHelper.replaceAll(valueString, ", '", ', "')
                    valueString = StringHelper.replaceAll(valueString, "['", '["')
                    valueString = StringHelper.replaceAll(valueString, "']", '"]')
                    try {
                        const arrayOrObject = JSON.parse(valueString)
                        this.keyValues[keyValue[0]] = arrayOrObject
                    } catch (err) {
                        Logging.error(`Error: ${err} from JSON string ${valueString}`)
                    }
                } else {
                    this.keyValues[keyValue[0]] = valueString
                }
            }
        }
    }

    /**
     * Extract form-encoded keyValues from the text property, and merge this with keyValues.
     * We assume any {{keyValues?...}} capture is right at the start of the string. If so, we extract the key values
     * and remove the capture.
     * As with the mobile app, the code for extracting the values is slightly hacky. It does deal with:
     * - Scalar values
     * - Object values
     * - Arrays, including arrays of objects
     * Note however that use of single-quotes within values is NOT supported.
     */
    _sanitiseProperty(field) {
        let text = this[field]
        if (!text) {
            return
        }
        const keyValuesPrefix = '{{keyValues?'
        while (text.length) {
            const prefixIndex = text.indexOf(keyValuesPrefix)
            if (prefixIndex < 0) {
                break
            }
            // Get termination point of closing brackets, respecting nesting
            let level = 0
            let term = text.length
            for (let i = 0; i < text.length; i++) {
                if (text[i] == '{') {
                    level++
                }
                if (text[i] == '}') {
                    if (--level == 0) {
                        term = i - 1 // before first of closing pair
                        break
                    }
                }
            }
            if (term < text.length) {
                const formEncodedValues = text.substr(
                    prefixIndex + keyValuesPrefix.length,
                    term - (prefixIndex + keyValuesPrefix.length)
                )
                this._addFormEncodedKeyValuesFromString(formEncodedValues)
                // Crop the start from the text we're processing
                text = text.substr(term + 2)
                // Extract the processed {{...}} block from the original field
                const fieldBeforeKeyValues = this[field].substr(0, prefixIndex)
                const fieldAfterKeyValues = this[field].substr(term + 2, this[field].length - (term + 2))
                this[field] = fieldBeforeKeyValues + fieldAfterKeyValues
            }
        }
    }

    // Resolve any links etc.
    resolve(slugToObject) {
        if (this.itemSlugs == undefined) {
            // Already resolved
            return
        }
        // Items
        this.items = []
        this.itemSlugToItem = {}
        this.itemSlugs.forEach(slug => {
            this.items.push(slugToObject[slug])
            this.itemSlugToItem = slugToObject[slug]
        })
        delete this.itemSlugs
    }

    // Does object contain the specified tag?
    containsTag(tag) {
        return this.tags.includes(tag)
    }
}

/**
 * This is an amalgamation of the mobile app Content.Type and Step.Type enumerations.
 */
BaseContent.Type = {
    // From Content.Type
    exerciseRoutine: 'exerciseRoutine',
    file: 'file',
    image: 'image',
    info: 'info',
    js: 'js',
    survey: 'survey',
    video: 'video',

    // From Step.Type
    exercise: 'exercise',
    infoStep: 'infoStep',
    question: 'question',
    form: 'form',
    painScale: 'painScale',
    healthScale: 'healthScale',
    sliderScale: 'sliderScale'
}

export default BaseContent
