import ClinicalMilestone from '@model/ClinicalMilestone'
import Logging from '@serv/Logging'
import moment from 'moment'
import PatientService from '@serv/PatientService'
import store from '@/store'
import TaskService from '@serv/TaskService'

const functions = {}
export function _resolveObjectListColumnOfType_tasks(column, row) {
    const fnName = `_resolveObjectListColumnOfSubtype_${column.subtype}`
    if (functions[fnName]) {
        functions[fnName](column, row)
    } else {
        Logging.error(`No resolve function for ListColumn of subtype ${column.subtype}`)
    }
}

export function _resolvePatientJourneyListColumnOfType_tasks(column, patientJourney, row) {
    const fnName = `_resolvePatientJourneyListColumnOfSubtype_${column.subtype}`
    if (functions[fnName]) {
        functions[fnName](column, patientJourney, row)
    } else {
        Logging.error(`No resolve function for ListColumn of subtype ${column.subtype}`)
    }
}

// eslint-disable-next-line no-unused-vars
export function _resolvePatientJourneyListColumnOfType_taskCount(column, patientJourney, row, errors) {
    column.value = row[column.id]?.count
}

functions._resolveObjectListColumnOfSubtype_rtmTasks = (column, row) => {
    let tasks = 0
    const journey = store.state.resources.journeys[row.firstJourney.journeySlug]
    if (journey.hasRtm) {
        const recentMilestone = PatientService.getPatientRecentRtmPeriodReviewMilestone(row)

        if (recentMilestone) {
            const hasReviewedRtmPeriodCurrentMonth = PatientService.hasReviewedRtmPeriodInCurrentMonth(row)

            // If most recent RTM period milestone (98980) has not been reviewed then add +1
            // With feature flag RTM_PERIOD_REFACTOR, this applies to RTM period review milestone with RTM code 98977
            if (!recentMilestone.reviewedDate && !hasReviewedRtmPeriodCurrentMonth) {
                tasks++
            }
        }

        // +1 task if RTM window review is needing review
        const rtmDischargeMilestone = row.firstJourney.getMilestoneOfSlug('rtm-discharge')
        const rtmWindowEndMoment = PatientService.getPatientRtmWindowEndMoment(
            row,
            row.firstJourney,
            journey.rtmEndSchedule
        )

        if (!rtmDischargeMilestone && rtmWindowEndMoment && rtmWindowEndMoment.diff(moment(), 'days') < 8) {
            tasks++
        }
    }

    column.value = tasks
}

functions._resolveObjectListColumnOfSubtype_careNavigatorTasks = (column, row) => {
    column.value = PatientService.getPatientCareNavigatorNumTasks(row)
}

functions._resolveObjectListColumnOfSubtype_surveyReviewTasks = (column, row) => {
    let tasks = 0

    row.clinicalMilestones.forEach(milestone => {
        if (milestone.type == ClinicalMilestone.Type.survey && (milestone.isTask || milestone.isDownloadPdfTask)) {
            tasks++
        }
    })

    if (TaskService.isPreassessmentSurveyTask(row)) {
        tasks++
    }

    column.value = tasks
}

functions._resolvePatientJourneyListColumnOfSubtype_careNavigatorTasks = (column, patientJourney, row) => {
    column.value = row.numCareNavigatorTasks
}

functions._resolvePatientJourneyListColumnOfSubtype_rtmTasks = (column, patientJourney, row) => {
    column.value = row.numRtmTasks
}

functions._resolvePatientJourneyListColumnOfSubtype_surveyReviewTasks = (column, patientJourney, row) => {
    column.value = row.numReviewableSurveys
}

functions._resolvePatientJourneyListColumnOfSubtype_jhubTasks = (column, patientJourney, row) => {
    column.value = row.numJhubTasks
}
