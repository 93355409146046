import { mapState } from 'vuex'
import PopupBrowserCompatibility from '@comp/popups/PopupBrowserCompatibility.vue'
import Utils from '@serv/Utils'

export default {
    name: 'LegacyBrowserMixin',
    components: {
        PopupBrowserCompatibility
    },
    props: {
        config: { type: Object, required: false }
    },
    computed: {
        ...mapState({
            popupClass: state => state.popup.class,
            popupConfig: state => state.popup.config
        })
    },
    mounted() {
        const browserSupported = Utils.isBrowserVersionSupported()

        if (!browserSupported) {
            this.$store.commit('popup/setConfig', {
                onCancel: this.dismissPopup
            })
            this.$store.commit('popup/setClass', PopupBrowserCompatibility)
        }
    }
}
