import ListTransform from '@model/list/ListTransform'

class ListTransformRtmDataReview extends ListTransform {
    constructor(object) {
        object.filterKey = 'rtmNumDistinctDays'
        super(object)
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'rtm_num_distinct_days'
    }
}

export default ListTransformRtmDataReview
