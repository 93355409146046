import DataTransformTagDimension from '@model/data/DataTransformTagDimension'
import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import store from '@/store'

/**
 * List filter for region column.
 * If this.departments = true, we build up the list from Departments rather than Providers.
 */
class ListTransformRegion extends ListTransform {
    constructor(object) {
        super(object)
        this.departments = object.departments
    }

    get getFilterComponent() {
        return 'DataFilterStringArray'
    }

    get getFilterConfig() {
        const providersMap = store.state.resources.providers
        let providers
        let valueLabels
        if (this.column && this.column.valueFilterOptions) {
            valueLabels = this.column.valueFilterOptions.map(option => {
                const text = Locale.getLanguageItem(option)

                return { value: text, label: text }
            })
        } else {
            if (this.departments) {
                // Use departments
                providers = Object.values(store.state.resources.departments).map(department => {
                    return providersMap[department.providerSlug]
                })
            } else {
                // Use providers
                providers = Object.values(providersMap)
            }
            // We assume all providers share the same regionDimension
            const regionDimension = providers[0].regionDimension
            const regions = [...new Set(providers.map(provider => provider.region))]
            valueLabels = regions.map(region => {
                const stringKey = DataTransformTagDimension.getStringKeyForTagDimensionValue(regionDimension, region)
                const label = Locale.getLanguageItem(stringKey)

                return { value: label, label: label }
            })
        }
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: valueLabels.sort((a, b) => a.label.localeCompare(b.label))
        }

        return config
    }
}

export default ListTransformRegion
