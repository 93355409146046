import _ from 'lodash'
import Clinician from '@model/Clinician'
import ClinicianMockService from '@serv/ClinicianMockService'
import Logging from '@serv/Logging'
import MockService from '@serv/MockService'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import Provider from '@model/Provider'
import store from '@/store'

import Surgeon from '@model/Surgeon'
import Team from '@model/Team'
import User from '@model/User'
import Utils from '@serv/Utils'

/**
 * Functions for creating mock data for exec logins.
 */
let numPatients = 0

class ExecMockService {
    /**
     * Get an object to use to permute values on report rows.
     * For each key, we will add a row for each value of the array of values.
     * user.tags is the starting point for this, although some of the tag keys need renaming.
     */
    getRowPermutationKeyValues() {
        const tagKeyMap = {
            department: 'departmentSlug'
        }
        const user = store.state.user.user
        const keyValues = _.cloneDeep(user.tags)
        Object.keys(tagKeyMap).forEach(key => {
            if (keyValues.hasOwnProperty(key)) {
                keyValues[tagKeyMap[key]] = keyValues[key]
                delete keyValues[key]
            }
        })
        // gb-county and journeySlug will be calculated correctly from any department
        if (keyValues.departmentSlug) {
            delete keyValues['gb-county']
        } else {
            keyValues.journeySlug = Object.keys(store.state.resources.journeys)
        }

        return keyValues
    }

    /**
     * Given a keyValues object, ensure consistent keys and values as follows:
     * - If specifying departmentSlug, ensure that the corresponding provider regionDimension is set, and we have a valid journeySlug
     */
    ensureConsistentKeyValues(keyValues) {
        // Ensure consistent values
        if (keyValues.departmentSlug) {
            const department = store.state.resources.departments[keyValues.departmentSlug]
            const provider = store.state.resources.providers[department.providerSlug]
            keyValues[provider.regionDimension] = provider.region
            const teamsMap = store.state.user.teams
            let departmentTeams = Object.values(teamsMap).filter(
                team => team.departmentSlug == department.slug && team.journeySlugs.length > 0
            )
            if (departmentTeams.length == 0) {
                departmentTeams = Object.values(teamsMap).filter(
                    team => team.providerSlug == department.providerSlug && team.journeySlugs.length > 0
                )
            }
            if (departmentTeams.length == 0) {
                Logging.warn(`Could not find any valid teams for department: ${keyValues.departmentSlug}`)

                // Next array combination
                return
            }
            keyValues.journeySlug = _.sample(departmentTeams[0].journeySlugs)
        }
    }

    addMockProviders() {
        // Add providers with every permutation of dash user tag values
        const keyValues = this.getRowPermutationKeyValues()
        const providers = []
        Utils.callFunctionForEachArrayCombination(keyValues, providers, function (keyValues, providers) {
            const tags = []
            Object.keys(keyValues).forEach(key => tags.push({ dimension: key, value: keyValues[key] }))
            const id = nanoid()
            const object = {
                slug: `provider-${id}`,
                title: `Provider ${id}`,
                tags: tags
            }
            providers.push(new Provider(object))
        })
        providers.forEach(provider => (store.state.resources.providers[provider.slug] = provider))
    }

    addMockClinicians(type) {
        const numClinicians = 10
        let i = -1
        while (++i < numClinicians) {
            const clinician = this.getMockClinician(i, type)
            store.state.user.users[clinician.personaId] = clinician

            if (type == 'surgeon') {
                store.state.user.surgeons[clinician.personaUid] = clinician
                // Also add mock Team with surgeon as lead
                const team = new Team({
                    id: clinician.personaId,
                    leadId: clinician.personaId,
                    providerSlug: clinician.providerSlugs[0],
                    journeySlugs: clinician.journeySlugs,
                    memberIds: []
                })
                store.state.user.teams[team.id] = team
            }
        }
    }

    /**
     * Return a mocked Clinician object, of the specified type
     */
    getMockClinician(index, type) {
        const rootId = 1
        const firstName = _.sample(MockService.firstNames)
        const lastName = _.sample(MockService.lastNames)

        const object = {
            capabilities: [
                User.Capability.canJoinTeam,
                User.Capability.canLeadTeam,
                User.Capability.canViewAllTeamPatients,
                User.Capability.canViewPatientData
            ],
            countryIso: 'GB',
            region: 'gb',
            userId: rootId + index,
            personaId: rootId + index,
            firstName: firstName,
            lastName: lastName,
            dob: moment()
                .add(-(20 + Math.random() * 60), 'years')
                .format(Utils.serialisedDateFormat),
            email: `${firstName}.${lastName}@gmail.com`.toLowerCase(),
            mobile: '+447876141972',
            persona: User.Persona.clinician,
            title: 'Dr',
            locale: 'en',
            tags: []
        }
        // Add type-specific fields, and create
        let clinician
        if (type == 'surgeon') {
            object.role = User.Persona.surgeon
            object.providerSlugs = [_.sample(Object.keys(store.state.resources.providers))]
            object.journeySlugs = Object.keys(store.state.resources.journeys)
            clinician = new Surgeon(object)
        } else {
            object.role = User.Persona.hcp
            clinician = new Clinician(object)
        }

        return clinician
    }

    /**
     * Return a mock report response, based on a report slug and query params object.
     * NOTE: This is identical to the function in ClinicianMockService, but uses a faked numPatients.
     */
    getMockReportWithQueryParams(reportSlug, paramsObject) {
        // Use the most appropriate numPatients
        const reports = store.state.resources.reports
        let totalPatients = numPatients
        if (paramsObject) {
            if (paramsObject.clinicians && reports.clinicianList) {
                // Component is on clinician page
                const clinicianUid = paramsObject.clinicians[0]
                const clinician = store.state.user.surgeons[clinicianUid]
                if (clinician) {
                    numPatients = clinician.totalPatientsInvited
                }
            } else if (paramsObject.teams && reports.departmentList) {
                // Component is on department page
                const teamUid = paramsObject.teams[0]
                const teamId = teamUid.split('-')[1]
                const team = store.state.user.teams[teamId]
                if (team && team.departmentSlug) {
                    const department = store.state.resources.departments[team.departmentSlug]
                    numPatients = department.totalPatientsInvited
                }
            }
        }
        switch (reportSlug) {
            case 'overview-kpi-report':
                return ClinicianMockService.getMockKpiReport(paramsObject, totalPatients)

            case 'overview-procedure-summary-report':
                return ClinicianMockService.getMockProcedureStatsReport(paramsObject, totalPatients)

            case 'overview-prom-compliance-tracker-report':
                return ClinicianMockService.getMockProgressTrackerReport(paramsObject)

            case 'overview-prom-survey-results-report':
                return ClinicianMockService.getMockPromSurveyResultsReport(paramsObject, totalPatients)

            case 'overview-experience-survey-results-report':
                return ClinicianMockService.getMockExperienceReport(paramsObject, totalPatients)

            default:
                return []
        }
    }

    getMockClinicianListReport() {
        // Use existing clinicians
        const clinicians = store.state.user.surgeons
        const rows = []
        const nowMoment = new moment()
        numPatients = 0
        Object.values(clinicians).forEach(clinician => {
            const patientsInvited = 2 + Math.floor(Math.random() * 50)
            numPatients += patientsInvited
            rows.push({
                hasInvitedLastMonth: _.sample([true, false]),
                onboardingDate: nowMoment
                    .add(-Math.floor(Math.random() * 300), 'days')
                    .format(Utils.serialisedDateFormat),
                percentPatientsSatisfied: 0.2 + Math.random() * 0.8,
                personaUid: clinician.personaUid,
                totalPatientsInvited: patientsInvited
            })
        })

        // Return the full object, not just the rows
        return {
            slug: 'clinician-list-report3',
            type: 'clinicianList',
            dataset: rows
        }
    }

    getMockDepartmentListReport() {
        // Use existing departments
        const departments = Object.values(store.state.resources.departments)
        const rows = []
        numPatients = 0
        departments.forEach(department => {
            const patientsInvited = 2 + Math.floor(Math.random() * 50)
            numPatients += patientsInvited
            rows.push({
                hasInvitedLastMonth: _.sample([true, false]),
                percentPatientsSatisfied: 0.2 + Math.random() * 0.8,
                departmentSlug: department.slug,
                totalPatientsInvited: patientsInvited
            })
        })

        // Return the full object, not just the rows
        return {
            slug: 'department-list-report3',
            type: 'departmentList',
            dataset: rows
        }
    }

    addMockData(user) {
        // Patient and survey results
        const reports = store.state.resources.reports
        if (user.isProducerExec) {
            // ProducerExec
            const type = 'surgeon'
            this.addMockProviders(type)
            this.addMockClinicians(type)
        } else {
            // ProviderExec
            MockService.mockProvidersDepartmentsTeamsClinicians({
                numProviders: 4
            })
            // Add user tags for all Departments
            user.tags.departmentSlug = Object.keys(store.state.resources.departments)
            reports.departmentList = this.getMockDepartmentListReport()
        }
        store.state.user.patients = {}
        reports.clinicianList = this.getMockClinicianListReport()
    }
}

export default new ExecMockService()
