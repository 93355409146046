import Logging from '@serv/Logging'
import Patient from '@model/Patient'

export function _resolveObjectListColumnOfType_keyValue(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.keyValue can only be used on objects of type: Patient`)

        return
    }

    const key = column.key || column.keyValue // for backwards-compat of config

    if (Array.isArray(row.keyValues[key])) {
        column.cellText = row.keyValues[key].join(',')
    } else {
        column.cellText = row.keyValues[key] || ''

        if (column.valueFilterOptions) {
            column.setExplicitValueConfig(row.keyValues[key])
        }
    }
}

export function _resolvePatientJourneyListColumnOfType_keyValue(column, patientJourney, row) {
    const keyValue = row[`${column.keyValue}Value`]

    column.cellText = keyValue || ''

    if (column.valueFilterOptions) {
        column.setExplicitValueConfig(keyValue)
    }
}
