import ListTransform from '@model/list/ListTransform'

/**
 * List transform for column of type surveysStatus.
 */
class ListTransformSurveysStatus extends ListTransform {
    getSortParameter() {
        return 'num_days_to_next'
    }
}

export default ListTransformSurveysStatus
