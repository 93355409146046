import ListTransform from '@model/list/ListTransform'

/**
 * List filter for name column.
 */
class ListTransformName extends ListTransform {
    /**
     * Sort the list of rows in place.
     * We sort first by lastName, then firstName.
     */
    sortRows(rows) {
        rows.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
    }
}

export default ListTransformName
