/* eslint no-console: "off" */
import * as Sentry from '@sentry/vue'
import Analytics from '@serv/Analytics'
import ConfigManager from '@config/ConfigManager'

/**
 * The Logging service logs messages to the console.
 * Debug messages can be toggled on or off by setting `localStorage.debug`
 * to [log, info, warn, error] or "log, info"
 */
class Logging {
    constructor() {
        const levels = localStorage?.debug

        this.logLevel = levels
            ? Array.isArray(levels)
                ? levels
                : levels.split(',').map(item => item.trim())
            : ['error']
    }

    /** @private */
    _log(level = 'log', message) {
        let Levels = ['log', 'info', 'warn', 'error']

        if (!Levels.includes(level)) {
            throw new Error('Invalid logging level:', level)
        }

        let displayLevel = `${level.toUpperCase()} »`

        console[level](displayLevel, ...message)
    }

    /**
     * Log message
     * @param message {String|Array}
     */
    log(...message) {
        if (this.logLevel.includes('log') || ConfigManager.isMockingServer) {
            this._log('log', message)
        }
    }

    /**
     * Log info message
     * @param message {String|Array}
     */
    info(...message) {
        if (this.logLevel.includes('info')) {
            this._log('info', message)
        }
    }

    /**
     * Log warning message
     * @param message {String|Array}
     */
    warn(...message) {
        if (this.logLevel.includes('warn')) {
            this._log('warn', message)
        }
    }

    /**
     * Log error message.
     * If exactly 2 params provided and second === 'true', we also log the error as a Mixpanel event.
     * @param message {String|Array}
     */
    error(...message) {
        let textArray
        if (Array.isArray(message) && message.length == 2 && message[1] === true) {
            Analytics.sendEvent('error', { message: message[0] })
            textArray = [message[0]]
        } else {
            textArray = message
        }
        this._log('error', textArray)
        // Send to Sentry, with callstack
        Sentry.captureException(new Error(textArray))
    }

    addSentryBreadcrumbs(messages, category = 'default') {
        if (!Array.isArray(messages)) {
            messages = [messages]
        }

        messages.forEach(message => {
            Sentry.addBreadcrumb({
                category: category,
                message: message,
                level: 'info'
            })
        })
    }
}

export default new Logging()
