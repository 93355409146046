/*
State to manage web surveys.
*/

const state = {
    clinicianCompleteForPatient: false,
    didSelectSingleSurvey: undefined, // boolean. Is true if the user selected the survey from the list in the Welcome screen.
    patientJourneysSurveys: [], // array of objects, each representing surveys to be completed for a single patientJourney
    taskHandler: undefined, // current TaskHandler, if any survey in progress,
    webSurveysInitCompleted: false,
    selfSurveyActivity: undefined
}

const getters = {
    clinicianCompleteForPatient: state => {
        return state.clinicianCompleteForPatient
    },
    didSelectSingleSurvey: state => {
        return state.didSelectSingleSurvey
    },
    isWebSurveysInitCompleted: state => {
        return state.webSurveysInitCompleted
    },
    patientJourneysSurveys: state => {
        return state.patientJourneysSurveys
    },
    taskHandler: state => {
        return state.taskHandler
    },
    /**
     * Get the next survey to complete on the web.
     * Return as an array of [patientJourneyId, scheduleEvent]
     */
    nextWebSurveyScheduleEvent: state => {
        for (const patientJourneyWebSurveys of state.patientJourneysSurveys) {
            for (const event of patientJourneyWebSurveys.scheduleEvents) {
                if (event.isComplete) {
                    // Already completed
                    continue
                }

                // Not already completed
                return [patientJourneyWebSurveys.patientJourneyId, event]
            }
        }
    },
    selfSurveyActivity: state => {
        return state.selfSurveyActivity
    },
    // Return the detail view to present, based on the state
    detailView: (state, getters) => {
        if (state.taskHandler) {
            return 'PatientSurvey'
        }
        const nextWebSurveyScheduleEvent = getters.nextWebSurveyScheduleEvent
        if (nextWebSurveyScheduleEvent == undefined) {
            return 'SurveysDone'
        }

        return 'SurveysWelcome'
    }
}

const actions = {}

const mutations = {
    setClinicianCompleteForPatient(state, value) {
        state.clinicianCompleteForPatient = value
    },
    setDidSelectSingleSurvey(state, value) {
        state.didSelectSingleSurvey = value
    },
    setPatientJourneysSurveys(state, objects) {
        state.patientJourneysSurveys = objects
    },
    setWebSurveysInitCompleted(state) {
        state.webSurveysInitCompleted = true
    },
    setTaskHandler(state, object) {
        state.taskHandler = object
    },
    setSelfSurveyActivity(state, selfSurveyData) {
        state.selfSurveyActivity = selfSurveyData
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
