<template>
    <div
        class="portal-registration"
        :class="{ 'portal-registration--android': isAndroid }"
    >
        <div class="portal-registration__wrapper">
            <div class="mr-logo mr-logo--top mr-logo--white">
                <img
                    :src="getIconUrl('logo-land-medu-whit-whit.svg')"
                    alt="logo"
                    class="mr-logo__img"
                />
            </div>
            <div class="portal-registration-card portal-registration-card--small-with-form">
                <div class="portal-registration-card__body">
                    <div class="portal-registration-card__title">
                        {{ getLangItem('patientRegHospitalNumberTitle') }}
                    </div>
                    <div class="portal-registration-card__text">
                        {{ getText }}
                    </div>

                    <Form
                        ref="hospitalNumberForm"
                        tag="form"
                        class="portal-registration-card__form mr-form"
                        @submit="validateAndNext()"
                    >
                        <!-- Hospital number -->
                        <validated-input
                            name="hospitalNumber"
                            :label="getHospitalNumberLabel"
                            :placeholder="getHospitalNumberPlaceholder"
                            rules="required"
                            field-wrapper-class="mr-form__field"
                            v-model="form.hospitalNumber"
                        />
                        <!-- Date of birth -->
                        <ValidationProvider
                            v-slot="{ errors }"
                            class="mr-form__field"
                            rules="required"
                            tag="div"
                            name="dob"
                            :model-value="form.dob"
                        >
                            <div class="veevalidated-input">
                                <div class="label-section">
                                    <label>{{ getLangItem('formDateOfBirth') }}*</label>
                                    <div
                                        v-if="0 < errors.length"
                                        class="invalid-feedback"
                                    >
                                        {{ errors[0] }}
                                    </div>
                                </div>
                                <MrDatePicker
                                    class="mr-datepicker"
                                    :range-low="100"
                                    :range-high="13"
                                    v-model="form.dob"
                                />
                            </div>
                        </ValidationProvider>

                        <div class="portal-registration-card__btn-group">
                            <button
                                id="back"
                                type="button"
                                class="mr-btn mr-btn--secondary"
                                @click="onBack"
                            >
                                {{ getLangItem('back') }}
                            </button>
                            <button
                                id="next"
                                class="mr-btn mr-btn--primary"
                            >
                                {{ getLangItem('next') }}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
        <template v-if="isLoading">
            <div class="mr-loader-bg" />
            <loader />
        </template>
        <NotifyMsg />
    </div>
</template>

<script>
import Analytics from '@serv/Analytics'
import Config from '@serv/Config'
import { Form } from 'vee-validate'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import MrDatePicker from '@comp/MrDatePicker.vue'
import NotifyMsg from '@comp/NotifyMsg.vue'
import NotifyService from '@serv/NotifyService'
import PortalRegistrationMixin from '@mixins/PortalRegistrationMixin'
import Redirect from '@serv/Redirect'
import RegistrationService from '@serv/RegistrationService'
import Request from '@serv/Request'
import StringHelper from '@serv/StringHelper'
import useImage from '@composables/useImage'
import ValidationMixin from '@mixins/ValidationMixin'

export default {
    name: 'PortalRegistrationHospitalNumber',
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    mixins: [PortalRegistrationMixin, ValidationMixin],
    components: {
        MrDatePicker,
        Form,
        NotifyMsg
    },
    data() {
        return {
            form: {},
            isFailed: false
        }
    },
    computed: {
        getText() {
            const label = this.isNhs
                ? Locale.getLanguageItem('patientRegHospitalNumberNhs')
                : Locale.getLanguageItem('patientRegHospitalNumberNotNhs').toLowerCase()

            return Locale.getLanguageItem('patientRegHospitalNumberText', [label])
        },
        getHospitalNumberLabel() {
            const stringKey = this.isNhs
                ? 'patientRegHospitalNumberNhs'
                : 'patientRegHospitalNumberNotNhs'

            return Locale.getLanguageItem(stringKey)
        },
        getHospitalNumberPlaceholder() {
            const label = this.isNhs
                ? Locale.getLanguageItem('patientRegHospitalNumberNhs')
                : Locale.getLanguageItem('patientRegHospitalNumberNotNhs').toLowerCase()

            return Locale.getLanguageItem('patientRegHospitalNumberPlaceholder', [
                label
            ])
        },
        nextStepRequiredResources() {
            return {
                content: this.content,
                journeys: this.journeys
            }
        }
    },
    methods: {
        validateAndNext() {
            this.$refs.hospitalNumberForm.validate().then(success => {
                if (success) {
                    this.isLoading = true
                    let params = {
                        hospitalNumber: StringHelper.removeWhitespace(
                            this.form.hospitalNumber
                        ),
                        dob: this.form.dob
                    }
                    let region
                    if (this.isNhs) {
                        params.hospitalNumberType = 'nhs'
                        region = 'gb'
                    } else {
                        // Do NOT set hospitalNumberType, backend will then match on type==null
                        region = 'us'
                    }
                    const config = Config.getCountryOptions(region)
                    params = Request.getObjectAsQueryParams(params)
                    const url = `${
                        config.base_url
                    }/${Request.Stem.hospitalNumberAuth.replace('{params}', params)}`
                    Request.get(url)
                        .then(res => {
                            this.isLoading = false

                            const token = res.data.unauthenticatedToken
                            if (!token) {
                                Logging.error(
                                    'Response did not provide unauthenticatedToken'
                                )

                                return res
                            }
                            Analytics.sendEvent(
                                'patientRegisterHospitalNumberAuthResponse',
                                {
                                    resultCode: res.status
                                }
                            )
                            Redirect.router.push({
                                name: 'Patient',
                                query: {
                                    token: token,
                                    region: region
                                }
                            })
                        })
                        .catch(error => {
                            this.isLoading = false

                            NotifyService.error(
                                Locale.getLanguageItem('patientRegHospitalNumberError')
                            )
                            Analytics.sendEvent(
                                'patientRegisterHospitalNumberAuthResponse',
                                {
                                    resultCode: error.response.status
                                }
                            )
                        })
                }
            })
        },
        onBack() {
            Redirect.router.back()
        }
    },
    created() {
        RegistrationService.initFromRouteName()
    },
    watch: {
        user(newValue, oldValue) {
            if (newValue && !oldValue) {
                RegistrationService.initFromRouteName()
            }
        },
        nextStepRequiredResources(newValue) {
            if (newValue.content && newValue.journeys) {
                RegistrationService.nextStep()
            }
        }
    }
}
</script>
