import Milestone from '@model/Milestone'
import moment from 'moment'
import ReviewMilestone from '@model/ReviewMilestone'
class RtmPeriodReviewMilestone extends ReviewMilestone {
    constructor(object) {
        object.type = Milestone.Type.rtmPeriodReview
        object.slug = object.slug || 'rtm-period-review'
        super(object)
        this.endDate = object.endDate
        this.rtmCode = object.rtmCode // 98977 and null if RTM_PERIOD_REFACTOR flag is enabled otherwise 98980 or null
        this.reviewedDate = object.reviewedDate
        this.notes = object.notes

        this.hasReviewedEmr = object.hasReviewedEmr
        this.hasPlannedPatientComms = object.hasPlannedPatientComms
        this.hasCompletedPatientComms = object.hasCompletedPatientComms

        // Legacy fields
        // Supporting only already existing RTM period review milestones, new milestones shouldn't use these fields
        this.hasReviewed20Mins = object.hasReviewed20Mins
        this.hasNotedChanges = object.hasNotedChanges
        this.patientCalledDate = object.patientCalledDate
    }

    setEndDate(date) {
        this.endDate = date // string in standard format
        this.endMoment = date ? new moment(date) : undefined
    }

    get isLegacy() {
        return this.hasReviewed20Mins
    }
}

RtmPeriodReviewMilestone.Code = {
    rtm_98980: 98980,
    rtm_98977: 98977
}

export default RtmPeriodReviewMilestone
