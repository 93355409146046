<template>
    <form @submit.prevent="onFormSubmit">
        <slot
            :errors="computedErrors"
            :meta="computedMeta"
            :reset-form="resetForm"
            :validate="validate"
            :values="computedValues"
        />
    </form>
</template>

<script setup>
import { useForm } from 'vee-validate'
import { computed, inject, provide, ref, useAttrs } from 'vue'

const { onSubmit } = useAttrs()
const { meta, errors, values, validate, resetForm, setErrors } = useForm()
const forms = ref([])

// we may already have an injected form register from a parent.  chain it
const registerForm = inject('registerForm') || ((form) => {
    forms.value.push(form)
})

const onFormSubmit = async function(event) {
    event.stopImmediatePropagation()
    if (!onSubmit) {
        return false
    }

    const validatedForm = await validate()
    if (validatedForm.valid) {
        onSubmit(validate)
    }
}

// add _this_ form to the forms collection
registerForm({
    meta,
    errors,
    values,
    resetForm,
    setErrors,
    validate
})

// create a form register fn at _this_ level and chain to parent
provide('registerForm', (form) => {
    registerForm(form)
})

defineExpose({
    values,
    validate,
    setErrors
})

// Aggregates all errors, this assumes all fields have unique names
// if your fields have similar names, consider scoping them or appending a prefix to their name
const computedErrors = computed(() => {
    return forms.value.reduce((acc, form) => {
        return { ...acc, ...form.errors }
    }, {})
})

// Aggregates all values, this assumes all fields have unique names
// if your fields have similar names, consider scoping them or appending a prefix to their name
const computedValues = computed(() => {
    return forms.value.reduce((acc, form) => {
        return { ...acc, ...form.values }
    }, {})
})

// Aggregates all meta flags
const computedMeta = computed(() => {
    return forms.value.reduce(
        (acc, form) => {
            return {
                valid: acc.valid && form.meta.valid,
                dirty: acc.dirty || form.meta.dirty,
                touched: acc.touched || form.meta.touched
            }
        },
        { valid: true, dirty: false, touched: false }
    )
})
</script>
