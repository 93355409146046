import ListTransform from '@model/list/ListTransform'

/**
 * List filter for name column.
 */
class ListTransformDepartment extends ListTransform {
    /**
     * Sort the list of rows in place.
     * We sort first by lastName, then firstName.
     */
    sortRows(rows) {
        rows.sort(
            (a, b) =>
                a.departmentSlug.localeCompare(b.departmentSlug) || a.departmentSlug.localeCompare(b.departmentSlug)
        )
    }
}

export default ListTransformDepartment
