import DataService from '@serv/DataService'
import ListTransform from '@model/list/ListTransform'
import moment from 'moment/moment'
import StringHelper from '@serv/StringHelper'
import Utils from '@serv/Utils'

class ListTransformMilestone extends ListTransform {
    constructor(object) {
        super(object)
        if (this.column.milestoneSlug) {
            this.filterKey = StringHelper.slugToCamelCase(this.column.milestoneSlug)
        }
    }

    get getFilterConfig() {
        return this.column.getExplicitValueFilterConfig()
    }

    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        const momentFuture = new moment(Utils.dateFarFuture)
        rows.sort((a, b) => {
            const valueA = a.listColumns[columnIndex].moment || momentFuture
            const valueB = b.listColumns[columnIndex].moment || momentFuture

            // If column is displaying "time since", then reverse the order
            return valueA.diff(valueB)
        })
    }

    reverse(rows) {
        DataService.reverseRowsByDate(rows, this.column)
    }

    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }

        const columnIndex = this.column.columnIndex

        return rows.filter(row => filterStrings.includes(row.listColumns[columnIndex].filterValue))
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings) {
        if (keyStrings.length == 0) {
            return {}
        }
        const key = keyStrings[0]
        if (this.column.valueFilterOptions[key] == undefined) {
            return
        }
        if (this.column.valueFilterOptions[key].includes(0)) {
            // Include only objects where this PJM does NOT exist
            return {
                exists: false
            }
        }

        return {
            exists: true
        }
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return `${StringHelper.slugToSnakeCase(this.column.milestoneSlug)}_date`
    }
}

export default ListTransformMilestone
