<template>
    <v-col
        class="patient-surveys-wrapper"
        xs="12"
        sm="12"
        md="12"
        :lg="fullWidth ? 12 : 8"
        :xl="fullWidth ? 12 : 8"
    >
        <component
            :is="cptClass"
            :single-survey="singleSurvey"
            :without-info-pages="withoutInfoPages"
            class="patient-surveys__detailview"
            @successfully-submitted="onSuccessfullySubmitted"
        />
        <component
            v-if="!!popupClass"
            :is="popupClass"
            :config="popupConfig"
        />
    </v-col>
</template>

<script>
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import { mapGetters } from 'vuex'
import PopupNoButton from '@comp/popups/PopupNoButton.vue'
import PopupOneButton from '@comp/popups/PopupOneButton.vue'
import PopupSummary from '@comp/popups/PopupSummary.vue'
import Survey from '@comp/websurvey/MrSurvey.vue'
import SurveysDone from '@comp/websurvey/SurveysDone.vue'
import SurveysMixin from '@mixins/SurveysMixin'
import SurveysWelcome from '@comp/websurvey/SurveysWelcome.vue'
import { isAndroid, isTablet } from 'mobile-device-detect'

export default {
    name: 'SurveysDetailView',
    emits: ['successfullySubmitted'],
    mixins: [SurveysMixin],
    components: {
        Survey,
        SurveysDone,
        SurveysWelcome,
        PopupNoButton,
        PopupOneButton,
        PopupSummary
    },
    props: {
        fullWidth: {
            type: Boolean,
            default: false
        },
        singleSurvey: {
            type: Boolean,
            default: false
        },
        withoutInfoPages: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters(['detailView']),
        cptClass() {
            const detailClassname = this.$store.getters.detailView
            Logging.log(`Detail view class: ${detailClassname}`)
            const classMap = {
                PatientSurvey: Survey,
                SurveysDone: SurveysDone,
                SurveysWelcome: SurveysWelcome
            }

            return classMap[detailClassname]
        },
        landscapeOrPortraitText() {
            return isTablet
                ? Locale.getLanguageItem('patientSurveyRotateToLandscape')
                : Locale.getLanguageItem('patientSurveyRotateToPortrait')
        }
    },
    methods: {
        orientationCheck() {
            if (isTablet) {
                if (window.innerHeight > window.innerWidth) {
                    this.popupShow()
                } else {
                    this.popupClear()
                }
            } else if (this.isPhone) {
                if (window.innerHeight > window.innerWidth) {
                    this.popupClear()
                } else {
                    this.popupShow()
                }
            }
        },
        popupShow() {
            this.$store.commit('popup/setConfig', {
                text: this.landscapeOrPortraitText,
                close: true
            })
            this.$store.commit('popup/setClass', PopupNoButton)
        },
        popupClear() {
            this.$store.commit('popup/dismiss')
        },
        onSuccessfullySubmitted() {
            this.$emit('successfullySubmitted')
        }
    },
    mounted() {
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            this.orientationCheck()
            window.addEventListener('resize', this.orientationCheck)
        }
        if (isAndroid) {
            setTimeout(() => {
                let viewheight = window.innerHeight
                let viewwidth = window.innerWidth
                let viewport = document.querySelector('meta[name=viewport]')
                viewport.setAttribute(
                    'content',
                    `height=${
                        viewheight
                        }px, width=${
                        viewwidth
                        }px, initial-scale=1.0`
                )
            }, 300)
        }
    }
}
</script>
