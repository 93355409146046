import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import store from '@/store'

export default function _resolveObjectListColumnOfType_jhubMilestoneToAt(column, row) {
    if (!(row instanceof Milestone)) {
        Logging.error(`ListColumn.Type.jhubMilestoneToAt can only be used on objects of type: Milestone`)

        return
    }
    const milestone = row
    if (milestone.type == Milestone.Type.referral) {
        const user = store.state.user.users[milestone.referredToId]
        if (user) {
            column.cellText = user.titledFullName
            column.user = user
        }
    } else if (milestone.subtypeKey) {
        column.cellText = Locale.getLanguageItemForModelEnum(milestone.subtypeKey, milestone.subtype)
    }
}
