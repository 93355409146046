export default {
    name: 'PopupMixin',
    props: {
        config: { type: Object, required: false }
    },
    data() {
        return {
            heading: undefined,
            isCloseDisabled: false
        }
    },
    methods: {
        // Override this to provide different styling, e.g. wider popup
        getPopupContentClasses() {
            return 'popup__content'
        },
        onClose() {
            if (this.config && this.config.onCancel) {
                this.config.onCancel()
            }
            this.$store.commit('popup/dismiss')
        },
        handleLeftButtonClick() {
            if (this.config && this.config.onLeftButton) {
                this.config.onLeftButton(this.config)
            }
            this.$store.commit('popup/dismiss')
        }
    }
}
