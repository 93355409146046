const Auth0Config = {
    qa: {
        gb: {
            domain: 'https://auth-qa.uk.msk.ai',
            clientId: 'T05t3O4hL7niQjgSIHiy8wZmfm7DSnxq',
            audience: 'https://prm-qa-gb.myrecoverytest.com',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://prm-qa-gb.myrecoverytest.com/auth/v1/auth0/logout/?redirect={{redirect}}'
        },
        us: {
            domain: 'https://us.auth.qa.myrecovery.ai',
            clientId: '6VzSQOMACrCBUYaJtxU4CHBDVAXGwYny',
            audience: 'https://prm-qa-us.myrecoverytest.com',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://prm-qa-us.myrecoverytest.com/auth/v1/auth0/logout/?redirect={{redirect}}'
        }
    },
    spoof: {
        gb: {
            domain: 'https://auth-qa.uk.msk.ai',
            clientId: 'T05t3O4hL7niQjgSIHiy8wZmfm7DSnxq',
            audience: 'https://prm-qa-gb.myrecoverytest.com',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://prm-qa-gb.myrecoverytest.com/auth/v1/auth0/logout/?redirect={{redirect}}'
        },
        us: {
            domain: 'https://us.auth.qa.myrecovery.ai',
            clientId: '6VzSQOMACrCBUYaJtxU4CHBDVAXGwYny',
            audience: 'https://prm-qa-us.myrecoverytest.com',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://prm-qa-us.myrecoverytest.com/auth/v1/auth0/logout/?redirect={{redirect}}'
        }
    },
    development: {
        gb: {
            domain: 'https://auth-qa.uk.msk.ai',
            clientId: 'T05t3O4hL7niQjgSIHiy8wZmfm7DSnxq',
            audience: 'https://prm-qa-gb.myrecoverytest.com',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://prm-qa-gb.myrecoverytest.com/auth/v1/auth0/logout/?redirect={{redirect}}'
        },
        us: {
            domain: 'https://us.auth.qa.myrecovery.ai',
            clientId: '6VzSQOMACrCBUYaJtxU4CHBDVAXGwYny',
            audience: 'https://prm-qa-us.myrecoverytest.com',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://prm-qa-us.myrecoverytest.com/auth/v1/auth0/logout/?redirect={{redirect}}'
        }
    },
    production: {
        gb: {
            domain: 'https://auth.uk.myrecovery.ai',
            clientId: '6MErX7Ay7QGsRD8n3ugGzUUWTeZvHoQF',
            audience: 'https://gb.api.myrecovery.ai',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://gb.api.myrecovery.ai/auth/v1/auth0/logout/?redirect={{redirect}}'
        },
        us: {
            domain: 'https://auth.us.myrecovery.ai',
            clientId: 'JERY4LurQKiBQgW6SMxhGDqSTtKYXY6k',
            audience: 'https://us.api.myrecovery.ai',
            scope: 'dash client',
            redirectUri: '{{baseUrl}}/auth?region={{region}}',
            logoutUri: 'https://us.api.myrecovery.ai/auth/v1/auth0/logout/?redirect={{redirect}}'
        }
    }
}

export default Object.freeze(Auth0Config)
