import Logging from '@serv/Logging'

/**
 * Static functions used by web survey JS interface.
 */
class Core {
    static printNative(text) {
        Logging.log(`JS Core: ${text}`)
    }
}

export default Core
