import Locale from '@serv/Locale.js'
import Logging from '@serv/Logging'
import moment from 'moment/moment'
import Patient from '@model/Patient'
import StringHelper from '@serv/StringHelper'
import Utils from '@serv/Utils'

function resolveColumnFromMilestoneDate(column, date, isGoddard) {
    let explicitValue
    if (date) {
        column.moment = moment(date)
        column.cellText = column.moment.format(Utils.readableDateFormat)
        explicitValue = 1
    } else {
        if (isGoddard) {
            // For columns like "Procedure date" to display "Waiting list" when operation date isn't set
            column.cellText = Locale.getLanguageItemOrUndefined(`${column.label}Null`) || ''
        }

        explicitValue = 0
    }
    column.setExplicitValueConfig(explicitValue)
}
export function _resolveObjectListColumnOfType_milestone(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.milestone can only be used on objects of types: Patient`)

        return
    }
    const milestone = row.firstJourney.getMilestoneOfSlug(column.milestoneSlug)
    const milestoneDate = milestone ? milestone.date : undefined
    resolveColumnFromMilestoneDate(column, milestoneDate)
}
export function _resolvePatientJourneyListColumnOfType_milestone(column, patientJourney, row, errors, isGoddard) {
    let value
    if (isGoddard) {
        value = row[column.id].date
    } else {
        const property = StringHelper.slugToCamelCase(`${column.milestoneSlug}-date`)
        value = row[property]
    }
    resolveColumnFromMilestoneDate(column, value, isGoddard)
}
