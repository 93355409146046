<template>
    <span
        :class="['alert-counter', alertBg, alertSize]"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >
        <span class="alert-counter__content">{{ formatted }}</span>
        <div
            v-if="hover && hoverText"
            class="alert-counter--hover"
        >
            {{ hoverText }}
        </div>
    </span>
</template>

<script>
export default {
    name: 'AlertCounter',
    props: {
        content: {
            type: [Number, String],
            default: '!'
        },
        // eslint-disable-next-line vue/require-default-prop
        maxValue: {
            type: Number
        },
        isGreyIfZero: {
            type: Boolean,
            default: false
        },
        hoverText: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: '',
            validator: value => ['', 'small'].indexOf(value) !== -1
        }
    },
    data() {
        return {
            hover: false
        }
    },
    computed: {
        formatted() {
            const total = +this.content

            if (isNaN(total)) {
                return this.content
            }

            return total > this.maxValue ? `${this.maxValue}+` : this.content
        },
        alertBg() {
            const total = +this.content
            if (total == 0 && this.isGreyIfZero) {
                return 'alert-counter--grey'
            }

            return ''
        },
        alertSize() {
            if (this.size) {
                return `alert-counter--${this.size}`
            }

            return ''
        }
    }
}
</script>
