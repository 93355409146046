import ListTransform from '@model/list/ListTransform'

class ListTransformReferrals extends ListTransform {
    constructor(object) {
        object.filterKey = 'referrals'
        super(object)
    }

    getQueryParamValueFromKeyStrings(keyStrings) {
        return keyStrings
    }

    getSortParameter() {
        return 'referrals'
    }
}

export default ListTransformReferrals
