import Logging from '@serv/Logging'
import Patient from '@model/Patient'
export function _resolveObjectListColumnOfType_steps(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.steps can only be used on objects of type: Patient`)

        return
    }
    let explicitValue = 2
    if (!row.isRegistered) {
        explicitValue = 0
    } else if (row.hasSteps) {
        explicitValue = 1
    }
    column.setExplicitValueConfig(explicitValue)
}
export function _resolvePatientJourneyListColumnOfType_steps(column, patientJourney, row) {
    const hasSteps = row.hasSteps == undefined ? 2 : row.hasSteps
    column.setExplicitValueConfig(hasSteps)
}

export function _resolvePatientJourneyListColumnOfType_hasSteps(column, patientJourney, row) {
    const hasSteps = row[column.id]?.value

    column.setExplicitValueConfig(hasSteps)
}
