import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'

class ListTransformHasRtm extends ListTransform {
    constructor(object) {
        super(object)
        if (object.filterSetKey == 'patientJourneyList') {
            this.filterKey = 'hasRtm'
        }
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: [
                {
                    value: true,
                    label: Locale.getLanguageItem('patientListFilterRtm')
                },
                {
                    value: false,
                    label: Locale.getLanguageItem('patientListFilterNonRtm')
                }
            ],
            addAll: true
        }

        return config
    }

    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        const filterValue = !!keyStrings[0]

        return isGoddard ? { values: [filterValue] } : filterValue
    }
}

export default ListTransformHasRtm
