<template>
    <div class="ui stackable doubling equal width centered grid">
        <div
            class="column four wide widescreen four wide large screen four wide computer two wide tablet hidden mobile"
        />

        <div
            class="column four wide widescreen four wide large screen four wide computer eight wide tablet twelve wide mobile"
        >
            <div
                v-if="content"
                class="support"
            >
                <div class="image-wrapper">
                    <img
                        class="support-image"
                        :src="getImageUrl(content.image)"
                        alt
                    />
                </div>
                <div class="header">
                    {{ getLangItem(content.header) }}
                </div>
                <div class="subheader">
                    {{ getSubheader }}
                </div>
                <div class="support-text">
                    {{ getTextPara }}
                </div>
                <div
                    v-if="content.buttons"
                    class="buttons"
                >
                    <div v-if="supportType == 'unsubscribe'">
                        <a
                            v-for="(button, key) in content.buttons"
                            :key="key"
                            class="ui button primary"
                            @click="updatePage(button.text)"
                        >
                            {{ getLangItem(button.text) }}
                        </a>
                    </div>
                    <div v-else>
                        <a
                            v-for="(button, key) in content.buttons"
                            :key="key"
                            :href="button.link"
                            class="ui button primary cta"
                        >
                            {{ getLangItem(button.text) }}
                        </a>
                    </div>
                </div>
                <p class="support-text botspace">
                    <span :v-html="getTextPara2" />
                </p>
            </div>
        </div>
        <div
            class="column four wide widescreen four wide large screen four wide computer two wide tablet hidden mobile"
        />
    </div>
</template>

<script>
import Locale from '@serv/Locale'
import SupportService from '@serv/SupportService'
import useImage from '@composables/useImage'
import '@images/support-lady-at-comp.png'

export default {
    name: 'MrSupport',
    setup() {
        const { getImageUrl } = useImage()

        return {
            getImageUrl
        }
    },
    emits: ['select'],
    // eslint-disable-next-line vue/require-prop-types
    props: ['supportType'],
    inject: ['$config'],
    data() {
        return {
            content: undefined
        }
    },
    computed: {
        // These getters use getLanguageItemOrUndefined(), whereas the 'lang' filter simply returns getLanguageItem()
        getSubheader() {
            return Locale.getLanguageItemOrUndefined(this.content.subheader)
        },
        getTextPara() {
            return Locale.getLanguageItemOrUndefined(this.content.paragraph)
        },
        getTextPara2() {
            return Locale.getLanguageItemOrUndefined(this.content.paragraphTwo)
        }
    },
    methods: {
        updatePage(buttonStringKey) {
            this.$emit('select', buttonStringKey)
        },
        updateContent() {
            this.content = SupportService.getSupportContent(this.supportType)
        }
    },
    beforeCreate() {
        document.body.className = 'mobile'
    },
    mounted() {
        this.updateContent()
    },
    watch: {
        supportType() {
            this.updateContent()
        }
    }
}
</script>

<style lang="scss">
@import '../styles/colors';

.ui.stackable.doubling {
    background: $white;

    .support {
        text-align: center;

        img {
            width: 192px;
            padding-top: 70px;
        }

        .header {
            color: $brandB;
            padding-top: 24px;
            padding-bottom: 24px;
            font-size: 28px;
            font-family: Omnes Medium;
        }

        .subheader {
            color: $mediumGrey;
            padding-top: 12px;
            padding-bottom: 12px;
            font-size: 20px;
            line-height: 24px;
            white-space: pre-wrap;
        }

        .support-text {
            color: $darkGrey;
            width: 280px;
            margin: 22px auto 16px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            white-space: pre-wrap;
        }

        .botspace {
            padding-bottom: 220px;
        }

        .buttons {
            a {
                margin-left: auto !important;
                margin-right: auto !important;
                margin-top: 24px !important;
            }

            a.ui.button.primary {
                margin-left: 12px !important;
                margin-right: 2px !important;
            }

            a.ui.button.primary.cta {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
}
</style>
