<template>
    <ValidationProvider
        v-slot="{ errors, meta, field }"
        :rules="rules"
        :name="name"
        mode="lazy"
        :model-value="modelValue"
        @update:model-value="$emit('update:modelValue', $event)"
    >
        <div
            class="validated-input-wrapper"
            :class="fieldWrapperClass"
        >
            <div class="veevalidated-input">
                <slot name="label">
                    <div class="label-section">
                        <label v-if="label">
                            {{ label }}
                            <small v-if="rules && rules.includes('required')">*</small>
                        </label>
                        <slot name="error">
                            <div
                                v-if="0 < errors.length"
                                class="invalid-feedback"
                            >
                                {{ errors[0] }}
                            </div>
                        </slot>
                    </div>
                </slot>
                <div :class="[{ focused: focused }, { 'has-label': label || $slots.label }]">
                    <slot>
                        <v-tooltip
                            v-if="hoverText"
                            :text="hoverText"
                            location="top"
                        >
                            <template #activator="{ props }">
                                <div
                                    v-bind="{ ...props }"
                                    class="tooltip-wrapper"
                                >
                                    <input
                                        v-bind="{ ...$attrs, ...field }"
                                        :valid="meta.valid"
                                        class="form-control"
                                        :class="inputClass(meta)"
                                        @blur="onBlur"
                                        @focus="onFocus"
                                    />
                                </div>
                            </template>
                        </v-tooltip>

                        <input
                            v-else
                            v-bind="{ ...$attrs, ...field }"
                            :valid="meta.valid"
                            class="form-control"
                            :class="inputClass(meta)"
                            @blur="onBlur"
                            @focus="onFocus"
                        />
                    </slot>
                    <slot name="info-block" />
                </div>
                <slot name="success">
                    <div
                        v-if="meta.valid && meta.touched && successMessage"
                        class="valid-feedback"
                    >
                        {{ successMessage }}
                    </div>
                </slot>
            </div>
        </div>
    </ValidationProvider>
</template>

<script>

export default {
    inheritAttrs: false,
    name: 'ValidatedInput',
    emits: ['blur', 'focus', 'update:modelValue'],
    props: {
        /**
         * Consider what needs to be a prop and what can be an attribute.  Data like maxlength, autocomplete, required, disabled,
         * autofocus, placeholder, type ... are all just attributes of the input field.
         *
         * Everything else is a prop.
         */
        name: {
            type: String,
            required: true
        },
        // eslint-disable-next-line vue/require-default-prop
        label: {
            type: String,
            description: 'Input label (text before input)'
        },
        // eslint-disable-next-line vue/require-default-prop
        error: {
            type: String,
            description: 'Input error (below input)'
        },
        successMessage: {
            type: String,
            description: 'Input success message',
            default: ''
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: {
            type: [String, Number],
            description: 'Input value'
        },
        // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
        rules: {
            description: 'Vee validate validation rules'
        },
        /*
            fieldWrapperClass is typically mr-form__field.  new version of veevalidate no longer creates a wrapping component
            but as our CSS depends on a "mr-form__field -> veevalidate-input -> input" hierarchy we have to stuff one in above
         */
        // eslint-disable-next-line vue/require-default-prop
        fieldWrapperClass: {
            type: [String, Object]
        },
        hoverText: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            focused: false
        }
    },
    methods: {
        onFocus(evt) {
            this.focused = true
            this.$emit('focus', evt)
        },
        onBlur(evt) {
            this.focused = false
            this.$emit('blur', evt)
        },
        inputClass(meta) {
            return {
                'is-valid border-success': meta.valid && meta.touched && this.successMessage,
                'is-invalid border-error': !meta.valid && meta.touched
            }
        }
    }
}
</script>

<style lang="scss">
.veevalidated-input {
    margin-bottom: 16px;

    .label-section {
        margin: 0 0 16px;
        color: #79829b;
        font-size: 16px;
        font-weight: 400;
        text-transform: none;
    }

    input {
        width: 100%;
        min-height: 48px;
    }

    .checkbox {
        margin-left: 6px;
        padding-right: 20px;
        width: 16px;
        min-height: 20px;
        position: fixed;
    }
}

.field .veevalidated-input input {
    padding: 12px;
}
</style>
