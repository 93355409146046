<template>
    <v-col
        class="patient-surveys-listview"
        xs="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
        :class="{ separator: !isPhone }"
    >
        <!-- Clinician banner -->
        <ClinicianBanner
            v-if="!isPhone && getClinicianBannerMediaImage"
            :banner-media-image="getClinicianBannerMediaImage"
            :logo-media-image="getClinicianLogoMediaImage"
        />
        <div
            v-for="(patientJourneySurveys, i) in patientJourneysSurveys"
            :key="`pjs-${i}`"
        >
            <!-- PJ title -->
            <div class="patient-surveys-listview__journey-title">
                {{ getPatientJourneyTitle(patientJourneySurveys) }}
            </div>
            <div class="patient-surveys-listview__journey-subtitle">
                {{ getPatientJourneySubtitle(patientJourneySurveys) }}
            </div>
            <!-- Surveys list -->
            <SurveysListViewRow
                v-for="(event, j) in patientJourneySurveys.scheduleEvents"
                :key="`wsa-${j}`"
                :patient-journey-id="patientJourneySurveys.patientJourneyId"
                :schedule-event="event"
                :delegate="getDelegate"
            />
        </div>

        <div
            v-if="!isPhone"
            class="patient-surveys__logout"
        >
            <a
                class="logout-link"
                @click="onLogout"
            >
                <img :src="getIconUrl('nav-side-bar-logout.svg')" />
                <span>{{ getLangItem('accountLogOut') }}</span>
            </a>
        </div>
    </v-col>
</template>

<script>
import ClinicianBanner from '@comp/ClinicianBanner.vue'
import ConfigManager from '@config/ConfigManager'
import ContentService from '@serv/ContentService'
import Locale from '@serv/Locale'
import { mapGetters } from 'vuex'
import moment from 'moment'
import Redirect from '@serv/Redirect'
import RegistrationService from '@serv/RegistrationService'
import SurveysListViewRow from '@comp/websurvey/SurveysListViewRow.vue'
import SurveysMixin from '@mixins/SurveysMixin'
import useImage from '@composables/useImage'
import Utils from '@serv/Utils'

export default {
    name: 'SurveysListView',
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    mixins: [SurveysMixin],
    components: {
        ClinicianBanner,
        SurveysListViewRow
    },
    computed: {
        ...mapGetters([
            'journeys',
            'patientJourneys',
            'patientJourneysSurveys',
            'surveys',
            'user'
        ]),
        getDelegate() {
            return this
        },
        hasMultiplePatientJourneys() {
            return this.user.pathwayJourneys.length > 1
        }
    },
    methods: {
        getPatientJourneyTitle(patientJourneySurveys) {
            const patientJourney = this.patientJourneys[
                patientJourneySurveys.patientJourneyId
            ]
            const journey = this.journeys[patientJourney.journeySlug]

            return journey.title
        },
        getPatientJourneySubtitle(patientJourneySurveys) {
            if (!this.hasMultiplePatientJourneys) {
                return
            }
            const patientJourney = this.patientJourneys[
                patientJourneySurveys.patientJourneyId
                ]
            let parts = []
            if (patientJourney.side == 'left' || patientJourney.side == 'right') {
                parts.push(Locale.getLanguageItem(patientJourney.side))
            }
            let date = patientJourney.primaryMilestoneDate
            if (date) {
                parts.push(moment(date).format(Utils.readableDateFormat))
            }

            return parts.join(', ')
        },
        getActivitySurveySummary(activity) {
            const survey = this.surveys[activity.contentSlug]
            if (ConfigManager.devConfig.patientSurveysRandomAccess) {
                return survey.slug
            }
            const surveyTitle = ContentService.sanitiseText(survey.title, this.patient)
            const numSteps = survey.questionStepSlugs.length
            const questionsSummary =
                numSteps == 1
                    ? Locale.getLanguageItem('patientSurveyQuestion')
                    : Locale.getLanguageItem('patientSurveyQuestions', [numSteps])

            return `${surveyTitle} (${questionsSummary})`
        },
        // Start the selected survey immediately
        onClickActivityRow(patientJourneyId, scheduleEvent) {
            this.$store.commit('setDidSelectSingleSurvey', true)
            const activity = scheduleEvent.activity
            Redirect.gotoPath(`patient-web-surveys/${patientJourneyId}/${activity.slug}/`)
        },
        onLogout() {
            RegistrationService.logoutAndRedirect()
        }
    }
}
</script>
