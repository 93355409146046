/**
 * Helper class for storing a list of ScheduleEvents related to a specific PatientJourney.
 */
class PatientJourneyWebSurveys {
    constructor(object) {
        this.patientJourneyId = object.patientJourneyId
        // Array of ScheduleEvents where there is a SurveyResult to create/update
        this.scheduleEvents = object.scheduleEvents.sort((a, b) => a.activity.order - b.activity.order)
    }
}

export default PatientJourneyWebSurveys
