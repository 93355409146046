import Config from '@serv/Config'
import Logging from '@serv/Logging'

/**
 * Cycle a request through all available countries for the environment, to find out which
 * country the request belongs to. Use this country to configure Config and save the
 * country to Storage so it's preserved across sessions.
 */

class RequestCycler {
    /**
     * Cycle a request through all available countries.
     * @param {Promise} request - The request to cycle.
     * @return {Promise} - The cycled request.
     */

    cycleRequest(request) {
        if (Config.isConfigured) {
            Logging.log('RequestCycle: No need to cycle request, the app is already configured')

            return request(Config.base_url, Config.country, Config.oauth_client, Config.oauth_secret)
        }

        let promises = Config.countries.map(country => {
            const config = Config.getCountryOptions(country)
            if (!config) {
                return
            }
            let promise = request(config.base_url, country, config.oauth_client, config.oauth_secret).then(
                response => {
                    Logging.info('Request cycle succeeded for:', country)
                    Config.setCountry(country)

                    return response
                },
                err => {
                    // This throw is essential - otherwise the error callback in Login.vue will never be called.
                    Logging.warn('Request cycle failed for:', country)
                    throw err
                }
            )

            return promise
        })

        return Promise.any(promises.filter(Boolean))
    }
}

export default new RequestCycler()
