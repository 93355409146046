import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import ResourceService from '@serv/ResourceService'

class ListTransformRtmUser extends ListTransform {
    constructor(object) {
        object.filterKey = 'rtmClinicianId'
        super(object)
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const rtmProviders = ResourceService.getRtmProviders()
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: rtmProviders?.map(rtmProvider => {
                return { value: rtmProvider.value, label: rtmProvider.title }
            }),
            addAll: true,
            addNone: true
        }

        return config
    }

    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        const fakeLead = { firstName: 'Zzz', lastName: 'Zzz' }
        rows.sort((a, b) => {
            const valueA = a.listColumns[columnIndex].rtmUser || fakeLead
            const valueB = b.listColumns[columnIndex].rtmUser || fakeLead

            return valueA.lastName.localeCompare(valueB.lastName) || valueA.firstName.localeCompare(valueB.firstName)
        })
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        if (keyStrings.length == 1) {
            if (keyStrings[0] == 'all') {
                return
            }

            if (keyStrings[0] == 'none') {
                return { exists: false }
            }
        }

        const values = [...Object.values(keyStrings)]

        return isGoddard ? { values } : values
    }
    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'rtm_user_name'
    }
}

export default ListTransformRtmUser
