import Locale from '@serv/Locale'
import Milestone from '@model/Milestone'
import Utils from '@serv/Utils'

/*
An appointment milestone object.
*/
class AppointmentMilestone extends Milestone {
    constructor(object) {
        object.type = Milestone.Type.appointment
        object.slug = object.slug || 'appointment'
        super(object)
        this.appointmentType = object.appointmentType
        this.notes = object.notes
        this.time = object.time
        this.clinicianId = object.clinicianId
        this.setting = object.setting
        this.acceptance = object.acceptance
        this.attendance = object.attendance
        this.referralId = object.referralId
        this.createdTime = object.createdTime
    }

    get subtypeKey() {
        return 'appointmentType'
    }

    // Return a summary of the form '{type} ({date})', e.g. 'Physio (3 Feb 2022)'
    get summary() {
        const typeLabel = Locale.getLanguageItemForModelEnum('appointmentType', this.appointmentType)

        return `${typeLabel} (${this.moment.format(Utils.readableDateFormat)})`
    }
}

/**
 * All AppointmentMilestone appointment types.
 */
AppointmentMilestone.AppointmentType = {
    default: 'default',
    jhubCourse: 'jhubCourse',
    jhubEri: 'jhubEri',
    jhubPhysio: 'jhubPhysio'
}

export default AppointmentMilestone
