import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'

/**
 * List filter for boolean column.
 * If the column spec defines property dateMilestone, the boolean value is expected to represent
 * the existence (or not) of a PJM with that slug.
 */
class ListTransformBoolean extends ListTransform {
    constructor(object) {
        super(object)
        if (object.filterSetKey == 'patientJourneyList' && this.column.dateMilestone) {
            this.filterKey = this.column.dateMilestone
        }
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: [
                {
                    value: 'yes',
                    label: Locale.getLanguageItem('yes')
                },
                {
                    value: 'no',
                    label: Locale.getLanguageItem('no')
                }
            ],
            addAll: true
        }

        return config
    }

    /**
     * Sort the list of rows in place.
     */
    sortRows(rows) {
        rows.sort((a, b) => {
            const columnIndex = this.column.columnIndex
            const valueA = a.listColumns[columnIndex].boolean ? 1 : 0
            const valueB = b.listColumns[columnIndex].boolean ? 1 : 0

            return valueA - valueB
        })
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        if (this.column.dateMilestone) {
            // Sorting by date is equivalent to sorting by boolean existence
            return `${this.column.dateMilestone}_date`
        }
    }

    /**
     * Filter and return the list of rows, based on input strings.
     * The string is the dashstring key corresponding to all, before date, after date.
     */
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const filtered = rows.filter(row => {
            const columnIndex = this.column.columnIndex
            const value = row.listColumns[columnIndex].boolean

            return (value == true && filterStrings.includes('yes')) || (value == false && filterStrings.includes('no'))
        })

        return filtered
    }
    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        if (keyStrings.length > 0) {
            const value = keyStrings[0] == 'yes'

            if (isGoddard) {
                return {
                    values: [value]
                }
            }

            if (this.column.dateMilestone) {
                return {
                    exists: value
                }
            }
        }
    }
}

export default ListTransformBoolean
