import ListService from '@serv/ListService'
import store from '@/store'

import User from '@model/User'

class Clinician extends User {
    constructor(object) {
        super(object)

        this.journeySlugs = object.journeySlugs || []
        this.providerSlugs = object.providerSlugs || []
        this.departmentSlugs = [] // populated in resolveClinicians()

        this.hasChat = object.hasChat

        this.slugPrefix = object.slugPrefix
        this.personaUid = `${this.region}-${this.personaId}`
        this.isTransferTarget = !!object.isTransferTarget
        this.isRtmProvider = !!object.isRtmProvider
    }

    get adminListProperties() {
        let properties = `isRegistered: ${this.isRegistered}`
        if (this.phone) {
            properties += `, phone: ${this.phone}`
        }
        if (this.slugPrefix) {
            properties += `, slugPrefix: ${this.slugPrefix}`
        }
        properties += `, providers: ${this.providerSlugs}`
        // properties += `, departments: ${this.departments}`

        return properties
    }

    /**
     * Rebuild this.listColumns.
     * We only do this if they already exist - otherwise the startup flow will do it anyway.
     */
    rebuildListColumns() {
        if (this.listColumns) {
            const clinicianListColumns = ListService.getClinicianListColumns()
            const listColumns = ListService.getListColumnsResolvedForRow(clinicianListColumns, this)
            store.commit('setClinicianListColumns', {
                personaId: this.personaId,
                data: listColumns
            })
        }
    }
}

export default Clinician
