import ClinicalMilestone from '@model/ClinicalMilestone'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'

/**
 * A ListColumn object.
 * This defines the type of the column, the label, and other metadata.
 */
class ListColumn {
    constructor(object) {
        Object.keys(object).forEach(key => (this[key] = object[key]))

        // Validation
        if (this.clinicalMilestoneType == ClinicalMilestone.Type.survey) {
            if (!this.contentTag && !this.contentSlug && !this.contentSlugs && !this.reportProperty) {
                Logging.error(
                    `ListColumn of type clinicalMilestone does not specify contentTag, contentSlug, contentSlugs or reportProperty: ${JSON.stringify(
                        object
                    )}`
                )
            }
            if (!this.scheduleSlug && (this.contentTag || this.contentSlug || this.contentSlugs)) {
                Logging.error(
                    `ListColumn of type clinicalMilestone does not specify scheduleSlug: ${JSON.stringify(object)}`
                )
            }
        }
        this.clinicalMilestone = object.clinicalMilestone // if type == ListColumn.Type.clinicalMilestone
        this.dateMilestone = object.dateMilestone // if type == ListColumn.Type.date

        // This is used by Vuetify data-table @TODO: could be put within template instead of hardcoding
        this.value = object.headerValue || (object.columnIndex || 0).toString()

        if (object.label && !object.text) {
            this.text = Locale.getLanguageItem(object.label)
            this.title = Locale.getLanguageItem(object.label)
        }
        this.filter = object.filter
        this.filterable = false // v-data-table property, must be false or it expects 'filter' to be a function
    }

    // Based on column type, return CSS width classes
    get cssWidthClasses() {
        const typeToWidth = {
            name: 2, // first ClinicianList column
            procedure: 2 // first PatientList column
        }
        // Default to 1, unless in map above
        const width = typeToWidth[this.type] || 1

        return `custom-grid custom-grid--${width}`
    }

    /**
     * Return true if a column of type clinicalMilestone specifies an "explicit value". This means it specifies one of:
     * - valueScoreSection
     * In this case, the column text, icon, icon colour, subtitle and hover text all come from explicit column
     * configuration mappings of the "value".
     */
    get hasExplicitValue() {
        return this.valueScoreSection || this.valueTextMap || this.valueIconMap || this.valueFilterOptions
    }

    /**
     * Usually called from ListService when configuring ListColumn clones for a specific patient row.
     * If the column config specifies things like valueIconMap, use these to calculate properties such as
     * iconFilename, etc.
     *
     * This kind of explicit configuration is supported across multiple ListColumn types including clinicalMilestone,
     * keyValue, patientJourneyKeyValue.
     *
     * If a context object is provided, this is referenced only if the explicitValue is undefined. It is used to
     * calculate special config map keys, for example if a SurveyResult is not completed but within schedule, or
     * the schedule has expired.
     */
    setExplicitValueConfig(explicitValue, context) {
        if (explicitValue == undefined) {
            explicitValue = 'none'

            // Consider using special map values, based on context
            if (!context) {
                return
            }
            if (context.surveyResult) {
                if (context.surveyResult.status == 'partial') {
                    explicitValue = 'surveyResultPartial'
                }
            } else if (context.endMoment) {
                // No SurveyResult
                const nowMoment = new moment()
                if (nowMoment.diff(context.endMoment, 'days') <= 0) {
                    // Schedule end date NOT in the past
                    explicitValue = 'scheduleNotExpired'
                } else {
                    explicitValue = 'scheduleExpired'
                }
            }
        }
        if (this.valueIconMap) {
            this.iconFilename = this.valueIconMap[explicitValue]
        }
        if (this.valueCssFilterMap) {
            this.cssFilter = this.valueCssFilterMap[explicitValue]
        }
        if (this.valueTextMap) {
            const stringKey = this.valueTextMap[explicitValue]
            if (stringKey) {
                this.cellText = Locale.getLanguageItem(stringKey)
            }
        }
        if (this.valueSubtitleMap) {
            const stringKey = this.valueSubtitleMap[explicitValue]
            if (stringKey) {
                this.cellSubtitleText = Locale.getLanguageItem(stringKey)
            }
        }
        if (this.valueHoverMap) {
            const stringKey = this.valueHoverMap[explicitValue]
            if (stringKey) {
                this.cellHoverText = Locale.getLanguageItem(stringKey)
            }
        }
        // To support sorting
        if (this.valueNumberMap) {
            this.number = this.valueNumberMap[explicitValue]
        }
        if (this.filter) {
            const filterOptions = this.valueFilterOptions
            if (filterOptions) {
                // Set filterValue for the purpose of filtering
                for (const key of Object.keys(filterOptions)) {
                    const values = filterOptions[key]
                    if (values.includes(explicitValue)) {
                        this.filterValue = key
                        break
                    }
                }
            }
        }
    }

    getExplicitValueFilterConfig() {
        const filterOptions = this.valueFilterOptions
        if (!filterOptions) {
            return {}
        }
        const valueLabels = Object.keys(filterOptions).map(key => {
            return {
                value: key,
                label: Locale.getLanguageItem(key)
            }
        })
        this.sortValueLabels(valueLabels)
        const config = {
            title: Locale.getLanguageItem(this.label),
            valueLabels: valueLabels,
            addAll: true
        }

        return config
    }

    /**
     * This is a temporary solution to resolve a mutating props error in the DataFilterString component.
     * FilterDefaultValue is being explicitly set to undefined when an "All" filter is selected to "prevent" the
     * filter being reset to the default value when navigating away from the journey.  A better solution should be
     * to see why the app is not recognising "All" as a valid filter
     *
     * @param filterDefaultValue
     */
    setFilterDefaultValue(filterDefaultValue) {
        this.filterDefaultValue = filterDefaultValue
    }

    // Get sorted value labels, based on any column config sort type
    sortValueLabels(valueLabels) {
        if (this.filterSorting != 'none') {
            valueLabels.sort((a, b) => a.label.localeCompare(b.label))
        }

        return valueLabels
    }
}

/**
 * All ListColumn types.
 */
ListColumn.Type = {
    appointments: 'appointments',
    boolean: 'boolean',
    bundleEndDate: 'bundleEndDate',
    careNavigatorUser: 'careNavigatorUser',
    carePeriodEndDate: 'carePeriodEndDate',
    carePeriodFacility: 'carePeriodFacility',
    carePeriodPac: 'carePeriodPac',
    carePeriodPlannedEndDate: 'carePeriodPlannedEndDate',
    carePeriodStartDate: 'carePeriodStartDate',
    carePeriodStatus: 'carePeriodStatus',
    clinicalMilestone: 'clinicalMilestone',
    consent: 'consent',
    date: 'date',
    dateDifference: 'dateDifference',
    department: 'department',
    grmaStocPreOpEducationAppt: 'grmaStocPreOpEducationAppt',
    hasRtm: 'hasRtm',
    hasSteps: 'hasSteps',
    hopcoClinicalCheck: 'hopcoClinicalCheck',
    hopcoMultiSurveyRag: 'hopcoMultiSurveyRag',
    jhubMilestoneToAt: 'jhubMilestoneToAt',
    keyValue: 'keyValue',
    lastActive: 'lastActive',
    messages: 'messages',
    milestone: 'milestone',
    milestoneAcceptance: 'milestoneAcceptance',
    milestoneDate: 'milestoneDate',
    milestoneExists: 'milestoneExists',
    milestoneService: 'milestoneService',
    milestoneToClinician: 'milestoneToClinician',
    milestoneToTeam: 'milestoneToTeam',
    name: 'name',
    number: 'number',
    patientJourney: 'patientJourney',
    patientJourneyKeyValue: 'patientJourneyKeyValue',
    properties: 'properties',
    procedure: 'procedure',
    provider: 'provider',
    ptUser: 'ptUser',
    ptVirtualUser: 'ptVirtualUser',
    referees: 'referees', // NO LONGER USED?
    referrals: 'referrals',
    region: 'region',
    rtmDataReview: 'rtmDataReview',
    rtmReviewReminder: 'rtmReviewReminder', // NO LONGER USED?
    rtmScheduleEnd: 'rtmScheduleEnd', // NO LONGER USED?
    rtmStatus: 'rtmStatus',
    rtmUser: 'rtmUser',
    steps: 'steps',
    string: 'string',
    surveyResult: 'surveyResult',
    surveyStatus: 'surveyStatus',
    surveysCompleted: 'surveysCompleted',
    surveysStatus: 'surveysStatus',
    taskCount: 'taskCount',
    tasks: 'tasks',
    teamLead: 'teamLead',
    teamMembers: 'teamMembers',
    unreadMessageCount: 'unreadMessageCount',
    videosCompleted: 'videosCompleted',
    // TODO: After Dash 5.2.0 goes live rtmTasks and careNavigatorTasks can be removed
    careNavigatorTasks: 'careNavigatorTasks',
    rtmTasks: 'rtmTasks'
}

/**
 * Map of ListColumn types to default relative widths.
 * These can be overriden in the JSON config, using 'width'
 */
ListColumn.typeWidth = {
    appointments: 3.0,
    boolean: 1.0,
    clinicalMilestone: 1.0,
    consent: 0.5,
    department: 1.0,
    date: 1.0,
    grmaStocPreOpEducationAppt: 1.0,
    keyValue: 2.0,
    messages: 1.0,
    name: 1.0,
    number: 1.0,
    patientJourney: 2.0,
    patientJourneyKeyValue: 1.0,
    procedure: 0.5,
    provider: 1.0,
    referees: 1.0,
    region: 1.0,
    steps: 1.0,
    string: 1.0,
    surveysStatus: 2.0,
    teamLead: 1.0,
    teamMembers: 2.0
}

/**
 * All ListColumn qualifiers. These can be used, for example, to find a unique date from an array.
 */
ListColumn.Qualifier = {
    earliest: 'earliest',
    latest: 'latest'
}

export default ListColumn
