<template>
    <div class="container-progressbar">
        <ul
            v-if="totalSteps < 13"
            class="progressbar"
        >
            <li
                v-for="(step, i) in totalSteps"
                :key="i"
                :class="{ active: isStepActive(i) }"
                :style="{ width: sizeControl }"
            />
        </ul>
        <v-app
            v-else
            class="slider"
        >
            <v-progress-linear v-model="percentageActive" />
        </v-app>
    </div>
</template>

<script>
import SurveysMixin from '@mixins/SurveysMixin'

export default {
    name: 'ProgressBar',
    mixins: [SurveysMixin],
    computed: {
        sizeControl() {
            return `${100 / this.totalSteps}%`
        },
        numberStepsActive() {
            return this.taskHandler.stepIndex + 1
        },
        percentageActive() {
            return (100 / this.totalSteps) * this.numberStepsActive
        },
        totalSteps() {
            return this.taskHandler.lastStepIndex + 1
        }
    },
    methods: {
        isStepActive(i) {
            return this.numberStepsActive > i
        }
    }
}
</script>
