import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import store from '@/store'

export default function _resolveObjectListColumnOfType_milestoneToClinician(column, row) {
    if (!(row instanceof Milestone)) {
        Logging.error(`ListColumn.Type.milestoneToClinician can only be used on objects of type: Milestone`)

        return
    }
    const milestone = row
    let user

    if (milestone.type == Milestone.Type.referral && milestone.referredToId) {
        user = store.state.user.users[milestone.referredToId]
    } else if (milestone.type == Milestone.Type.appointment && milestone.clinicianId) {
        user = store.state.user.users[milestone.clinicianId]
    }

    if (user) {
        column.cellText = user.titledFullName
        column.user = user
    }
}
