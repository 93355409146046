import { auth0 } from '@/plugins/auth0'
import Auth0Config from '@config/Auth0Config'
import axios from 'axios'
import Config from '@serv/Config'
import Logging from '@serv/Logging'
import Storage from '@serv/Storage'

class Auth0 {
    constructor() {
        this.region = undefined
        this.organization = undefined
    }

    get clientOptions() {
        const env = Config.environment
        const auth0Config = Auth0Config[env][this.region] || {}

        auth0Config.redirectUri = auth0Config.redirectUri
            .replace('{{baseUrl}}', window.location.origin)
            .replace('{{region}}', this.region)

        if (this.organization) {
            auth0Config.redirectUri = `${auth0Config.redirectUri}&org=${this.organization}`
        }

        const options = {
            redirect_uri: auth0Config.redirectUri,
            audience: auth0Config.audience,
            scope: auth0Config.scope
        }

        if (this.organization) {
            options.organization = this.organization
        }

        return options
    }

    reset() {
        delete axios.defaults.headers.common['Authorization']
        this.session = {}
        Object.keys(Storage.getStorage())
            .filter(key => key.startsWith('@@'))
            .concat(['mr_session', 'auth_source'])
            .forEach(item => {
                Storage.remove(item)
            })
    }

    revokeToken(region) {
        const env = Config.environment
        const auth0Config = Auth0Config[env][region]

        window.location.href = auth0Config.logoutUri.replace('{{redirect}}', encodeURIComponent(window.location.origin))
    }

    doSilentAuth({ organization, region }) {
        this.organization = organization
        this.region = region

        return new Promise((resolve, reject) => {
            auth0
                .getAccessTokenSilently({
                    authorizationParams: this.clientOptions
                })
                .then(() => {
                    const session = Storage.get('mr_session')
                    this._authCallback({ data: session })
                    Config.configure(this.region)
                    resolve(session)
                })
                .catch(error => {
                    Logging.error('Auth0: Cannot get token silently')
                    reject(error)
                })
        })
    }

    _authCallback(res) {
        this.session = res.data
        this.session.environment = Config.environment

        if (!this.session.access_token) {
            throw new Error('No access_token provided by the server')
        }

        Storage.set('mr_session', this.session)
        this._setHeader(this.session.access_token)
        Logging.log('Session ', this.session)

        return this.session
    }

    _setHeader(access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    }
}

export default new Auth0()
