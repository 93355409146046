<!-- Display simple loader spinner. -->
<!-- The parent component should use LoaderMixin, and display this component if isLoader==false. -->

<template>
    <div class="mr-loader-wrapper">
        <div class="ui active inline loader loader-large">
            <div
                v-if="isShowingPercentage"
                class="loader__percentage"
            >
                {{ percentage }} %
            </div>
        </div>
    </div>
</template>

<script>
/*
We attempt here to establish a standard pattern:
- isLoaded is false until ALL required data is loaded (report response, and any Vuex state).
- isEmpty is true only if isLoaded=true and the dataset is empty

Flow as follows:
- mounted() ...if surgeonId, requests the report with requestReport(), and stores data in this.dataset
- buildChartIfDataReady() updates the status of allLoaded, and builds the chart (from this.dataset) only if true
- watch reports() .... if not surgeonId, copies the required report dataset into this.dataset
- Any Vuex watchers simply call buildChartIfDataReady()
- Any global filter watchers simply store the filter state, e.g. in this.country, and call buildChartIfDataReady()
- buildChartIfDataReady() respects any global filters
*/
import { mapGetters } from 'vuex'

export default {
    name: 'MrLoader',
    props: {
        isShowingPercentage: { type: Boolean, required: false, default: false }
    },
    data: () => ({
        percentage: 0
    }),
    computed: {
        ...mapGetters(['downloadProgress'])
    },
    watch: {
        downloadProgress(newValue) {
            if (this.isShowingPercentage) {
                this.percentage = Math.floor(newValue)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/colors';
@import '../styles/fonts';

.loader__percentage {
    font-size: $veryLargeFont;
    color: $darkGrey;
    text-align: center;
    width: 170px;
    height: 170px;
    line-height: 164px;
}
</style>
