import ListTransform from '@model/list/ListTransform'

class ListTransformClinicalCheck extends ListTransform {
    constructor(object) {
        object.filterKey = 'hopcoClinicalCheckValue'
        super(object)
    }

    get getFilterConfig() {
        return this.column.getExplicitValueFilterConfig()
    }

    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        rows.sort((a, b) => {
            const valueA = a.listColumns[columnIndex].number || -1
            const valueB = b.listColumns[columnIndex].number || -1

            return valueA - valueB
        })
    }
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex

        return rows.filter(row => filterStrings.includes(row.listColumns[columnIndex].filterValue))
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        // Each keyString is 'patientClinicalCheckFilterGreen' etc.
        const values = keyStrings.reduce((values, string) => {
            const newValues = this.column.valueFilterOptions[string]

            return [...values, ...(newValues || [])]
        }, [])

        return isGoddard ? { values } : values
    }
    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'hopco_clinical_check_value'
    }
}

export default ListTransformClinicalCheck
