<template>
    <div class="parent-form">
        <slot
            :errors="errors"
            :meta="meta"
            :values="values"
        />
    </div>
</template>

<script setup>
import { computed, provide, ref } from 'vue'

const forms = ref([])
// This acts as a collector function that the child forms will use
// to pass their data to the forms array, you would then have an array containing
// all the reactive forms data which you can iterate on to construct the aggregated state
provide('registerForm', (form) => {
    const existingFormIndex = forms.value.findIndex(existingForm => existingForm.uid == form.uid)
    if (existingFormIndex >= 0) {
        forms.value[existingFormIndex] = form
    } else {
        forms.value.push(form)
    }
})

// Aggregates all errors, this assumes all fields have unique names
// if your fields have similair names, consider scoping them or appending a prefix to their name
const errors = computed(() => {
    return forms.value.reduce((acc, form) => {
        return { ...acc, ...form.errors }
    }, {})
})

// Aggregates all values, this assumes all fields have unique names
// if your fields have similair names, consider scoping them or appending a prefix to their name
const values = computed(() => {
    return forms.value.reduce((acc, form) => {
        return { ...acc, ...form.values }
    }, {})
})

// Aggregates all meta flags
const meta = computed(() => {
    return forms.value.reduce(
        (acc, form) => {
            return {
                valid: acc.valid && form.meta.valid,
                dirty: acc.dirty || form.meta.dirty,
                touched: acc.touched || form.meta.touched
            }
        },
        { valid: true, dirty: false, touched: false }
    )
})

// Validates all the fields in all forms
function validate() {
    return Promise.all(forms.value.map((f) => f.validate()))
}

// Resets all the fields in all forms
function resetForm() {
    return forms.value.map((f) => f.resetForm())
}


defineExpose({
    errors,
    validate,
    resetForm
})
</script>
