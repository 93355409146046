import store from '@/store'

export function _resolvePatientJourneyListColumnOfType_careNavigatorUser(column, patientJourney, row) {
    const user = store.state.user.users[row.careNavigatorClinicianId]

    if (user) {
        column.careNavigatorUser = user
        column.cellText = user.titledFullName
    }
}
