import _ from 'lodash'
import ListTransform from '@model/list/ListTransform'
import Locale from '@serv/Locale'
import store from '@src/store/index'
import User from '@model/User'

/**
 * List transform for team lead column.
 */
class ListTransformTeamLead extends ListTransform {
    constructor(object) {
        object.filterKey = 'leadName'
        super(object)
    }

    get getFilterComponent() {
        return 'DataFilterString'
    }

    get getFilterConfig() {
        const teams = Object.values(store.state.user.teams).filter(team => !team.isLegacy)
        let teamLeads = [...new Set(teams.map(team => store.state.user.users[team.leadId]).filter(user => user))]
        teamLeads = _.orderBy(teamLeads, ['lastName', 'firstName'])
        const teamLeadTitles = teamLeads.map(teamLead =>
            this.column.hideRole ? teamLead.titledFullName : teamLead.titledFullNameWithRole
        )
        const config = {
            title: Locale.getLanguageItem(this.column.label),
            valueLabels: teamLeadTitles.map(value => {
                return { value: value, label: value }
            }),
            addAll: true
        }

        return config
    }

    // Sort by team lead lastName, firstName
    sortRows(rows) {
        const columnIndex = this.column.columnIndex
        const fakeLead = { firstName: 'Zzz', lastName: 'Zzz' }
        rows.sort((a, b) => {
            const valueA = a.listColumns[columnIndex].teamLead || fakeLead
            const valueB = b.listColumns[columnIndex].teamLead || fakeLead

            return valueA.lastName.localeCompare(valueB.lastName) || valueA.firstName.localeCompare(valueB.firstName)
        })
    }

    /**
     * Each filterString is a titledFullNameWithRole/titledFullName of a potential clinician.
     * Simply do a substring match against the cellText, which is assumed to be a list of relevant clinicians.
     */
    filterRows(rows, filterStrings) {
        if (filterStrings.length == 0) {
            return rows
        }
        const columnIndex = this.column.columnIndex

        return rows.filter(row =>
            filterStrings.some(filterString => {
                if (row.listColumns[columnIndex].cellText.includes(filterString)) {
                    return true
                }
                if (_.isEmpty(row.listColumns[columnIndex].cellText)) {
                    // If patient row cellText does not include any of the selected clinician names,
                    // we assume their existence in the patient list is because the dash user
                    // invited them. So match on the filter being the dash user
                    const user = store.state.user.user
                    const userFullName = this.column.hideRole ? user.titledFullName : user.titledFullNameWithRole

                    return filterString == userFullName
                }

                return false
            })
        )
    }

    // Get a value suitable for use in request payload or query params, from the keyStrings value.
    getQueryParamValueFromKeyStrings(keyStrings, isGoddard) {
        // Each keyString is User.titledFullName or User.titledFullNameWithRole
        const userLastFirstNames = keyStrings.map(titledFullName => {
            const user = this.column.hideRole
                ? User.getUserByTitledFullName(titledFullName)
                : User.getUserByTitledFullNameWithRole(titledFullName)

            return user ? `${user.lastName} ${user.firstName}` : ''
        })

        return isGoddard ? { values: userLastFirstNames } : userLastFirstNames
    }

    // Get the SQL report parameter to sort by
    getSortParameter() {
        return 'lead_name'
    }
}

export default ListTransformTeamLead
