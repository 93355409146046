import _ from 'lodash'
import AlertCounter from '@comp/AlertCounter.vue'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import MilestoneService from '@serv/MilestoneService'
import NotifyService from '@serv/NotifyService'
import Patient from '@model/Patient'
import PopupOneButton from '@comp/popups/PopupOneButton.vue'
import Redirect from '@serv/Redirect'
import Request from '@serv/Request'
import TabCptRequestDetails from '@model/TabCptRequestDetails'
import Utils from '@serv/Utils'
import { mapGetters, mapState } from 'vuex'

export default {
    components: {
        AlertCounter
    },
    inject: ['$set'],
    props: {
        config: { type: Object, required: true }, // should be considered immutable
        /**
         * We can redirect the store to an external object,
         * e.g. for invite
         */
        storeRedirected: { type: Object, required: false }
    },
    data: () => ({
        title: '',
        subtitle: '',
        isSaveHidden: false,
        isSaveEnabled: false,
        isRightButtonHidden: true,
        isDisabled: false,
        isLoggingEnabled: false,

        store: {},
        storePrev: {},
        requestDetails: [],
        configLocal: {}, // copied from config on load
        rightButtonText: '',

        showTaskAlert: false,
        // Private
        _isMounted: false
    }),
    computed: {
        ...mapGetters(['departments', 'owner', 'patients', 'patientJourneys', 'user']),
        ...mapState({
            selectedPatientJourney: state => state.data.selectedPatientJourney
        }),
        isTabCpt() {
            return true
        },
        personaId() {
            // Used for patients
            const personaId = parseInt(this.$route.params.id)

            return isNaN(personaId) ? undefined : personaId
        },
        personaUid() {
            return this.$route.params.id
        },
        patientId() {
            return this.personaId
        },
        department() {
            return this.departments[this.$route.params.id]
        },
        patient() {
            if (this.checkRoute('DepartmentPage') || !this.patients) {
                return undefined
            }

            return this.patients[this.personaId]
        },
        patientJourney() {
            return this.selectedPatientJourney || (this.patient || {}).firstJourney
        },
        clinician() {
            return this.personaUid && this.surgeons ? this.surgeons[this.personaUid] : undefined
        },
        persona() {
            return this.checkRoute('ClinicianProfile') ? this.clinician : this.patient
        },
        getDelegate() {
            return this
        }
    },
    methods: {
        // Should the component configure itself for create (invite patient, add patient journey) or update?
        // This does not fully work as a computed - there are some instances where the route is /new, but a component
        // isCreate has not been updated from false.
        isCreate() {
            return this.$route.path.includes('new') || this.$route.path.includes('add-pathway')
        },
        hasReadOnlyPatientDash(key) {
            const ownerLevelReadOnlyFields = [
                'dob',
                'firstName',
                'hospitalNumber',
                'lastName',
                'procedureDate',
                'procedureLabel',
                'sex',
                'side'
            ]

            const patientLevelReadOnlyFields = [
                'dob',
                'firstName',
                'hospitalNumber',
                'lastName',
                'procedureLabel',
                'sex',
                'side'
            ]

            if (this.owner.hasReadOnlyPatientDash) {
                return ownerLevelReadOnlyFields.includes(key)
            }

            return this.patient && this.patient.hasReadOnlyPatientDash && patientLevelReadOnlyFields.includes(key)
        },
        checkRoute(routeString) {
            return this.$route.name == routeString
        },
        isElementDisabled(key) {
            if (Array.isArray(this.configLocal.elementsDisabled)) {
                return !!(this.configLocal.elementsDisabled || []).includes(key) || this.hasReadOnlyPatientDash(key)
            }

            return !!(this.configLocal.elementsDisabled || {})[key] || this.hasReadOnlyPatientDash(key)
        },
        isElementHidden(key) {
            return (this.configLocal.elementsHidden || []).includes(key)
        },
        isElementRequired(key) {
            if (this.isElementDisabled(key)) {
                return false // we can't be required _and_ disabled
            }

            return (this.config.elementsRequired || []).includes(key)
        },
        allRequestsComplete() {
            const incomplete = this.requestDetails.filter(requestDetail => requestDetail.complete == false)

            return incomplete.length == 0
        },
        hasStoreChanged() {
            return !_.isEqual(this.store, this.storePrev)
        },
        copyStore(storeKey) {
            if (storeKey == undefined) {
                this.storePrev = _.cloneDeep(this.store)
            } else {
                this.storePrev[storeKey] = _.cloneDeep(this.store[storeKey])
            }
        },
        disableIfPatientJourneyInactive() {
            const disabled = this.selectedPatientJourney && !this.selectedPatientJourney.isActive
            this.isDisabled = disabled
            this.isSaveHidden = disabled
        },
        /**
         * Implement in subclass to perform form validation, whereby Save button is only enabled for valid form.
         */
        isValid() {
            return true
        },
        /**
         * Subcomponent should call updated whenever fields are updated.
         * Manages enabled state of save button
         */
        storeUpdated() {
            if (this.hasStoreChanged()) {
                this.isSaveEnabled = this.isValid()
                if (this.isLoggingEnabled) {
                    Logging.log(`storeUpdated, changed to: ${JSON.stringify(this.store, null, 2)}`)
                }
            } else {
                this.isSaveEnabled = false
                if (this.isLoggingEnabled) {
                    Logging.log('storeUpdated, not changed')
                }
            }
        },
        // saveStore() helper - every field copied from payload to specified object.
        saveStorePayloadFieldsOnObject(payload, obj) {
            if (obj instanceof Patient) {
                payload.personaId = obj.personaId
                this.$store.commit('updatePatient', payload)
            } else {
                payload.personaUid = obj.personaUid
                this.$store.commit('updateClinician', payload)
            }
        },
        // Get single RequestDetails object matching the response.
        getRequestDetailsFromResponse(response) {
            let matchingDetails = this.requestDetails.filter(requestDetail => {
                const url = Request.replaceUrlParams(requestDetail.url, (requestDetail.config || {}).params || {})

                return url == (response.config || {}).url
            })
            if (matchingDetails.length == 0) {
                Logging.error(`Found 0 matching request details for response URL: ${response.config.url}`)
            }
            if (matchingDetails.length > 1) {
                // This can happen if posting or patching multiple milestones in a single Save
                Logging.warn(
                    `Found ${matchingDetails.length} matching request details for response URL: ${response.config.url}. Attempting to uniquely identify...`
                )
                // Attempt to drill down to the correct one
                if (response.data && response.data.slug) {
                    matchingDetails = matchingDetails.filter(
                        requestDetail => requestDetail.payload.slug == response.data.slug
                    )
                }
            }
            if (matchingDetails.length != 1) {
                Logging.error(`Could not uniquely identify RequestDetails from response.data.slug`)

                return
            }

            return matchingDetails[0]
        },
        // Set the request to complete, and enable the component only if all requests complete.
        setRequestComplete(requestDetails) {
            requestDetails.complete = true
            if (this.allRequestsComplete()) {
                this.requestDetails = []
                this.isDisabled = false
                this.onAllRequestsComplete()
            }
        },
        // Cpt can override to provide specific fallback behaviour
        onRequestDetailsError(requestDetails, error) {
            NotifyService.errorWithResponse(error.response)
        },
        onRequestError(error) {
            if (!error.response) {
                this.isDisabled = false
                NotifyService.error(error)

                return
            }
            const requestDetails = this.getRequestDetailsFromResponse(error)
            if (!requestDetails) {
                this.isDisabled = false
                NotifyService.error(error)

                return
            }
            this.onRequestDetailsError(requestDetails, error)
            this.setRequestComplete(requestDetails)
        },
        onRequestSuccess(response) {
            const requestDetails = this.getRequestDetailsFromResponse(response)
            if (requestDetails == undefined) {
                return
            }
            if (this.isLoggingEnabled) {
                Logging.log(`Success: store updated (for key: ${requestDetails.storeKey})`)
            }
            // Copy our local view store current->previous
            this.copyStore(requestDetails.storeKey)

            if (this.isCreate()) {
                Logging.warn('MUST CHANGE THIS! WE DO NOT WANT TO RE-REQUEST THE WHOLE PATIENT LIST AFTER INVITE')
                if (this.patient) {
                    this.dispatchPatientlist()
                }
                const langStr = this.patient ? 'invitePatientSuccessInvite' : 'inviteClinicianSuccessInvite'
                NotifyService.success(Locale.getLanguageItem(langStr, [`${this.persona.fullName}`]))
                this.redirectLocation()
            } else {
                this.saveStore(requestDetails.payload, requestDetails.storeKey, response)
                // We do NOT show a success notification - we leave to onAllRequestsComplete
            }
            this.setRequestComplete(requestDetails)
        },
        redirectLocation() {
            if (this.addAnother) {
                window.location.reload()
            } else {
                Redirect.gotoPatientListPageForOwner(this.owner)
            }
        },
        /**
         * Called when Save button is pressed. Superclass may perform other tasks first, such as validation.
         * NOTE: For Corin, the superclass check of this.errors may need some sort of debouncing such as
         * setTimeout(() => { if errors... }
         */
        onSave() {
            this.isDisabled = true
            this.isSaveEnabled = false

            // Get requests as a map of URL to payload
            this.requestDetails = this.getRequestDetails ? this.getRequestDetails() : []

            if (this.requestDetails.length <= 0) {
                this.isDisabled = false
                this.isSaveEnabled = true
                Logging.warn('Nothing to save, not saving form.')

                return
            }

            // Make all requests
            const promises = []
            this.requestDetails.forEach(requestDetail => {
                requestDetail.complete = false
                switch (requestDetail.verb) {
                    case Request.Verb.patch:
                        promises.push(
                            Request.patch(requestDetail.url, requestDetail.payload, requestDetail.config).then(
                                this.onRequestSuccess,
                                this.onRequestError
                            )
                        )
                        break

                    case Request.Verb.post:
                        promises.push(
                            Request.post(requestDetail.url, requestDetail.payload, requestDetail.config).then(
                                this.onRequestSuccess,
                                this.onRequestError
                            )
                        )
                        break

                    case Request.Verb.put:
                        promises.push(
                            Request.put(requestDetail.url, requestDetail.payload, requestDetail.config).then(
                                this.onRequestSuccess,
                                this.onRequestError
                            )
                        )
                        break

                    case Request.Verb.delete:
                        promises.push(
                            Request.delete(requestDetail.url, requestDetail.config).then(
                                this.onRequestSuccess,
                                this.onRequestError
                            )
                        )
                        break
                }
            })
            Promise.all(promises).then(() => {
                // All promises completed, each will have already called its own success/error callback
                if (this.owner.hasPatientJourneyList && this.patient) {
                    Request.updateDwh({
                        patientJourneyId: this.patientJourney.patientJourneyId
                    })
                }
            })
        },
        // eslint-disable-next-line no-empty-function
        onRightButton() {},
        // Merge Patient.keyValues and PatientJourney.keyValues, from store and current models, ready for PATCH
        mergeStoreKeyValues() {
            // Patient.keyValues
            if (this.store.keyValues) {
                this.store.keyValues = this.patient
                    ? _.merge(this.patient.keyValues, this.store.keyValues)
                    : this.store.keyValues
            }
            // PatientJourney.keyValues
            if ((this.store.journey || {}).keyValues) {
                this.store.journey.keyValues = this.patientJourney
                    ? _.merge(this.patientJourney.keyValues, this.store.journey.keyValues)
                    : this.store.journey.keyValues
            }
        },
        /**
         * Helper to get an array of TabCptRequestDetails from:
         * - store.journey, which must hold all fields required to PATCH the PatientJourney, named correctly
         * - store.milestoneDates, which must hold a map of slugs to dates for Milestones of generic type (PATCH or POST)
         */
        getRequestDetailsForPatientJourneyAndMilestones() {
            const requestDetails = []
            if (this.store.journey && !_.isEqual(this.store.journey, this.storePrev.journey)) {
                // journey fields changed
                // Perform simple iteration over journey properties
                // payload assumes field names are identical to those for the PATCH
                const payload = Utils.getObjectsDiff(this.store.journey, this.storePrev.journey)
                requestDetails.push(
                    new TabCptRequestDetails({
                        verb: Request.Verb.patch,
                        url: Request.Stem.patientJourney,
                        payload: payload,
                        storeKey: 'journey',
                        config: {
                            params: {
                                patientId: this.patient.personaId,
                                patientJourneyId: this.selectedPatientJourney.patientJourneyId
                            },
                            patient: this.patient
                        }
                    })
                )
            }
            const milestoneDates = this.store.milestoneDates || {}
            Object.keys(milestoneDates).forEach(milestoneSlug => {
                if (
                    this.storePrev.milestoneDates == undefined ||
                    !_.isEqual(this.store.milestoneDates[milestoneSlug], this.storePrev.milestoneDates[milestoneSlug])
                ) {
                    // milestone field changed
                    const milestone = this.selectedPatientJourney.getMilestoneOfSlug(milestoneSlug)

                    // An empty date must be 'null' NOT 'undefined'
                    const payloadDate =
                        this.store.milestoneDates[milestoneSlug] === undefined
                            ? null
                            : this.store.milestoneDates[milestoneSlug]

                    // Global or pathway milestone?
                    const patientJourneyId = MilestoneService.isMilestoneSlugGlobal(milestoneSlug)
                        ? this.patient.globalJourney.patientJourneyId
                        : this.selectedPatientJourney.patientJourneyId

                    if (milestone == undefined && payloadDate !== null) {
                        // POST new milestone with date
                        const payload = {
                            slug: milestoneSlug,
                            date: payloadDate
                        }
                        if (milestoneSlug == 'appointment') {
                            payload.type = Milestone.Type.appointment
                            payload.appointmentType = 'jhubPhysio' // TODO change when BE becomes string field
                        } else {
                            payload.type = Milestone.Type.default
                        }
                        requestDetails.push(
                            new TabCptRequestDetails({
                                verb: Request.Verb.post,
                                url: Request.Stem.patientJourneyMilestones,
                                payload: payload,
                                storeKey: milestoneSlug,
                                config: {
                                    params: {
                                        patientJourneyId: patientJourneyId
                                    },
                                    patient: this.patient
                                }
                            })
                        )
                    } else if (payloadDate !== null) {
                        // PATCH existing milestone date
                        requestDetails.push(
                            new TabCptRequestDetails({
                                verb: Request.Verb.patch,
                                url: Request.Stem.patientJourneyMilestone,
                                payload: {
                                    date: payloadDate
                                },
                                storeKey: milestoneSlug,
                                config: {
                                    params: {
                                        patientJourneyId: patientJourneyId,
                                        milestoneId: milestone.id
                                    },
                                    patient: this.patient
                                }
                            })
                        )
                    } else if (milestone != undefined) {
                        // DELETE existing milestone
                        requestDetails.push(
                            new TabCptRequestDetails({
                                verb: Request.Verb.delete,
                                url: Request.Stem.patientJourneyMilestone,
                                storeKey: milestoneSlug,
                                config: {
                                    params: {
                                        patientJourneyId: patientJourneyId,
                                        milestoneId: milestone.id
                                    },
                                    patient: this.patient
                                }
                            })
                        )
                    }
                }
            })

            return requestDetails
        },
        /**
         * Checks for changes in primary patient journey
         */
        verifyPrimaryPatientJourney(response) {
            if (
                response.data.primaryPatientJourneyId &&
                response.data.primaryPatientJourneyId != this.patient.primaryPatientJourneyId
            ) {
                const primaryPatientJourney = this.patientJourneys[response.data.primaryPatientJourneyId]
                if (primaryPatientJourney) {
                    // We have visibility of the new primary journey
                    this.patient.changePrimaryJourney(primaryPatientJourney)
                } else {
                    // We have no visibility of the new primary journey
                    this.patient.changePrimaryJourney(undefined)
                }
                this.displayPrimaryPathwayChangedPopup(response.data.primaryPatientJourneyId)
            }
        },
        /**
         * Helper to store a success response payload, either on the patient journey, or Milestones of
         * generic type.
         */
        saveStoreForPatientJourneyAndMilestones(payload, storeKey, response) {
            const milestoneDates = this.store.milestoneDates || {}
            if (storeKey == 'journey') {
                // PatientJourney fields
                payload.patientJourneyId = this.selectedPatientJourney.patientJourneyId
                this.$store.commit('updatePatientJourney', payload)
                this.verifyPrimaryPatientJourney(response)

                return
            }
            // Milestones
            const milestoneSlug = storeKey
            if (Object.keys(milestoneDates).includes(milestoneSlug)) {
                if (response.config.method == 'delete') {
                    // We have deleted a milestone
                    const milestone = this.patient.getMilestoneOfId(response.data.id)
                    if (milestone) {
                        this.selectedPatientJourney.removeMilestone(milestone)
                    }
                } else {
                    // We have created or updated a milestone
                    const milestoneId = response.data.patientJourneyMilestoneId
                    let milestone = this.patient.getMilestoneOfId(milestoneId)
                    if (milestone == undefined) {
                        // We cannot find the milestone with the returned ID, so create it
                        milestone = new Milestone({
                            slug: milestoneSlug,
                            id: milestoneId,
                            date: payload.date // Required because adding milestone will trigger rebuild without date set and setMilestoneOfSlugDate() never executed
                        })
                        this.selectedPatientJourney.addMilestone(milestone)
                    }
                    // All milestone date updates, when successful, come here (for POST or PATCH).
                    this.selectedPatientJourney.setMilestoneOfSlugDate(milestoneSlug, payload.date)
                }
                this.verifyPrimaryPatientJourney(response)

                return
            }

            Logging.error(
                `Saving success response, but storeKey did not match 'journey' or any milestone slugs: ${Object.keys(
                    milestoneDates
                )}`
            )
        },
        /**
         * Given a new primary patientJourney ID, display a popup informing the user that it's changed.
         */
        displayPrimaryPathwayChangedPopup(primaryPatientJourneyId) {
            const primaryPatientJourney = this.patientJourneys[primaryPatientJourneyId]
            let popupTextPrefix
            if (primaryPatientJourney) {
                // Dash user has visibility of new primary patientJourney
                const primaryJourney = this.journeys[primaryPatientJourney.journeySlug]
                popupTextPrefix = Locale.getLanguageItem('patientJourneyPrimaryChangedPopupTextVisible', [
                    this.patient.fullName,
                    primaryJourney.title
                ])
            } else {
                // Dash user has no visibility of new primary patientJourney
                popupTextPrefix = Locale.getLanguageItem('patientJourneyPrimaryChangedPopupTextInvisible', [
                    this.patient.fullName
                ])
            }
            const popupTextSuffix = Locale.getLanguageItem('patientJourneyPrimaryChangedPopupTextSuffix')
            this.$store.commit('popup/setConfig', {
                title: Locale.getLanguageItem('patientJourneyPrimaryChangedPopupTitle'),
                text: `${popupTextPrefix}\n\n${popupTextSuffix}`,
                buttonText: Locale.getLanguageItem('ok')
            })
            this.$store.commit('popup/setClass', PopupOneButton)
        },
        dismissPopup() {
            this.$store.commit('popup/dismiss')
        },
        onAllRequestsComplete() {
            const langStr = this.patient ? 'invitePatientSuccessSave' : 'inviteClinicianSuccessSave'
            if (this.persona) {
                NotifyService.success(Locale.getLanguageItem(langStr, [`${this.persona.fullName}`]))
            }
        },
        /**
         * Pause vee-validate validator until next tick.
         * This is typically called at the start of a watch on a reactive data property that in turn
         * may cause the destruction of a component that is using v-validate. Without this, vee-validate
         * may log an error that it tried to validate an unknown component.
         */
        // PING CHECK END OF VALIDATION IMPL
        pauseValidatorUntilNextTick() {
            // this.$validator.pause()
            // this.$nextTick(() => {
            //     this.$validator.reset()
            //     this.$validator.resume()
            // })
        },
        /**
         * Prepare the store to be reactive to the required sub-objects.
         * We can optionally pass a config object:
         * - config.patientKeyValues are set on store.keyValues
         * - config.patientJourneyKeyValues are set on store.journey.keyValues
         */
        prepareStore(config) {
            this.$set(this.store, 'keyValues', {})
            this.$set(this.store, 'milestoneDates', {})
            this.$set(this.store, 'journey', {})
            this.$set(this.store.journey, 'keyValues', {})

            config = config || {}
            config.patientKeyValues = config.patientKeyValues || []
            config.patientJourneyKeyValues = config.patientJourneyKeyValues || []

            const patient = this.patient || { keyValues: {} }

            let patientJourney = { keyValues: {} }
            if (this.patientJourney && !this.isCreate()) {
                patientJourney = this.patientJourney
            }

            config.patientKeyValues.forEach(key => {
                this.$set(this.store.keyValues, key, patient.keyValues[key])
            })
            config.patientJourneyKeyValues.forEach(key => {
                this.$set(this.store.journey.keyValues, key, patientJourney.keyValues[key])
            })
        },
        // eslint-disable-next-line no-empty-function
        loadStore() {}
    },
    created() {
        this.configLocal = _.clone(this.config)

        if (this.configLocal) {
            this.configLocal.elementsDisabled = this.configLocal.elementsDisabled || {}
        }

        // For component to implement:
        if (this.isLoggingEnabled) {
            Logging.log('created, calling loadStore')
        }
        if (!this.isCreate()) {
            this.loadStore()
        }

        // Copy the store we have just set up into storePrev
        if (this.isLoggingEnabled) {
            Logging.log('created, calling copyStore')
        }
        this.copyStore()
    },
    mounted() {
        if (this.isLoggingEnabled) {
            Logging.log('mounted')
        }
        if (this.storeRedirected) {
            this.store = this.storeRedirected
        }
        this.isSaveHidden = this.isCreate()
        // Save button always starts as disabled
        this.isSaveEnabled = false
        this._isMounted = true
        if (this.isCreate()) {
            this.loadStore()
        }
    },
    watch: {
        // Deep-watch on the store, handler called when any property changed
        store: {
            handler: function () {
                if (!this.isCreate() && this._isMounted) {
                    this.storeUpdated()
                }
            },
            deep: true
        }
    }
}
