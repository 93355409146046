<template>
    <div
        :key="`row-${resetId}`"
        :class="['patient-surveys-listview-row', {
            'patient-surveys-listview-row--disabled': !!taskHandler || scheduleEvent.isLatestResultCompleteFixed,
            'patient-surveys-listview-row--selected': isSurveyInProgress
        }]"
        @click="delegate.onClickActivityRow(patientJourneyId, scheduleEvent)"
    >
        <div
            class="patient-surveys-listview-row__title"
            :class="getClass"
        >
            {{ getTitle }}
            <div
                class="patient-surveys-listview-row__title-small"
                :class="getClass"
            >
                {{ getQuestionCount }}
            </div>
        </div>

        <img
            v-if="getIcon.src"
            class="patient-surveys-listview-row__icon"
            :class="getIcon.class"
            :src="getIcon.src"
        />
    </div>
</template>

<script>
/*
A single row of the web surveys list view.
*/
import ConfigManager from '@config/ConfigManager'
import ContentService from '@serv/ContentService'
import Journey from '@model/Journey'
import Locale from '@serv/Locale'
import { mapGetters } from 'vuex'
import ScheduleEvent from '@model/ScheduleEvent'
import SurveysMixin from '@mixins/SurveysMixin'
import useImage from '@composables/useImage'

export default {
    name: 'SurveysListViewRow',
    setup() {
        const { getIconUrl } = useImage()

        return {
            getIconUrl
        }
    },
    mixins: [SurveysMixin],
    props: {
        patientJourneyId: { type: Number, required: true },
        scheduleEvent: { type: Object, required: true },
        delegate: { type: Object, required: true }
    },
    data: () => ({
        resetId: 0
    }),
    computed: {
        ...mapGetters(['journeys', 'patientJourneys', 'surveys']),
        activity() {
            return this.scheduleEvent.activity
        },
        isSurveyInProgress() {
            return this.taskHandler && this.taskHandler.activity == this.activity
        },
        isScheduleEventPartial() {
            return this.scheduleEvent.status == ScheduleEvent.Status.partial
        },
        getClass() {
            // Return class to highlight if we're the survey in progress
            return this.isSurveyInProgress
                ? 'patient-surveys-listview-row__title-bold'
                : undefined
        },
        getIcon() {
            if (this.scheduleEvent.isComplete) {
                if (this.scheduleEvent.isLatestResultCompleteFixed) {
                    // Complete, can't be appended
                    return {
                      src: this.getIconUrl('icon-tick-filled.svg'),
                      class: 'svg--green'
                    }
                }

                // Complete, can be appended
                return {
                  src: this.getIconUrl('icon-tick-filled.svg'),
                  class: 'svg--brandA'
                }
            }

            if (this.isScheduleEventPartial || this.isSurveyInProgress) {
                // Partial result exists (survey may be in progress)
                return {
                    src: this.getIconUrl('icon-pending.svg'),
                    class: 'svg--grey'
                }
            }

            // Survey not yet started
            return { src: undefined, class: '' }
        },
        getQuestionCount() {
            const survey = this.surveys[this.activity.contentSlug]
            const numSteps = survey.questionStepSlugs.length
            const questionsSummary =
                numSteps == 1
                    ? Locale.getLanguageItem('patientSurveyQuestion')
                    : Locale.getLanguageItem('patientSurveyQuestions', [numSteps])

            return questionsSummary
        },
        getTitle() {
            const survey = this.surveys[this.activity.contentSlug]
            if (ConfigManager.devConfig.patientSurveysRandomAccess) {
                return survey.slug
            }
            let surveyTitle = ContentService.sanitiseText(survey.title, this.patient)
            if (ConfigManager.devConfig.patientSurveysTitleSlug) {
                surveyTitle += ` ${survey.slug}`
            }

            // Bilateral?
            const patientJourney = this.patientJourneys[this.patientJourneyId]
            if (this.activity && patientJourney) {
                const journey = this.journeys[patientJourney.journeySlug]
                const side = this.activity.getBilateralSide(journey)
                if (side == Journey.Side.left || side == Journey.Side.right) {
                    surveyTitle += ` ${Locale.getLanguageItem(
                        'surveyBilateralTitleSuffix',
                        [
                            Locale.getLanguageItem(
                                side == Journey.Side.left ? 'left' : 'right'
                            )
                        ]
                    )}`
                }
            }

            return `${surveyTitle}`
        }
    },
    watch: {
        activity: {
            handler() {
                this.resetId++
            },
            deep: true
        }
    }
}
</script>
