import InfoStep from '@model/content/InfoStep'
import Journey from '@model/Journey'
import Locale from '@serv/Locale'
import store from '@/store'

import TaskHandler from '@serv/TaskHandler'

let singleton = undefined

/**
 * Singleton TaskUi object.
 * Constructed once only.
 */
class TaskUi {
    constructor() {
        this.reset()
    }

    /**
     * Because we instantiate this class once only (NOT at the start of each survey), we need to reset state at the
     * start of each survey.
     */
    reset() {
        this.shouldHidePrevButton = false

        // View-Models that can be set
        this.stepTitles = {}
        this.stepTexts = {}
    }

    //-------------------------------------------------------------------------
    // Private functions, used only within this file
    //-------------------------------------------------------------------------

    //-------------------------------------------------------------------------
    // JavaScript-native interface.
    //-------------------------------------------------------------------------
    static get() {
        return singleton
    }

    /**
     * Web UI code should always use this to get the current step title.
     *
     * NOTE: Mobile app handles bilateral modifications by calling TaskPresenter.amendSurveyForBilateral() when survey is started.
     * This is not a great solution, as it means the base content is being repeatedly modified.
     * Instead here we do it dynamically, assuming TaskHandler.activity provides the relevant info.
     */
    getStepTitle() {
        const step = TaskHandler.get().currentStep
        let title = this.stepTitles[step.slug] || step.title

        // Bilateral?
        const activity = TaskHandler.get().activity
        const patientJourney = TaskHandler.get().patientJourney
        if (activity && patientJourney) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]
            const side = activity.getBilateralSide(journey)
            if (side == Journey.Side.left || side == Journey.Side.right) {
                title += ` ${Locale.getLanguageItem('surveyBilateralTitleSuffix', [
                    Locale.getLanguageItem(side == Journey.Side.left ? 'left' : 'right')
                ])}`
            }
        }

        return title
    }

    // JS script can use this to set the current step title.
    setStepTitle(title) {
        const step = TaskHandler.get().currentStep
        this.stepTitles[step.slug] = title
    }

    /**
     * Web UI code should always use this to get the current step text.
     *
     * NOTE: Mobile app handles bilateral modifications by calling TaskPresenter.amendSurveyForBilateral() when survey is started.
     * This is not a great solution, as it means the base content is being repeatedly modified.
     * Instead here we do it dynamically, assuming TaskHandler.activity provides the relevant info.
     */
    getStepText() {
        const step = TaskHandler.get().currentStep
        let text = this.stepTexts[step.slug] || step.text

        // Bilateral?
        if (TaskHandler.get().stepIndex == 0 && step instanceof InfoStep) {
            const activity = TaskHandler.get().activity
            const patientJourney = TaskHandler.get().patientJourney
            if (activity && patientJourney) {
                const journey = store.state.resources.journeys[patientJourney.journeySlug]
                const side = activity.getBilateralSide(journey)
                if (side == Journey.Side.left || side == Journey.Side.right) {
                    text += ` **${Locale.getLanguageItem(
                        side == Journey.Side.left ? 'surveyBilateralIntroSuffixLeft' : 'surveyBilateralIntroSuffixRight'
                    )}**`
                }
            }
        }

        return text
    }

    // JS script can use this to set the current step text.
    setStepText(text) {
        const step = TaskHandler.get().currentStep
        this.stepTexts[step.slug] = text
    }

    setPrevButtonHidden(state) {
        this.shouldHidePrevButton = state
    }

    showSimpleAlert(title, text) {
        // Display popup with specified title/text
        // TODO i18n
        store.commit('popup/setConfig', {
            title: title,
            text: text,
            buttonText: Locale.getLanguageItem('ok')
        })
        store.commit('popup/setClass', 'PopupOneButton')
    }
}
singleton = new TaskUi()

export default TaskUi
