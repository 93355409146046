import { defineStore } from 'pinia'
import { ref } from 'vue'
import RequestGroup from '@serv/RequestGroup'
import { UserType } from '@/models/UserType'

export const useAdminStore = defineStore('admin', () => {
    const requestGroup = ref<RequestGroup | undefined>(undefined)

    const setAdminRequestGroup = (object: RequestGroup) => {
        requestGroup.value = object
    }
    const updateAdminRequestGroupProgress = () => {
        if (requestGroup.value) {
            requestGroup.value.updateDownloadProgress()
        }
    }

    const adminUsers = ref<UserType[]>([])

    const setAdminUsers = (users: UserType[]) => {
        adminUsers.value = users
    }

    function $reset() {
        requestGroup.value = undefined
        adminUsers.value = []
    }

    return {
        adminUsers,
        requestGroup,
        setAdminRequestGroup,
        setAdminUsers,
        updateAdminRequestGroupProgress,
        $reset
    }
})
