let ConfigOptions = {
    mock: {
        gb: {
            // Base URL will be changed to /mock when we implement
            // mocking of the POST/PUT requests.
            base_url: '.',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'client',
            oauth_secret: 'secret'
        }
    },
    spoof: {
        gb: {
            base_url: 'https://gb.api.myrecovery.ai',
            ws_url: 'wss://gb.api.myrecovery.ai',
            oauth_client: 'to5iJztkiePBhcfaZWXZCsyg4IRFsjEFOzYRSteU',
            oauth_secret:
                'KsWVOy6X9twiptFrgg50bpMSzKhi6f9TerIBsMSMFmIE7Dqa24hPgb38o28fTiqfDwPgGUdlY91jxxg3hkHo8sljwMDyQsnmKQLJ1k54EnU7zSweSWAgUO3iEVOUDH94'
        },
        us: {
            base_url: 'https://us.api.myrecovery.ai',
            ws_url: 'wss://us.api.myrecovery.ai',
            oauth_client: 'l6ZSHme4oQJArUXtZT4ZVjyZOtZ8QUUVFZk6OUTI',
            oauth_secret:
                'eUS2zzQ820DVblS0rvaF41RxWAn8uzWyaDLpavjQ9NFRsxU6hjUge0Culy8JMajeP9vwAsTtH0VI9QXPY8JVfMGmYM84JJ0n4YwsnsAKrORtosV0rxmRUUGZ7b6QcP2u'
        },
        fr: {
            base_url: 'https://fr.api.myrecovery.ai',
            ws_url: 'wss://fr.api.myrecovery.ai',
            oauth_client: 'to5iJztkiePBhcfaZWXZCsyg4IRFsjEFOzYRSteU',
            oauth_secret:
                'KsWVOy6X9twiptFrgg50bpMSzKhi6f9TerIBsMSMFmIE7Dqa24hPgb38o28fTiqfDwPgGUdlY91jxxg3hkHo8sljwMDyQsnmKQLJ1k54EnU7zSweSWAgUO3iEVOUDH94'
        }
    },
    local: {
        gb: {
            base_url: 'http://localhost:8000/gb',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'ANF1TuJfpr0tDMEcxQ9gwARyJn69ddWsYOiTSInb',
            oauth_secret:
                '7T8VNxQI87uk29eALHsMefntl2NfVFHKF5RLRyjZYSxqPPdkXWCjWPpVzcCN1C8S2RirnywEQj2StiVYvSWHP6KdjYHXYMBQxVT4HqIOo3xEld3OHuykTy7r0ZVCtZOa'
        },
        fr: {
            base_url: 'http://localhost:8000/fr',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'ANF1TuJfpr0tDMEcxQ9gwARyJn69ddWsYOiTSInb',
            oauth_secret:
                '7T8VNxQI87uk29eALHsMefntl2NfVFHKF5RLRyjZYSxqPPdkXWCjWPpVzcCN1C8S2RirnywEQj2StiVYvSWHP6KdjYHXYMBQxVT4HqIOo3xEld3OHuykTy7r0ZVCtZOa'
        },
        us: {
            base_url: 'http://localhost:8000/us',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'ANF1TuJfpr0tDMEcxQ9gwARyJn69ddWsYOiTSInb',
            oauth_secret:
                '7T8VNxQI87uk29eALHsMefntl2NfVFHKF5RLRyjZYSxqPPdkXWCjWPpVzcCN1C8S2RirnywEQj2StiVYvSWHP6KdjYHXYMBQxVT4HqIOo3xEld3OHuykTy7r0ZVCtZOa'
        }
    },
    testing: {
        // These are used in tests, change with care.
        gb: {
            base_url: 'http://localhost:8000/gb',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'GB-client',
            oauth_secret: 'secret'
        },
        us: {
            base_url: 'http://localhost:8000/us',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'US-client',
            oauth_secret: 'secret'
        }
    },
    coverage: {
        // These are used in tests, change with care.
        gb: {
            base_url: 'http://localhost:8000/gb',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'GB-client',
            oauth_secret: 'secret'
        },
        us: {
            base_url: 'http://localhost:8000/us',
            ws_url: 'ws://localhost:8000',
            oauth_client: 'US-client',
            oauth_secret: 'secret'
        }
    },
    development: {
        gb: {
            base_url: 'https://prm-dev-gb.myrecoverytest.com',
            ws_url: 'wss://prm-dev-gb.myrecoverytest.com',
            oauth_client: 'EYhVKGxMU3HANeL2SKqSCTQTBbKIWMV7lI8Ry0zX',
            oauth_secret:
                'J6fFBu4eYYrND2SthQ6MHymnUtlpKdUdpe8lIl9Tc7LJczru4Ue0JY8ek3E5BUYW0KnMUcAmcBfCZcSxXBqc5Tzp7u8YIrJkTWidtmkJTkpXfLjUCR7vShfXRZBTE6Tu'
        },
        fr: {
            base_url: 'https://prm-dev-fr.myrecoverytest.com',
            ws_url: 'wss://prm-dev-fr.myrecoverytest.com',
            oauth_client: 'EYhVKGxMU3HANeL2SKqSCTQTBbKIWMV7lI8Ry0zX',
            oauth_secret:
                'J6fFBu4eYYrND2SthQ6MHymnUtlpKdUdpe8lIl9Tc7LJczru4Ue0JY8ek3E5BUYW0KnMUcAmcBfCZcSxXBqc5Tzp7u8YIrJkTWidtmkJTkpXfLjUCR7vShfXRZBTE6Tu'
        },
        us: {
            base_url: 'https://prm-dev-us.myrecoverytest.com',
            ws_url: 'wss://prm-dev-us.myrecoverytest.com',
            oauth_client: 'EYhVKGxMU3HANeL2SKqSCTQTBbKIWMV7lI8Ry0zX',
            oauth_secret:
                'J6fFBu4eYYrND2SthQ6MHymnUtlpKdUdpe8lIl9Tc7LJczru4Ue0JY8ek3E5BUYW0KnMUcAmcBfCZcSxXBqc5Tzp7u8YIrJkTWidtmkJTkpXfLjUCR7vShfXRZBTE6Tu'
        }
    },
    qa: {
        gb: {
            base_url: 'https://prm-qa-gb.myrecoverytest.com',
            ws_url: 'wss://prm-qa-gb.myrecoverytest.com',
            oauth_client: 'ANF1TuJfpr0tDMEcxQ9gwARyJn69ddWsYOiTSInb',
            oauth_secret:
                '7T8VNxQI87uk29eALHsMefntl2NfVFHKF5RLRyjZYSxqPPdkXWCjWPpVzcCN1C8S2RirnywEQj2StiVYvSWHP6KdjYHXYMBQxVT4HqIOo3xEld3OHuykTy7r0ZVCtZOa'
        },
        fr: {
            base_url: 'https://prm-qa-fr.myrecoverytest.com',
            ws_url: 'wss://prm-qa-fr.myrecoverytest.com',
            oauth_client: 'ANF1TuJfpr0tDMEcxQ9gwARyJn69ddWsYOiTSInb',
            oauth_secret:
                '7T8VNxQI87uk29eALHsMefntl2NfVFHKF5RLRyjZYSxqPPdkXWCjWPpVzcCN1C8S2RirnywEQj2StiVYvSWHP6KdjYHXYMBQxVT4HqIOo3xEld3OHuykTy7r0ZVCtZOa'
        },
        us: {
            base_url: 'https://prm-qa-us.myrecoverytest.com',
            ws_url: 'wss://prm-qa-us.myrecoverytest.com',
            oauth_client: 'ANF1TuJfpr0tDMEcxQ9gwARyJn69ddWsYOiTSInb',
            oauth_secret:
                '7T8VNxQI87uk29eALHsMefntl2NfVFHKF5RLRyjZYSxqPPdkXWCjWPpVzcCN1C8S2RirnywEQj2StiVYvSWHP6KdjYHXYMBQxVT4HqIOo3xEld3OHuykTy7r0ZVCtZOa'
        }
    },
    staging: {
        gb: {
            base_url: 'https://prm-staging-gb.myrecoverytest.com',
            ws_url: 'wss://prm-staging-gb.myrecoverytest.com',
            oauth_client: 'ZQ7HUOpMuzQyGd6LF7jqbMCUdMvxsDWn3yPjSPVl',
            oauth_secret:
                'tRZnk8BSgvSBxuxQs4cHdNmS8fXZidOPtB0ypyuRTlxW9k0GbLOGX9iOdcj2QWS1jaCvpbzu4NQRRL9oMOVAc0HnoH2VvvVl5Te6j07Z1gCtFV7MIz94KjqZXQnyvZNt'
        }
    },
    demo: {
        gb: {
            base_url: 'https://prm-demo-gb.myrecoverytest.com',
            ws_url: 'wss://prm-demo-gb.myrecoverytest.com',
            oauth_client: 'ANF1TuJfpr0tDMEcxQ9gwARyJn69ddWsYOiTSInb',
            oauth_secret:
                '7T8VNxQI87uk29eALHsMefntl2NfVFHKF5RLRyjZYSxqPPdkXWCjWPpVzcCN1C8S2RirnywEQj2StiVYvSWHP6KdjYHXYMBQxVT4HqIOo3xEld3OHuykTy7r0ZVCtZOa'
        }
    },
    production: {
        gb: {
            base_url: 'https://gb.api.myrecovery.ai',
            ws_url: 'wss://gb.api.myrecovery.ai',
            oauth_client: 'to5iJztkiePBhcfaZWXZCsyg4IRFsjEFOzYRSteU',
            oauth_secret:
                'KsWVOy6X9twiptFrgg50bpMSzKhi6f9TerIBsMSMFmIE7Dqa24hPgb38o28fTiqfDwPgGUdlY91jxxg3hkHo8sljwMDyQsnmKQLJ1k54EnU7zSweSWAgUO3iEVOUDH94'
        },
        us: {
            base_url: 'https://us.api.myrecovery.ai',
            ws_url: 'wss://us.api.myrecovery.ai',
            oauth_client: 'l6ZSHme4oQJArUXtZT4ZVjyZOtZ8QUUVFZk6OUTI',
            oauth_secret:
                'eUS2zzQ820DVblS0rvaF41RxWAn8uzWyaDLpavjQ9NFRsxU6hjUge0Culy8JMajeP9vwAsTtH0VI9QXPY8JVfMGmYM84JJ0n4YwsnsAKrORtosV0rxmRUUGZ7b6QcP2u'
        },
        fr: {
            base_url: 'https://fr.api.myrecovery.ai',
            ws_url: 'wss://fr.api.myrecovery.ai',
            oauth_client: 'to5iJztkiePBhcfaZWXZCsyg4IRFsjEFOzYRSteU',
            oauth_secret:
                'KsWVOy6X9twiptFrgg50bpMSzKhi6f9TerIBsMSMFmIE7Dqa24hPgb38o28fTiqfDwPgGUdlY91jxxg3hkHo8sljwMDyQsnmKQLJ1k54EnU7zSweSWAgUO3iEVOUDH94'
        }
    }
}

export default Object.freeze(ConfigOptions)
