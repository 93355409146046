<template>
    <div
        class="survey-question-step-choice"
        @click="onClick"
    >
        <div
            class="survey-question-step-choice__text"
            :class="{ 'survey-question-step-choice__text--selected': isSelected }"
        >
            {{ getText }}
        </div>
        <div
            class="survey-question-step-choice__icon"
            :class="getCss"
        />
    </div>
</template>

<script>
export default {
    name: 'SurveyQuestionStepChoice',
    components: {},
    inject: ['$bus', '$config'],
    props: {
        delegate: { type: Object, required: true },
        choiceIndex: { type: Number, required: true },
        isSelected: { type: Boolean, required: true }
    },
    data() {
        return {
            status: undefined
        }
    },
    computed: {
        getText() {
            return this.delegate.step.choices[this.choiceIndex].text
        },
        getCss() {
            return this.status ? 'svg-selected' : 'svg-unselected'
        }
    },
    methods: {
        onClick() {
            this.delegate.onChoiceClicked(this.choiceIndex)
        },
        updateStatus() {
            this.status = this.delegate.choicesSelected[this.choiceIndex]
        }
    },
    created() {
        this.status = this.isSelected
        this.$bus.$on('surveyQuestionStepChoice', this.updateStatus)
    },
    unmounted() {
        document.removeEventListener('surveyQuestionStepChoice', this.updateStatus)
    }
}
</script>
