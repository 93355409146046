import BaseContent from '@model/content/BaseContent'
import Step from '@model/content/Step'
import TextChoice from '@model/content/TextChoice'

/*
"answerFormat": {
    "type": "textChoice",
    "values": [
        {
            "value": "0",
            "text": "Never"
        },
*/

class QuestionStep extends Step {
    constructor(object) {
        object.type = BaseContent.Type.question
        super(object)

        // JSON has 'answerFormat', we want array of choices
        const answerFormat = object.answerFormat || {}
        const jsonChoices = answerFormat.values || []
        this.choices = []
        this.choiceValueToText = {}
        jsonChoices.forEach(choice => {
            const textChoice = new TextChoice(choice)
            this.choices.push(textChoice)
            this.choiceValueToText[textChoice.value] = textChoice.text
        })
        this.requiredTextField = (object.answerFormat || {}).requiredTextField
        this.optionalTextField = (object.answerFormat || {}).optionalTextField

        this.minSelected = answerFormat.minSelected
        this.maxSelected = answerFormat.maxSelected
    }

    // Is this a multi-select step?
    get isMultiSelect() {
        return this.maxSelected != undefined && this.maxSelected > 0
    }

    // Is this step a "pure free-text" question?
    get isPureFreeText() {
        const result = this.choices.length == 1 && !!(this.requiredTextField || this.optionalTextField)

        return result
    }

    // Find the choice index of the specified optionalTextField, or -1
    get optionalTextFieldChoiceIndex() {
        return this.choices.findIndex(choice => choice.text == this.optionalTextField)
    }

    // Find the choice index of the specified requiredTextField, or -1
    get requiredTextFieldChoiceIndex() {
        return this.choices.findIndex(choice => choice.text == this.requiredTextField)
    }

    // Find the choice index of the specified free text field, or -1
    get textFieldChoiceIndex() {
        return this.optionalTextFieldChoiceIndex >= 0
            ? this.optionalTextFieldChoiceIndex
            : this.requiredTextFieldChoiceIndex
    }
}

export default QuestionStep
