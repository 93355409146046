import Logging from '@serv/Logging'
import moment from 'moment/moment'
import Patient from '@model/Patient'
import store from '@/store'
import Utils from '@serv/Utils'

function resolveColumnFromDateTime(column, datetime) {
    const nowMoment = moment()
    const lastActiveMoment = moment(datetime)
    const diffText = Utils.getMomentDiffSummary(nowMoment, lastActiveMoment)
    column.cellText = lastActiveMoment.format(Utils.readableDateFormat)
    column.cellSubtitle = diffText
}
export function _resolveObjectListColumnOfType_lastActive(column, row) {
    if (!(row instanceof Patient)) {
        Logging.error(`ListColumn.Type.lastActive can only be used on objects of type: Patient`)

        return
    }
    const report = store.state.resources.reports && store.state.resources.reports['last-active-datetime']
    if (report) {
        const lastActiveTimeObj = report.dataset.filter(data => data.patientId == row.patientId)[0]
        const lastActiveTime = lastActiveTimeObj && lastActiveTimeObj.datetime
        if (lastActiveTime) {
            resolveColumnFromDateTime(column, lastActiveTime)
        }
    }
}
export function _resolvePatientJourneyListColumnOfType_lastActive(column, patientJourney, row, errors, isGoddard) {
    const lastActiveTime = isGoddard && row[column.id] ? row[column.id].time : row.lastActiveTime

    if (lastActiveTime) {
        resolveColumnFromDateTime(column, lastActiveTime)
    }
}
