import Clinician from '@model/Clinician'
import User from '@model/User'

/**
 * Surgeon object.
 */
class Surgeon extends Clinician {
    constructor(object) {
        object.persona = User.Persona.clinician
        object.role = User.Role.surgeon
        super(object)

        this.surgeonId = object.id

        this.temporaryPassword = object.temporaryPassword

        this.locale = object.language || object.locale
    }
}

export default Surgeon
